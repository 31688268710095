<template>
  <div :class="{ loader: true, fadeout: !show }">
    <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/splashscreen/dark-theme/innoxel-logo-white.svg" />
    <img v-else src="@/assets/splashscreen/light-theme/innoxel-logo-black.svg" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SplashScreen',
  props: {
    show: Boolean
  },
  data() {
    return {
      customizedViewportHeight: 0,
      resizeTimer: null
    }
  },
  mounted() {
    this.calculateViewPortHeight()

    window.addEventListener('resize', this.resizeHandler)
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  computed: {
    ...mapGetters(['getBrowserType', 'getUsedTheme'])
  },
  methods: {
    resizeHandler() {
      if (this.show) {
        this.clearResizeTimer()
        this.runResizeTimer()
      }
    },
    runResizeTimer() {
      let count = 0

      this.resizeTimer = setInterval(() => {
        if (count <= 20) {
          this.calculateViewPortHeight()
          count += 1
        } else {
          this.clearResizeTimer()
        }
      }, 50)
    },
    clearResizeTimer() {
      if (this.resizeTimer != null) {
        clearInterval(this.resizeTimer)
        this.resizeTimer = null
      }
    },
    calculateViewPortHeight() {
      // See also https://css-tricks.com/the-trick-to-viewport-units-on-mobile
      let vh = window.innerHeight * 0.01
      if (this.customizedViewportHeight != vh) {
        this.customizedViewportHeight = vh
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      }
    }
  }
}
</script>

<style scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: var(--background-color-primary);
  z-index: 10;
  position: fixed;
}

.fadeout {
  animation: fadeout 2s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
