<template>
  <div
    class="button-plus-container"
    :class="isButtonPressed ? 'button-plus-pressed-background' : ''"
    @mousedown.stop.passive="this.isButtonPressed = true"
    @mouseup.stop.passive="this.isButtonPressed = false"
    @mouseleave.stop.passive="this.isButtonPressed = false"
    @touchstart.stop.passive="this.isButtonPressed = true"
    @touchend.stop.passive="this.isButtonPressed = false"
    @touchcancel.stop.passive="this.isButtonPressed = false"
    @click.stop.passive="buttonPlusClicked"
  >
    <img v-if="getUsedTheme === 'dark-theme'" :src="iconButtonPlusDarkMode" />
    <img v-else :src="iconButtonPlusLightMode" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ButtonPlus',
  emits: ['buttonClickedEvent'],
  data() {
    return {
      isButtonPressed: false,
      iconButtonPlusLightMode: require('@/assets/widgets/roomclimateelement/light-theme/button-plus.svg'),
      iconButtonPlusDarkMode: require('@/assets/widgets/roomclimateelement/dark-theme/button-plus.svg')
    }
  },
  computed: {
    ...mapGetters(['getUsedTheme'])
  },
  methods: {
    buttonPlusClicked() {
      this.$emit('buttonClickedEvent')
    }
  }
}
</script>

<style scoped>
.button-plus-container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
}

.button-plus-pressed-background {
  border-radius: 7px;
  background-color: var(--pressed-background-color);
}
</style>
