class Temperature {
  constructor() {
    this.value = null
    this.unit = null
    this.noxnetError = null
  }

  setTemperature(value, unit) {
    this.value = value
    this.unit = unit
  }

  setNoxnetError(noxnetError) {
    this.noxnetError = noxnetError
  }
}

export default Temperature
