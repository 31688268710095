<template>
  <div class="favorites-view-container">
    <div class="favorites-view-header">{{ $t('favorites') }}</div>
    <div v-show="isPlaceholderForWebUiMenuEnabled" class="favorites-view-placeholder-webui-menu" />
    <div v-show="isConnectionLost" class="favorites-view-connection-lost">
      <connection-lost @tryToReconnectEvent="tryToReconnect" />
    </div>
    <div id="scrollable-favorites-view" v-show="!isConnectionLost" class="favorites-view-main">
      <control-elements :idsOfControlElements="idsOfFavorites" />
      <div v-if="showScrolledToBottomSpace" class="favorites-view-scrolled-to-bottom-space" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ControlElements from '@/ui/components/ControlElements.vue'
import ConnectionLost from '@/ui/components/ConnectionLost.vue'

export default {
  name: 'FavoritesView',
  components: { ControlElements, ConnectionLost },
  emits: ['tryToReconnectEvent'],
  data() {
    return {
      elementScrollableFavoritesView: null,
      showScrolledToBottomSpace: false,
      idsOfFavorites: []
    }
  },
  beforeMount() {
    this.idsOfFavorites = this.$appManager.getIdsOfFavorites()
  },
  mounted() {
    this.$appManager.getLatestStates()

    this.elementScrollableFavoritesView = document.getElementById('scrollable-favorites-view')

    if (this.elementScrollableFavoritesView != null) {
      this.elementScrollableFavoritesView.onscroll = () => {
        this.enableScrolledToBottomSpace()
      }

      this.elementScrollableFavoritesView.scrollTop = 0
    }
  },
  computed: {
    ...mapGetters(['isPlaceholderForWebUiMenuEnabled', 'isConnectionLost'])
  },
  methods: {
    enableScrolledToBottomSpace() {
      if (this.elementScrollableFavoritesView != null) {
        const scrollTop = this.elementScrollableFavoritesView.scrollTop
        const invisiblePart =
          this.elementScrollableFavoritesView.scrollHeight - this.elementScrollableFavoritesView.clientHeight
        const scrollbarPosition = (scrollTop / invisiblePart) * 100

        this.showScrolledToBottomSpace = scrollbarPosition > 0
      }
    },
    tryToReconnect() {
      this.$emit('tryToReconnectEvent')
    }
  }
}
</script>

<style scoped>
.favorites-view-container {
  display: grid;
  grid-template-columns: 1fr 55px;
  grid-template-rows: 50px auto auto;
  background-color: var(--background-color-secondary);
}

.favorites-view-header {
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-primary);
  background-color: var(--background-color-primary);
}

.favorites-view-placeholder-webui-menu {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  align-self: stretch;
  justify-self: stretch;
  border-top: solid var(--background-color-primary) 19px;
  border-right: solid var(--background-color-primary) 13px;
  border-bottom: solid var(--background-color-primary) 5px;
  border-left: solid var(--background-color-primary) 16px;
  background-color: var(--background-color-secondary);
}

.favorites-view-connection-lost {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  align-self: center;
}

.favorites-view-main {
  grid-column: 1 / span 2;
  grid-row: 2 / span 1;
  overflow-y: auto;
  margin: 1px 0;
}

.favorites-view-scrolled-to-bottom-space {
  grid-column: 1 / span 2;
  grid-row: 3 / span 1;
  height: 35px;
}
</style>
