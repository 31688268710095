class BrightnessData {
  constructor() {
    this.sunBrightnessEastValue = null
    this.sunBrightnessEastUnit = null
    this.sunBrightnessSouthValue = null
    this.sunBrightnessSouthUnit = null
    this.sunBrightnessWestValue = null
    this.sunBrightnessWestUnit = null
    this.sunTwilightValue = null
    this.sunTwilightUnit = null
    this.isCivilTwilight = null
  }

  setSunBrightnessEast(value, unit) {
    this.sunBrightnessEastValue = value
    this.sunBrightnessEastUnit = unit
  }

  setSunBrightnessSouth(value, unit) {
    this.sunBrightnessSouthValue = value
    this.sunBrightnessSouthUnit = unit
  }

  setSunBrightnessWest(value, unit) {
    this.sunBrightnessWestValue = value
    this.sunBrightnessWestUnit = unit
  }

  setSunTwilight(value, unit, isCivilTwilight) {
    this.sunTwilightValue = value
    this.sunTwilightUnit = unit
    this.isCivilTwilight = isCivilTwilight
  }
}

export default BrightnessData
