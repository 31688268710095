import ControlElementBase from '@/entities/controlelements/base/controlelementbase'

/**
 * The class ControlElementIpCamera stores the following information of a control element of type 'ipcamera':
 *
 *  - ID of control element (e.g. '3C454D67-3367-41CD-B81B-D147DAE35233')
 *  - Name of control element (e.g. 'Outdoor camera')
 *  - Assignments of control element (an array containing objects of type 'Assignment')
 *  - Information if control element is enabled or not
 *  - Information if control element is favorite or not
 *
 * The property rerender shall be incremented after a property has changed.
 */
class ControlElementIpCamera extends ControlElementBase {
  /**
   * The constructor of this class stores the given ID, name, assignments, isEnabled and isFavorite.
   * The property rerender is initialized with 0.
   *
   * @param {string} id ID of control element
   * @param {string} name Name of control element
   * @param {Array<Assignment>} assignments Assignments of control element
   * @param {boolean} isEnabled Information if control element is enabled or not
   * @param {boolean} isFavorite Information if control element is favorite or not
   */
  constructor(id, name, assignments, isEnabled, isFavorite) {
    super(id, name, 'ipcamera', assignments, isEnabled, isFavorite)

    this.rerender = 0
  }

  /**
   * This static method returns a clone of the given 'Control Element'. This method returns null if the given
   * 'Control Element' is not from type 'ipcamera'.
   *
   * @param {ControlElementIpCamera} controlElement 'Control Element' to be cloned
   * @returns Cloned 'Control Element'
   */
  static clone(controlElement) {
    let clonedControlElement = null

    if (controlElement.type === 'ipcamera') {
      clonedControlElement = new ControlElementIpCamera(
        controlElement.id,
        controlElement.name,
        controlElement.assignments,
        controlElement.isEnabled,
        controlElement.isFavorite
      )
    }

    return clonedControlElement
  }

  /**
   * This method copies the following properties of the given 'Control Element':
   *
   *  - Name
   *  - Assignments
   *
   * The property rerender is incremented (even there is no change). This method does not copy
   * when if the given 'Control Element' is not from type 'ipcamera'.
   *
   * @param {ControlElementIpCamera} controlElement 'Control Element' to be copied
   */
  update(controlElement) {
    if (controlElement.type === 'ipcamera') {
      this.name = controlElement.name
      this.assignments = controlElement.assignments

      this.rerender = this.rerender + 1
    }
  }
}

export default ControlElementIpCamera
