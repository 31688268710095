<template>
  <div class="main-screen-container">
    <global-view v-if="activeView === 'Global'" class="main-screen-view" @tryToReconnectEvent="tryToReconnect" />
    <favorites-view
      v-else-if="activeView === 'Favorites'"
      class="main-screen-view"
      @tryToReconnectEvent="tryToReconnect"
    />
    <rooms-view v-else-if="activeView === 'Rooms'" class="main-screen-view" @tryToReconnectEvent="tryToReconnect" />
    <climate-view v-else-if="activeView === 'Climate'" class="main-screen-view" @tryToReconnectEvent="tryToReconnect" />
    <more-view v-else-if="activeView === 'More'" class="main-screen-view" />
    <tab-bar class="main-screen-tab-bar" @changeViewEvent="changeView" />
  </div>
  <div v-if="isModalModusEnabled" class="main-screen-blocked" />
</template>

<script>
import { mapGetters } from 'vuex'
import GlobalView from '@/ui/views/GlobalView.vue'
import FavoritesView from '@/ui/views/FavoritesView.vue'
import RoomsView from '@/ui/views/RoomsView.vue'
import ClimateView from '@/ui/views/ClimateView.vue'
import MoreView from '@/ui/views/MoreView.vue'
import TabBar from '@/ui/views/TabBar.vue'

export default {
  name: 'MainScreen',
  components: {
    GlobalView,
    FavoritesView,
    RoomsView,
    ClimateView,
    MoreView,
    TabBar
  },
  emits: ['tryToReconnectEvent'],
  data() {
    return {
      activeView: null,
      customizedViewportHeight: 0,
      resizeTimer: null
    }
  },
  mounted() {
    this.calculateViewPortHeight()

    window.addEventListener('resize', this.resizeHandler)

    this.showRoomsView()
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  computed: {
    ...mapGetters(['getBrowserType', 'isModalModusEnabled'])
  },
  methods: {
    resizeHandler() {
      this.clearResizeTimer()
      this.runResizeTimer()
    },
    runResizeTimer() {
      let count = 0

      this.resizeTimer = setInterval(() => {
        if (count <= 20) {
          this.calculateViewPortHeight()
          count += 1
        } else {
          this.clearResizeTimer()
        }
      }, 50)
    },
    clearResizeTimer() {
      if (this.resizeTimer != null) {
        clearInterval(this.resizeTimer)
        this.resizeTimer = null
      }
    },
    calculateViewPortHeight() {
      // See also https://css-tricks.com/the-trick-to-viewport-units-on-mobile
      let vh = window.innerHeight * 0.01
      if (this.customizedViewportHeight != vh) {
        this.customizedViewportHeight = vh
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      }
    },
    showRoomsView() {
      this.activeView = 'Rooms'
    },
    changeView(view) {
      this.activeView = view
    },
    tryToReconnect() {
      this.$emit('tryToReconnectEvent')
    }
  }
}
</script>

<style scoped>
.main-screen-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 50px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: var(--background-color-primary);
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.main-screen-view {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  overflow-y: auto;
}

.main-screen-tab-bar {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
}

.main-screen-blocked {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: var(--blocked-color-primary);
}
</style>
