/**
 * The class AreasAccessor is used for accessing the 'Areas' stored in the
 * appropriate object store (IndexedDB).
 */
class AreasAccessor {
  /**
   * The constructor of this class stores the references to the database and to
   * the object store containing the 'Areas'.
   *
   * @param {any} database Reference to database.
   * @param {any} objectStore Reference to object store containing 'Areas'.
   */
  constructor(database, objectStore) {
    this.database = database
    this.objectStore = objectStore
  }

  /**
   * This method writes the given 'Areas' to the object store. 'Areas' with the same
   * ID are overwritten.
   *
   *  - Writing succeed: Returns empty Promise resolve.
   *  - Writing failed:  Returns Promise reject containing error message.
   *
   * @param {any} areas 'Areas' to be stored to object store.
   * @returns Promise containing result.
   */
  async write(areas) {
    return new Promise((resolve, reject) => {
      if (this.database != null && this.objectStore != null) {
        // Database and object store are valid

        // Create transaction for writing 'Areas'
        const transaction = this.database.transaction(this.objectStore, 'readwrite')

        // Get object store for writing 'Areas'
        const objectStore = transaction.objectStore(this.objectStore)

        // Put all 'Areas' to object store
        areas.forEach(area => {
          objectStore.put(area)
        })

        /**
         * This event handler is called when writing 'Areas' to the object store
         * succeed. It returns an empty Promise resolve.
         */
        transaction.oncomplete = () => {
          resolve()
        }

        /**
         * This event handler is called when writing 'Areas' to the object store
         * failed. This event handler returns a Promise reject containing the error
         * message.
         *
         * @param {Event} event Generated by objectStore.put()
         */
        transaction.onerror = event => {
          const errorMessage = `Error: Writing areas failed! (${event.target.error})`
          reject(errorMessage)
        }
      } else {
        // Database and/or object store are invalid
        const errorMessage = 'Error: Writing areas failed! Invalid database or object store.'
        reject(errorMessage)
      }
    })
  }

  /**
   * This method reads the 'Areas' from the object store.
   *
   *  - Reading succeed: Returns Promise resolve containing stored 'Areas'.
   *  - Reading failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async read() {
    return new Promise((resolve, reject) => {
      if (this.database != null && this.objectStore != null) {
        // Database and object store are valid

        // Create transaction for reading 'Areas'
        const transaction = this.database.transaction(this.objectStore, 'readonly')

        // Get object store for reading 'Areas'
        const objectStore = transaction.objectStore(this.objectStore)

        // Get 'Areas' ordered by 'order' from object store
        const index = objectStore.index('order')
        const request = index.getAll()

        /**
         * This event handler is called when reading the 'Areas' from the object
         * store succeed. This event handler returns a Promise resolve containing
         * the 'Areas'.
         *
         * @param {Event} event Generated by objectStore.getAll()
         */
        request.onsuccess = event => {
          let areas = []

          // Get 'Areas' stored in event
          if (event.target.result.length > 0) {
            areas = event.target.result
          }

          resolve(areas)
        }

        /**
         * This event handler is called when reading the 'Areas' from the object
         * store failed. This event handler returns a Promise reject containing
         * the error message.
         *
         * @param {Event} event Generated by objectStore.getAll()
         */
        request.onerror = event => {
          const errorMessage = `Error: Reading areas failed! (${event.target.error})`
          reject(errorMessage)
        }
      } else {
        // Database and/or object store are invalid
        const errorMessage = 'Error: Reading areas failed! Invalid database or object store.'
        reject(errorMessage)
      }
    })
  }

  /**
   * This method deletes all 'Areas' from the object store.
   *
   *  - Deleting succeed: Returns empty Promise resolve.
   *  - Deleting failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async clear() {
    return new Promise((resolve, reject) => {
      if (this.database != null && this.objectStore != null) {
        // Database and object store are valid

        // Create transaction for deleting 'Areas'
        const transaction = this.database.transaction(this.objectStore, 'readwrite')

        // Get object store for deleting 'Areas'
        const objectStore = transaction.objectStore(this.objectStore)

        // Delete 'Areas' from object store
        const request = objectStore.clear()

        /**
         * This event handler is called when deleting 'Areas' from the object store
         * succeed. It returns an empty Promise resolve.
         */
        transaction.oncomplete = () => {
          resolve()
        }

        /**
         * This event handler is called when deleting 'Areas' from the object store
         * failed. This event handler returns a Promise reject containing the error
         * message.
         *
         * @param {Event} event Generated by objectStore.clear()
         */
        request.onerror = event => {
          const errorMessage = `Error: Deleting areas failed! (${event.target.error})`
          reject(errorMessage)
        }
      } else {
        // Database and/or object store are invalid
        const errorMessage = 'Error: Deleting areas failed! Invalid database or object store.'
        reject(errorMessage)
      }
    })
  }
}

export default AreasAccessor
