import { version } from '../../../../package.json'

const application = {
  state: {
    appVersion: version,
    commitHash: process.env.VUE_APP_COMMIT_HASH,
    browserType: null,
    configurationInformation: null,
    protocol: window.location.protocol,
    ipAddress: window.location.ipAddress,
    port: window.location.port,
    modalModusEnabled: false,
    idOfCurrentArea: null,
    idOfCurrentRoom: null,
    currentControlElements: [],
    connectionLost: true,
    soapInterfaceRunning: false,
    error: { show: false, text: '' }
  },
  getters: {
    getAppVersion: state => state.appVersion,
    getCommitHash: state => state.commitHash,
    getBrowserType: state => state.browserType,
    getConfigurationInformation: state => state.configurationInformation,
    getProtocol: state => state.protocol,
    getIpAddress: state => state.ipAddress,
    getPort: state => state.port,
    isModalModusEnabled: state => state.modalModusEnabled,
    getIdOfCurrentArea: state => state.idOfCurrentArea,
    getIdOfCurrentRoom: state => state.idOfCurrentRoom,
    getCurrentControlElement: state => controlElementId => {
      return state.currentControlElements.find(tmpControlElement => tmpControlElement.id === controlElementId)
    },
    getCurrentControlElements: state => state.currentControlElements,
    isConnectionLost: state => state.connectionLost,
    isSoapInterfaceRunning: state => state.soapInterfaceRunning
  },
  mutations: {
    setBrowserType(state, browserType) {
      state.browserType = browserType
    },
    setConfigurationInformation(state, configurationInformation) {
      state.configurationInformation = configurationInformation
    },
    setProtocol(state, protocol) {
      state.protocol = protocol
    },
    setIpAddress(state, ipAddress) {
      state.ipAddress = ipAddress
    },
    setPort(state, port) {
      state.port = port
    },
    enableModalModus(state, enable) {
      state.modalModusEnabled = enable
    },
    setIdOfCurrentArea(state, areaId) {
      state.idOfCurrentArea = areaId
    },
    setIdOfCurrentRoom(state, roomId) {
      state.idOfCurrentRoom = roomId
    },
    addCurrentControlElement(state, controlElement) {
      state.currentControlElements.push(controlElement)
    },
    removeCurrentControlElement(state, controlElement) {
      const index = state.currentControlElements.indexOf(controlElement)

      if (index > -1) {
        state.currentControlElements.splice(index, 1)
      }
    },
    updateCurrentControlElement(state, controlElement) {
      const currentControlElement = state.currentControlElements.find(
        tmpControlElement => tmpControlElement.id === controlElement.id
      )

      if (currentControlElement != null) {
        currentControlElement.update(controlElement)
      }
    },
    setLostConnection(state, connectionLost) {
      state.connectionLost = connectionLost
    },
    setSoapInterfaceRunning(state, isRunnning) {
      state.soapInterfaceRunning = isRunnning
    },
    showError(state, message) {
      state.error.show = true
      state.error.text = message
    }
  }
}

export default application
