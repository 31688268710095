<template>
  <div class="language-selector-container">
    <div v-for="(language, index) in getLanguages" :key="language.code" :value="language.code">
      <div v-if="this.selectedLanguageCode === language.code" :class="getBoxStyle(null, index)">
        <div class="language-selector-selected-box-text">{{ language.name }}</div>
        <div class="language-selector-selected-box-icon">
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/checkmark/dark-theme/checkmark.svg" />
          <img v-else src="@/assets/checkmark/light-theme/checkmark.svg" />
        </div>
      </div>
      <div
        v-else
        :class="getBoxStyle(language, index)"
        @mousedown.stop.passive="pressedLanguageCode = language.code"
        @mouseup.stop.passive="pressedLanguageCode = null"
        @mouseleave.stop.passive="pressedLanguageCode = null"
        @touchstart.stop.passive="pressedLanguageCode = language.code"
        @touchend.stop.passive="pressedLanguageCode = null"
        @touchcancel.stop.passive="pressedLanguageCode = null"
        @click.stop.passive="boxClicked(language.code)"
      >
        <div class="language-selector-not-selected-box-text">
          {{ language.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'LanguageSelector',
  data() {
    return {
      selectedLanguageCode: null,
      pressedLanguageCode: null
    }
  },
  beforeMount() {
    this.selectedLanguageCode = this.getSelectedLanguageCode
  },
  computed: {
    ...mapGetters(['getLanguages', 'getSelectedLanguageCode', 'getUsedTheme'])
  },
  methods: {
    ...mapMutations(['setLanguageCode']),
    getBoxStyle(language, index) {
      let retVal = 'language-selector-box'

      if (index === 0) {
        retVal += ' language-selector-box-first'
      }

      if (index === this.getLanguages.length - 1) {
        retVal += ' language-selector-box-last'
      }

      if (language) {
        if (this.pressedLanguageCode === language.code) {
          retVal = retVal + ' language-selector-pressed-box'
        } else {
          retVal = retVal + ' language-selector-not-pressed-box'
        }
      }

      return retVal
    },
    boxClicked(languageCode) {
      this.selectedLanguageCode = languageCode
      this.$i18n.locale = this.selectedLanguageCode
      this.setLanguageCode(languageCode)
    }
  }
}
</script>

<style scoped>
.language-selector-container {
  display: flex;
  align-content: stretch;
  flex-direction: column;
}

.language-selector-box-first {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.language-selector-box-last {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.language-selector-box {
  height: 60px;
  width: auto;
  background-color: var(--background-color-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 2px 0;
}

.language-selector-selected-box-text {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-secondary);
  margin: 0 0 0 20px;
}

.language-selector-not-selected-box-text {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-light-gray);
  margin: 0 0 0 20px;
}

.language-selector-selected-box-icon {
  margin: 0 20px 0 0;
}

.language-selector-pressed-box {
  background-color: var(--pressed-background-color);
}

.language-selector-not-pressed-box {
  background-color: var(--background-color-primary);
}
</style>
