import WeatherData from '@/entities/weatherdata/weatherdata'
import TemperatureData from '@/entities/temperaturedata/temperaturedata'
import PrecipitationData from '@/entities/precipitationdata/precipitationdata'
import WindData from '@/entities/winddata/winddata'
import BrightnessData from '@/entities/brightnessdata/brightnessdata'
import ThermostatData from '@/entities/thermostatdata/thermostatdata'
import Temperature from '@/entities/temperature/temperature'

/**
 * The class ModuleListManager is responsible to manage the modules of control
 * elements currently shown. This class optimizes the module list.
 */
class ModuleListManager {
  /**
   * The constructor of this class creates an empty list containing all modules.
   */
  constructor() {
    this.modules = []
    this.callback = null
  }

  /**
   * This method adds the modules used by the given control element to the module
   * list.
   *
   * @param {ControlElement} controlElement Control Element containing modules
   */
  addToModuleList(controlElement) {
    if (controlElement != null) {
      if (controlElement.assignments != null) {
        controlElement.assignments.forEach(assignment => {
          this.addAssignment(assignment, controlElement)
        })
      }
    }
  }

  /**
   * This method removes the modules used by the given control element from the
   * module list.
   *
   * @param {ControlElement} controlElement Control Element containing modules
   */
  removeFromModuleList(controlElement) {
    if (controlElement != null) {
      // Remove ID of control element from all channels
      this.modules.forEach(module => {
        module.channels.forEach(channel => {
          channel.removeControlElement(controlElement)
        })
      })

      // Remove all channels containing no control element IDs anymore
      this.modules.forEach(module => {
        for (let i = module.channels.length - 1; i >= 0; i--) {
          const channel = module.channels[i]

          if (channel.getControlElements().length === 0) {
            const index = module.channels.indexOf(channel)

            if (index > -1) {
              module.channels.splice(index, 1)
            }
          }
        }
      })

      // Remove all modules containing no channels anymore
      for (let i = this.modules.length - 1; i >= 0; i--) {
        const module = this.modules[i]

        if (module.channels.length === 0) {
          const index = this.modules.indexOf(module)

          if (index > -1) {
            this.modules.splice(index, 1)
          }
        }
      }
    }
  }

  /**
   * This method subscribes a callback of type function(controlElementId: string, state: boolean)
   * to the current 'Module List'. The callback is invoked when the 'Module List' is updated.
   *
   * @param {method(string, boolean):void} callback Callback to be called when 'Module List' is updated
   */
  subscribeToCurrentModuleList(callback) {
    this.callback = callback
  }

  /**
   * This method returns the list containing all modules.
   *
   * @returns List containing all modules
   */
  getCurrentModuleList() {
    return this.modules
  }

  /**
   * This method adds the given assignment and the given control element ID
   * to the module list.
   *
   * @param {Assignment} assignment Assignment to be added
   * @param {ControlElement} controlElement Control element
   */
  addAssignment(assignment, controlElement) {
    const moduleClass = assignment.moduleClass
    const moduleIndex = assignment.moduleIndex

    let module = this.modules.find(module => module.moduleIndex === moduleIndex && module.moduleClass === moduleClass)

    if (module == null) {
      module = new Module(moduleClass, moduleIndex)

      if (
        moduleClass === 'masterWeatherModule' ||
        moduleClass === 'masterRoomClimateModule' ||
        moduleClass === 'masterTimeSwitchModule'
      ) {
        module.addControlElement(controlElement)
      }

      this.modules.push(module)
    }

    const channelClass = assignment.channelClass
    const channelIndex = assignment.channelIndex

    module.addChannel(channelClass, channelIndex, controlElement)
  }

  updateModuleList(response) {
    if (response != null) {
      if (response.moduleList != null) {
        if (response.moduleList.modules != null) {
          response.moduleList.modules.forEach(module => {
            switch (module.moduleClass) {
              case 'masterInModule':
                //console.log('masterInModule')
                break
              case 'masterOutModule':
                this.handleMasterOutModule(module)
                break
              case 'masterDimModule':
                this.handleMasterDimModule(module)
                break
              case 'masterWeatherModule':
                this.handleMasterWeatherModule(module)
                break
              case 'masterRoomClimateModule':
                this.handleMasterRoomClimateModule(module)
                break
              case 'masterBlindModule':
                this.handleMasterBlindModule(module)
                break
              case 'masterTimeSwitchModule':
                this.handleMasterTimeSwitchModule(module)
                break
              default:
                console.log('Unknown: ', module.moduleClass)
                break
            }
          })
        }
      }
    }
  }

  handleMasterOutModule(module) {
    this.modules.forEach(tmpModule => {
      if (tmpModule.moduleClass === 'masterOutModule' && tmpModule.moduleIndex === module.index) {
        // Module found
        tmpModule.channels.forEach(tmpChannel => {
          module.channels.forEach(channel => {
            if (tmpChannel.channelClass === 'masterOutModuleChannel' && tmpChannel.channelIndex === channel.index) {
              // Channel found
              tmpChannel.outState = channel.outState
              tmpChannel.controlElements.forEach(controlElement => {
                const newState = channel.outState === 'on' ? true : false
                const hasChanged = controlElement.setState(newState)

                if (hasChanged) {
                  this.callback(controlElement.id)
                }
              })
            }
          })
        })
      }
    })
  }

  handleMasterDimModule(module) {
    this.modules.forEach(tmpModule => {
      if (tmpModule.moduleClass === 'masterDimModule' && tmpModule.moduleIndex === module.index) {
        // Module found
        tmpModule.channels.forEach(tmpChannel => {
          module.channels.forEach(channel => {
            if (tmpChannel.channelClass === 'masterDimModuleChannel' && tmpChannel.channelIndex === channel.index) {
              // Channel found
              tmpChannel.outState = channel.outState
              tmpChannel.controlElements.forEach(controlElement => {
                let hasChanged = false

                if (controlElement.type === 'switch') {
                  const nbrValue = Number(channel.outState)
                  if (nbrValue > 0) {
                    hasChanged = controlElement.setState(true)
                  } else {
                    hasChanged = controlElement.setState(false)
                  }
                }

                if (controlElement.type === 'dimmer') {
                  hasChanged = controlElement.setDimmerValue(channel.outState)
                }

                if (hasChanged) {
                  this.callback(controlElement.id)
                }
              })
            }
          })
        })
      }
    })
  }

  handleMasterWeatherModule(module) {
    this.modules.forEach(tmpModule => {
      if (tmpModule.moduleClass === 'masterWeatherModule') {
        const temperatureData = this.getTemperatureData(module)
        const precipitationData = this.getPrecipitationData(module)
        const windData = this.getWindData(module)
        const brightnessData = this.getBrightnessData(module)

        const weatherData = new WeatherData()
        weatherData.setTemperatureData(temperatureData)
        weatherData.setPrecipitationData(precipitationData)
        weatherData.setWindData(windData)
        weatherData.setBrightnessData(brightnessData)

        const controlElement = tmpModule.controlElements[0]
        const hasChanged = controlElement.updateWeatherData(weatherData)

        if (hasChanged) {
          this.callback(controlElement.id)
        }
      }
    })
  }

  getTemperatureData(module) {
    const temperatureData = new TemperatureData()

    if (module.weatherData != null) {
      if (module.weatherData.temperatureAir != null) {
        if (module.weatherData.temperatureAir.noxnetError === null) {
          temperatureData.setTemperatureAir(
            module.weatherData.temperatureAir.value,
            module.weatherData.temperatureAir.unit
          )
        }
      }

      if (module.weatherData.temperatureAirFelt != null) {
        if (module.weatherData.temperatureAirFelt.noxnetError === null) {
          temperatureData.setTemperatureAirFelt(
            module.weatherData.temperatureAirFelt.value,
            module.weatherData.temperatureAirFelt.unit
          )
        }
      }
    }

    return temperatureData
  }

  getPrecipitationData(module) {
    const precipitationData = new PrecipitationData()

    if (module.weatherData != null) {
      if (module.weatherData.precipitation != null) {
        if (module.weatherData.precipitation.noxnetError === null) {
          precipitationData.setPrecipitation(module.weatherData.precipitation.type, '-')
        }
      }
    }

    return precipitationData
  }

  getWindData(module) {
    const windData = new WindData()

    if (module.weatherData != null) {
      if (module.weatherData.windSpeed != null) {
        if (module.weatherData.windSpeed.noxnetError === null) {
          windData.setWindSpeed(module.weatherData.windSpeed.value, module.weatherData.windSpeed.unit)
        }
      }
    }

    return windData
  }

  getBrightnessData(module) {
    const brightnessData = new BrightnessData()

    if (module.weatherData != null) {
      if (module.weatherData.sunBrightnessEast != null) {
        if (module.weatherData.sunBrightnessEast.noxnetError === null) {
          brightnessData.setSunBrightnessEast(
            module.weatherData.sunBrightnessEast.value,
            module.weatherData.sunBrightnessEast.unit
          )
        }
      }

      if (module.weatherData.sunBrightnessSouth != null) {
        if (module.weatherData.sunBrightnessSouth.noxnetError === null) {
          brightnessData.setSunBrightnessSouth(
            module.weatherData.sunBrightnessSouth.value,
            module.weatherData.sunBrightnessSouth.unit
          )
        }
      }

      if (module.weatherData.sunBrightnessWest != null) {
        if (module.weatherData.sunBrightnessWest.noxnetError === null) {
          brightnessData.setSunBrightnessWest(
            module.weatherData.sunBrightnessWest.value,
            module.weatherData.sunBrightnessWest.unit
          )
        }
      }

      if (module.weatherData.sunTwilight != null) {
        if (module.weatherData.sunTwilight.noxnetError === null) {
          brightnessData.setSunTwilight(
            module.weatherData.sunTwilight.value,
            module.weatherData.sunTwilight.unit,
            module.weatherData.sunTwilight.civilTwilight
          )
        }
      }
    }

    return brightnessData
  }

  handleMasterRoomClimateModule(module) {
    this.modules.forEach(tmpModule => {
      if (tmpModule.moduleClass === module.moduleClass && tmpModule.moduleIndex === module.index) {
        // Module found

        const thermostatData = new ThermostatData()
        let alarmState = null

        if (module != null) {
          // 'Module' is available

          if (module.thermostatData != null) {
            if (module.thermostatData.noxnetError != null) {
              // 'NOXnet Error' is available
              thermostatData.setNoxnetError(module.thermostatData.noxnetError)
            } else {
              // 'Thermostat Data' is available
              const operatingState = this.getOperatingState(module.thermostatData)
              const valveState = this.getValveState(module.thermostatData)
              const actualTemperatureMean = this.getActualTemperatureMean(module.thermostatData)
              const setTemperatureHeating = this.getSetTemperatureHeating(module.thermostatData)
              const setTemperatureCooling = this.getSetTemperatureCooling(module.thermostatData)
              const nightSetbackTemperatureHeating = this.getNightSetbackTemperatureHeating(module.thermostatData)
              const nightSetbackTemperatureCooling = this.getNightSetbackTemperatureCooling(module.thermostatData)
              const absenceSetbackTemperatureHeating = this.getAbsenceSetbackTemperatureHeating(module.thermostatData)
              const absenceSetbackTemperatureCooling = this.getAbsenceSetbackTemperatureCooling(module.thermostatData)

              thermostatData.setOperatingState(operatingState)
              thermostatData.setValveState(valveState)
              thermostatData.setActualTemperatureMean(actualTemperatureMean)
              thermostatData.setSetTemperatureHeating(setTemperatureHeating)
              thermostatData.setSetTemperatureCooling(setTemperatureCooling)
              thermostatData.setNightSetbackTemperatureHeating(nightSetbackTemperatureHeating)
              thermostatData.setNightSetbackTemperatureCooling(nightSetbackTemperatureCooling)
              thermostatData.setAbsenceSetbackTemperatureHeating(absenceSetbackTemperatureHeating)
              thermostatData.setAbsenceSetbackTemperatureCooling(absenceSetbackTemperatureCooling)
            }

            if (module.alarmState != null) {
              alarmState = module.alarmState
            }
          }
        }

        tmpModule.controlElements.forEach(controlElement => {
          let hasChanged = controlElement.updateThermostatData(thermostatData)
          hasChanged ||= controlElement.updateAlarmState(alarmState)

          if (hasChanged) {
            this.callback(controlElement.id)
          }
        })
      }
    })
  }

  getOperatingState(thermostatData) {
    let operatingState = ''

    if (thermostatData.operatingState != null) {
      // 'Operating State' is available
      operatingState = thermostatData.operatingState
    }

    return operatingState
  }

  getValveState(thermostatData) {
    let valveState = ''

    if (thermostatData.valveState != null) {
      // 'Valve State' is available
      valveState = thermostatData.valveState
    }

    return valveState
  }

  getActualTemperatureMean(thermostatData) {
    // Create new 'Temperature' object
    const temperature = new Temperature()

    if (thermostatData.actualTemperatureMean != null) {
      // 'Actual Temperature Mean' is avaialble

      if (thermostatData.actualTemperatureMean.noxnetError != null) {
        // 'NOXnet Error' is available
        temperature.setNoxnetError(thermostatData.actualTemperatureMean.noxnetError)
      } else {
        // 'Actual Temperature Mean' is valid
        temperature.setTemperature(
          thermostatData.actualTemperatureMean.value,
          thermostatData.actualTemperatureMean.unit
        )
      }
    }

    return temperature
  }

  getSetTemperatureHeating(thermostatData) {
    // Create new 'Temperature' object
    const temperature = new Temperature()

    if (thermostatData.setTemperatureHeating != null) {
      // 'Set Temperature Heating' is avaialble

      if (thermostatData.setTemperatureHeating.noxnetError != null) {
        // 'NOXnet Error' is available
        temperature.setNoxnetError(thermostatData.setTemperatureHeating.noxnetError)
      } else {
        // 'Set Temperature Heating' is valid
        temperature.setTemperature(
          thermostatData.setTemperatureHeating.value,
          thermostatData.setTemperatureHeating.unit
        )
      }
    }

    return temperature
  }

  getSetTemperatureCooling(thermostatData) {
    // Create new 'Temperature' object
    const temperature = new Temperature()

    if (thermostatData.setTemperatureCooling != null) {
      // 'Set Temperature Cooling' is avaialble

      if (thermostatData.setTemperatureCooling.noxnetError != null) {
        // 'NOXnet Error' is available
        temperature.setNoxnetError(thermostatData.setTemperatureCooling.noxnetError)
      } else {
        // 'Set Temperature Cooling' is valid
        temperature.setTemperature(
          thermostatData.setTemperatureCooling.value,
          thermostatData.setTemperatureCooling.unit
        )
      }
    }

    return temperature
  }

  getNightSetbackTemperatureHeating(thermostatData) {
    // Create new 'Temperature' object
    const temperature = new Temperature()

    if (thermostatData.nightSetbackTemperatureHeating != null) {
      // 'Night Setback Temperature Heating' is avaialble

      if (thermostatData.nightSetbackTemperatureHeating.noxnetError != null) {
        // 'NOXnet Error' is available
        temperature.setNoxnetError(thermostatData.nightSetbackTemperatureHeating.noxnetError)
      } else {
        // 'Night Setback Temperature Heating' is valid
        temperature.setTemperature(
          thermostatData.nightSetbackTemperatureHeating.value,
          thermostatData.nightSetbackTemperatureHeating.unit
        )
      }
    }

    return temperature
  }

  getNightSetbackTemperatureCooling(thermostatData) {
    // Create new 'Temperature' object
    const temperature = new Temperature()

    if (thermostatData.nightSetbackTemperatureCooling != null) {
      // 'Night Setback Temperature Cooling' is avaialble

      if (thermostatData.nightSetbackTemperatureCooling.noxnetError != null) {
        // 'NOXnet Error' is available
        temperature.setNoxnetError(thermostatData.nightSetbackTemperatureCooling.noxnetError)
      } else {
        // 'Night Setback Temperature Cooling' is valid
        temperature.setTemperature(
          thermostatData.nightSetbackTemperatureCooling.value,
          thermostatData.nightSetbackTemperatureCooling.unit
        )
      }
    }

    return temperature
  }

  getAbsenceSetbackTemperatureHeating(thermostatData) {
    // Create new 'Temperature' object
    const temperature = new Temperature()

    if (thermostatData.absenceSetbackTemperatureHeating != null) {
      // 'Absence Setback Temperature Heating' is avaialble

      if (thermostatData.absenceSetbackTemperatureHeating.noxnetError != null) {
        // 'NOXnet Error' is available
        temperature.setNoxnetError(thermostatData.absenceSetbackTemperatureHeating.noxnetError)
      } else {
        // 'Absence Setback Temperature Heating' is valid
        temperature.setTemperature(
          thermostatData.absenceSetbackTemperatureHeating.value,
          thermostatData.absenceSetbackTemperatureHeating.unit
        )
      }
    }

    return temperature
  }

  getAbsenceSetbackTemperatureCooling(thermostatData) {
    // Create new 'Temperature' object
    const temperature = new Temperature()

    if (thermostatData.absenceSetbackTemperatureCooling != null) {
      // 'Absence Setback Temperature Cooling' is avaialble

      if (thermostatData.absenceSetbackTemperatureCooling.noxnetError != null) {
        // 'NOXnet Error' is available
        temperature.setNoxnetError(thermostatData.absenceSetbackTemperatureCooling.noxnetError)
      } else {
        // 'Absence Setback Temperature Cooling' is valid
        temperature.setTemperature(
          thermostatData.absenceSetbackTemperatureCooling.value,
          thermostatData.absenceSetbackTemperatureCooling.unit
        )
      }
    }

    return temperature
  }

  handleMasterBlindModule(module) {
    this.modules.forEach(tmpModule => {
      if (tmpModule.moduleClass === 'masterBlindModule' && tmpModule.moduleIndex === module.index) {
        // Module found
        tmpModule.channels.forEach(tmpChannel => {
          module.channels.forEach(channel => {
            if (tmpChannel.channelClass === 'masterBlindModuleChannel' && tmpChannel.channelIndex === channel.index) {
              // Channel found
              tmpChannel.currentAction = channel.getCurrentAction()
              tmpChannel.relativePosition = channel.getRelativePosition()
              tmpChannel.relativeTilt = channel.getRelativeTilt()
              tmpChannel.lowerLimit = channel.getLowerLimit()
              tmpChannel.cleaningLock = channel.getCleaningLock()
              tmpChannel.weatherLock = channel.getWeatherLock()
              tmpChannel.lockoutProtection = channel.getLockoutProtection()
              tmpChannel.manuallyOverridden = channel.getManuallyOverridden()
              tmpChannel.positionUnreliable = channel.getPositionUnreliable()

              tmpChannel.controlElements.forEach(controlElement => {
                // Set 'Current Action', 'Relative Position' and 'Relative Tilt'
                let hasChanged = controlElement.setCurrentAction(channel.getCurrentAction())
                hasChanged |= controlElement.setRelativePosition(channel.getRelativePosition())
                hasChanged |= controlElement.setRelativeTilt(channel.getRelativeTilt())
                hasChanged |= controlElement.setLowerLimitStatus(channel.getLowerLimit())
                hasChanged |= controlElement.setCleaningLockStatus(channel.getCleaningLock())
                hasChanged |= controlElement.setWeatherLockStatus(channel.getWeatherLock())
                hasChanged |= controlElement.setLockoutProtectionStatus(channel.getLockoutProtection())
                hasChanged |= controlElement.setManuallyOverriddenStatus(channel.getManuallyOverridden())
                hasChanged |= controlElement.setPositionUnreliableStatus(channel.getPositionUnreliable())

                if (hasChanged) {
                  this.callback(controlElement.id)
                }
              })
            }
          })
        })
      }
    })
  }

  handleMasterTimeSwitchModule(module) {
    this.modules.forEach(tmpModule => {
      if (tmpModule.moduleClass === module.moduleClass && tmpModule.moduleIndex === module.index) {
        // Module found

        let state = module.getOperatingState() === 'enabled' ? true : false

        tmpModule.controlElements.forEach(controlElement => {
          let hasChanged = controlElement.setState(state)

          if (hasChanged) {
            this.callback(controlElement.id)
          }
        })
      }
    })
  }
}

class Module {
  constructor(moduleClass, moduleIndex) {
    this.moduleClass = moduleClass
    this.moduleIndex = moduleIndex
    this.channels = []
    this.controlElements = []
  }

  addChannel(channelClass, channelIndex, controlElement) {
    if (channelClass != null && channelIndex != null && controlElement != null) {
      let channel = this.channels.find(
        channel => channel.channelClass === channelClass && channel.channelIndex === channelIndex
      )

      if (channel == null) {
        channel = new Channel(channelClass, channelIndex, controlElement)
        this.channels.push(channel)
      } else {
        channel.addControlElement(controlElement)
      }
    }
  }

  addControlElement(controlElement) {
    this.controlElements.push(controlElement)
  }
}

class Channel {
  constructor(channelClass, channelIndex, controlElement) {
    this.channelClass = channelClass
    this.channelIndex = channelIndex
    this.controlElements = []
    this.outState = null

    this.controlElements.push(controlElement)
  }

  addControlElement(controlElement) {
    this.controlElements.push(controlElement)
  }

  removeControlElement(controlElement) {
    const foundControlElement = this.controlElements.find(
      tmpControlElement => tmpControlElement.id === controlElement.id
    )

    if (foundControlElement != null) {
      const index = this.controlElements.indexOf(foundControlElement)

      if (index > -1) {
        this.controlElements.splice(index, 1)
      }
    }
  }

  getControlElements() {
    return this.controlElements
  }
}

export default ModuleListManager
