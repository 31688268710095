class ThermostatData {
  constructor() {
    this.operatingState = null
    this.valveState = null
    this.actualTemperatureMean = null
    this.setTemperatureHeating = null
    this.setTemperatureCooling = null
    this.nightSetbackTemperatureHeating = null
    this.nightSetbackTemperatureCooling = null
    this.absenceSetbackTemperatureHeating = null
    this.absenceSetbackTemperatureCooling = null
    this.noxnetError = null
  }

  setOperatingState(operatingState) {
    this.operatingState = operatingState
  }

  setValveState(valveState) {
    this.valveState = valveState
  }

  setActualTemperatureMean(actualTemperatureMean) {
    this.actualTemperatureMean = actualTemperatureMean
  }

  setSetTemperatureHeating(setTemperatureHeating) {
    this.setTemperatureHeating = setTemperatureHeating
  }

  setSetTemperatureCooling(setTemperatureCooling) {
    this.setTemperatureCooling = setTemperatureCooling
  }

  setNightSetbackTemperatureHeating(nightSetbackTemperatureHeating) {
    this.nightSetbackTemperatureHeating = nightSetbackTemperatureHeating
  }

  setNightSetbackTemperatureCooling(nightSetbackTemperatureCooling) {
    this.nightSetbackTemperatureCooling = nightSetbackTemperatureCooling
  }

  setAbsenceSetbackTemperatureHeating(absenceSetbackTemperatureHeating) {
    this.absenceSetbackTemperatureHeating = absenceSetbackTemperatureHeating
  }

  setAbsenceSetbackTemperatureCooling(absenceSetbackTemperatureCooling) {
    this.absenceSetbackTemperatureCooling = absenceSetbackTemperatureCooling
  }

  setNoxnetError(noxnetError) {
    this.noxnetError = noxnetError
  }
}

export default ThermostatData
