import ControlElementBase from '@/entities/controlelements/base/controlelementbase'

/**
 * The class ControlElementDimmer stores the following information of a control element of type 'dimmer':
 *
 *  - ID of control element (e.g. '2FFF0722-1E40-48F3-9850-1A12030CD8F4')
 *  - Name of control element (e.g. 'Reading Lamp')
 *  - Assignments of control element (an array containing objects of type 'Assignment')
 *  - Information if control element is enabled or not
 *  - Information if control element is favorite or not
 *  - Current dimmer value
 *
 * The property rerender shall be incremented after a property (e.g. new dimmer value) has changed.
 */
class ControlElementDimmer extends ControlElementBase {
  /**
   * The constructor of this class stores the given ID, name, assignments, isEnabled and isFavorite.
   * This constructor also also initializes the property dimmerValue with 0.
   *
   * @param {string} id ID of control element
   * @param {string} name Name of control element
   * @param {Array<Assignment>} assignments Assignments of control element
   * @param {boolean} isEnabled Information if control element is enabled or not
   * @param {boolean} isFavorite Information if control element is favorite or not
   */
  constructor(id, name, assignments, isEnabled, isFavorite) {
    super(id, name, 'dimmer', assignments, isEnabled, isFavorite)

    this.dimmerValue = 0
  }

  /**
   * This static method returns a clone of the given 'Control Element'. This method returns null if the given
   * 'Control Element' is not from type 'dimmer'.
   *
   * @param {ControlElementDimmer} controlElement 'Control Element' to be cloned
   * @returns Cloned 'Control Element'
   */
  static clone(controlElement) {
    let clonedControlElement = null

    if (controlElement.type === 'dimmer') {
      clonedControlElement = new ControlElementDimmer(
        controlElement.id,
        controlElement.name,
        controlElement.assignments,
        controlElement.isEnabled,
        controlElement.isFavorite
      )
    }

    return clonedControlElement
  }

  /**
   * This method copies the following properties of the given 'Control Element':
   *
   *  - Name
   *  - Assignments
   *
   * The property rerender is incremented (even there is no change). This method does not copy
   * when if the given 'Control Element' is not from type 'dimmer'.
   *
   * @param {ControlElementDimmer} controlElement 'Control Element' to be copied
   */
  update(controlElement) {
    if (controlElement.type === 'dimmer') {
      this.name = controlElement.name
      this.assignments = controlElement.assignments

      this.rerender = this.rerender + 1
    }
  }

  /**
   * This method sets the given dimmer value. This method increments the value of rerender if the
   * dimmer value has changed.
   *
   * @param {Number} dimmerValue New dimmer value to be stored
   * @returns true if dimmer value has changed otherwise false
   */
  setDimmerValue(dimmerValue) {
    let hasChanged = false

    if (this.dimmerValue !== dimmerValue) {
      hasChanged = true
      this.dimmerValue = dimmerValue
      this.rerender = this.rerender + 1
    }

    return hasChanged
  }
}

export default ControlElementDimmer
