<template>
  <div class="switch-element-props-container">
    <div class="switch-element-props-title" contenteditable @input="onInput">
      {{ name }}
    </div>
    <div class="switch-element-props-favorite">
      <div v-if="isFavorite">
        <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/favorite/dark-theme/favoritestar-active.svg" />
        <img v-else src="@/assets/favorite/light-theme/favoritestar-active.svg" />
      </div>
      <div v-else>
        <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/favorite/dark-theme/favoritestar-inactive.svg" />
        <img v-else src="@/assets/favorite/light-theme/favoritestar-inactive.svg" />
      </div>
    </div>
    <div class="switch-element-props-icon">
      <img v-if="state" :src="iconActive" />
      <img v-else :src="iconInactive" />
    </div>
    <div class="switch-element-props-status" :style="statusTextColor">
      {{ statusText }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SwitchElementProps',
  props: {
    controlElement: {
      type: Object,
      required: true
    },
    state: {
      type: Boolean,
      required: false
    }
  },
  emits: ['changedDataEvent'],
  data() {
    return {
      tmpControlElement: null,
      name: null,
      isFavorite: false,
      statusText: null,
      iconActive: null,
      iconInactive: null
    }
  },
  beforeMount() {
    this.initialize()
  },
  watch: {
    state() {
      if (this.state) {
        if (this.getUsedTheme === 'dark-theme') {
          this.statusText = this.tmpControlElement.configurationDarkMode.statusTextActive
        } else {
          this.statusText = this.tmpControlElement.configurationLightMode.statusTextActive
        }
      } else {
        if (this.getUsedTheme === 'dark-theme') {
          this.statusText = this.tmpControlElement.configurationDarkMode.statusTextInactive
        } else {
          this.statusText = this.tmpControlElement.configurationLightMode.statusTextInactive
        }
      }
    },
    controlElement: {
      deep: true,
      handler() {
        this.initialize()
      }
    }
  },
  computed: {
    ...mapGetters(['getUsedTheme']),
    statusTextColor() {
      let textColor = 'color: '

      if (this.state) {
        if (this.getUsedTheme === 'dark-theme') {
          textColor += this.tmpControlElement.configurationDarkMode.statusTextColorActive
        } else {
          textColor += this.tmpControlElement.configurationLightMode.statusTextColorActive
        }
      } else {
        if (this.getUsedTheme === 'dark-theme') {
          textColor += this.tmpControlElement.configurationDarkMode.statusTextColorInactive
        } else {
          textColor += this.tmpControlElement.configurationLightMode.statusTextColorInactive
        }
      }

      textColor += ';'
      return textColor
    }
  },
  methods: {
    initialize() {
      this.tmpControlElement = this.controlElement
      this.name = this.controlElement.name
      this.isFavorite = this.controlElement.isFavorite

      if (this.getUsedTheme === 'dark-theme') {
        this.statusText = this.controlElement.configurationDarkMode.statusTextInactive
        this.iconActive = require('@/assets/icons/dark-theme/' + this.controlElement.configurationDarkMode.iconActive)
        this.iconInactive = require('@/assets/icons/dark-theme/' +
          this.controlElement.configurationDarkMode.iconInactive)
      } else {
        this.statusText = this.controlElement.configurationLightMode.statusTextInactive
        this.iconActive = require('@/assets/icons/light-theme/' + this.controlElement.configurationLightMode.iconActive)
        this.iconInactive = require('@/assets/icons/light-theme/' +
          this.controlElement.configurationLightMode.iconInactive)
      }
    },
    onInput(e) {
      this.tmpControlElement.name = e.target.innerText
      this.$emit('changedDataEvent', this.tmpControlElement)
    }
  }
}
</script>

<style scoped>
.switch-element-props-container {
  display: grid;
  grid-template-columns: 28px 20px 62px 20px 28px;
  grid-template-rows: 48px 62px 48px;
  align-items: center;
  justify-items: center;
  width: 158px;
  height: 158px;
  border-radius: 14px;
  border-color: var(--border-color-primary);
  border-width: 1px;
  border-style: solid;
  background-color: var(--background-color-primary);
}

.switch-element-props-title {
  grid-column: 2 / span 3;
  grid-row: 1 / span 1;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: var(--text-color-primary);
}

.switch-element-props-favorite {
  grid-column: 4 / span 2;
  grid-row: 1 / span 1;
  margin: -4px 0 0px 4px;
}

.switch-element-props-icon {
  grid-column: 1 / span 5;
  grid-row: 2 / span 1;
  justify-self: center;
  align-self: center;
}

.switch-element-props-status {
  grid-column: 1 / span 5;
  grid-row: 3 / span 1;
  font-family: OpenSans;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}

#input {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  border: 1px solid var(--border-text-field);
  box-shadow: 1px 1px 1px 0 var(--shadow-text-field) inset;
  /*font: -moz-field;*/
  /*font: -webkit-small-control;*/
  /*margin-top: 5px;*/
  /*padding: 2px 3px;*/
  /*width: 398px;*/
}
</style>
