<template>
  <div class="small-confirm-dialog-container">
    <div class="small-confirm-dialog-message">{{ message }}</div>
    <div class="small-confirm-dialog-buttons">
      <div
        class="small-confirm-dialog-button"
        :class="isButtonOkPressed ? 'small-confirm-dialog-button-pressed' : 'small-confirm-dialog-button-not-pressed'"
        @mousedown.stop.passive="isButtonOkPressed = true"
        @mouseup.stop.passive="isButtonOkPressed = false"
        @mouseleave.stop.passive="isButtonOkPressed = false"
        @touchstart.stop.passive="isButtonOkPressed = true"
        @touchend.stop.passive="isButtonOkPressed = false"
        @touchcancel.stop.passive="isButtonOkPressed = false"
        @click.stop.passive="buttonOkClicked"
      >
        {{ textOkButton }}
      </div>
      <div
        class="small-confirm-dialog-button"
        :class="
          isButtonCancelPressed ? 'small-confirm-dialog-button-pressed' : 'small-confirm-dialog-button-not-pressed'
        "
        @mousedown.stop.passive="isButtonCancelPressed = true"
        @mouseup.stop.passive="isButtonCancelPressed = false"
        @mouseleave.stop.passive="isButtonCancelPressed = false"
        @touchstart.stop.passive="isButtonCancelPressed = true"
        @touchend.stop.passive="isButtonCancelPressed = false"
        @touchcancel.stop.passive="isButtonCancelPressed = false"
        @click.stop.passive="buttonCancelClicked"
      >
        {{ textCancelButton }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SmallConfirmDialog',
  props: {
    message: {
      type: String,
      required: true
    },
    textOkButton: {
      type: String,
      required: true
    },
    textCancelButton: {
      type: String,
      required: true
    }
  },
  emits: ['cancelEvent', 'okEvent'],
  data() {
    return {
      isButtonOkPressed: false,
      isButtonCancelPressed: false
    }
  },
  methods: {
    buttonOkClicked() {
      this.$emit('okEvent')
    },
    buttonCancelClicked() {
      this.$emit('cancelEvent')
    }
  }
}
</script>

<style scoped>
.small-confirm-dialog-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  height: 140px;
  border-radius: 16px;
  background-color: var(--background-color-primary);
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 50px;
  justify-items: stretch;
  align-items: center;
}

.small-confirm-dialog-message {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: var(--text-color-primary);
  margin: 0 18px;
}

.small-confirm-dialog-buttons {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  display: flex;
  flex-direction: row-reverse;
  margin: 0 12px 0 0;
}

.small-confirm-dialog-button {
  height: 50px;
  padding: 18px 20px 0 20px;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.small-confirm-dialog-button-not-pressed {
  color: var(--innoxel-blue-color);
}

.small-confirm-dialog-button-pressed {
  color: var(--innoxel-blue-color-pressed);
}
</style>
