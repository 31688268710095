import {
  Parse,
  NOXnetSoapGetUserInterfaceLayout,
  NOXnetWebFrameInitializeLayoutSuccessCallback
} from '@/javascript/SoapInterface.js'
import ControlElementSwitch from '@/entities/controlelements/switch/controlelementswitch'
import ControlElementDimmer from '@/entities/controlelements/dimmer/controlelementdimmer'
import ControlElementBlind from '@/entities/controlelements/blind/controlelementblind'
import ControlElementWeather from '@/entities/controlelements/weather/controlelementweather'
import ControlElementRoomClimate from '@/entities/controlelements/roomclimate/controlelementroomclimate'
import ControlElementIpCamera from '@/entities/controlelements/ipcamera/controlelementipcamera'
import LoadResult from '@/entities/loadresult/loadresult'
import ControlElementSwitchConfiguration from '@/entities/controlelements/switch/controlelementswitchconfiguration'
import GlobalFunctionsUpdater from '@/services/globalfunctionsupdater/globalfunctionsupdater'
import GlobalTimersUpdater from '@/services/globaltimersupdater/globaltimersupdater'
import GlobalScenesUpdater from '@/services/globalscenesupdater/globalscenesupdater'
import RoomRegulationsUpdater from '@/services/roomregulationsupdater/roomregulationsupdater'
import AreasUpdater from '@/services/areasupdater/areasupdater'
import RoomsUpdater from '@/services/roomsupdater/roomsupdater'
import ControlElementsUpdater from '@/services/controlelementsupdater/controlelementsupdater'

class ConfigurationManager {
  constructor() {
    this.appManager = null
    this.soapInterfaceIsEnabled = false
    this.soapUri = null
    this.name = null
    this.configurationId = null
    this.version = 0
    this.areas = []
    this.rooms = []
    this.controlElements = []
    this.idsOfGlobalFunctions = []
    this.idsOfGlobalTimers = []
    this.idsOfGlobalScenes = []
    this.idsOfRoomClimateElements = []
  }

  setAppManager(appManager) {
    this.appManager = appManager
  }

  initializeSoapInterface(enabled, protocol, ipAddress, port) {
    this.soapInterfaceIsEnabled = enabled

    if (this.soapInterfaceIsEnabled) {
      this.soapUri = `${protocol}//${ipAddress}:${port}/control`
    } else {
      this.soapUri = null
    }
  }

  async loadTestConfiguration(webAppConfiguration) {
    return await this.loadWebAppConfiguration(webAppConfiguration)
  }

  async loadLocalConfiguration() {
    const loadResult = new LoadResult()

    try {
      fetch('./WebAppDescriptorname.xml')
        .then(response => response.text())
        .then(data => {
          loadResult.webAppConfiguration = Parse(data)
          loadResult.parsed = true
        })

      while (!loadResult.parsed) {
        await new Promise(resolve => setTimeout(resolve, 100))
      }

      await this.loadWebAppConfiguration(loadResult.webAppConfiguration)
    } catch (error) {
      loadResult.error = error
    }

    return loadResult
  }

  async loadConfigurationFromMasterAsync() {
    const loadResult = new LoadResult()

    if (this.soapInterfaceIsEnabled) {
      try {
        NOXnetSoapGetUserInterfaceLayout(this.soapUri, NOXnetWebFrameInitializeLayoutSuccessCallback, null, loadResult)
        while (!loadResult.parsed) {
          await new Promise(resolve => setTimeout(resolve, 100))
        }

        await this.loadWebAppConfiguration(loadResult.webAppConfiguration)
      } catch (error) {
        loadResult.error = error
      }
    }

    return loadResult
  }

  async loadWebAppConfiguration(webAppConfiguration) {
    let loaded = false

    if (webAppConfiguration != null) {
      const a = await this.adjustStorage(webAppConfiguration)

      // 'App Manager' shall load configuration
      const result = this.loadConfiguration(a)

      // Return flag 'App is ready to use'
      loaded = result.loaded
    }

    return loaded
  }

  async adjustStorage(webAppConfiguration) {
    // Variable declarations
    let storeCompleteConfiguration = false
    let updateConfiguration = false

    // Get 'Configuration Information'
    let configurationInformation = await this.appManager.getConfigurationInformation()

    // Check 'Configuration Information'
    if (configurationInformation == null) {
      // 'Configuration Information' is missing

      // Store complete 'Configuration' in database
      storeCompleteConfiguration = true

      // Update 'Configuration Information'
      configurationInformation = await this.updateConfigurationInformation(webAppConfiguration)
    } else {
      // 'Configuration Information' is available

      // Check if 'Configuration ID' has changed
      if (configurationInformation.configurationId != webAppConfiguration.configurationId) {
        // 'Configuration ID' has changed -> Seems to be a new project
        storeCompleteConfiguration = true
      } else if (configurationInformation.version != webAppConfiguration.version) {
        // Configuration has changed
        updateConfiguration = true
      }
    }

    // Get name, ID and version of 'Configuration'
    const name = webAppConfiguration.name
    const id = this.getShortId(webAppConfiguration.configurationId)
    const version = webAppConfiguration.version

    if (storeCompleteConfiguration) {
      // Store complete 'Configuration' into database

      // Add log entry
      let message = 'Storing new configuration into database has been started:\n\n'
      message += `Name: ${name}\nID: ${id}\nVersion: ${version}`
      this.storeLogEntry(message)

      // Update 'Configuration Information'
      configurationInformation = await this.updateConfigurationInformation(webAppConfiguration)

      // Store complete 'Configuration' into database
      await this.storeConfiguration(webAppConfiguration, true)

      // Add log entry
      this.storeLogEntry('Storing new configuration into database has been finished')
    } else if (updateConfiguration) {
      // Add log entry
      this.storeLogEntry('Updating configuration in database has been started')

      // Update 'Configuration Information'
      configurationInformation = await this.updateConfigurationInformation(webAppConfiguration)

      // Update changes of 'Configuration' in database
      await this.storeConfiguration(webAppConfiguration, false)

      // Add log entry
      this.storeLogEntry('Updating configuration in database has been finished')
    }

    // Add log entry
    let message = 'Load configuration from database:\n\n'
    message += `Name: ${name}\nID: ${id}\nVersion: ${version}`
    this.storeLogEntry(message)

    // Load 'Configuration' from database
    const globalFunctions = await this.appManager.getGlobalFunctions()
    const globalTimers = await this.appManager.getGlobalTimers()
    const globalScenes = await this.appManager.getGlobalScenes()
    const roomRegulations = await this.appManager.getRoomRegulations()
    const areas = await this.appManager.getStoredAreas()
    const rooms = await this.appManager.getStoredRooms()
    const controlElements = await this.appManager.getStoredControlElements()
    const global = {
      globalFunctions: globalFunctions,
      globalTimers: globalTimers,
      globalScenes: globalScenes
    }
    const climate = {
      roomRegulations: roomRegulations
    }
    const webAppConfiguration2 = {
      global: global,
      climate: climate,
      areas: areas,
      rooms: rooms,
      controlElements: controlElements
    }
    return webAppConfiguration2
  }

  async updateConfigurationInformation(a) {
    // Create 'Configuration Information'
    const configurationInformation = {
      id: 1,
      name: a.name,
      configurationId: a.configurationId,
      version: a.version,
      setupVersion: a.setupVersion
    }

    // Write 'Configuration Information' to 'Storage Manager'
    await this.appManager.setConfigurationInformation(configurationInformation)

    // Return 'Configuration Information'
    return configurationInformation
  }

  async storeConfiguration(webAppConfiguration, reset) {
    // Update global functions
    await this.updateGlobalFunctions(webAppConfiguration, reset)

    // Update global timers
    await this.updateGlobalTimers(webAppConfiguration, reset)

    // Update global scenes
    await this.updateGlobalScenes(webAppConfiguration, reset)

    // Update room regulations
    await this.updateRoomRegulations(webAppConfiguration, reset)

    // Update all areas
    await this.updateAreas(webAppConfiguration, reset)

    // Update all rooms
    await this.updateRooms(webAppConfiguration, reset)

    // Update all control elements
    await this.updateControlElements(webAppConfiguration, reset)
  }

  /**
   * This method updates the global functions stored in the database.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @param {Boolean} reset Use true if 'WebApp Configuration' is completely new
   */
  async updateGlobalFunctions(webAppConfiguration, reset) {
    const globalFunctionsUpdater = new GlobalFunctionsUpdater()
    globalFunctionsUpdater.setAppManager(this.appManager)
    await globalFunctionsUpdater.update(webAppConfiguration, reset)
  }

  /**
   * This method updates the global timers stored in the database.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @param {Boolean} reset Use true if 'WebApp Configuration' is completely new
   */
  async updateGlobalTimers(webAppConfiguration, reset) {
    const globalTimersUpdater = new GlobalTimersUpdater()
    globalTimersUpdater.setAppManager(this.appManager)
    await globalTimersUpdater.update(webAppConfiguration, reset)
  }

  /**
   * This method updates the global scenes stored in the database.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @param {Boolean} reset Use true if 'WebApp Configuration' is completely new
   */
  async updateGlobalScenes(webAppConfiguration, reset) {
    const globalScenesUpdater = new GlobalScenesUpdater()
    globalScenesUpdater.setAppManager(this.appManager)
    await globalScenesUpdater.update(webAppConfiguration, reset)
  }

  /**
   * This method updates the room regulations stored in the database.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @param {Boolean} reset Use true if 'WebApp Configuration' is completely new
   */
  async updateRoomRegulations(webAppConfiguration, reset) {
    const roomRegulationsUpdater = new RoomRegulationsUpdater()
    roomRegulationsUpdater.setAppManager(this.appManager)
    await roomRegulationsUpdater.update(webAppConfiguration, reset)
  }

  /**
   * This method updates the areas stored in the database.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @param {Boolean} reset Use true if 'WebApp Configuration' is completely new
   */
  async updateAreas(webAppConfiguration, reset) {
    const areasUpdater = new AreasUpdater()
    areasUpdater.setAppManager(this.appManager)
    await areasUpdater.update(webAppConfiguration, reset)
  }

  /**
   * This method updates the rooms stored in the database.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @param {Boolean} reset Use true if 'WebApp Configuration' is completely new
   */
  async updateRooms(webAppConfiguration, reset) {
    const roomsUpdater = new RoomsUpdater()
    roomsUpdater.setAppManager(this.appManager)
    await roomsUpdater.update(webAppConfiguration, reset)
  }

  /**
   * This method updates the control elements stored in the database.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @param {Boolean} reset Use true if 'WebApp Configuration' is completely new
   */
  async updateControlElements(webAppConfiguration, reset) {
    const controlElementsUpdater = new ControlElementsUpdater()
    controlElementsUpdater.setAppManager(this.appManager)
    await controlElementsUpdater.update(webAppConfiguration, reset)
  }

  loadConfiguration(webAppConfiguration) {
    const loadResult = new LoadResult()

    try {
      if (webAppConfiguration != null) {
        loadResult.webAppConfiguration = webAppConfiguration
        this.setConfiguration(loadResult.webAppConfiguration)
        loadResult.loaded = true
      }
    } catch (error) {
      loadResult.error = error
    }

    return loadResult
  }

  setConfiguration(webAppConfiguration) {
    if (webAppConfiguration != null) {
      this.setAreas(webAppConfiguration)
      this.setRooms(webAppConfiguration)
      this.setControlElements(webAppConfiguration)
      this.setGlobalFunctions(webAppConfiguration)
      this.setGlobalTimers(webAppConfiguration)
      this.setGlobalScenes(webAppConfiguration)
      this.setRoomClimateElements(webAppConfiguration)
    }
  }

  setAreas(webAppConfiguration) {
    this.areas = []

    if (webAppConfiguration.areas != null) {
      this.areas = webAppConfiguration.areas
    }
  }

  setRooms(webAppConfiguration) {
    this.rooms = []

    if (webAppConfiguration.rooms != null) {
      this.rooms = webAppConfiguration.rooms
    }
  }

  setControlElements(webAppConfiguration) {
    this.controlElements = []

    if (webAppConfiguration.controlElements != null) {
      webAppConfiguration.controlElements.forEach(controlElement => {
        let newControlElement = null

        switch (controlElement.type) {
          case 'switch':
            {
              newControlElement = new ControlElementSwitch(
                controlElement.id,
                controlElement.name,
                controlElement.assignments,
                controlElement.confirmations,
                controlElement.isEnabled,
                controlElement.isFavorite
              )

              const configurationLightMode = new ControlElementSwitchConfiguration()
              configurationLightMode.setIconActive(controlElement.configurationLightMode.iconActive)
              configurationLightMode.setIconInactive(controlElement.configurationLightMode.iconInactive)
              configurationLightMode.setStatusTextActive(controlElement.configurationLightMode.statusTextActive)
              configurationLightMode.setStatusTextInactive(controlElement.configurationLightMode.statusTextInactive)
              configurationLightMode.setStatusTextColorActive(
                controlElement.configurationLightMode.statusTextColorActive
              )
              configurationLightMode.setStatusTextColorInactive(
                controlElement.configurationLightMode.statusTextColorInactive
              )
              newControlElement.setConfigurationForLightMode(configurationLightMode)

              const configurationDarkMode = new ControlElementSwitchConfiguration()
              configurationDarkMode.setIconActive(controlElement.configurationDarkMode.iconActive)
              configurationDarkMode.setIconInactive(controlElement.configurationDarkMode.iconInactive)
              configurationDarkMode.setStatusTextActive(controlElement.configurationDarkMode.statusTextActive)
              configurationDarkMode.setStatusTextInactive(controlElement.configurationDarkMode.statusTextInactive)
              configurationDarkMode.setStatusTextColorActive(controlElement.configurationDarkMode.statusTextColorActive)
              configurationDarkMode.setStatusTextColorInactive(
                controlElement.configurationDarkMode.statusTextColorInactive
              )
              newControlElement.setConfigurationForDarkMode(configurationDarkMode)
            }
            break
          case 'dimmer':
            {
              newControlElement = new ControlElementDimmer(
                controlElement.id,
                controlElement.name,
                controlElement.assignments,
                controlElement.isEnabled,
                controlElement.isFavorite
              )
            }
            break
          case 'blind':
            {
              newControlElement = new ControlElementBlind(
                controlElement.id,
                controlElement.name,
                controlElement.assignments,
                controlElement.isEnabled,
                controlElement.isFavorite
              )
            }
            break
          case 'weather':
            {
              newControlElement = new ControlElementWeather(
                controlElement.id,
                controlElement.name,
                controlElement.assignments,
                controlElement.isEnabled,
                controlElement.isFavorite
              )
            }
            break
          case 'roomclimate':
            {
              newControlElement = new ControlElementRoomClimate(
                controlElement.id,
                controlElement.name,
                controlElement.assignments,
                controlElement.isEnabled,
                controlElement.isFavorite
              )
            }
            break
          case 'ipcamera':
            {
              newControlElement = new ControlElementIpCamera(
                controlElement.id,
                controlElement.name,
                controlElement.assignments,
                controlElement.isEnabled,
                controlElement.isFavorite
              )
            }
            break
          default:
            break
        }

        this.controlElements.push(newControlElement)
      })
    }
  }

  setGlobalFunctions(webAppConfiguration) {
    this.idsOfGlobalFunctions = []

    if (webAppConfiguration.global != null) {
      if (webAppConfiguration.global.globalFunctions != null) {
        if (webAppConfiguration.global.globalFunctions.idsOfControlElements != null) {
          this.idsOfGlobalFunctions = webAppConfiguration.global.globalFunctions.idsOfControlElements
        }
      }
    }
  }

  setGlobalTimers(webAppConfiguration) {
    this.idsOfGlobalTimers = []

    if (webAppConfiguration.global != null) {
      if (webAppConfiguration.global.globalTimers != null) {
        if (webAppConfiguration.global.globalTimers.idsOfControlElements != null) {
          this.idsOfGlobalTimers = webAppConfiguration.global.globalTimers.idsOfControlElements
        }
      }
    }
  }

  setGlobalScenes(webAppConfiguration) {
    this.idsOfGlobalScenes = []

    if (webAppConfiguration.global != null) {
      if (webAppConfiguration.global.globalScenes != null) {
        if (webAppConfiguration.global.globalScenes.idsOfControlElements != null) {
          this.idsOfGlobalScenes = webAppConfiguration.global.globalScenes.idsOfControlElements
        }
      }
    }
  }

  setRoomClimateElements(webAppConfiguration) {
    this.idsOfRoomClimateElements = []

    if (webAppConfiguration.climate != null) {
      if (webAppConfiguration.climate.roomRegulations != null) {
        if (webAppConfiguration.climate.roomRegulations.idsOfControlElements != null) {
          this.idsOfRoomClimateElements = webAppConfiguration.climate.roomRegulations.idsOfControlElements
        }
      }
    }
  }

  getAreas() {
    return this.areas
  }

  getRooms(areaId) {
    const rooms = []
    const area = this.areas.find(area => area.id === areaId)

    if (area != null) {
      const idsOfRooms = area.idsOfRooms

      if (idsOfRooms != null) {
        idsOfRooms.forEach(roomId => {
          const room = this.rooms.find(room => room.id === roomId)

          if (room != null) {
            rooms.push(room)
          }
        })
      }
    }

    return rooms
  }

  getIdsOfControlElements(roomId) {
    let idsOfControlElements = []

    const room = this.rooms.find(room => room.id === roomId)

    if (room != null) {
      idsOfControlElements = room.idsOfControlElements
    }

    return idsOfControlElements
  }

  getControlElement(controlElementId) {
    return this.controlElements.find(controlElement => controlElement.id === controlElementId)
  }

  /**
   * This method returns the type of the 'Control Element' referenced by the given 'Control
   * Element ID'. This method returns null if there is no referenced 'Control Element'.
   *
   * @param {string} controlElementId 'Control Element ID' used for getting type
   * @returns {string} Type of 'Control Element' referenced by 'Control Element ID'
   */
  getTypeOfControlElement(controlElementId) {
    let type = null

    const controlElement = this.controlElements.find(controlElement => controlElement.id === controlElementId)

    if (controlElement != null) {
      type = controlElement.type
    }

    return type
  }

  /**
   * This method returns the information if the 'Control Element' referenced by the given
   * 'Control Element ID' shall be enabled or not.
   *
   * @param {string} controlElementId 'Control Element ID' used for getting enabled value
   * @returns true if 'Control Element' shall be enabled, otherwise false
   */
  isControlElementEnabled(controlElementId) {
    let isEnabled = false

    const controlElement = this.controlElements.find(controlElement => controlElement.id === controlElementId)

    if (controlElement != null) {
      isEnabled = controlElement.isEnabled
    }

    return isEnabled
  }

  getIdsOfGlobalFunctions() {
    return this.idsOfGlobalFunctions
  }

  getIdsOfGlobalTimers() {
    return this.idsOfGlobalTimers
  }

  getIdsOfGlobalScenes() {
    return this.idsOfGlobalScenes
  }

  getIdsOfRoomClimateElements() {
    return this.idsOfRoomClimateElements
  }

  setAsFavorite(controlElementId, isFavorite) {
    const updatingControlElement = this.controlElements.find(
      tmpControlElement => tmpControlElement.id === controlElementId
    )

    updatingControlElement.isFavorite = isFavorite
  }

  getIdsOfFavorites() {
    const idsOfFavorites = []

    const favorites = this.controlElements.filter(controlElement => controlElement.isFavorite)

    favorites.forEach(favorite => {
      idsOfFavorites.push(favorite.id)
    })

    return idsOfFavorites
  }

  cloneControlElement(controlElementId) {
    const controlElement = this.getControlElement(controlElementId)

    let copyOfControlElement = null

    switch (controlElement.type) {
      case 'switch':
        copyOfControlElement = ControlElementSwitch.clone(controlElement)
        break
      case 'dimmer':
        copyOfControlElement = ControlElementDimmer.clone(controlElement)
        break
    }

    return copyOfControlElement
  }

  /**
   * This method returns the first sevent characters of the given ID.
   *
   * @param {String} id ID as UUID
   * @returns ID containing first seven characters
   */
  getShortId(id) {
    let shortId = ''

    if (id) {
      shortId = id.substring(0, 7).toLowerCase()
    }

    return shortId
  }

  /**
   * This method creates a log entry containing the current time and the given
   * message. The log entry is stored into the database.
   *
   * @param {String} message Message used for log entry
   */
  storeLogEntry(message) {
    this.appManager.storeLogEntry({ timestamp: new Date(), message: message })
  }
}

export default ConfigurationManager
