<template>
  <div class="tab-bar-container">
    <div @click.stop.passive="handleClickOnIconGlobal">
      <div v-if="getUsedTheme === 'dark-theme'">
        <img v-show="iconGlobalIsActive" src="@/assets/tabbar/dark-theme/global/global-active.svg" />
        <img v-show="!iconGlobalIsActive" src="@/assets/tabbar/dark-theme/global/global-inactive.svg" />
      </div>
      <div v-else>
        <img v-show="iconGlobalIsActive" src="@/assets/tabbar/light-theme/global/global-active.svg" />
        <img v-show="!iconGlobalIsActive" src="@/assets/tabbar/light-theme/global/global-inactive.svg" />
      </div>
    </div>
    <div @click.stop.passive="handleClickOnIconFavorites">
      <div v-if="getUsedTheme === 'dark-theme'">
        <img v-show="iconFavoritesIsActive" src="@/assets/tabbar/dark-theme/favorites/favorites-active.svg" />
        <img v-show="!iconFavoritesIsActive" src="@/assets/tabbar/dark-theme/favorites/favorites-inactive.svg" />
      </div>
      <div v-else>
        <img v-show="iconFavoritesIsActive" src="@/assets/tabbar/light-theme/favorites/favorites-active.svg" />
        <img v-show="!iconFavoritesIsActive" src="@/assets/tabbar/light-theme/favorites/favorites-inactive.svg" />
      </div>
    </div>
    <div @click.stop.passive="handleClickOnIconRooms">
      <div v-if="getUsedTheme === 'dark-theme'">
        <img v-show="iconRoomsIsActive" src="@/assets/tabbar/dark-theme/rooms/rooms-active.svg" />
        <img v-show="!iconRoomsIsActive" src="@/assets/tabbar/dark-theme/rooms/rooms-inactive.svg" />
      </div>
      <div v-else>
        <img v-show="iconRoomsIsActive" src="@/assets/tabbar/light-theme/rooms/rooms-active.svg" />
        <img v-show="!iconRoomsIsActive" src="@/assets/tabbar/light-theme/rooms/rooms-inactive.svg" />
      </div>
    </div>
    <div @click.stop.passive="handleClickOnIconClimate">
      <div v-if="getUsedTheme === 'dark-theme'">
        <img v-show="iconClimateIsActive" src="@/assets/tabbar/dark-theme/climate/climate-active.svg" />
        <img v-show="!iconClimateIsActive" src="@/assets/tabbar/dark-theme/climate/climate-inactive.svg" />
      </div>
      <div v-else>
        <img v-show="iconClimateIsActive" src="@/assets/tabbar/light-theme/climate/climate-active.svg" />
        <img v-show="!iconClimateIsActive" src="@/assets/tabbar/light-theme/climate/climate-inactive.svg" />
      </div>
    </div>
    <div @click.stop.passive="handleClickOnIconMore">
      <div v-if="getUsedTheme === 'dark-theme'">
        <img v-show="iconMoreIsActive" src="@/assets/tabbar/dark-theme/more/more-active.svg" />
        <img v-show="!iconMoreIsActive" src="@/assets/tabbar/dark-theme/more/more-inactive.svg" />
      </div>
      <div v-else>
        <img v-show="iconMoreIsActive" src="@/assets/tabbar/light-theme/more/more-active.svg" />
        <img v-show="!iconMoreIsActive" src="@/assets/tabbar/light-theme/more/more-inactive.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TabBar',
  emits: ['changeViewEvent'],
  data() {
    return {
      iconGlobalIsActive: false,
      iconFavoritesIsActive: false,
      iconRoomsIsActive: true,
      iconClimateIsActive: false,
      iconMoreIsActive: false
    }
  },
  computed: {
    ...mapGetters(['isModalModusEnabled', 'getUsedTheme'])
  },
  methods: {
    handleClickOnIconGlobal() {
      if (!this.iconGlobalIsActive && !this.isModalModusEnabled) {
        this.deactivateAllIcons()
        this.iconGlobalIsActive = true
        this.$emit('changeViewEvent', 'Global')
      }
    },
    handleClickOnIconFavorites() {
      if (!this.iconFavoritesIsActive && !this.isModalModusEnabled) {
        this.deactivateAllIcons()
        this.iconFavoritesIsActive = true
        this.$emit('changeViewEvent', 'Favorites')
      }
    },
    handleClickOnIconRooms() {
      if (!this.iconRoomsIsActive && !this.isModalModusEnabled) {
        this.deactivateAllIcons()
        this.iconRoomsIsActive = true
        this.$emit('changeViewEvent', 'Rooms')
      }
    },
    handleClickOnIconClimate() {
      if (!this.iconClimateIsActive && !this.isModalModusEnabled) {
        this.deactivateAllIcons()
        this.iconClimateIsActive = true
        this.$emit('changeViewEvent', 'Climate')
      }
    },
    handleClickOnIconMore() {
      if (!this.iconMoreIsActive && !this.isModalModusEnabled) {
        this.deactivateAllIcons()
        this.iconMoreIsActive = true
        this.$emit('changeViewEvent', 'More')
      }
    },
    deactivateAllIcons() {
      this.iconGlobalIsActive = false
      this.iconFavoritesIsActive = false
      this.iconRoomsIsActive = false
      this.iconClimateIsActive = false
      this.iconMoreIsActive = false
    }
  }
}
</script>

<style scoped>
.tab-bar-container {
  display: flex;
  justify-content: space-evenly;
  background-color: var(--background-color-primary);
}

img {
  display: block;
}
</style>
