<template>
  <div class="connection-lost-container">
    <img
      v-if="getUsedTheme === 'dark-theme'"
      class="connection-lost-icon"
      src="@/assets/connectionlost/dark-theme/connection-lost.svg"
    />
    <img v-else class="connection-lost-icon" src="@/assets/connectionlost/light-theme/connection-lost.svg" />
    <div class="connection-lost-text-lost-connection">{{ $t('lostConnection') }}</div>
    <div v-if="isSoapInterfaceRunning" class="connection-lost-text-try-to-reconnect">{{ $t('tryToReconnect') }}</div>
    <div
      v-if="!isSoapInterfaceRunning"
      class="connection-lost-button-try-again"
      :class="
        isButtonTryAgainPressed
          ? 'connection-lost-button-try-again-pressed'
          : 'connection-lost-button-try-again-not-pressed'
      "
      @mousedown.stop.passive="isButtonTryAgainPressed = true"
      @mouseup.stop.passive="isButtonTryAgainPressed = false"
      @mouseleave.stop.passive="isButtonTryAgainPressed = false"
      @touchstart.stop.passive="isButtonTryAgainPressed = true"
      @touchend.stop.passive="isButtonTryAgainPressed = false"
      @touchcancel.stop.passive="isButtonTryAgainPressed = false"
      @click.stop.passive="buttonTryToReconnectClicked"
    >
      {{ $t('tryAgain') }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ConnectionLost',
  emits: ['tryToReconnectEvent'],
  data() {
    return {
      isButtonTryAgainPressed: false
    }
  },
  computed: {
    ...mapGetters(['getUsedTheme', 'isSoapInterfaceRunning'])
  },
  methods: {
    buttonTryToReconnectClicked() {
      this.$emit('tryToReconnectEvent')
    }
  }
}
</script>

<style scoped>
.connection-lost-container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 48px 120px 50px 30px 80px;
  align-items: center;
  justify-items: center;
}

.connection-lost-icon {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
}

.connection-lost-text-lost-connection {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
  font-family: OpenSans;
  font-size: 24px;
  font-weight: 600;
  color: var(--text-color-light-gray);
}

.connection-lost-text-try-to-reconnect {
  grid-column: 1 / span 1;
  grid-row: 4 / span 1;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color-light-gray);
}

.connection-lost-button-try-again {
  grid-column: 1 / span 1;
  grid-row: 5 / span 1;
  width: auto;
  height: 32px;
  line-height: 32px;
  border-radius: 18px;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: var(--text-color-white);
  padding: 0 15px;
}

.connection-lost-button-try-again-pressed {
  background-color: var(--innoxel-blue-color-pressed);
}

.connection-lost-button-try-again-not-pressed {
  background-color: var(--innoxel-blue-color);
}
</style>
