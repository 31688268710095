/**
 * The class LoadResult stores the following information after loading a
 * configuration (e.g. 'WebAppDescriptorname.xml'):
 *
 *  - 'parsed' storing the information whether the configuration
 *    is parsed or not
 *  - 'loaded' storing the information whether the configuration
 *    is loaded or not
 *  - 'webAppConfiguration' storing the configuration
 *  - 'error' storing errors occurred during loading the configuration
 */
class LoadResult {
  /**
   * The constructor of this class initializes the values 'loaded', 'webAppConfiguration'
   * and 'error' with default values.
   */
  constructor() {
    this.parsed = false
    this.loaded = false
    this.webAppConfiguration = null
    this.error = null
  }
}

export default LoadResult
