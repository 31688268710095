import { createI18n } from 'vue-i18n'
import de from '@/i18n/translations/de'
import en from '@/i18n/translations/en'
import fr from '@/i18n/translations/fr'
import it from '@/i18n/translations/it'

const messages = {
  de,
  en,
  fr,
  it
}

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages
})

export default i18n
