<template>
  <div
    class="ip-camera-element-container"
    :class="isPressed ? 'ip-camera-element-pressed-background' : 'ip-camera-element-not-pressed-background'"
    :rerender="rerender"
  >
    <toggle-switch
      v-show="isEditModeEnabled"
      class="ip-camera-element-toggle-switch"
      @mousedown.stop.passive="isPressed = false"
      @mouseup.stop.passive="isPressed = false"
      @mouseleave.stop.passive="isPressed = false"
      @touchstart.stop.passive="isPressed = false"
      @touchend.stop.passive="isPressed = false"
      @touchcancel.stop.passive="isPressed = false"
      @click.stop.passive
      :enable="isEnabled()"
      @toggleEvent="enableControlElement"
    />
    <div class="ip-camera-element-title">
      {{ controlElement.name }}
    </div>
    <div
      class="ip-camera-element-favorite"
      @mousedown.stop.passive="isPressed = false"
      @mouseup.stop.passive="isPressed = false"
      @mouseleave.stop.passive="isPressed = false"
      @touchstart.stop.passive="isPressed = false"
      @touchend.stop.passive="isPressed = false"
      @touchcancel.stop.passive="isPressed = false"
      @click.stop.passive="clickOnFavoriteStar"
    >
      <div v-show="isFavoriteStarEnabled">
        <div v-if="isFavorite">
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/favorite/dark-theme/favoritestar-active.svg" />
          <img v-else src="@/assets/favorite/light-theme/favoritestar-active.svg" />
        </div>
        <div v-else>
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/favorite/dark-theme/favoritestar-inactive.svg" />
          <img v-else src="@/assets/favorite/light-theme/favoritestar-inactive.svg" />
        </div>
      </div>
    </div>
    <div
      class="ip-camera-element-touch-area"
      @mousedown.stop.passive="isPressed = true"
      @mouseup.stop.passive="isPressed = false"
      @mouseleave.stop.passive="isPressed = false"
      @touchstart.stop.passive="isPressed = true"
      @touchend.stop.passive="isPressed = false"
      @touchcancel.stop.passive="isPressed = false"
      @click.stop.passive="clickOnFullScreenButton"
    >
      <ip-camera
        class="ip-camera-element-ip-camera"
        :id="getIpCameraId"
        :name="controlElement.name"
        :width="304"
        :height="228"
        :liveImageUrl="getLiveImageUrl"
        :liveVideoUrl="getLiveVideoUrl"
        :stopRunningVideo="getStopRunningVideo"
      />
      <img
        v-if="getUsedTheme === 'dark-theme'"
        class="ip-camera-element-icon-scale-up"
        src="@/assets/widgets/ipvideoelement/dark-theme/scale-up.svg"
      />
      <img
        v-else
        class="ip-camera-element-icon-scale-up"
        src="@/assets/widgets/ipvideoelement/light-theme/scale-up.svg"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ToggleSwitch from '@/ui/components/widgets/ToggleSwitch.vue'
import IpCamera from '@/ui/components/widgets/IpCamera.vue'

export default {
  name: 'IpCameraElement',
  components: { ToggleSwitch, IpCamera },
  props: {
    controlElementId: {
      type: String,
      required: true
    },
    stopPlayingVideo: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['showAsFullScreenEvent'],
  data() {
    return {
      countRerender: null,
      controlElement: null,
      isFavorite: false,
      liveImageUrl: null,
      liveVideoUrl: null,
      isPressed: false,
      stopRunningVideo: 0
    }
  },
  beforeMount() {
    // Register control element in Vuex store to get feedback if
    // its property 'rerender' has changed
    this.registerControlElement()

    // Initialize 'IP Camera Element'
    this.initialize()
  },
  beforeUnmount() {
    // Unregister control element from Vuex store
    this.deregisterControlElement()
  },
  watch: {
    stopPlayingVideo() {
      if (this.stopPlayingVideo) {
        // Stop running video
        const date = new Date()
        this.stopRunningVideo = date.getTime()
      }
    }
  },
  computed: {
    ...mapGetters([
      'isFavoriteStarEnabled',
      'isEditModeEnabled',
      'getCurrentControlElement',
      'getUsedTheme',
      'getProtocol',
      'getIpAddress',
      'getPort'
    ]),
    rerender() {
      if (this.countRerender !== this.controlElement.rerender) {
        this.update()
      }

      return this.controlElement.rerender
    },
    getLiveImageUrl() {
      return this.liveImageUrl
    },
    getLiveVideoUrl() {
      return this.liveVideoUrl
    },
    getStopRunningVideo() {
      return this.stopRunningVideo
    },
    getIpCameraId() {
      return `${this.controlElementId}_normalscreen`
    }
  },
  methods: {
    ...mapMutations(['addCurrentControlElement', 'removeCurrentControlElement']),
    isEnabled() {
      let isEnabled = false

      if (this.controlElement != null) {
        isEnabled = this.controlElement.isEnabled
      }

      return isEnabled
    },
    enableControlElement(enabled) {
      const controlElement = this.$appManager.getControlElement(this.controlElementId)

      if (controlElement != null) {
        controlElement.isEnabled = enabled

        // Update control element in database
        this.$appManager.updateControlElement(controlElement)
      }
    },
    registerControlElement() {
      // Get control element from 'App Manager'
      const controlElement = this.$appManager.getControlElement(this.controlElementId)

      // Add control element to Vuex store
      this.addCurrentControlElement(controlElement)
    },
    deregisterControlElement() {
      // Remove control element from Vuex store
      this.removeCurrentControlElement(this.controlElement)
    },
    initialize() {
      // Get control element from Vuex store to get feedback if
      // its property 'rerender' has changed
      this.controlElement = this.getCurrentControlElement(this.controlElementId)

      // Update 'IP Camera Element' with information from control element
      this.update()
    },
    update() {
      this.countRerender = this.controlElement.rerender
      this.isFavorite = this.controlElement.isFavorite

      const baseUrl = `${this.getProtocol}//${this.getIpAddress}:${this.getPort}/proxy`
      const moduleIndex = this.controlElement.assignments[0].moduleIndex
      this.liveImageUrl = `${baseUrl}/jpg/liveimage?moduleindex=${moduleIndex}&resolution=640x480`
      this.liveVideoUrl = `${baseUrl}/mjpg/livevideo?moduleindex=${moduleIndex}&resolution=640x480` //&lifetime=120`
    },
    clickOnFavoriteStar() {
      if (this.isEditModeEnabled) {
        this.activateEditMode()
      } else {
        this.toggleFavorite()
      }
    },
    clickOnFullScreenButton() {
      // Stop running video
      const date = new Date()
      this.stopRunningVideo = date.getTime()

      // Show video as full screen
      this.$emit('showAsFullScreenEvent', this.controlElement)
    },
    activateEditMode() {
      this.$emit('showPropertiesDialogEvent', this.controlElement)
    },
    toggleFavorite() {
      this.isFavorite = !this.isFavorite
      this.$appManager.setAsFavorite(this.controlElementId, this.isFavorite)

      // Update control element in database
      // (Why is controlElement a proxy object? Because of Vuex?)
      const tmpControlElement = JSON.parse(JSON.stringify(this.controlElement))
      this.$appManager.updateControlElement(tmpControlElement)
    }
  }
}
</script>

<style scoped>
.ip-camera-element-container {
  display: grid;
  grid-template-columns: 48px 228px 48px;
  grid-template-rows: 48px 228px 48px;
  align-items: center;
  justify-items: center;
  width: 324px;
  height: 324px;
  border-radius: 14px;
}

.ip-camera-element-toggle-switch {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  align-self: start;
  justify-self: start;
  margin: 5px 0px 0px 5px;
}

.ip-camera-element-title {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: var(--text-color-primary);
}

.ip-camera-element-favorite {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  margin: -4px 0 0 4px;
}

.ip-camera-element-touch-area {
  grid-column: 1 / span 3;
  grid-row: 2 / span 2;
  display: grid;
  grid-template-columns: 48px 228px 48px;
  grid-template-rows: 228px 48px;
}

.ip-camera-element-not-pressed-background {
  background-color: var(--background-controlelement);
}

.ip-camera-element-pressed-background {
  background-color: var(--pressed-background-color);
}

.ip-camera-element-ip-camera {
  grid-column: 1 / span 3;
  grid-row: 1 / span 1;
  align-self: center;
  justify-self: center;
}

.ip-camera-element-icon-scale-up {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  align-self: center;
  justify-self: center;
}
</style>
