<template>
  <div
    class="switch-element-container"
    :class="isPressed ? 'switch-element-pressed-background' : 'switch-element-not-pressed-background'"
    :rerender="rerender"
    @mousedown.stop.passive="isPressed = true"
    @mouseup.stop.passive="isPressed = false"
    @mouseleave.stop.passive="isPressed = false"
    @touchstart.stop.passive="isPressed = true"
    @touchend.stop.passive="isPressed = false"
    @touchcancel.stop.passive="isPressed = false"
    @click.stop.passive="clickOnSwitchElement"
  >
    <toggle-switch
      v-show="isEditModeEnabled"
      class="switch-element-toggle-switch"
      @mousedown.stop.passive="isPressed = false"
      @mouseup.stop.passive="isPressed = false"
      @mouseleave.stop.passive="isPressed = false"
      @touchstart.stop.passive="isPressed = false"
      @touchend.stop.passive="isPressed = false"
      @touchcancel.stop.passive="isPressed = false"
      @click.stop.passive
      :enable="isEnabled()"
      @toggleEvent="enableControlElement"
    />
    <div class="switch-element-title">
      {{ controlElement.name }}
    </div>
    <div
      class="switch-element-favorite"
      @mousedown.stop.passive="isPressed = false"
      @mouseup.stop.passive="isPressed = false"
      @mouseleave.stop.passive="isPressed = false"
      @touchstart.stop.passive="isPressed = false"
      @touchend.stop.passive="isPressed = false"
      @touchcancel.stop.passive="isPressed = false"
      @click.stop.passive="clickOnFavoriteStar"
    >
      <div v-show="isFavoriteStarEnabled">
        <div v-if="isFavorite">
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/favorite/dark-theme/favoritestar-active.svg" />
          <img v-else src="@/assets/favorite/light-theme/favoritestar-active.svg" />
        </div>
        <div v-else>
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/favorite/dark-theme/favoritestar-inactive.svg" />
          <img v-else src="@/assets/favorite/light-theme/favoritestar-inactive.svg" />
        </div>
      </div>
    </div>
    <div class="switch-element-icon">
      <img v-if="state" :src="iconActive" />
      <img v-else :src="iconInactive" />
    </div>
    <div v-if="state" class="switch-element-status" :style="statusTextColorActive">
      {{ statusTextActive }}
    </div>
    <div v-else class="switch-element-status" :style="statusTextColorInactive">
      {{ statusTextInactive }}
    </div>
    <small-confirm-dialog
      v-if="isConfirmDialogShown"
      :message="messageConfirmDialog"
      :textOkButton="buttonOkConfirmDialog"
      :textCancelButton="buttonCancelConfirmDialog"
      @cancelEvent="closeDialog"
      @okEvent="continueToggleState"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ToggleSwitch from '@/ui/components/widgets/ToggleSwitch.vue'
import SmallConfirmDialog from '@/ui/components/ConfirmDialogs/SmallConfirmDialog.vue'

export default {
  name: 'SwitchElement',
  components: { ToggleSwitch, SmallConfirmDialog },
  props: {
    controlElementId: {
      type: String,
      required: true
    }
  },
  emits: ['showPropertiesDialogEvent'],
  data() {
    return {
      countRerender: null,
      controlElement: null,
      isPressed: false,
      isFavorite: false,
      state: false,
      statusTextActive: null,
      statusTextInactive: null,
      iconActive: null,
      iconInactive: null,
      isConfirmDialogShown: false,
      messageConfirmDialog: null,
      buttonOkConfirmDialog: null,
      buttonCancelConfirmDialog: null,
      resetStateAfterFewSeconds: false,
      resetStateTimer: null
    }
  },
  beforeMount() {
    // Register control element in Vuex store to get feedback if
    // its property 'rerender' has changed
    this.registerControlElement()

    // Initialize 'Switch Element'
    this.initialize()
  },
  beforeUnmount() {
    // Unregister control element from Vuex store
    this.deregisterControlElement()
  },
  computed: {
    ...mapGetters(['isFavoriteStarEnabled', 'isEditModeEnabled', 'getCurrentControlElement', 'getUsedTheme']),
    rerender() {
      if (this.countRerender !== this.controlElement.rerender) {
        this.update()
      }

      return this.controlElement.rerender
    },
    statusTextColorActive() {
      let statusTextColorActive = ''

      if (this.getUsedTheme === 'dark-theme') {
        statusTextColorActive = this.controlElement.configurationDarkMode.statusTextColorActive
      } else {
        statusTextColorActive = this.controlElement.configurationLightMode.statusTextColorActive
      }

      return 'color: ' + statusTextColorActive + ';'
    },
    statusTextColorInactive() {
      let statusTextColorInactive = ''

      if (this.getUsedTheme === 'dark-theme') {
        statusTextColorInactive = this.controlElement.configurationDarkMode.statusTextColorInactive
      } else {
        statusTextColorInactive = this.controlElement.configurationLightMode.statusTextColorInactive
      }

      return 'color: ' + statusTextColorInactive + ';'
    }
  },
  methods: {
    ...mapMutations(['addCurrentControlElement', 'removeCurrentControlElement', 'enableModalModus']),
    isEnabled() {
      let isEnabled = false

      if (this.controlElement != null) {
        isEnabled = this.controlElement.isEnabled
      }

      return isEnabled
    },
    enableControlElement(enabled) {
      const controlElement = this.$appManager.getControlElement(this.controlElementId)

      if (controlElement != null) {
        controlElement.isEnabled = enabled

        // Update control element in database
        this.$appManager.updateControlElement(controlElement)
      }
    },
    registerControlElement() {
      // Get control element from 'App Manager'
      const controlElement = this.$appManager.getControlElement(this.controlElementId)

      // Add control element to Vuex store
      this.addCurrentControlElement(controlElement)

      // Add to module list
      this.$appManager.addToModuleList(controlElement)
    },
    deregisterControlElement() {
      // Remove control element from Vuex store
      this.removeCurrentControlElement(this.controlElement)

      // Remove from module list
      this.$appManager.removeFromModuleList(this.controlElement)
    },
    initialize() {
      // Get control element from Vuex store to get feedback if
      // its property 'rerender' has changed
      this.controlElement = this.getCurrentControlElement(this.controlElementId)

      // Update 'Switch Element' with informatin from control element
      this.update()
    },
    update() {
      this.countRerender = this.controlElement.rerender
      this.isFavorite = this.controlElement.isFavorite
      this.resetStateAfterFewSeconds = this.controlElement.resetStateAfterFewSeconds

      if (this.getUsedTheme === 'dark-theme') {
        this.iconActive = require('@/assets/icons/dark-theme/' + this.controlElement.configurationDarkMode.iconActive)
        this.iconInactive = require('@/assets/icons/dark-theme/' +
          this.controlElement.configurationDarkMode.iconInactive)
        this.statusTextActive = this.controlElement.configurationDarkMode.statusTextActive
        this.statusTextInactive = this.controlElement.configurationDarkMode.statusTextInactive
      } else {
        this.iconActive = require('@/assets/icons/light-theme/' + this.controlElement.configurationLightMode.iconActive)
        this.iconInactive = require('@/assets/icons/light-theme/' +
          this.controlElement.configurationLightMode.iconInactive)
        this.statusTextActive = this.controlElement.configurationLightMode.statusTextActive
        this.statusTextInactive = this.controlElement.configurationLightMode.statusTextInactive
      }

      this.state = this.controlElement.state
    },
    clickOnFavoriteStar() {
      if (this.isEditModeEnabled) {
        this.activateEditMode()
      } else {
        this.toggleFavorite()
      }
    },
    clickOnSwitchElement() {
      if (this.isEditModeEnabled) {
        this.activateEditMode()
      } else {
        this.toggleState()
      }
    },
    activateEditMode() {
      this.$emit('showPropertiesDialogEvent', this.controlElement)
    },
    toggleFavorite() {
      this.isFavorite = !this.isFavorite
      this.$appManager.setAsFavorite(this.controlElementId, this.isFavorite)

      // Update control element in database
      // (Why is controlElement a proxy object? Because of Vuex?)
      const tmpControlElement = JSON.parse(JSON.stringify(this.controlElement))
      this.$appManager.updateControlElement(tmpControlElement)
    },
    toggleState() {
      // Check if a confirmation by the user is needed
      if (this.controlElement.confirmations) {
        if (this.controlElement.confirmations.length > 0) {
          // Confirmation by the user needed

          let confirmation = null

          for (let i = 0; i < this.controlElement.confirmations.length; i++) {
            // Get confirmation
            confirmation = this.controlElement.confirmations[i]

            if (this.state && confirmation.transition === 'on:off') {
              // State is 'On' and transition is 'On -> Off'
              break
            } else if (!this.state && confirmation.transition === 'off:on') {
              // State is 'Off' and transition is 'Off -> On'
              break
            } else if (confirmation.transition === 'all') {
              // Transition is 'All'
              break
            }
          }

          if (confirmation) {
            // Get message, text for button 'OK and text for
            // button 'Cancel' for for confirm dialog
            this.messageConfirmDialog = confirmation.message
            this.buttonOkConfirmDialog = confirmation.keyOk
            this.buttonCancelConfirmDialog = confirmation.keyAbort

            // Enable modal modus
            this.enableModalModus(true)

            // Show confirm dialog
            this.isConfirmDialogShown = true
          }
        }
      }

      if (!this.isConfirmDialogShown) {
        // No confirmation by the user needed

        // Toggle state immediately
        this.continueToggleState()
      }
    },
    closeDialog() {
      this.enableModalModus(false)
      this.isConfirmDialogShown = false
      this.messageConfirmDialog = null
      this.buttonOkConfirmDialog = null
      this.buttonCancelConfirmDialog = null
    },
    continueToggleState() {
      if (this.isConfirmDialogShown) {
        this.closeDialog()
      }

      if (this.resetStateAfterFewSeconds) {
        if (this.resetStateTimer) {
          clearTimeout(this.resetStateTimer)
          this.resetStateTimer = null
        }

        this.resetStateTimer = setTimeout(() => {
          this.controlElement.setState(false)
          this.resetStateTimer = null
        }, 3000)

        this.controlElement.setState(true)
      } else {
        this.controlElement.setState(!this.state)
      }

      this.$appManager.toggleState(this.controlElementId)
    }
  }
}
</script>

<style scoped>
.switch-element-container {
  display: grid;
  grid-template-columns: 28px 20px 62px 20px 28px;
  grid-template-rows: 48px 62px 48px;
  align-items: center;
  justify-items: center;
  width: 158px;
  height: 158px;
  border-radius: 14px;
}

.switch-element-not-pressed-background {
  background-color: var(--background-controlelement);
}

.switch-element-pressed-background {
  background-color: var(--pressed-background-color);
}

.switch-element-toggle-switch {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  align-self: start;
  justify-self: start;
  margin: 5px 0px 0px 5px;
}

.switch-element-title {
  grid-column: 2 / span 3;
  grid-row: 1 / span 1;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: var(--text-color-primary);
}

.switch-element-favorite {
  grid-column: 4 / span 2;
  grid-row: 1 / span 1;
  margin: -4px 0 0 4px;
}

.switch-element-icon {
  grid-column: 1 / span 5;
  grid-row: 2 / span 1;
  justify-self: center;
  align-self: center;
}

.switch-element-status {
  grid-column: 1 / span 5;
  grid-row: 3 / span 1;
  font-family: OpenSans;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}
</style>
