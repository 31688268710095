class RoomsUpdater {
  constructor() {
    this.appManager = null
    this.logChanges = true
  }

  setAppManager(appManager) {
    this.appManager = appManager
  }

  /**
   * This method updates the rooms stored in the database.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @param {Boolean} reset Use true if 'WebApp Configuration' is completely new
   */
  async update(webAppConfiguration, reset) {
    if (this.appManager != null) {
      // Variable containing rooms to be stored in database
      let rooms = []

      if (reset) {
        // 'WebApp Configuration' is completely new

        // Get all rooms stored in 'WebApp Configuration'
        rooms = webAppConfiguration.rooms
      } else {
        // 'WebApp Configuration' is updated

        // Get all rooms stored in 'WebApp Configuration'
        // and log all changes made to rooms
        rooms = await this.getUpdatedRooms(webAppConfiguration)
      }

      // Delete all rooms stored in database
      await this.appManager.deleteRooms()

      // Store new/changed rooms into database
      await this.appManager.storeRooms(rooms)
    }
  }

  /**
   * This method updates the rooms in the database and also logs the changes.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @returns Array containing rooms to be stored in database
   */
  async getUpdatedRooms(webAppConfiguration) {
    // Variable containing rooms to be stored in database
    let rooms = []

    // Get rooms stored in database
    const storedRooms = await this.appManager.getStoredRooms()

    // Get rooms stored in 'WebApp Configuration'
    const roomsInWebAppConfiguration = webAppConfiguration.rooms

    storedRooms.forEach(storedRoom => {
      // Check if 'Stored Room' is also available in 'WebApp Configuration'
      const roomInWebAppConfiguration = roomsInWebAppConfiguration.find(element => element.id === storedRoom.id)

      if (roomInWebAppConfiguration) {
        // 'Stored Room' is also available in 'WebApp Configuration'

        // Add 'Room' as defined in 'WebApp Configuration' to
        // variable containing rooms to be stored into database
        rooms.push(roomInWebAppConfiguration)

        if (this.logChanges) {
          // Log changes made to rooms

          // Get name of 'Stored Room' and of room stored in 'WebApp Configuration'
          const nameOfStoredRoom = storedRoom.name
          const nameOfRoomInWebAppConfiguration = roomInWebAppConfiguration.name

          // Check if name of room has changed
          const nameOfRoomChanged = nameOfStoredRoom !== nameOfRoomInWebAppConfiguration

          // Get IDs of control elements of 'Stored Room' and of room stored in 'WebApp Configuration'
          const idsOfControlElementsOfStoredRoom = storedRoom.idsOfControlElements
          const idsOfControlElementsOfRoomInWebAppConfiguration = roomInWebAppConfiguration.idsOfControlElements

          // Check if at least one 'ID of control element' is removed from room
          let idOfControlElementRemoved = false

          idsOfControlElementsOfStoredRoom.forEach(idOfControlElement => {
            // Check if 'ID of control element' is also available in room stored in 'WebApp Configuration'
            const foundIdOfControlElement = idsOfControlElementsOfRoomInWebAppConfiguration.find(
              element => element === idOfControlElement
            )

            if (!foundIdOfControlElement) {
              // 'ID of control element' is not available in room stored in 'WebApp Configuration'
              idOfControlElementRemoved = true
            }
          })

          // Check if at least one 'ID of control element' is added to room
          let idOfControlElementAdded = false

          idsOfControlElementsOfRoomInWebAppConfiguration.forEach(idOfControlElement => {
            // Check if 'ID of control element' is also available in stored room
            const foundIdOfControlElement = idsOfControlElementsOfStoredRoom.find(
              element => element === idOfControlElement
            )

            if (!foundIdOfControlElement) {
              // 'ID of control element' is not available in stored room
              idOfControlElementAdded = true
            }
          })

          if (nameOfRoomChanged || idOfControlElementRemoved || idOfControlElementAdded) {
            // There are changes made to room

            // Define counter of changes
            let counter = 0

            // Get ID of room (short version)
            const shortRoomId = this.getShortId(storedRoom.id)

            // Define message describing changes made to room
            let message = `Room '${storedRoom.name}' has been changed (Room ID: ${shortRoomId}):\n`

            if (nameOfStoredRoom !== nameOfRoomInWebAppConfiguration) {
              // Name of room has changed

              // Increment counter of changes
              counter += 1

              // Add text describing name change to message
              message += `\n${counter}) Name changed from '${nameOfStoredRoom}' to '${nameOfRoomInWebAppConfiguration}'`
            }

            if (idOfControlElementRemoved) {
              // 'ID of control element' is removed from room

              idsOfControlElementsOfStoredRoom.forEach(idOfControlElement => {
                const foundIdOfControlElement = idsOfControlElementsOfRoomInWebAppConfiguration.find(
                  element => element === idOfControlElement
                )

                if (!foundIdOfControlElement) {
                  // Increment counter of changes
                  counter += 1

                  // Get ID of control element (short version)
                  const shortControlElementId = this.getShortId(idOfControlElement)

                  // Add text describing room change to message
                  message += `\n${counter}) Removed control element (ID: ${shortControlElementId})`
                }
              })
            }

            if (idOfControlElementAdded) {
              // 'ID of control element' is added to room

              idsOfControlElementsOfRoomInWebAppConfiguration.forEach(idOfControlElement => {
                const foundIdOfControlElement = idsOfControlElementsOfStoredRoom.find(
                  element => element === idOfControlElement
                )

                if (!foundIdOfControlElement) {
                  // Increment counter of changes
                  counter += 1

                  // Get ID of control element (short version)
                  const shortControlElementId = this.getShortId(idOfControlElement)

                  // Add text describing room change to message
                  message += `\n${counter}) Added control element (ID: ${shortControlElementId})`
                }
              })
            }

            // Store log entry in database
            this.storeLogEntry(message)
          }
        }
      } else {
        // 'Stored Room' is not available in 'WebApp Configuration'

        if (this.logChanges) {
          // Log changes made to rooms

          // Get ID of room (short version)
          const shortRoomId = this.getShortId(storedRoom.id)

          // Define message describing removing room
          const message = `Room '${storedRoom.name}' removed (Room ID: ${shortRoomId})`

          // Store log entry in database
          this.storeLogEntry(message)
        }
      }
    })

    roomsInWebAppConfiguration.forEach(roomInWebAppConfiguration => {
      // Check if 'Room in WebApp Configuration' is also available in 'Stored Rooms'
      const roomInStoredRooms = storedRooms.find(element => element.id === roomInWebAppConfiguration.id)

      if (!roomInStoredRooms) {
        // 'Room in WebApp Configuration' is not available in 'Stored Rooms'

        // Add 'Room' as defined in 'WebApp Configuration' to
        // variable containing rooms to be stored into database
        rooms.push(roomInWebAppConfiguration)

        if (this.logChanges) {
          // Log changes made to rooms

          // Get ID of room (short version)
          const shortRoomId = this.getShortId(roomInWebAppConfiguration.id)

          // Define message describing adding room
          const message = `Room '${roomInWebAppConfiguration.name}' added (Room ID: ${shortRoomId})`

          // Store log entry in database
          this.storeLogEntry(message)
        }
      }
    })

    return rooms
  }

  /**
   * This method returns the first sevent characters of the given ID.
   *
   * @param {String} id ID as UUID
   * @returns ID containing first seven characters
   */
  getShortId(id) {
    let shortId = ''

    if (id) {
      shortId = id.substring(0, 7).toLowerCase()
    }

    return shortId
  }

  /**
   * This method creates a log entry containing the current time and the given
   * message. The log entry is stored into the database.
   *
   * @param {String} message Message used for log entry
   */
  storeLogEntry(message) {
    this.appManager.storeLogEntry({ timestamp: new Date(), message: message })
  }
}

export default RoomsUpdater
