<template>
  <div class="blind-element-settings-container">
    <div
      class="blind-element-settings-icon blind-element-settings-slats-position-1"
      :class="
        isSlatsPosition1Pressed || isSlatsPosition1Active ? 'blind-element-settings-icon-left-pressed-background' : ''
      "
      @mousedown.stop.passive="isSlatsPosition1Pressed = true && !locked"
      @mouseup.stop.passive="isSlatsPosition1Pressed = false"
      @mouseleave.stop.passive="isSlatsPosition1Pressed = false"
      @touchstart.stop.passive="isSlatsPosition1Pressed = true && !locked"
      @touchend.stop.passive="isSlatsPosition1Pressed = false"
      @touchcancel.stop.passive="isSlatsPosition1Pressed = false"
      @click.stop.passive="clickOnSlatsPosition1"
    >
      <img
        v-show="isSlatsPosition1Active && getUsedTheme === 'dark-theme'"
        src="@/assets/widgets/blindelement/dark-theme/slats-position-1-selected.svg"
      />
      <img
        v-show="!isSlatsPosition1Active && getUsedTheme === 'dark-theme'"
        src="@/assets/widgets/blindelement/dark-theme/slats-position-1.svg"
      />
      <img
        v-show="isSlatsPosition1Active && getUsedTheme === 'light-theme'"
        src="@/assets/widgets/blindelement/light-theme/slats-position-1-selected.svg"
      />
      <img
        v-show="!isSlatsPosition1Active && getUsedTheme === 'light-theme'"
        src="@/assets/widgets/blindelement/light-theme/slats-position-1.svg"
      />
    </div>
    <div class="blind-element-settings-separator blind-element-settings-separator-position-1" />
    <div
      class="blind-element-settings-icon blind-element-settings-slats-position-2"
      :class="isSlatsPosition2Pressed || isSlatsPosition2Active ? 'blind-element-settings-icon-pressed-background' : ''"
      @mousedown.stop.passive="isSlatsPosition2Pressed = true && !locked"
      @mouseup.stop.passive="isSlatsPosition2Pressed = false"
      @mouseleave.stop.passive="isSlatsPosition2Pressed = false"
      @touchstart.stop.passive="isSlatsPosition2Pressed = true && !locked"
      @touchend.stop.passive="isSlatsPosition2Pressed = false"
      @touchcancel.stop.passive="isSlatsPosition2Pressed = false"
      @click.stop.passive="clickOnSlatsPosition2"
    >
      <img
        v-show="isSlatsPosition2Active && getUsedTheme === 'dark-theme'"
        src="@/assets/widgets/blindelement/dark-theme/slats-position-2-selected.svg"
      />
      <img
        v-show="!isSlatsPosition2Active && getUsedTheme === 'dark-theme'"
        src="@/assets/widgets/blindelement/dark-theme/slats-position-2.svg"
      />
      <img
        v-show="isSlatsPosition2Active && getUsedTheme === 'light-theme'"
        src="@/assets/widgets/blindelement/light-theme/slats-position-2-selected.svg"
      />
      <img
        v-show="!isSlatsPosition2Active && getUsedTheme === 'light-theme'"
        src="@/assets/widgets/blindelement/light-theme/slats-position-2.svg"
      />
    </div>
    <div class="blind-element-settings-separator blind-element-settings-separator-position-2" />
    <div
      class="blind-element-settings-icon blind-element-settings-slats-position-3"
      :class="isSlatsPosition3Pressed || isSlatsPosition3Active ? 'blind-element-settings-icon-pressed-background' : ''"
      @mousedown.stop.passive="isSlatsPosition3Pressed = true && !locked"
      @mouseup.stop.passive="isSlatsPosition3Pressed = false"
      @mouseleave.stop.passive="isSlatsPosition3Pressed = false"
      @touchstart.stop.passive="isSlatsPosition3Pressed = true && !locked"
      @touchend.stop.passive="isSlatsPosition3Pressed = false"
      @touchcancel.stop.passive="isSlatsPosition3Pressed = false"
      @click.stop.passive="clickOnSlatsPosition3"
    >
      <img
        v-show="isSlatsPosition3Active && getUsedTheme === 'dark-theme'"
        src="@/assets/widgets/blindelement/dark-theme/slats-position-3-selected.svg"
      />
      <img
        v-show="!isSlatsPosition3Active && getUsedTheme === 'dark-theme'"
        src="@/assets/widgets/blindelement/dark-theme/slats-position-3.svg"
      />
      <img
        v-show="isSlatsPosition3Active && getUsedTheme === 'light-theme'"
        src="@/assets/widgets/blindelement/light-theme/slats-position-3-selected.svg"
      />
      <img
        v-show="!isSlatsPosition3Active && getUsedTheme === 'light-theme'"
        src="@/assets/widgets/blindelement/light-theme/slats-position-3.svg"
      />
    </div>
    <div class="blind-element-settings-separator blind-element-settings-separator-position-3" />
    <div
      class="blind-element-settings-icon blind-element-settings-slats-position-4"
      :class="isSlatsPosition4Pressed || isSlatsPosition4Active ? 'blind-element-settings-icon-pressed-background' : ''"
      @mousedown.stop.passive="isSlatsPosition4Pressed = true && !locked"
      @mouseup.stop.passive="isSlatsPosition4Pressed = false"
      @mouseleave.stop.passive="isSlatsPosition4Pressed = false"
      @touchstart.stop.passive="isSlatsPosition4Pressed = true && !locked"
      @touchend.stop.passive="isSlatsPosition4Pressed = false"
      @touchcancel.stop.passive="isSlatsPosition4Pressed = false"
      @click.stop.passive="clickOnSlatsPosition4"
    >
      <img
        v-show="isSlatsPosition4Active && getUsedTheme === 'dark-theme'"
        src="@/assets/widgets/blindelement/dark-theme/slats-position-4-selected.svg"
      />
      <img
        v-show="!isSlatsPosition4Active && getUsedTheme === 'dark-theme'"
        src="@/assets/widgets/blindelement/dark-theme/slats-position-4.svg"
      />
      <img
        v-show="isSlatsPosition4Active && getUsedTheme === 'light-theme'"
        src="@/assets/widgets/blindelement/light-theme/slats-position-4-selected.svg"
      />
      <img
        v-show="!isSlatsPosition4Active && getUsedTheme === 'light-theme'"
        src="@/assets/widgets/blindelement/light-theme/slats-position-4.svg"
      />
    </div>
    <div class="blind-element-settings-separator blind-element-settings-separator-position-4" />
    <div
      class="blind-element-settings-icon blind-element-settings-slats-position-5"
      :class="isSlatsPosition5Pressed || isSlatsPosition5Active ? 'blind-element-settings-icon-pressed-background' : ''"
      @mousedown.stop.passive="isSlatsPosition5Pressed = true && !locked"
      @mouseup.stop.passive="isSlatsPosition5Pressed = false"
      @mouseleave.stop.passive="isSlatsPosition5Pressed = false"
      @touchstart.stop.passive="isSlatsPosition5Pressed = true && !locked"
      @touchend.stop.passive="isSlatsPosition5Pressed = false"
      @touchcancel.stop.passive="isSlatsPosition5Pressed = false"
      @click.stop.passive="clickOnSlatsPosition5"
    >
      <img
        v-show="isSlatsPosition5Active && getUsedTheme === 'dark-theme'"
        src="@/assets/widgets/blindelement/dark-theme/slats-position-5-selected.svg"
      />
      <img
        v-show="!isSlatsPosition5Active && getUsedTheme === 'dark-theme'"
        src="@/assets/widgets/blindelement/dark-theme/slats-position-5.svg"
      />
      <img
        v-show="isSlatsPosition5Active && getUsedTheme === 'light-theme'"
        src="@/assets/widgets/blindelement/light-theme/slats-position-5-selected.svg"
      />
      <img
        v-show="!isSlatsPosition5Active && getUsedTheme === 'light-theme'"
        src="@/assets/widgets/blindelement/light-theme/slats-position-5.svg"
      />
    </div>
    <div class="blind-element-settings-separator blind-element-settings-separator-position-5" />
    <div
      class="blind-element-settings-icon blind-element-settings-slats-position-6"
      :class="isSlatsPosition6Pressed || isSlatsPosition6Active ? 'blind-element-settings-icon-pressed-background' : ''"
      @mousedown.stop.passive="isSlatsPosition6Pressed = true && !locked"
      @mouseup.stop.passive="isSlatsPosition6Pressed = false"
      @mouseleave.stop.passive="isSlatsPosition6Pressed = false"
      @touchstart.stop.passive="isSlatsPosition6Pressed = true && !locked"
      @touchend.stop.passive="isSlatsPosition6Pressed = false"
      @touchcancel.stop.passive="isSlatsPosition6Pressed = false"
      @click.stop.passive="clickOnSlatsPosition6"
    >
      <img
        v-show="isSlatsPosition6Active && getUsedTheme === 'dark-theme'"
        src="@/assets/widgets/blindelement/dark-theme/slats-position-6-selected.svg"
      />
      <img
        v-show="!isSlatsPosition6Active && getUsedTheme === 'dark-theme'"
        src="@/assets/widgets/blindelement/dark-theme/slats-position-6.svg"
      />
      <img
        v-show="isSlatsPosition6Active && getUsedTheme === 'light-theme'"
        src="@/assets/widgets/blindelement/light-theme/slats-position-6-selected.svg"
      />
      <img
        v-show="!isSlatsPosition6Active && getUsedTheme === 'light-theme'"
        src="@/assets/widgets/blindelement/light-theme/slats-position-6.svg"
      />
    </div>
    <div class="blind-element-settings-separator blind-element-settings-separator-position-6" />
    <div
      class="blind-element-settings-icon blind-element-settings-settings"
      :class="isSettingsPressed ? 'blind-element-settings-icon-right-pressed-background' : ''"
      @mousedown.stop.passive="isSettingsPressed = true"
      @mouseup.stop.passive="isSettingsPressed = false"
      @mouseleave.stop.passive="isSettingsPressed = false"
      @touchstart.stop.passive="isSettingsPressed = true"
      @touchend.stop.passive="isSettingsPressed = false"
      @touchcancel.stop.passive="isSettingsPressed = false"
      @click.stop.passive="clickOnSettings"
    >
      <img v-show="getUsedTheme === 'dark-theme'" src="@/assets/widgets/blindelement/dark-theme/settings.svg" />
      <img v-show="getUsedTheme === 'light-theme'" src="@/assets/widgets/blindelement/light-theme/settings.svg" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BlindElementSettings',
  props: {
    locked: {
      type: Boolean,
      required: false,
      default: false
    },
    slatsAngle: {
      type: Number,
      required: true
    }
  },
  emits: ['slatsAngleChangedEvent', 'showSettingsDialogEvent'],
  data() {
    return {
      isSlatsPosition1Pressed: false,
      isSlatsPosition2Pressed: false,
      isSlatsPosition3Pressed: false,
      isSlatsPosition4Pressed: false,
      isSlatsPosition5Pressed: false,
      isSlatsPosition6Pressed: false,
      isSlatsPosition1Active: false,
      isSlatsPosition2Active: false,
      isSlatsPosition3Active: false,
      isSlatsPosition4Active: false,
      isSlatsPosition5Active: false,
      isSlatsPosition6Active: false,
      isSettingsPressed: false
    }
  },
  computed: {
    ...mapGetters(['getUsedTheme'])
  },
  mounted() {
    this.updateSelectedSlatsPosition()
  },
  watch: {
    slatsAngle() {
      this.updateSelectedSlatsPosition()
    }
  },
  methods: {
    updateSelectedSlatsPosition() {
      this.isSlatsPosition1Active = this.slatsAngle === 0
      this.isSlatsPosition2Active = this.slatsAngle === 18
      this.isSlatsPosition3Active = this.slatsAngle === 36
      this.isSlatsPosition4Active = this.slatsAngle === 54
      this.isSlatsPosition5Active = this.slatsAngle === 72
      this.isSlatsPosition6Active = this.slatsAngle === 90
    },
    clickOnSlatsPosition1() {
      if (!this.locked) {
        this.$emit('slatsAngleChangedEvent', 0)
      }
    },
    clickOnSlatsPosition2() {
      if (!this.locked) {
        this.$emit('slatsAngleChangedEvent', 18)
      }
    },
    clickOnSlatsPosition3() {
      if (!this.locked) {
        this.$emit('slatsAngleChangedEvent', 36)
      }
    },
    clickOnSlatsPosition4() {
      if (!this.locked) {
        this.$emit('slatsAngleChangedEvent', 54)
      }
    },
    clickOnSlatsPosition5() {
      if (!this.locked) {
        this.$emit('slatsAngleChangedEvent', 72)
      }
    },
    clickOnSlatsPosition6() {
      if (!this.locked) {
        this.$emit('slatsAngleChangedEvent', 90)
      }
    },
    clickOnSettings() {
      this.$emit('showSettingsDialogEvent')
    }
  }
}
</script>

<style scoped>
.blind-element-settings-container {
  display: grid;
  grid-template-columns: auto repeat(6, 1px auto);
  align-items: center;
  justify-items: center;
  border: solid 1px var(--border-color-secondary);
  border-radius: 9px;
}

.blind-element-settings-icon {
  justify-self: stretch;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blind-element-settings-separator {
  width: 1px;
  height: 28px;
  border-radius: 0.5px;
  background-color: var(--border-color-secondary);
}

.blind-element-settings-icon-left-pressed-background {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin: 1px;
  background-color: var(--border-color-secondary);
}

.blind-element-settings-icon-pressed-background {
  margin: 1px;
  background-color: var(--border-color-secondary);
}

.blind-element-settings-icon-right-pressed-background {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  margin: 1px;
  background-color: var(--border-color-secondary);
}

.blind-element-settings-slats-position-1 {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin: 1px;
}

.blind-element-settings-separator-position-1 {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
}

.blind-element-settings-slats-position-2 {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  margin: 1px;
}

.blind-element-settings-separator-position-2 {
  grid-column: 4 / span 1;
  grid-row: 1 / span 1;
}

.blind-element-settings-slats-position-3 {
  grid-column: 5 / span 1;
  grid-row: 1 / span 1;
  margin: 1px;
}

.blind-element-settings-separator-position-3 {
  grid-column: 6 / span 1;
  grid-row: 1 / span 1;
}

.blind-element-settings-slats-position-4 {
  grid-column: 7 / span 1;
  grid-row: 1 / span 1;
  margin: 1px;
}

.blind-element-settings-separator-position-4 {
  grid-column: 8 / span 1;
  grid-row: 1 / span 1;
}

.blind-element-settings-slats-position-5 {
  grid-column: 9 / span 1;
  grid-row: 1 / span 1;
  margin: 1px;
}

.blind-element-settings-separator-position-5 {
  grid-column: 10 / span 1;
  grid-row: 1 / span 1;
}

.blind-element-settings-slats-position-6 {
  grid-column: 11 / span 1;
  grid-row: 1 / span 1;
  margin: 1px;
}

.blind-element-settings-separator-position-6 {
  grid-column: 12 / span 1;
  grid-row: 1 / span 1;
}

.blind-element-settings-settings {
  grid-column: 13 / span 1;
  grid-row: 1 / span 1;
  margin: 1px;
}
</style>
