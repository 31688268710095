<template>
  <div v-if="isPlaceholderForWebUiMenuEnabled" class="rooms-view-container">
    <areas
      v-show="showAreas()"
      class="rooms-view-areas-with-placeholder-webui-menu"
      :areas="areas"
      @collapsedEvent="enableModalModus(false)"
      @expandedEvent="enableModalModus(true)"
      @areaChangedEvent="changeArea"
    />
    <rooms
      :class="
        showAreas()
          ? 'rooms-view-rooms-with-areas-and-with-placeholder-webui-menu'
          : 'rooms-view-rooms-without-areas-and-with-placeholder-webui-menu'
      "
      :rooms="rooms"
      :showSeparator="showAreas()"
      @roomChangedEvent="changeRoom"
    />
    <div class="rooms-view-placeholder-webui-menu" />
    <div v-show="isConnectionLost" class="rooms-view-connection-lost">
      <connection-lost @tryToReconnectEvent="tryToReconnect" />
    </div>
    <div
      id="scrollable-room-view"
      v-show="!isConnectionLost"
      :class="showAreas() ? 'rooms-view-control-elements-with-areas' : 'rooms-view-control-elements-without-areas'"
    >
      <control-elements :idsOfControlElements="idsOfControlElements" />
      <div v-if="showScrolledToBottomSpace" class="rooms-view-scrolled-to-bottom-space" />
    </div>
  </div>
  <div v-else class="rooms-view-container">
    <areas
      v-show="showAreas()"
      class="rooms-view-areas"
      :areas="areas"
      @collapsedEvent="enableModalModus(false)"
      @expandedEvent="enableModalModus(true)"
      @areaChangedEvent="changeArea"
    />
    <rooms
      :class="showAreas() ? 'rooms-view-rooms-with-areas' : 'rooms-view-rooms-without-areas'"
      :rooms="rooms"
      :showSeparator="showAreas()"
      @roomChangedEvent="changeRoom"
    />
    <div v-show="isConnectionLost" class="rooms-view-connection-lost">
      <connection-lost @tryToReconnectEvent="tryToReconnect" />
    </div>
    <div
      id="scrollable-room-view"
      v-show="!isConnectionLost"
      :class="showAreas() ? 'rooms-view-control-elements-with-areas' : 'rooms-view-control-elements-without-areas'"
    >
      <control-elements :idsOfControlElements="idsOfControlElements" />
      <div v-if="showScrolledToBottomSpace" class="rooms-view-scrolled-to-bottom-space" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Areas from '@/ui/components/Areas.vue'
import Rooms from '@/ui/components/Rooms.vue'
import ControlElements from '@/ui/components/ControlElements.vue'
import ConnectionLost from '@/ui/components/ConnectionLost.vue'

export default {
  name: 'RoomsView',
  components: { Areas, Rooms, ControlElements, ConnectionLost },
  emits: ['tryToReconnectEvent'],
  data() {
    return {
      elementScrollableRoomView: null,
      showScrolledToBottomSpace: false,
      areas: null,
      rooms: null,
      idsOfControlElements: null,
      activeArea: null,
      activeRoom: null
    }
  },
  mounted() {
    this.elementScrollableRoomView = document.getElementById('scrollable-room-view')

    if (this.elementScrollableRoomView != null) {
      this.elementScrollableRoomView.onscroll = () => {
        this.enableScrolledToBottomSpace()
      }

      this.elementScrollableRoomView.scrollTop = 0
    }
  },
  beforeMount() {
    this.areas = this.$appManager.getAreas()
  },
  updated() {
    this.$appManager.getLatestStates()
  },
  computed: {
    ...mapGetters(['isPlaceholderForWebUiMenuEnabled', 'isConnectionLost'])
  },
  methods: {
    ...mapMutations(['enableModalModus']),
    enableScrolledToBottomSpace() {
      if (this.elementScrollableRoomView != null) {
        const scrollTop = this.elementScrollableRoomView.scrollTop
        const invisiblePart = this.elementScrollableRoomView.scrollHeight - this.elementScrollableRoomView.clientHeight
        const scrollbarPosition = (scrollTop / invisiblePart) * 100

        this.showScrolledToBottomSpace = scrollbarPosition > 0
      }
    },
    changeArea(area) {
      this.activeRoom = null
      this.controlElements = null
      this.activeArea = area
      this.rooms = this.$appManager.getRooms(area.id)
    },
    changeRoom(room) {
      this.controlElements = null
      this.idsOfControlElements = null
      this.activeRoom = room

      if (this.activeRoom != null) {
        this.idsOfControlElements = this.$appManager.getIdsOfControlElements(room.id)
      }

      if (this.elementScrollableRoomView != null) {
        this.elementScrollableRoomView.scrollTop = 0
      }
    },
    showAreas() {
      let showAreas = false

      if (this.areas != null) {
        showAreas = this.areas.length > 1
      }

      return showAreas
    },
    tryToReconnect() {
      this.$emit('tryToReconnectEvent')
    }
  }
}
</script>

<style scoped>
.rooms-view-container {
  display: grid;
  grid-template-columns: auto 55px;
  grid-template-rows: 55px 40px auto auto;
  background-color: var(--background-color-secondary);
}

.rooms-view-areas {
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
}

.rooms-view-areas-with-placeholder-webui-menu {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
}

.rooms-view-rooms-with-areas {
  grid-column: 1 / span 2;
  grid-row: 2 / span 1;
}

.rooms-view-rooms-with-areas-and-with-placeholder-webui-menu {
  grid-column: 1 / span 2;
  grid-row: 2 / span 1;
}

.rooms-view-rooms-without-areas {
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
}

.rooms-view-rooms-without-areas-and-with-placeholder-webui-menu {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
}

.rooms-view-placeholder-webui-menu {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  align-self: stretch;
  justify-self: stretch;
  border-top: solid var(--background-color-primary) 19px;
  border-right: solid var(--background-color-primary) 13px;
  border-bottom: solid var(--background-color-primary) 10px;
  border-left: solid var(--background-color-primary) 16px;
  background-color: var(--background-color-secondary);
}

.rooms-view-connection-lost {
  grid-column: 1 / span 2;
  grid-row: 1 / span 3;
  align-self: center;
}

.rooms-view-control-elements-with-areas {
  grid-column: 1 / span 2;
  grid-row: 3 / span 2;
  overflow-y: auto;
  margin: 1px 0;
}

.rooms-view-control-elements-without-areas {
  grid-column: 1 / span 2;
  grid-row: 2 / span 2;
  overflow-y: auto;
  margin: 1px 0;
}

.rooms-view-scrolled-to-bottom-space {
  grid-column: 1 / span 2;
  grid-row: auto / span 1;
  height: 35px;
}

@media (min-width: 600px) {
  .rooms-view-container {
    grid-template-columns: 300px auto 55px;
    grid-template-rows: 55px auto auto;
  }

  .rooms-view-areas {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
  }

  .rooms-view-areas-with-placeholder-webui-menu {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
  }

  .rooms-view-rooms-with-areas {
    grid-column: 2 / span 2;
    grid-row: 1 / span 1;
  }

  .rooms-view-rooms-with-areas-and-with-placeholder-webui-menu {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
  }

  .rooms-view-rooms-without-areas {
    grid-column: 1 / span 3;
    grid-row: 1 / span 1;
  }

  .rooms-view-rooms-without-areas-and-with-placeholder-webui-menu {
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
  }

  .rooms-view-placeholder-webui-menu {
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
    align-self: stretch;
    justify-self: stretch;
    border-top: solid var(--background-color-primary) 19px;
    border-right: solid var(--background-color-primary) 13px;
    border-bottom: solid var(--background-color-primary) 10px;
    border-left: solid var(--background-color-primary) 16px;
    background-color: var(--background-color-secondary);
  }

  .rooms-view-connection-lost {
    grid-column: 1 / span 3;
    grid-row: 1 / span 2;
    align-self: center;
  }

  .rooms-view-control-elements-with-areas {
    grid-column: 1 / span 3;
    grid-row: 2 / span 1;
  }

  .rooms-view-control-elements-without-areas {
    grid-column: 1 / span 3;
    grid-row: 2 / span 1;
  }

  .rooms-view-scrolled-to-bottom-space {
    grid-column: 1 / span 3;
    grid-row: auto / span 1;
    height: 35px;
  }
}
</style>
