import ConfigurationManager from '@/services/configurationmanager/configurationmanager'
import ModuleListManager from '@/services/modulelistmanager/modulelistmanager'
import SoapInterfaceManager from '@/services/soapinterfacemanager/soapinterfacemanager'
import StorageManager from '@/services/storagemanager/storagemanager'
import LoadResult from '@/entities/loadresult/loadresult'

/**
 * The class AppManager works as a proxy...
 */
class AppManager {
  /**
   * The constructor of this class initializes the references to the following objects:
   *
   *  - 'Configuration Manager'
   *  - 'Module List Manager'
   *  - 'SOAP Interface Manager'
   *  - 'Storage Manager'
   */
  constructor() {
    this.configurationManager = null
    this.moduleListManager = null
    this.soapInterfaceManager = null
    this.storageManager = null

    // Variable used for 'SOAP' communication
    this.connectionStatusCallback = null
    this.soapInterfaceTimer = null
    this.isRunning = false
    this.soapInterfaceIntervalCounter = 0
    this.bootId = null
    this.stateId = null
  }

  /**
   * This method stores the reference to the given 'Configuration Manager'.
   *
   * @param {ConfigurationManager} configurationManager Reference to 'Configuration Manager'
   */
  setConfigurationManager(configurationManager) {
    if (configurationManager instanceof ConfigurationManager) {
      this.configurationManager = configurationManager
    } else {
      throw new Error('configurationManager is not an instance of ConfigurationManager')
    }
  }

  /**
   * This method stores the reference to the given 'Module List Manager'.
   *
   * @param {ModuleListManager} moduleListManager Reference to 'Module List Manager'
   */
  setModuleListManager(moduleListManager) {
    if (moduleListManager instanceof ModuleListManager) {
      this.moduleListManager = moduleListManager
    } else {
      throw new Error('moduleListManager is not an instance of ModuleListManager')
    }
  }

  /**
   * This method stores the reference to the given 'SOAP Interface Manager'.
   *
   * @param {SoapInterfaceManager} soapInterfaceManager Reference to 'SOAP Interface Manager'
   */
  setSoapInterfaceManager(soapInterfaceManager) {
    if (soapInterfaceManager instanceof SoapInterfaceManager) {
      this.soapInterfaceManager = soapInterfaceManager
    } else {
      throw new Error('soapInterfaceManager is not an instance of SoapInterfaceManager')
    }
  }

  /**
   * This method stores the reference to the given 'Storage Manager'.
   *
   * @param {StorageManager} storageManager Reference to 'Storage Manager'
   */
  setStorageManager(storageManager) {
    if (storageManager instanceof StorageManager) {
      this.storageManager = storageManager
    } else {
      throw new Error('storageManager is not an instance of StorageManager')
    }
  }

  /**
   * This method initializes the 'Storage Manager'.
   */
  async initializeStorageManager() {
    if (this.storageManager != null) {
      await this.storageManager.initialize()
    }
  }

  /**
   * This method resets the 'Storage Manager'. First, the database
   * is completely deleted. If deleting the database is successfully,
   * then the app is reloaded to initialize the 'Storage Manager' again.
   * Otherwise a 'Log Entry' is stored in the database.
   */
  async resetStorageManager() {
    if (this.storageManager != null) {
      // Delete database
      this.storageManager.deleteDatabase().then(
        () => {
          // Reload app
          window.location.reload()
        },
        errorMessage => {
          // Add 'Log Entry' to database
          this.storeLogEntry({
            timestamp: new Date(),
            message: errorMessage
          })
        }
      )
    }
  }

  /**
   * This method deletes the 'Configuration'. If deleting the
   * 'Configuration' is successfully, then the app is reloaded
   * to load the 'Configuration' again. Otherwise a 'Log Entry'
   * is stored in the database.
   */
  async deleteConfiguration() {
    if (this.storageManager != null) {
      try {
        // Delete configuration information
        await this.setConfigurationInformation({ id: 1 })

        // Delete configuration
        await this.deleteGlobalFunctions()
        await this.deleteGlobalTimers()
        await this.deleteGlobalScenes()
        await this.deleteRoomRegulations()
        await this.deleteAreas()
        await this.deleteRooms()
        await this.deleteControlElements()

        // Reload app
        window.location.reload()
      } catch (error) {
        // Add 'Log Entry' to database
        this.storeLogEntry({
          timestamp: new Date(),
          message: error
        })
      }
    }
  }

  /**
   * This method reads the settings from the 'Storage Manager'.
   *
   * @returns Stored settings.
   */
  async getSettings() {
    let settings = null

    if (this.storageManager != null) {
      settings = await this.storageManager.readSettings()
    }

    return settings
  }

  /**
   * This method writes the settings to the 'Storage Manager'.
   *
   * @param {object} settings Settings to be stored.
   */
  async setSettings(settings) {
    if (this.storageManager != null) {
      await this.storageManager.writeSettings(settings)
    }
  }

  async getConfigurationInformation() {
    let configurationInformation = null

    if (this.storageManager != null) {
      configurationInformation = await this.storageManager.readConfigurationInformation()
    }

    return configurationInformation
  }

  async setConfigurationInformation(configurationInformation) {
    if (this.storageManager != null) {
      await this.storageManager.writeConfigurationInformation(configurationInformation)
    }
  }

  async storeGlobalFunctions(globalFunctions) {
    if (this.storageManager != null) {
      await this.storageManager.writeGlobalFunctions(globalFunctions)
    }
  }

  async getGlobalFunctions() {
    let globalFunctions = []

    if (this.storageManager != null) {
      globalFunctions = await this.storageManager.readGlobalFunctions()
    }

    return globalFunctions
  }

  async deleteGlobalFunctions() {
    if (this.storageManager != null) {
      await this.storageManager.deleteGlobalFunctions()
    }
  }

  async storeGlobalTimers(globalTimers) {
    if (this.storageManager != null) {
      await this.storageManager.writeGlobalTimers(globalTimers)
    }
  }

  async getGlobalTimers() {
    let globalTimers = []

    if (this.storageManager != null) {
      globalTimers = await this.storageManager.readGlobalTimers()
    }

    return globalTimers
  }

  async deleteGlobalTimers() {
    if (this.storageManager != null) {
      await this.storageManager.deleteGlobalTimers()
    }
  }

  async storeGlobalScenes(globalScenes) {
    if (this.storageManager != null) {
      await this.storageManager.writeGlobalScenes(globalScenes)
    }
  }

  async getGlobalScenes() {
    let globalScenes = []

    if (this.storageManager != null) {
      globalScenes = await this.storageManager.readGlobalScenes()
    }

    return globalScenes
  }

  async deleteGlobalScenes() {
    if (this.storageManager != null) {
      await this.storageManager.deleteGlobalScenes()
    }
  }

  async storeRoomRegulations(roomRegulations) {
    if (this.storageManager != null) {
      await this.storageManager.writeRoomRegulations(roomRegulations)
    }
  }

  async getRoomRegulations() {
    let roomRegulations = []

    if (this.storageManager != null) {
      roomRegulations = await this.storageManager.readRoomRegulations()
    }

    return roomRegulations
  }

  async deleteRoomRegulations() {
    if (this.storageManager != null) {
      await this.storageManager.deleteRoomRegulations()
    }
  }

  async storeAreas(areas) {
    if (this.storageManager != null) {
      await this.storageManager.writeAreas(areas)
    }
  }

  async getStoredAreas() {
    let areas = []

    if (this.storageManager != null) {
      areas = await this.storageManager.readAreas()
    }

    return areas
  }

  async deleteAreas() {
    if (this.storageManager != null) {
      await this.storageManager.deleteAreas()
    }
  }

  async storeRooms(rooms) {
    if (this.storageManager != null) {
      await this.storageManager.writeRooms(rooms)
    }
  }

  async getStoredRooms() {
    let rooms = []

    if (this.storageManager != null) {
      rooms = await this.storageManager.readRooms()
    }

    return rooms
  }

  async getStoredRoom(roomId) {
    let room = null

    if (this.storageManager != null) {
      room = await this.storageManager.readRoom(roomId)
    }

    return room
  }

  async deleteRooms() {
    if (this.storageManager != null) {
      await this.storageManager.deleteRooms()
    }
  }

  async storeControlElements(controlElements) {
    if (this.storageManager != null) {
      await this.storageManager.writeControlElements(controlElements)
    }
  }

  async getStoredControlElements() {
    let controlElements = []

    if (this.storageManager != null) {
      controlElements = await this.storageManager.readControlElements()
    }

    return controlElements
  }

  async deleteControlElements() {
    if (this.storageManager != null) {
      await this.storageManager.deleteControlElements()
    }
  }

  async updateControlElement(controlElement) {
    if (this.configurationManager != null) {
      await this.storageManager.updateControlElement(controlElement)
    }
  }

  async storeLogEntry(logEntry) {
    if (this.storageManager != null) {
      await this.storageManager.writeLogEntry(logEntry)

      // Do not store more than 250 log entries
      await this.storageManager.keepNumberOfMaximumLogEntries(250)
    }
  }

  async getStoredLogEntries() {
    let logEntries = []

    if (this.storageManager != null) {
      logEntries = await this.storageManager.readLogEntries()
    }

    return logEntries
  }

  async deleteLogEntries() {
    if (this.storageManager != null) {
      await this.storageManager.deleteLogEntries()
    }
  }

  initializeSoapInterface(enabled, protocol, ipAddress, port, soapServiceNamespace) {
    if (this.configurationManager != null) {
      this.configurationManager.initializeSoapInterface(enabled, protocol, ipAddress, port)
    }

    if (this.soapInterfaceManager != null) {
      this.soapInterfaceManager.initializeSoapInterface(enabled, protocol, ipAddress, port, soapServiceNamespace)
    }
  }

  async loadTestConfiguration(webAppConfiguration) {
    let loadResult = false

    if (this.configurationManager != null) {
      loadResult = await this.configurationManager.loadTestConfiguration(webAppConfiguration)
    }

    return loadResult
  }

  /**
   * This method loads the file 'WebAppDescriptorname.xml' stored in folder 'public'.
   * This method shall only be used for test and demonstration purposes.
   *
   * @returns {LoadResult} 'Load result' containing result of loading 'WebAppDescriptorname.xml'
   */
  async loadLocalConfiguration() {
    let loadResult = false

    if (this.configurationManager != null) {
      loadResult = await this.configurationManager.loadLocalConfiguration()
    }

    return loadResult
  }

  /**
   * This method loads the file 'WebAppDescriptorname.xml' stored on the INNOXEL Master 3.
   *
   * @returns {LoadResult} 'Load result' containing result of loading 'WebAppDescriptorname.xml'
   */
  async loadConfigurationFromMasterAsync() {
    let loadResult = false

    if (this.configurationManager != null) {
      loadResult = await this.configurationManager.loadConfigurationFromMasterAsync()
    }

    return loadResult
  }

  /**
   * This method returns an array containing all 'Areas' stored in the 'Configuration
   * Manager'.
   *
   * @returns {Array<Area>} Array containing all 'Areas'.
   */
  getAreas() {
    let areas = []

    if (this.configurationManager != null) {
      areas = this.configurationManager.getAreas()
    }

    return areas
  }

  /**
   * This method returns an array containing all 'Rooms' assigned to the given 'Area ID'.
   *
   * @param {string} areaId 'Area ID' used for getting 'Rooms'
   * @returns {Array<Room>} Array containing all 'Rooms' assigned to 'Area ID'
   */
  getRooms(areaId) {
    let rooms = []

    if (this.configurationManager != null) {
      rooms = this.configurationManager.getRooms(areaId)
    }

    return rooms
  }

  /**
   * This method returns an array containing all 'Control Element IDs' assigned to the given
   * 'Room ID'.
   *
   * @param {string} roomId 'Room ID' used for getting 'Control Element IDs'
   * @returns {Array<string>} Array containing all 'Control Element IDs' assigned to 'Room ID'
   */
  getIdsOfControlElements(roomId) {
    let idsOfControlElements = []

    if (this.configurationManager != null) {
      idsOfControlElements = this.configurationManager.getIdsOfControlElements(roomId)
    }

    return idsOfControlElements
  }

  /**
   * This method returns the 'Control Element' referenced by the given 'Control Element ID'.
   *
   * @param {string} controlElementId 'Control Element ID' used for getting 'Control Element'
   * @returns {ControlElement} 'Control Element' referenced by 'Control Element ID'
   */
  getControlElement(controlElementId) {
    let controlElement = null

    if (this.configurationManager != null) {
      controlElement = this.configurationManager.getControlElement(controlElementId)
    }

    return controlElement
  }

  /**
   * This method returns the type of the 'Control Element' referenced by the given 'Control
   * Element ID'. This method returns null if there is no referenced 'Control Element'.
   *
   * @param {string} controlElementId 'Control Element ID' used for getting type
   * @returns {string} Type of 'Control Element' referenced by 'Control Element ID'
   */
  getTypeOfControlElement(controlElementId) {
    let type = null

    if (this.configurationManager != null) {
      type = this.configurationManager.getTypeOfControlElement(controlElementId)
    }

    return type
  }

  /**
   * This method returns the information if the 'Control Element' referenced by the given
   * 'Control Element ID' shall be enabled or not.
   *
   * @param {string} controlElementId 'Control Element ID' used for getting enabled value
   * @returns true if 'Control Element' shall be enabled, otherwise false
   */
  isControlElementEnabled(controlElementId) {
    let isEnabled = false

    if (this.configurationManager != null) {
      isEnabled = this.configurationManager.isControlElementEnabled(controlElementId)
    }

    return isEnabled
  }

  /**
   * This method returns an array containing all 'Control Element IDs' of 'Global Functions'.
   *
   * @returns {Array<string>} Array containing all 'Control Element IDs' of 'Global Functions'
   */
  getIdsOfGlobalFunctions() {
    let idsOfGlobalFunctions = []

    if (this.configurationManager != null) {
      idsOfGlobalFunctions = this.configurationManager.getIdsOfGlobalFunctions()
    }

    return idsOfGlobalFunctions
  }

  /**
   * This method returns an array containing all 'Control Element IDs' of 'Global Timers'.
   *
   * @returns {Array<string>} Array containing all 'Control Element IDs' of 'Global Timers'
   */
  getIdsOfGlobalTimers() {
    let idsOfGlobalTimers = []

    if (this.configurationManager != null) {
      idsOfGlobalTimers = this.configurationManager.getIdsOfGlobalTimers()
    }

    return idsOfGlobalTimers
  }

  /**
   * This method returns an array containing all 'Control Element IDs' of 'Global Scenes'.
   *
   * @returns {Array<string>} Array containing all 'Control Element IDs' of 'Global Scenes'
   */
  getIdsOfGlobalScenes() {
    let idsOfGlobalScenes = []

    if (this.configurationManager != null) {
      idsOfGlobalScenes = this.configurationManager.getIdsOfGlobalScenes()
    }

    return idsOfGlobalScenes
  }

  /**
   * This method returns an array containing all 'Control Element IDs' of 'Room Climate Elements'.
   *
   * @returns {Array<string>} Array containing all 'Control Element IDs' of 'Room Climate Elements'.
   */
  getIdsOfRoomClimateElements() {
    let idsOfRoomClimateElements = []

    if (this.configurationManager != null) {
      idsOfRoomClimateElements = this.configurationManager.getIdsOfRoomClimateElements()
    }

    return idsOfRoomClimateElements
  }

  /**
   * This method sets the given flag 'Is Favorite' to the given 'Control Element ID'.
   *
   * @param {string} controlElementId 'Control Element ID' used to set flag 'Is Favorite'
   * @param {boolean} isFavorite Value of flag 'Is Favorite' to be set
   */
  setAsFavorite(controlElementId, isFavorite) {
    if (this.configurationManager != null) {
      this.configurationManager.setAsFavorite(controlElementId, isFavorite)
    }
  }

  /**
   * This method returns an array containing all 'Control Element IDs' of 'Favorites'.
   *
   * @returns {Array<string>} Array containing all 'Control Element IDs' of 'Favorites'
   */
  getIdsOfFavorites() {
    let idsOfFavorites = []

    if (this.configurationManager != null) {
      idsOfFavorites = this.configurationManager.getIdsOfFavorites()
    }

    return idsOfFavorites
  }

  /**
   * This method creates and returns a clone of the 'Control Element' referenced by the given
   * 'Control Element ID'.
   *
   * @param {string} controlElementId 'Control Element ID' used for cloning 'Control Element'
   * @returns {ControlElement} Clone of 'Control Element' referenced by given 'Control Element ID'
   */
  cloneControlElement(controlElementId) {
    let controlElement = null

    if (this.configurationManager != null) {
      controlElement = this.configurationManager.cloneControlElement(controlElementId)
    }

    return controlElement
  }

  /**
   * This method toggles the state of the 'Control Element' referenced by the given 'Control
   * Element ID'.
   *
   * @param {string} controlElementId 'Control Element' referenced by 'Control Element ID'
   */
  toggleState(controlElementId) {
    const controlElement = this.getControlElement(controlElementId)

    if (controlElement != null && this.soapInterfaceManager != null) {
      if (controlElement.type === 'switch') {
        this.soapInterfaceManager.toggleState(controlElement)
      }
    }
  }

  /**
   * This method sets the given value to the 'Control Element' referenced by the given 'Control
   * Element ID'.
   *
   * @param {string} controlElementId 'Control Element' referenced by 'Control Element ID'
   * @param {Number} value New value for 'Control Element'
   */
  setDimmerValue(controlElementId, value) {
    const controlElement = this.getControlElement(controlElementId)

    if (controlElement != null && this.soapInterfaceManager != null) {
      if (controlElement.type === 'dimmer') {
        this.soapInterfaceManager.setDimmerValue(controlElement, value)
      }
    }
  }

  setRelativePosition(controlElementId, value) {
    const controlElement = this.getControlElement(controlElementId)

    if (controlElement != null && this.soapInterfaceManager != null) {
      if (controlElement.type === 'blind') {
        this.soapInterfaceManager.setRelativePosition(controlElement, value)
      }
    }
  }

  setRelativeTilt(controlElementId, value) {
    const controlElement = this.getControlElement(controlElementId)

    if (controlElement != null && this.soapInterfaceManager != null) {
      if (controlElement.type === 'blind') {
        this.soapInterfaceManager.setRelativeTilt(controlElement, value)
      }
    }
  }

  setSetTemperatureHeating(controlElementId, value, unit) {
    const controlElement = this.getControlElement(controlElementId)

    if (controlElement != null && this.soapInterfaceManager != null) {
      if (controlElement.type === 'roomclimate') {
        this.soapInterfaceManager.setSetTemperatureHeating(controlElement, value, unit)
      }
    }
  }

  setSetTemperatureCooling(controlElementId, value, unit) {
    const controlElement = this.getControlElement(controlElementId)

    if (controlElement != null && this.soapInterfaceManager != null) {
      if (controlElement.type === 'roomclimate') {
        this.soapInterfaceManager.setSetTemperatureCooling(controlElement, value, unit)
      }
    }
  }

  setNightSetbackTemperatureHeating(controlElementId, value, unit) {
    const controlElement = this.getControlElement(controlElementId)

    if (controlElement != null && this.soapInterfaceManager != null) {
      if (controlElement.type === 'roomclimate') {
        this.soapInterfaceManager.setNightSetbackTemperatureHeating(controlElement, value, unit)
      }
    }
  }

  setNightSetbackTemperatureCooling(controlElementId, value, unit) {
    const controlElement = this.getControlElement(controlElementId)

    if (controlElement != null && this.soapInterfaceManager != null) {
      if (controlElement.type === 'roomclimate') {
        this.soapInterfaceManager.setNightSetbackTemperatureCooling(controlElement, value, unit)
      }
    }
  }

  setAbsenceSetbackTemperatureHeating(controlElementId, value, unit) {
    const controlElement = this.getControlElement(controlElementId)

    if (controlElement != null && this.soapInterfaceManager != null) {
      if (controlElement.type === 'roomclimate') {
        this.soapInterfaceManager.setAbsenceSetbackTemperatureHeating(controlElement, value, unit)
      }
    }
  }

  setAbsenceSetbackTemperatureCooling(controlElementId, value, unit) {
    const controlElement = this.getControlElement(controlElementId)

    if (controlElement != null && this.soapInterfaceManager != null) {
      if (controlElement.type === 'roomclimate') {
        this.soapInterfaceManager.setAbsenceSetbackTemperatureCooling(controlElement, value, unit)
      }
    }
  }

  /**
   * This method gets the states of the modules stored in the given 'Module List'. Changed
   * states are handled by the callback 'updateModuleList()' of this class.
   *
   * @param {Array<Module>} moduleList List containing all 'modules' for getting their states
   */
  getStates(moduleList) {
    if (this.soapInterfaceManager != null) {
      this.soapInterfaceManager.getStates(moduleList, this.updateModuleList.bind(this))
    }
  }

  /**
   * This method gets the 'Boot ID' and 'State ID'. Changed 'Boot ID' and 'State ID' are
   * handled by the callback 'handleBootIdAndStateId()' of this class.
   */
  getBootIdAndStateId() {
    if (this.soapInterfaceManager != null) {
      this.soapInterfaceManager.getBootIdAndStateId(this.handleBootIdAndStateId.bind(this))
    }
  }

  /**
   * This method adds the modules used by the given 'Control Element' to the 'Module List'.
   *
   * @param {ControlElement} controlElement 'Control Element' to be added to 'Module List'.
   */
  addToModuleList(controlElement) {
    if (this.moduleListManager != null) {
      this.moduleListManager.addToModuleList(controlElement)
    }
  }

  /**
   * This method removes the modules used by the given 'Control Element' from the 'Module List'.
   *
   * @param {ControlElement} controlElement 'Control Element' to be removed to 'Module List'.
   */
  removeFromModuleList(controlElement) {
    if (this.moduleListManager != null) {
      this.moduleListManager.removeFromModuleList(controlElement)
    }
  }

  /**
   * This method subscribes a callback of type function(controlElementId: string, state: boolean)
   * to the current 'Module List' managed by the 'Module List Manager'. The callback is invoked
   * when the 'Module List' is updated.
   *
   * @param {method(string, boolean):void} callback Callback to be called when 'Module List' is updated
   */
  subscribeToCurrentModuleList(callback) {
    if (this.moduleListManager != null) {
      this.moduleListManager.subscribeToCurrentModuleList(callback)
    }
  }

  /**
   * This method returns an array containing the current 'Modules'.
   *
   * @returns {Array<Module>} Array containing current 'Modules'
   */
  getCurrentModuleList() {
    let moduleList = null

    if (this.moduleListManager != null) {
      moduleList = this.moduleListManager.getCurrentModuleList()
    }

    return moduleList
  }

  /**
   * This method updates the 'Connection Status' and the current 'Module List' with the information
   * stored in the given 'State Response' received from INNOXEL Master 3 device.
   *
   * @param {object} stateResponse Received 'State Response'
   */
  updateModuleList(stateResponse) {
    this.updateConnectionStatus(stateResponse.status)

    if (this.moduleListManager != null) {
      this.moduleListManager.updateModuleList(stateResponse)
    }
  }

  /**
   * This method updates the 'Connection Status' and the current 'Boot ID / State ID' with the
   * information stored in the given 'Response' received from INNOXEL Master 3 device.
   *
   * @param {object} response Received 'Response'
   */
  handleBootIdAndStateId(response) {
    this.updateConnectionStatus(response._status)

    if (this.bootId != response._bootId) {
      this.bootId = response._bootId
    }

    if (this.stateId != null) {
      if (this.stateId != response._stateId) {
        // 'State ID' has changed
        this.stateId = response._stateId

        // Get states for current module list
        this.getLatestStates()
      }
    } else {
      // Get 'State ID' first time
      this.stateId = response._stateId
    }
  }

  /**
   * This method updates the connection status.
   *
   * @param {Number} status Status of connection
   */
  updateConnectionStatus(status) {
    if (status == -1) {
      this.connectionStatusCallback(true)
    } else {
      this.connectionStatusCallback(false)
    }
  }

  getLatestStates() {
    // Get states for current module list
    const moduleList = this.getCurrentModuleList()
    this.getStates(moduleList)

    // Set 'Soap Interface Interval Counter' to 1
    this.soapInterfaceIntervalCounter = 1
  }

  getLatestBootIdAndStateId() {
    // Get 'Boot ID' and 'State ID'
    this.getBootIdAndStateId()

    // Increment 'Soap Interface Interval Counter'
    this.soapInterfaceIntervalCounter += 1
  }

  run(callback, connectionStatusCallback) {
    try {
      if (!this.isRunning) {
        this.isRunning = true

        this.storeLogEntry({
          timestamp: new Date(),
          message: 'Start to communicate with INNOXEL Master 3 device.'
        })

        this.connectionStatusCallback = connectionStatusCallback

        this.subscribeToCurrentModuleList(callback)

        this.getLatestStates()

        this.soapInterfaceTimer = setInterval(() => {
          if (this.soapInterfaceIntervalCounter === 0) {
            this.getLatestStates()
          } else {
            this.getLatestBootIdAndStateId()
          }

          if (this.soapInterfaceIntervalCounter === 10) {
            this.soapInterfaceIntervalCounter = 0
          }
        }, 1000)
      }
    } catch (exception) {
      console.log(exception)
    }
  }

  stop() {
    if (this.soapInterfaceTimer != null) {
      clearInterval(this.soapInterfaceTimer)
      this.soapInterfaceTimer = null
      this.isRunning = false

      this.storeLogEntry({
        timestamp: new Date(),
        message: 'Stop to communicate with INNOXEL Master 3 device.'
      })
    }
  }
}

export default AppManager
