import InnoxelSoapApi, {
  MasterInModuleChannelRequest,
  MasterInModuleRequest,
  MasterInModuleChannelSetRequest,
  MasterInModuleSetRequest,
  MasterOutModuleChannelRequest,
  MasterOutModuleRequest,
  MasterDimModuleChannelRequest,
  MasterDimModuleRequest,
  MasterDimModuleChannelSetRequest,
  MasterDimModuleSetRequest,
  MasterWeatherModuleRequest,
  MasterRoomClimateModuleRequest,
  MasterRoomClimateModuleSetRequest,
  ModuleListRequest,
  MasterBlindModuleChannelRequest,
  MasterBlindModuleRequest,
  MasterBlindModuleSetRequest,
  MasterBlindModuleChannelSetRequest,
  MasterTimeSwitchModuleRequest,
  MasterTimeSwitchModuleSetRequest
} from 'master-3-soap-api'

class SoapInterfaceManager {
  constructor() {
    this.soapInterfaceIsEnabled = false
    this.innoxelSoapApi = null

    // Still used for setting state (should be replaced by 'master-3-soap-api')
    this.soapUri = null
  }

  initializeSoapInterface(enabled, protocol, ipAddress, port, soapServiceNamespace) {
    this.soapInterfaceIsEnabled = enabled

    if (this.soapInterfaceIsEnabled) {
      if (this.innoxelSoapApi === null) {
        this.innoxelSoapApi = new InnoxelSoapApi(protocol, ipAddress, port, soapServiceNamespace)
      }

      // Still used for setting state (should be replaced by 'master-3-soap-api')
      this.soapUri = `${protocol}//${ipAddress}:${port}/control`
    } else {
      this.InnoxelSoapApi = null
      this.soapUri = null
    }
  }

  async toggleState(controlElement) {
    if (this.soapInterfaceIsEnabled) {
      try {
        if (controlElement != null) {
          if (controlElement.assignments != null) {
            for (let i = 0; i < controlElement.assignments.length; i++) {
              const moduleClass = controlElement.assignments[i].moduleClass

              switch (moduleClass) {
                case 'masterInModule':
                  {
                    const moduleIndex = controlElement.assignments[i].moduleIndex
                    const channelIndex = controlElement.assignments[i].channelIndex

                    // Create 'Master In Module Channel Set Request'
                    const channel = new MasterInModuleChannelSetRequest(channelIndex)
                    channel.setFlagAutoImpulse()

                    // Create 'Master In Module Set Request'
                    const module = new MasterInModuleSetRequest(moduleIndex)
                    module.addChannel(channel)

                    // Create 'Module List Request'
                    const moduleList = new ModuleListRequest()
                    moduleList.addModule(module)

                    // Send 'Module List Request' to INNOXEL Master 3 device
                    await this.innoxelSoapApi.setState(moduleList)
                  }
                  break
                case 'masterTimeSwitchModule':
                  {
                    const moduleIndex = controlElement.assignments[i].moduleIndex

                    // Create 'Master Time Switch Module Set Request'
                    const module = new MasterTimeSwitchModuleSetRequest(moduleIndex)
                    module.setFlagToggleState()

                    // Create 'Module List Request'
                    const moduleList = new ModuleListRequest()
                    moduleList.addModule(module)

                    // Send 'Module List Request' to INNOXEL Master 3 device
                    await this.innoxelSoapApi.setState(moduleList)
                  }
                  break
              }
            }
          }
        }
      } catch (error) {
        //context.commit('showError', error)
      }
    }
  }

  async setDimmerValue(controlElement, value) {
    if (this.soapInterfaceIsEnabled) {
      try {
        if (controlElement != null) {
          if (controlElement.assignments != null) {
            for (let i = 0; i < controlElement.assignments.length; i++) {
              if (controlElement.assignments[i].moduleClass === 'masterDimModule') {
                const moduleIndex = controlElement.assignments[i].moduleIndex
                const channelIndex = controlElement.assignments[i].channelIndex

                // Create 'Master Dim Module Channel Set Request'
                const channel = new MasterDimModuleChannelSetRequest(channelIndex)

                // Create 'Master Dim Module Set Request'
                const module = new MasterDimModuleSetRequest(moduleIndex)
                channel.setDimValue(value)
                module.addChannel(channel)

                // Create 'Module List Request'
                const moduleList = new ModuleListRequest()
                moduleList.addModule(module)

                // Send 'Module List Request' to INNOXEL Master 3 device
                await this.innoxelSoapApi.setState(moduleList)
              }
            }
          }
        }
      } catch (error) {
        //context.commit('showError', error)
      }
    }
  }

  async setRelativePosition(controlElement, value) {
    if (this.soapInterfaceIsEnabled) {
      try {
        if (controlElement != null) {
          if (controlElement.assignments != null) {
            for (let i = 0; i < controlElement.assignments.length; i++) {
              if (controlElement.assignments[i].moduleClass === 'masterBlindModule') {
                let moduleIndex = controlElement.assignments[i].moduleIndex
                let channelIndex = controlElement.assignments[i].channelIndex

                // Create 'Master Blind Module Channel Set Request'
                const channel = new MasterBlindModuleChannelSetRequest(channelIndex)
                channel.setRelativePosition(value)
                channel.setRelativeTilt(controlElement.relativeTilt)

                // Create 'Master Blind Module Set Request'
                const module = new MasterBlindModuleSetRequest(moduleIndex)
                module.addChannel(channel)

                // Create 'Module List Request'
                const moduleList = new ModuleListRequest()
                moduleList.addModule(module)

                // Send 'Module List Request' to INNOXEL Master 3 device
                await this.innoxelSoapApi.setState(moduleList)
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  async setRelativeTilt(controlElement, value) {
    if (this.soapInterfaceIsEnabled) {
      try {
        if (controlElement != null) {
          if (controlElement.assignments != null) {
            for (let i = 0; i < controlElement.assignments.length; i++) {
              if (controlElement.assignments[i].moduleClass === 'masterBlindModule') {
                let moduleIndex = controlElement.assignments[i].moduleIndex
                let channelIndex = controlElement.assignments[i].channelIndex

                // Create 'Master Blind Module Channel Set Request'
                const channel = new MasterBlindModuleChannelSetRequest(channelIndex)
                channel.setRelativePosition(controlElement.relativePosition)
                channel.setRelativeTilt(value)

                // Create 'Master Blind Module Set Request'
                const module = new MasterBlindModuleSetRequest(moduleIndex)
                module.addChannel(channel)

                // Create 'Module List Request'
                const moduleList = new ModuleListRequest()
                moduleList.addModule(module)

                // Send 'Module List Request' to INNOXEL Master 3 device
                await this.innoxelSoapApi.setState(moduleList)
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  async setSetTemperatureHeating(controlElement, value, unit) {
    if (this.soapInterfaceIsEnabled) {
      try {
        if (controlElement != null) {
          if (controlElement.assignments != null) {
            // Should have only one assignment?
            for (let i = 0; i < controlElement.assignments.length; i++) {
              if (controlElement.assignments[i].moduleClass === 'masterRoomClimateModule') {
                let moduleIndex = controlElement.assignments[i].moduleIndex

                const module = new MasterRoomClimateModuleSetRequest(moduleIndex)
                module.setSetTemperatureHeating(value, unit)

                const moduleList = new ModuleListRequest()
                moduleList.addModule(module)
                await this.innoxelSoapApi.setState(moduleList)
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  async setSetTemperatureCooling(controlElement, value, unit) {
    if (this.soapInterfaceIsEnabled) {
      try {
        if (controlElement != null) {
          if (controlElement.assignments != null) {
            // Should have only one assignment?
            for (let i = 0; i < controlElement.assignments.length; i++) {
              if (controlElement.assignments[i].moduleClass === 'masterRoomClimateModule') {
                let moduleIndex = controlElement.assignments[i].moduleIndex

                const module = new MasterRoomClimateModuleSetRequest(moduleIndex)
                module.setSetTemperatureCooling(value, unit)

                const moduleList = new ModuleListRequest()
                moduleList.addModule(module)
                await this.innoxelSoapApi.setState(moduleList)
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  async setNightSetbackTemperatureHeating(controlElement, value, unit) {
    if (this.soapInterfaceIsEnabled) {
      try {
        if (controlElement != null) {
          if (controlElement.assignments != null) {
            // Should have only one assignment?
            for (let i = 0; i < controlElement.assignments.length; i++) {
              if (controlElement.assignments[i].moduleClass === 'masterRoomClimateModule') {
                let moduleIndex = controlElement.assignments[i].moduleIndex

                const module = new MasterRoomClimateModuleSetRequest(moduleIndex)
                module.setNightSetbackTemperatureHeating(value, unit)

                const moduleList = new ModuleListRequest()
                moduleList.addModule(module)
                await this.innoxelSoapApi.setState(moduleList)
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  async setNightSetbackTemperatureCooling(controlElement, value, unit) {
    if (this.soapInterfaceIsEnabled) {
      try {
        if (controlElement != null) {
          if (controlElement.assignments != null) {
            // Should have only one assignment?
            for (let i = 0; i < controlElement.assignments.length; i++) {
              if (controlElement.assignments[i].moduleClass === 'masterRoomClimateModule') {
                let moduleIndex = controlElement.assignments[i].moduleIndex

                const module = new MasterRoomClimateModuleSetRequest(moduleIndex)
                module.setNightSetbackTemperatureCooling(value, unit)

                const moduleList = new ModuleListRequest()
                moduleList.addModule(module)
                await this.innoxelSoapApi.setState(moduleList)
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  async setAbsenceSetbackTemperatureHeating(controlElement, value, unit) {
    if (this.soapInterfaceIsEnabled) {
      try {
        if (controlElement != null) {
          if (controlElement.assignments != null) {
            // Should have only one assignment?
            for (let i = 0; i < controlElement.assignments.length; i++) {
              if (controlElement.assignments[i].moduleClass === 'masterRoomClimateModule') {
                let moduleIndex = controlElement.assignments[i].moduleIndex

                const module = new MasterRoomClimateModuleSetRequest(moduleIndex)
                module.setAbsenceSetbackTemperatureHeating(value, unit)

                const moduleList = new ModuleListRequest()
                moduleList.addModule(module)
                await this.innoxelSoapApi.setState(moduleList)
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  async setAbsenceSetbackTemperatureCooling(controlElement, value, unit) {
    if (this.soapInterfaceIsEnabled) {
      try {
        if (controlElement != null) {
          if (controlElement.assignments != null) {
            // Should have only one assignment?
            for (let i = 0; i < controlElement.assignments.length; i++) {
              if (controlElement.assignments[i].moduleClass === 'masterRoomClimateModule') {
                let moduleIndex = controlElement.assignments[i].moduleIndex

                const module = new MasterRoomClimateModuleSetRequest(moduleIndex)
                module.setAbsenceSetbackTemperatureCooling(value, unit)

                const moduleList = new ModuleListRequest()
                moduleList.addModule(module)
                await this.innoxelSoapApi.setState(moduleList)
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  async getBootIdAndStateId(callbackOnSuccess) {
    if (this.soapInterfaceIsEnabled) {
      const response = await this.innoxelSoapApi.getBootIdAndStateId()
      callbackOnSuccess(response)
    }
  }

  async getStates(moduleList, callbackOnSuccess) {
    if (this.soapInterfaceIsEnabled) {
      if (moduleList.length > 0) {
        const moduleListRequest = new ModuleListRequest()

        moduleList.forEach(tmpModule => {
          switch (tmpModule.moduleClass) {
            case 'masterInModule':
              {
                const module = new MasterInModuleRequest(tmpModule.moduleIndex)

                if (tmpModule.channels != null) {
                  tmpModule.channels.forEach(tmpChannel => {
                    const channel = new MasterInModuleChannelRequest(tmpChannel.channelIndex)
                    module.addChannel(channel)
                  })
                }

                moduleListRequest.addModule(module)
              }
              break
            case 'masterOutModule':
              {
                const module = new MasterOutModuleRequest(tmpModule.moduleIndex)

                if (tmpModule.channels != null) {
                  tmpModule.channels.forEach(tmpChannel => {
                    const channel = new MasterOutModuleChannelRequest(tmpChannel.channelIndex)
                    module.addChannel(channel)
                  })
                }

                moduleListRequest.addModule(module)
              }
              break
            case 'masterDimModule':
              {
                const module = new MasterDimModuleRequest(tmpModule.moduleIndex)

                if (tmpModule.channels != null) {
                  tmpModule.channels.forEach(tmpChannel => {
                    const channel = new MasterDimModuleChannelRequest(tmpChannel.channelIndex)
                    module.addChannel(channel)
                  })
                }

                moduleListRequest.addModule(module)
              }
              break
            case 'masterWeatherModule':
              {
                const module = new MasterWeatherModuleRequest()
                moduleListRequest.addModule(module)
              }
              break
            case 'masterRoomClimateModule':
              {
                const module = new MasterRoomClimateModuleRequest(tmpModule.moduleIndex)
                module.setFlagActualTemperatureMean()
                module.setFlagSetTemperatureHeating()
                module.setFlagSetTemperatureCooling()
                module.setFlagNightSetbackTemperatureHeating()
                module.setFlagNightSetbackTemperatureCooling()
                module.setFlagAbsenceSetbackTemperatureHeating()
                module.setFlagAbsenceSetbackTemperatureCooling()
                moduleListRequest.addModule(module)
              }
              break
            case 'masterBlindModule':
              {
                const module = new MasterBlindModuleRequest(tmpModule.moduleIndex)

                if (tmpModule.channels != null) {
                  tmpModule.channels.forEach(tmpChannel => {
                    const channel = new MasterBlindModuleChannelRequest(tmpChannel.channelIndex)
                    module.addChannel(channel)
                  })
                }

                moduleListRequest.addModule(module)
              }
              break
            case 'masterTimeSwitchModule':
              {
                const module = new MasterTimeSwitchModuleRequest(tmpModule.moduleIndex)
                module.setFlagState()
                moduleListRequest.addModule(module)
              }
              break
            default:
              break
          }
        })

        const response = await this.innoxelSoapApi.getState(moduleListRequest)
        callbackOnSuccess(response)
      }
    }
  }
}

export default SoapInterfaceManager
