/**
 * The class Area stores the following information about an area:
 *
 *  - ID of area (e.g. '08063EC9-0045-4EA6-A923-05CE36F2B8CD')
 *  - Name of area (e.g. 'Second floor')
 *  - Order of area
 *  - IDs of all rooms assigned to area (e.g. [ '190CA8A2-811F-4622-A675-5651091242D9',
 *                                              '27C0E8B0-1369-4662-BEE2-51118325CB1C' ] )
 *
 */
class Area {
  /**
   * The constructor of this class stores the given ID and name. It also initializes
   * an empty array used to store the IDs of the rooms assigned to the area. The
   * constructor throws an error if the given id or name is not valid.
   *
   * @param {string} id Unique ID of area
   * @param {string} name Name of area
   * @param {number} order Order of area
   */
  constructor(id, name, order) {
    // Check and store ID
    if (id) {
      if (typeof id === 'string') {
        this.id = id
      } else {
        throw new Error('id is not of type string')
      }
    } else {
      throw new Error('id is not valid')
    }

    // Check and store name
    if (name) {
      if (typeof name === 'string') {
        this.name = name
      } else {
        throw new Error('name is not of type string')
      }
    } else {
      throw new Error('name is not valid')
    }

    // Check and store order
    if (order) {
      if (typeof order === 'number') {
        this.order = order
      } else {
        throw new Error('order is not of type number')
      }
    } else {
      throw new Error('order is not valid')
    }

    // Initialize array for room IDs
    this.idsOfRooms = []
  }

  /**
   * This method adds the given room ID to the array storing the IDs of the rooms
   * assigned to the area. The given room ID is ignored if it is already stored in
   * the array. This method throws an error if the given room id is not valid.
   *
   * @param {string} roomId Unique ID of room
   */
  addRoomId(roomId) {
    if (roomId) {
      if (typeof roomId === 'string') {
        if (!this.idsOfRooms.includes(roomId)) {
          this.idsOfRooms.push(roomId)
        }
      } else {
        throw new Error('roomId is not of type string')
      }
    } else {
      throw new Error('roomId is not valid')
    }
  }
}

export default Area
