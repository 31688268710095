import { createStore } from 'vuex'
import settings from '@/store/modules/settings'
import application from '@/store/modules/application'

export default createStore({
  modules: {
    settings,
    application
  }
})
