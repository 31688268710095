class AreasUpdater {
  constructor() {
    this.appManager = null
    this.logChanges = true
  }

  setAppManager(appManager) {
    this.appManager = appManager
  }

  /**
   * This method updates the areas stored in the database.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @param {Boolean} reset Use true if 'WebApp Configuration' is completely new
   */
  async update(webAppConfiguration, reset) {
    if (this.appManager != null) {
      // Variable containing areas to be stored in database
      let areas = []

      if (reset) {
        // 'WebApp Configuration' is completely new

        // Get all areas stored in 'WebApp Configuration'
        areas = webAppConfiguration.areas
      } else {
        // 'WebApp Configuration' is updated

        // Get all areas stored in 'WebApp Configuration'
        // and log all changes made to areas
        areas = await this.getUpdatedAreas(webAppConfiguration)
      }

      // Delete all areas stored in database
      await this.appManager.deleteAreas()

      // Store new/changed areas into database
      await this.appManager.storeAreas(areas)
    }
  }

  /**
   * This method updates the areas in the database and also logs the changes.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @returns Array containing areas to be stored in database
   */
  async getUpdatedAreas(webAppConfiguration) {
    // Variable containing areas to be stored in database
    let areas = []

    // Get areas stored in database
    const storedAreas = await this.appManager.getStoredAreas()

    // Get areas stored in 'WebApp Configuration'
    const areasInWebAppConfiguration = webAppConfiguration.areas

    storedAreas.forEach(storedArea => {
      // Check if 'Stored Area' is also available in 'WebApp Configuration'
      const areaInWebAppConfiguration = areasInWebAppConfiguration.find(element => element.id === storedArea.id)

      if (areaInWebAppConfiguration) {
        // 'Stored Area' is also available in 'WebApp Configuration'

        // Add 'Area' as defined in 'WebApp Configuration' to
        // variable containing areas to be stored into database
        areas.push(areaInWebAppConfiguration)

        if (this.logChanges) {
          // Log changes made to areas

          // Get name of 'Stored Area' and of area stored in 'WebApp Configuration'
          const nameOfStoredArea = storedArea.name
          const nameOfAreaInWebAppConfiguration = areaInWebAppConfiguration.name

          // Check if name of area has changed
          const nameOfAreaChanged = nameOfStoredArea !== nameOfAreaInWebAppConfiguration

          // Get order of 'Stored Area' and of area stored in 'WebApp Configuration'
          const orderOfStoredArea = storedArea.order
          const orderOfAreaInWebAppConfiguration = areaInWebAppConfiguration.order

          // Check if order of area has changed
          const orderOfAreaChanged = orderOfStoredArea !== orderOfAreaInWebAppConfiguration

          // Get IDs of Rooms of 'Stored Area' and of area stored in 'WebApp Configuration'
          const idsOfRoomsOfStoredArea = storedArea.idsOfRooms
          const idsOfRoomsOfAreaInWebAppConfiguration = areaInWebAppConfiguration.idsOfRooms

          // Check if at least one 'ID of room' is removed from area
          let idOfRoomRemoved = false

          idsOfRoomsOfStoredArea.forEach(idOfRoom => {
            // Check if 'ID of room' is also available in area stored in 'WebApp Configuration'
            const foundIdOfRoom = idsOfRoomsOfAreaInWebAppConfiguration.find(element => element === idOfRoom)

            if (!foundIdOfRoom) {
              // 'ID of room' is not available in area stored in 'WebApp Configuration'
              idOfRoomRemoved = true
            }
          })

          // Check if at least one 'ID of room' is added to area
          let idOfRoomAdded = false

          idsOfRoomsOfAreaInWebAppConfiguration.forEach(idOfRoom => {
            // Check if 'ID of room' is also available in stored area
            const foundIdOfRoom = idsOfRoomsOfStoredArea.find(element => element === idOfRoom)

            if (!foundIdOfRoom) {
              // 'ID of room' is not available in stored area
              idOfRoomAdded = true
            }
          })

          if (nameOfAreaChanged || orderOfAreaChanged || idOfRoomRemoved || idOfRoomAdded) {
            // There are changes made to area

            // Define counter of changes
            let counter = 0

            // Get ID of area (short version)
            const shortAreaId = this.getShortId(storedArea.id)

            // Define message describing changes made to area
            let message = `Area '${storedArea.name}' has been changed (Area ID: ${shortAreaId}):\n`

            if (nameOfStoredArea !== nameOfAreaInWebAppConfiguration) {
              // Name of area has changed

              // Increment counter of changes
              counter += 1

              // Add text describing name change to message
              message += `\n${counter}) Name changed from '${nameOfStoredArea}' to '${nameOfAreaInWebAppConfiguration}'`
            }

            if (orderOfStoredArea !== orderOfAreaInWebAppConfiguration) {
              // Order of area has changed

              // Increment counter of changes
              counter += 1

              // Add text describing order change to message
              message += `\n${counter}) Order changed from ${orderOfStoredArea} to ${orderOfAreaInWebAppConfiguration}`
            }

            if (idOfRoomRemoved) {
              // 'ID of room' is removed from area

              idsOfRoomsOfStoredArea.forEach(idOfRoom => {
                const foundIdOfRoom = idsOfRoomsOfAreaInWebAppConfiguration.find(element => element === idOfRoom)

                if (!foundIdOfRoom) {
                  // Increment counter of changes
                  counter += 1

                  // Get ID of room (short version)
                  const shortRoomId = this.getShortId(idOfRoom)

                  // Add text describing room change to message
                  message += `\n${counter}) Removed room (ID: ${shortRoomId})`
                }
              })
            }

            if (idOfRoomAdded) {
              // 'ID of room' is added to area

              idsOfRoomsOfAreaInWebAppConfiguration.forEach(idOfRoom => {
                const foundIdOfRoom = idsOfRoomsOfStoredArea.find(element => element === idOfRoom)

                if (!foundIdOfRoom) {
                  // Increment counter of changes
                  counter += 1

                  // Get ID of room (short version)
                  const shortRoomId = this.getShortId(idOfRoom)

                  // Add text describing room change to message
                  message += `\n${counter}) Added room (ID: ${shortRoomId})`
                }
              })
            }

            // Store log entry in database
            this.storeLogEntry(message)
          }
        }
      } else {
        // 'Stored Area' is not available in 'WebApp Configuration'

        if (this.logChanges) {
          // Log changes made to areas

          // Get ID of area (short version)
          const shortAreaId = this.getShortId(storedArea.id)

          // Define message describing removing area
          const message = `Area '${storedArea.name}' removed (Area ID: ${shortAreaId})`

          // Store log entry in database
          this.storeLogEntry(message)
        }
      }
    })

    areasInWebAppConfiguration.forEach(areaInWebAppConfiguration => {
      // Check if 'Area in WebApp Configuration' is also available in 'Stored Areas'
      const areaInStoredAreas = storedAreas.find(element => element.id === areaInWebAppConfiguration.id)

      if (!areaInStoredAreas) {
        // 'Area in WebApp Configuration' is not available in 'Stored Areas'

        // Add 'Area' as defined in 'WebApp Configuration' to
        // variable containing areas to be stored into database
        areas.push(areaInWebAppConfiguration)

        if (this.logChanges) {
          // Log changes made to areas

          // Get ID of area (short version)
          const shortAreaId = this.getShortId(areaInWebAppConfiguration.id)

          // Define message describing adding area
          const message = `Area '${areaInWebAppConfiguration.name}' added (Area ID: ${shortAreaId})`

          // Store log entry in database
          this.storeLogEntry(message)
        }
      }
    })

    return areas
  }

  /**
   * This method returns the first sevent characters of the given ID.
   *
   * @param {String} id ID as UUID
   * @returns ID containing first seven characters
   */
  getShortId(id) {
    let shortId = ''

    if (id) {
      shortId = id.substring(0, 7).toLowerCase()
    }

    return shortId
  }

  /**
   * This method creates a log entry containing the current time and the given
   * message. The log entry is stored into the database.
   *
   * @param {String} message Message used for log entry
   */
  storeLogEntry(message) {
    this.appManager.storeLogEntry({ timestamp: new Date(), message: message })
  }
}

export default AreasUpdater
