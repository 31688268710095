/**
 * The class ControlElementSwitchConfiguration stores the configuration of a control element of type
 * 'switch':
 *
 *  - Icon for active state of control element (e.g. '0101w-ffb500.svg')
 *  - Icon for inactive state of control element (e.g. '0102w-7d7d7d.svg')
 *  - Status text for active state of control element (e.g. 'On')
 *  - Status text for inactive state of control element (e.g. 'Off')
 *  - Color of status text for active state of control element (e.g. '#ffb500')
 *  - Color of status text for inactive state of control element (e.g. '#7d7d7d')
 *
 */
class ControlElementSwitchConfiguration {
  /**
   * The constructor of this class initializes the properties iconActive, iconInactive, statusTextActive,
   * statusTextInactive, statusTextColorActive and statusTextColorInactive with null.
   */
  constructor() {
    this.iconActive = null
    this.iconInactive = null
    this.statusTextActive = null
    this.statusTextInactive = null
    this.statusTextColorActive = null
    this.statusTextColorInactive = null
  }

  /**
   * This method sets the given 'Icon Active'.
   *
   * @param {string} iconActive 'Icon Active' to be stored
   */
  setIconActive(iconActive) {
    this.iconActive = iconActive
  }

  /**
   * This method sets the given 'Icon Inactive'.
   *
   * @param {string} iconInactive 'Icon Inactive' to be stored
   */
  setIconInactive(iconInactive) {
    this.iconInactive = iconInactive
  }

  /**
   * This method sets the given 'Status Text Active'.
   *
   * @param {string} statusTextActive 'Status Text Active' to be stored
   */
  setStatusTextActive(statusTextActive) {
    this.statusTextActive = statusTextActive
  }

  /**
   * This method sets the given 'Status Text Inactive'.
   *
   * @param {string} statusTextInactive 'Status Text Inactive' to be stored
   */
  setStatusTextInactive(statusTextInactive) {
    this.statusTextInactive = statusTextInactive
  }

  /**
   * This method sets the given 'Status Text Color Active'.
   *
   * @param {string} statusTextColorActive 'Status Text Color Active' to be stored
   */
  setStatusTextColorActive(statusTextColorActive) {
    this.statusTextColorActive = statusTextColorActive
  }

  /**
   * This method sets the given 'Status Text Color Inactive'.
   *
   * @param {string} statusTextColorInactive 'Status Text Color Inactive' to be stored
   */
  setStatusTextColorInactive(statusTextColorInactive) {
    this.statusTextColorInactive = statusTextColorInactive
  }
}

export default ControlElementSwitchConfiguration
