<template>
  <div class="room-climate-element-settings-dialog-container" :rerender="rerender">
    <div class="room-climate-element-settings-dialog-title">
      {{ controlElement.name }}
    </div>
    <div
      class="room-climate-element-settings-dialog-button"
      :class="
        isButtonDonePressed
          ? 'room-climate-element-settings-dialog-button-pressed'
          : 'room-climate-element-settings-dialog-button-not-pressed'
      "
      @mousedown.stop.passive="isButtonDonePressed = true"
      @mouseup.stop.passive="isButtonDonePressed = false"
      @mouseleave.stop.passive="isButtonDonePressed = false"
      @touchstart.stop.passive="isButtonDonePressed = true"
      @touchend.stop.passive="isButtonDonePressed = false"
      @touchcancel.stop.passive="isButtonDonePressed = false"
      @click.stop.passive="buttonDoneClicked"
    >
      {{ $t('done') }}
    </div>
    <div class="room-climate-element-settings-dialog-content">
      <div class="room-climate-element-settings-dialog-label-current-room-temperature">
        {{ $t('currentRoomTemperature') }}
      </div>
      <div class="room-climate-element-settings-dialog-current-room-temperature">{{ actualRoomTemperature }}°C</div>
      <div class="room-climate-element-settings-dialog-info-box-container">
        <div class="room-climate-element-settings-dialog-target-temperature-container">
          <img class="room-climate-element-settings-dialog-icon-target-temperature" :src="iconTargetTemperature" />
          <div class="room-climate-element-settings-dialog-value-target-temperature">
            {{ $t('targetTemperature') }} {{ valueTargetTemperature }}
          </div>
          <div class="room-climate-element-settings-dialog-info-target-temperature">Info</div>
        </div>
        <div class="room-climate-element-settings-dialog-alarms-container">
          <img
            v-if="getUsedTheme === 'dark-theme'"
            class="room-climate-element-settings-dialog-icon-alarms"
            :src="iconAlarmsDarkMode"
          />
          <img v-else class="room-climate-element-settings-dialog-icon-alarms" :src="iconAlarmsLightMode" />
          <div class="room-climate-element-settings-dialog-label-alarms">{{ $t('alarms') }}</div>
          <div class="room-climate-element-settings-dialog-info-alarms">{{ textAlarmState }}</div>
        </div>
        <div class="room-climate-element-settings-dialog-heating-valve-container">
          <img
            v-if="getUsedTheme === 'dark-theme'"
            class="room-climate-element-settings-dialog-icon-heating-valve"
            :src="iconHeatingValveDarkMode"
          />
          <img
            v-else
            class="room-climate-element-settings-dialog-icon-heating-valve"
            :src="iconHeatingValveLightMode"
          />
          <div class="room-climate-element-settings-dialog-label-heating-valve">{{ $t('heatingValve') }}</div>
          <div class="room-climate-element-settings-dialog-info-heating-valve">{{ statusHeatingValve }}</div>
        </div>
      </div>
      <div class="room-climate-element-settings-dialog-target-temperatures-settings-container">
        <div class="room-climate-element-settings-dialog-target-temperatures-heating-containter">
          <div class="room-climate-element-settings-dialog-label-target-temperatures">
            {{ $t('targetTemperaturesHeating') }}
          </div>
          <div class="room-climate-element-settings-dialog-target-temperature-normal-container">
            <img
              v-if="getUsedTheme === 'dark-theme'"
              class="room-climate-element-settings-dialog-target-temperature-setting-icon"
              :src="iconHeatingDarkMode"
            />
            <img
              v-else
              class="room-climate-element-settings-dialog-target-temperature-setting-icon"
              :src="iconHeatingLightMode"
            />
            <div class="room-climate-element-settings-dialog-target-temperature-setting-label">
              {{ $t('normalDay') }}
            </div>
            <div
              class="
                room-climate-element-settings-dialog-target-temperature-setting-value
                room-climate-element-settings-dialog-target-temperature-heating-setting-value-color
              "
            >
              {{ targetSetTemperatureHeating }}
            </div>
            <div class="room-climate-element-settings-dialog-buttons-container">
              <button-plus
                class="room-climate-element-settings-dialog-button-plus"
                @buttonClickedEvent="incrementSetTemperatureHeating"
              />
              <button-minus
                class="room-climate-element-settings-dialog-button-minus"
                @buttonClickedEvent="decrementSetTemperatureHeating"
              />
            </div>
          </div>
          <div class="room-climate-element-settings-dialog-target-temperature-night-setback-container">
            <img
              v-if="getUsedTheme === 'dark-theme'"
              class="room-climate-element-settings-dialog-target-temperature-setting-icon"
              :src="iconNightSetbackHeatingDarkMode"
            />
            <img
              v-else
              class="room-climate-element-settings-dialog-target-temperature-setting-icon"
              :src="iconNightSetbackHeatingLightMode"
            />
            <div class="room-climate-element-settings-dialog-target-temperature-setting-label">
              {{ $t('nightReduction') }}
            </div>
            <div
              class="
                room-climate-element-settings-dialog-target-temperature-setting-value
                room-climate-element-settings-dialog-target-temperature-heating-setting-value-color
              "
            >
              {{ targetNightSetbackTemperatureHeating }}
            </div>
            <div class="room-climate-element-settings-dialog-buttons-container">
              <button-plus
                class="room-climate-element-settings-dialog-button-plus"
                @buttonClickedEvent="incrementNightSetbackTemperatureHeating"
              />
              <button-minus
                class="room-climate-element-settings-dialog-button-minus"
                @buttonClickedEvent="decrementNightSetbackTemperatureHeating"
              />
            </div>
          </div>
          <div class="room-climate-element-settings-dialog-target-temperature-absence-setback-container">
            <img
              v-if="getUsedTheme === 'dark-theme'"
              class="room-climate-element-settings-dialog-target-temperature-setting-icon"
              :src="iconAbsenceSetbackHeatingDarkMode"
            />
            <img
              v-else
              class="room-climate-element-settings-dialog-target-temperature-setting-icon"
              :src="iconAbsenceSetbackHeatingLightMode"
            />
            <div class="room-climate-element-settings-dialog-target-temperature-setting-label">
              {{ $t('absenceReduction') }}
            </div>
            <div
              class="
                room-climate-element-settings-dialog-target-temperature-setting-value
                room-climate-element-settings-dialog-target-temperature-heating-setting-value-color
              "
            >
              {{ targetAbsenceSetbackTemperatureHeating }}
            </div>
            <div class="room-climate-element-settings-dialog-buttons-container">
              <button-plus
                class="room-climate-element-settings-dialog-button-plus"
                @buttonClickedEvent="incrementAbsenceSetbackTemperatureHeating"
              />
              <button-minus
                class="room-climate-element-settings-dialog-button-minus"
                @buttonClickedEvent="decrementAbsenceSetbackTemperatureHeating"
              />
            </div>
          </div>
        </div>
        <div class="room-climate-element-settings-dialog-target-temperatures-cooling-containter">
          <div class="room-climate-element-settings-dialog-label-target-temperatures">
            {{ $t('targetTemperaturesCooling') }}
          </div>
          <div class="room-climate-element-settings-dialog-target-temperature-normal-container">
            <img
              v-if="getUsedTheme === 'dark-theme'"
              class="room-climate-element-settings-dialog-target-temperature-setting-icon"
              :src="iconCoolingDarkMode"
            />
            <img
              v-else
              class="room-climate-element-settings-dialog-target-temperature-setting-icon"
              :src="iconCoolingLightMode"
            />
            <div class="room-climate-element-settings-dialog-target-temperature-setting-label">
              {{ $t('normalDay') }}
            </div>
            <div
              class="
                room-climate-element-settings-dialog-target-temperature-setting-value
                room-climate-element-settings-dialog-target-temperature-cooling-setting-value-color
              "
            >
              {{ targetSetTemperatureCooling }}
            </div>
            <div class="room-climate-element-settings-dialog-buttons-container">
              <button-plus
                class="room-climate-element-settings-dialog-button-plus"
                @buttonClickedEvent="incrementSetTemperatureCooling"
              />
              <button-minus
                class="room-climate-element-settings-dialog-button-minus"
                @buttonClickedEvent="decrementSetTemperatureCooling"
              />
            </div>
          </div>
          <div class="room-climate-element-settings-dialog-target-temperature-night-setback-container">
            <img
              v-if="getUsedTheme === 'dark-theme'"
              class="room-climate-element-settings-dialog-target-temperature-setting-icon"
              :src="iconNightSetbackCoolingDarkMode"
            />
            <img
              v-else
              class="room-climate-element-settings-dialog-target-temperature-setting-icon"
              :src="iconNightSetbackCoolingLightMode"
            />
            <div class="room-climate-element-settings-dialog-target-temperature-setting-label">
              {{ $t('nightReduction') }}
            </div>
            <div
              class="
                room-climate-element-settings-dialog-target-temperature-setting-value
                room-climate-element-settings-dialog-target-temperature-cooling-setting-value-color
              "
            >
              {{ targetNightSetbackTemperatureCooling }}
            </div>
            <div class="room-climate-element-settings-dialog-buttons-container">
              <button-plus
                class="room-climate-element-settings-dialog-button-plus"
                @buttonClickedEvent="incrementNightSetbackTemperatureCooling"
              />
              <button-minus
                class="room-climate-element-settings-dialog-button-minus"
                @buttonClickedEvent="decrementNightSetbackTemperatureCooling"
              />
            </div>
          </div>
          <div class="room-climate-element-settings-dialog-target-temperature-absence-setback-container">
            <img
              v-if="getUsedTheme === 'dark-theme'"
              class="room-climate-element-settings-dialog-target-temperature-setting-icon"
              :src="iconAbsenceSetbackCoolingDarkMode"
            />
            <img
              v-else
              class="room-climate-element-settings-dialog-target-temperature-setting-icon"
              :src="iconAbsenceSetbackCoolingLightMode"
            />
            <div class="room-climate-element-settings-dialog-target-temperature-setting-label">
              {{ $t('absenceReduction') }}
            </div>
            <div
              class="
                room-climate-element-settings-dialog-target-temperature-setting-value
                room-climate-element-settings-dialog-target-temperature-cooling-setting-value-color
              "
            >
              {{ targetAbsenceSetbackTemperatureCooling }}
            </div>
            <div class="room-climate-element-settings-dialog-buttons-container">
              <button-plus
                class="room-climate-element-settings-dialog-button-plus"
                @buttonClickedEvent="incrementAbsenceSetbackTemperatureCooling"
              />
              <button-minus
                class="room-climate-element-settings-dialog-button-minus"
                @buttonClickedEvent="decrementAbsenceSetbackTemperatureCooling"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from '@/i18n'
import ButtonPlus from '@/ui/components/controlelements/roomclimateelement/ButtonPlus.vue'
import ButtonMinus from '@/ui/components/controlelements/roomclimateelement/ButtonMinus.vue'

export default {
  name: 'RoomClimateElementSettingsDialog',
  components: { ButtonPlus, ButtonMinus },
  props: {
    controlElementId: {
      type: String,
      required: true
    }
  },
  emits: ['doneEvent'],
  data() {
    return {
      countRerender: null,
      controlElement: null,
      operatingState: null,
      valveState: null,
      actualTemperatureMean: null,
      setTemperatureHeating: null,
      setTemperatureCooling: null,
      nightSetbackTemperatureHeating: null,
      nightSetbackTemperatureCooling: null,
      absenceSetbackTemperatureHeating: null,
      absenceSetbackTemperatureCooling: null,
      alarmState: null,
      isButtonDonePressed: false,
      iconHeatingLightMode: require('@/assets/widgets/roomclimateelement/light-theme/heating.svg'),
      iconCoolingLightMode: require('@/assets/widgets/roomclimateelement/light-theme/cooling.svg'),
      iconNightSetbackHeatingLightMode: require('@/assets/widgets/roomclimateelement/light-theme/night-setback-heating.svg'),
      iconNightSetbackCoolingLightMode: require('@/assets/widgets/roomclimateelement/light-theme/night-setback-cooling.svg'),
      iconAbsenceSetbackHeatingLightMode: require('@/assets/widgets/roomclimateelement/light-theme/absence-setback-heating.svg'),
      iconAbsenceSetbackCoolingLightMode: require('@/assets/widgets/roomclimateelement/light-theme/absence-setback-cooling.svg'),
      iconAlarmsLightMode: require('@/assets/widgets/roomclimateelement/light-theme/alarms.svg'),
      iconHeatingValveLightMode: require('@/assets/widgets/roomclimateelement/light-theme/heating-valve.svg'),
      iconHeatingDarkMode: require('@/assets/widgets/roomclimateelement/dark-theme/heating.svg'),
      iconCoolingDarkMode: require('@/assets/widgets/roomclimateelement/dark-theme/cooling.svg'),
      iconNightSetbackHeatingDarkMode: require('@/assets/widgets/roomclimateelement/dark-theme/night-setback-heating.svg'),
      iconNightSetbackCoolingDarkMode: require('@/assets/widgets/roomclimateelement/dark-theme/night-setback-cooling.svg'),
      iconAbsenceSetbackHeatingDarkMode: require('@/assets/widgets/roomclimateelement/dark-theme/absence-setback-heating.svg'),
      iconAbsenceSetbackCoolingDarkMode: require('@/assets/widgets/roomclimateelement/dark-theme/absence-setback-cooling.svg'),
      iconAlarmsDarkMode: require('@/assets/widgets/roomclimateelement/dark-theme/alarms.svg'),
      iconHeatingValveDarkMode: require('@/assets/widgets/roomclimateelement/dark-theme/heating-valve.svg')
    }
  },
  beforeMount() {
    // Initialize 'Room Climate Element'
    this.initialize()
  },
  computed: {
    ...mapGetters(['getCurrentControlElement', 'getUsedTheme']),
    rerender() {
      if (this.countRerender !== this.controlElement.rerender) {
        this.update()
      }

      return this.controlElement.rerender
    },
    actualRoomTemperature() {
      let actualRoomTemperature = '--.-'

      if (this.actualTemperatureMean != null) {
        if (this.actualTemperatureMean.unit === '°C') {
          actualRoomTemperature = this.actualTemperatureMean.value
        }
      }

      return actualRoomTemperature
    },
    valueTargetTemperature() {
      let temperature = '--.-'
      let unit = '°C'

      if (this.operatingState != null) {
        switch (this.operatingState) {
          case 'heating':
            temperature = this.getTemperature(this.setTemperatureHeating)
            break
          case 'cooling':
            temperature = this.getTemperature(this.setTemperatureCooling)
            break
          case 'nightSetbackHeating':
            temperature = this.getTemperature(this.nightSetbackTemperatureHeating)
            break
          case 'nightSetbackCooling':
            temperature = this.getTemperature(this.nightSetbackTemperatureCooling)
            break
          case 'absenceSetbackHeating':
            temperature = this.getTemperature(this.absenceSetbackTemperatureHeating)
            break
          case 'absenceSetbackCooling':
            temperature = this.getTemperature(this.absenceSetbackTemperatureCooling)
            break
          case 'heatUpHeating':
            temperature = this.getTemperature(this.setTemperatureHeating)
            break
          case 'heatUpCooling':
            temperature = this.getTemperature(this.setTemperatureCooling)
            break
        }
      }

      const valueTargetTemperature = `${temperature}${unit}`

      return valueTargetTemperature
    },
    iconTargetTemperature() {
      const isDarkMode = this.getUsedTheme === 'dark-theme'
      let icon = isDarkMode ? this.iconHeatingDarkMode : this.iconHeatingLightMode

      if (this.operatingState != null) {
        switch (this.operatingState) {
          case 'heating':
            icon = isDarkMode ? this.iconHeatingDarkMode : this.iconHeatingLightMode
            break
          case 'cooling':
            icon = isDarkMode ? this.iconCoolingDarkMode : this.iconCoolingLightMode
            break
          case 'nightSetbackHeating':
            icon = isDarkMode ? this.iconNightSetbackHeatingDarkMode : this.iconNightSetbackHeatingLightMode
            break
          case 'nightSetbackCooling':
            icon = isDarkMode ? this.iconNightSetbackCoolingDarkMode : this.iconNightSetbackCoolingLightMode
            break
          case 'absenceSetbackHeating':
            icon = isDarkMode ? this.iconAbsenceSetbackHeatingDarkMode : this.iconAbsenceSetbackHeatingLightMode
            break
          case 'absenceSetbackCooling':
            icon = isDarkMode ? this.iconAbsenceSetbackCoolingDarkMode : this.iconAbsenceSetbackCoolingLightMode
            break
          case 'heatUpHeating':
            icon = isDarkMode ? this.iconHeatingDarkMode : this.iconHeatingLightMode
            break
          case 'heatUpCooling':
            icon = isDarkMode ? this.iconCoolingDarkMode : this.iconCoolingLightMode
            break
        }
      }

      return icon
    },
    statusHeatingValve() {
      let statusHeatingValve = '--'

      if (this.valveState != null) {
        switch (this.valveState) {
          case 'open':
            statusHeatingValve = i18n.global.t('open')
            break
          case 'closed':
            statusHeatingValve = i18n.global.t('closed')
            break
          case 'test':
            statusHeatingValve = i18n.global.t('test')
            break
        }
      }

      return statusHeatingValve
    },
    targetSetTemperatureHeating() {
      const temperature = this.getTemperature(this.setTemperatureHeating) + '°C'
      return temperature
    },
    targetNightSetbackTemperatureHeating() {
      const temperature = this.getTemperature(this.nightSetbackTemperatureHeating) + '°C'
      return temperature
    },
    targetAbsenceSetbackTemperatureHeating() {
      const temperature = this.getTemperature(this.absenceSetbackTemperatureHeating) + '°C'
      return temperature
    },
    targetSetTemperatureCooling() {
      const temperature = this.getTemperature(this.setTemperatureCooling) + '°C'
      return temperature
    },
    targetNightSetbackTemperatureCooling() {
      const temperature = this.getTemperature(this.nightSetbackTemperatureCooling) + '°C'
      return temperature
    },
    targetAbsenceSetbackTemperatureCooling() {
      const temperature = this.getTemperature(this.absenceSetbackTemperatureCooling) + '°C'
      return temperature
    },
    textAlarmState() {
      let textAlarmState = '-'

      if (this.alarmState != null) {
        switch (this.alarmState) {
          case 'temperatureUnknownAlarm':
            textAlarmState = i18n.global.t('temperatureUnknownAlarm')
            break
          case 'temperatureUnderrunAlarm':
            textAlarmState = i18n.global.t('temperatureUnderrunAlarm')
            break
          case 'temperatureOverrunAlarm':
            textAlarmState = i18n.global.t('temperatureOverrunAlarm')
            break
          case 'forceValveTimeoutAlarm':
            textAlarmState = i18n.global.t('forceValveTimeoutAlarm')
            break
        }
      }

      return textAlarmState
    }
  },
  methods: {
    initialize() {
      // Get control element from Vuex store to get feedback if
      // its property 'rerender' has changed
      this.controlElement = this.getCurrentControlElement(this.controlElementId)

      // Update 'Room Climate Element' with information from control element
      this.update()
    },
    update() {
      this.countRerender = this.controlElement.rerender

      if (this.controlElement.thermostatData != null) {
        this.operatingState = this.controlElement.thermostatData.operatingState
        this.valveState = this.controlElement.thermostatData.valveState
        this.actualTemperatureMean = this.controlElement.thermostatData.actualTemperatureMean
        this.setTemperatureHeating = this.controlElement.thermostatData.setTemperatureHeating
        this.setTemperatureCooling = this.controlElement.thermostatData.setTemperatureCooling
        this.nightSetbackTemperatureHeating = this.controlElement.thermostatData.nightSetbackTemperatureHeating
        this.nightSetbackTemperatureCooling = this.controlElement.thermostatData.nightSetbackTemperatureCooling
        this.absenceSetbackTemperatureHeating = this.controlElement.thermostatData.absenceSetbackTemperatureHeating
        this.absenceSetbackTemperatureCooling = this.controlElement.thermostatData.absenceSetbackTemperatureCooling
      } else {
        this.operatingState = null
        this.valveState = null
        this.actualTemperatureMean = null
        this.setTemperatureHeating = null
        this.setTemperatureCooling = null
        this.nightSetbackTemperatureHeating = null
        this.nightSetbackTemperatureCooling = null
        this.absenceSetbackTemperatureHeating = null
        this.absenceSetbackTemperatureCooling = null
      }

      this.alarmState = this.controlElement.alarmState
    },
    getTemperature(temperature) {
      let tmpTemperature = '--.-'

      if (temperature != null) {
        if (temperature.value != null && temperature.unit != null) {
          if (temperature.unit === '°C') {
            tmpTemperature = Number(temperature.value).toFixed(1)
          }
        }
      }

      return tmpTemperature
    },
    incrementSetTemperatureHeating() {
      if (this.setTemperatureHeating != null) {
        const value = Number(this.setTemperatureHeating.value) + 0.5
        const unit = '°C'

        // Set value via 'Control Element stored in 'App Manager'
        // to send it to Innoxel Master 3
        this.$appManager.setSetTemperatureHeating(this.controlElementId, value, unit)
      }
    },
    decrementSetTemperatureHeating() {
      if (this.setTemperatureHeating != null) {
        const value = Number(this.setTemperatureHeating.value) - 0.5
        const unit = '°C'

        // Set value via 'Control Element stored in 'App Manager'
        // to send it to Innoxel Master 3
        this.$appManager.setSetTemperatureHeating(this.controlElementId, value, unit)
      }
    },
    incrementSetTemperatureCooling() {
      if (this.setTemperatureCooling != null) {
        const value = Number(this.setTemperatureCooling.value) + 0.5
        const unit = '°C'

        // Set value via 'Control Element stored in 'App Manager'
        // to send it to Innoxel Master 3
        this.$appManager.setSetTemperatureCooling(this.controlElementId, value, unit)
      }
    },
    decrementSetTemperatureCooling() {
      if (this.setTemperatureCooling != null) {
        const value = Number(this.setTemperatureCooling.value) - 0.5
        const unit = '°C'

        // Set value via 'Control Element stored in 'App Manager'
        // to send it to Innoxel Master 3
        this.$appManager.setSetTemperatureCooling(this.controlElementId, value, unit)
      }
    },
    incrementNightSetbackTemperatureHeating() {
      if (this.nightSetbackTemperatureHeating != null) {
        const value = Number(this.nightSetbackTemperatureHeating.value) + 0.5
        const unit = '°C'

        // Set value via 'Control Element stored in 'App Manager'
        // to send it to Innoxel Master 3
        this.$appManager.setNightSetbackTemperatureHeating(this.controlElementId, value, unit)
      }
    },
    decrementNightSetbackTemperatureHeating() {
      if (this.nightSetbackTemperatureHeating != null) {
        const value = Number(this.nightSetbackTemperatureHeating.value) - 0.5
        const unit = '°C'

        // Set value via 'Control Element stored in 'App Manager'
        // to send it to Innoxel Master 3
        this.$appManager.setNightSetbackTemperatureHeating(this.controlElementId, value, unit)
      }
    },
    incrementNightSetbackTemperatureCooling() {
      if (this.nightSetbackTemperatureCooling != null) {
        const value = Number(this.nightSetbackTemperatureCooling.value) + 0.5
        const unit = '°C'

        // Set value via 'Control Element stored in 'App Manager'
        // to send it to Innoxel Master 3
        this.$appManager.setNightSetbackTemperatureCooling(this.controlElementId, value, unit)
      }
    },
    decrementNightSetbackTemperatureCooling() {
      if (this.nightSetbackTemperatureCooling != null) {
        const value = Number(this.nightSetbackTemperatureCooling.value) - 0.5
        const unit = '°C'

        // Set value via 'Control Element stored in 'App Manager'
        // to send it to Innoxel Master 3
        this.$appManager.setNightSetbackTemperatureCooling(this.controlElementId, value, unit)
      }
    },
    incrementAbsenceSetbackTemperatureHeating() {
      if (this.absenceSetbackTemperatureHeating != null) {
        const value = Number(this.absenceSetbackTemperatureHeating.value) + 0.5
        const unit = '°C'

        // Set value via 'Control Element stored in 'App Manager'
        // to send it to Innoxel Master 3
        this.$appManager.setAbsenceSetbackTemperatureHeating(this.controlElementId, value, unit)
      }
    },
    decrementAbsenceSetbackTemperatureHeating() {
      if (this.absenceSetbackTemperatureHeating != null) {
        const value = Number(this.absenceSetbackTemperatureHeating.value) - 0.5
        const unit = '°C'

        // Set value via 'Control Element stored in 'App Manager'
        // to send it to Innoxel Master 3
        this.$appManager.setAbsenceSetbackTemperatureHeating(this.controlElementId, value, unit)
      }
    },
    incrementAbsenceSetbackTemperatureCooling() {
      if (this.absenceSetbackTemperatureCooling != null) {
        const value = Number(this.absenceSetbackTemperatureCooling.value) + 0.5
        const unit = '°C'

        // Set value via 'Control Element stored in 'App Manager'
        // to send it to Innoxel Master 3
        this.$appManager.setAbsenceSetbackTemperatureCooling(this.controlElementId, value, unit)
      }
    },
    decrementAbsenceSetbackTemperatureCooling() {
      if (this.absenceSetbackTemperatureCooling != null) {
        const value = Number(this.absenceSetbackTemperatureCooling.value) - 0.5
        const unit = '°C'

        // Set value via 'Control Element stored in 'App Manager'
        // to send it to Innoxel Master 3
        this.$appManager.setAbsenceSetbackTemperatureCooling(this.controlElementId, value, unit)
      }
    },
    buttonDoneClicked() {
      this.$emit('doneEvent')
    }
  }
}
</script>

<style scoped>
.room-climate-element-settings-dialog-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 340px;
  height: 90%;
  border-radius: 16px;
  background-color: var(--background-color-primary);
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 64px auto 16px;
  justify-items: stretch;
  align-items: center;
}

.room-climate-element-settings-dialog-title {
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
  font-family: OpenSans;
  font-size: 17px;
  font-weight: 600;
  text-align: start;
  color: var(--text-color-primary);
  margin: 0 0 0 24px;
}

.room-climate-element-settings-dialog-button {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: 600;
  margin: 0 24px 0 0;
}

.room-climate-element-settings-dialog-button-not-pressed {
  color: var(--innoxel-blue-color);
}

.room-climate-element-settings-dialog-button-pressed {
  color: var(--innoxel-blue-color-pressed);
}

.room-climate-element-settings-dialog-content {
  grid-column: 1 / span 3;
  grid-row: 2 / span 1;
  justify-self: stretch;
  align-self: stretch;
  padding: 0 24px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 40px 90px 180px auto;
  overflow-y: auto;
}

.room-climate-element-settings-dialog-label-current-room-temperature {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  font-family: OpenSans;
  font-size: 18px;
  font-weight: bold;
  text-align: start;
  color: var(--text-color-primary);
}

.room-climate-element-settings-dialog-current-room-temperature {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  font-family: OpenSans;
  font-size: 48px;
  font-weight: 600;
  text-align: start;
  color: var(--text-color-primary);
}

.room-climate-element-settings-dialog-info-box-container {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr 1fr;
}

.room-climate-element-settings-dialog-target-temperature-container {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  display: grid;
  grid-template-columns: 24px auto;
  grid-template-rows: 24px 24px;
}

.room-climate-element-settings-dialog-icon-target-temperature {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
}

.room-climate-element-settings-dialog-value-target-temperature {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
  font-size: 15px;
  font-weight: 600;
  text-align: start;
  color: var(--text-color-primary);
}

.room-climate-element-settings-dialog-info-target-temperature {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  align-self: center;
  font-family: OpenSans;
  font-size: 13px;
  font-weight: 600;
  text-align: start;
  color: var(--text-color-quaternary);
}

.room-climate-element-settings-dialog-alarms-container {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  display: grid;
  grid-template-columns: 24px auto;
  grid-template-rows: 24px 24px;
}

.room-climate-element-settings-dialog-icon-alarms {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
}

.room-climate-element-settings-dialog-label-alarms {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
  font-size: 15px;
  font-weight: 600;
  text-align: start;
  color: var(--text-color-primary);
}

.room-climate-element-settings-dialog-info-alarms {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  align-self: center;
  font-family: OpenSans;
  font-size: 13px;
  font-weight: 600;
  text-align: start;
  color: var(--text-color-quaternary);
}

.room-climate-element-settings-dialog-heating-valve-container {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
  display: grid;
  grid-template-columns: 24px auto;
  grid-template-rows: 24px 24px;
}

.room-climate-element-settings-dialog-icon-heating-valve {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
}

.room-climate-element-settings-dialog-label-heating-valve {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
  font-size: 15px;
  font-weight: 600;
  text-align: start;
  color: var(--text-color-primary);
}

.room-climate-element-settings-dialog-info-heating-valve {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  align-self: center;
  font-family: OpenSans;
  font-size: 13px;
  font-weight: 600;
  text-align: start;
  color: var(--text-color-quaternary);
}

.room-climate-element-settings-dialog-target-temperatures-settings-container {
  grid-column: 1 / span 1;
  grid-row: 4 / span 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}

.room-climate-element-settings-dialog-target-temperatures-heating-containter {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 64px 52px 52px 52px auto;
}

.room-climate-element-settings-dialog-target-temperatures-cooling-containter {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 64px 52px 52px 52px auto;
}

.room-climate-element-settings-dialog-label-target-temperatures {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: bold;
  text-align: start;
  color: var(--text-color-primary);
}

.room-climate-element-settings-dialog-target-temperature-normal-container {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  display: grid;
  grid-template-columns: 26px auto 56px;
  grid-template-rows: 19px 19px;
}

.room-climate-element-settings-dialog-target-temperature-night-setback-container {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
  display: grid;
  grid-template-columns: 26px auto 56px;
  grid-template-rows: 19px 19px;
}

.room-climate-element-settings-dialog-target-temperature-absence-setback-container {
  grid-column: 1 / span 1;
  grid-row: 4 / span 1;
  display: grid;
  grid-template-columns: 26px auto 56px;
  grid-template-rows: 19px 19px;
}

.room-climate-element-settings-dialog-target-temperature-setting-icon {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
}

.room-climate-element-settings-dialog-target-temperature-setting-label {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
  font-size: 14px;
  font-weight: 600;
  text-align: start;
  color: var(--text-color-primary);
}

.room-climate-element-settings-dialog-target-temperature-setting-value {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  align-self: center;
  font-family: OpenSans;
  font-size: 12px;
  font-weight: 600;
  text-align: start;
}

.room-climate-element-settings-dialog-buttons-container {
  grid-column: 3 / span 1;
  grid-row: 1 / span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
}

.room-climate-element-settings-dialog-button-plus {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  justify-self: start;
}

.room-climate-element-settings-dialog-button-minus {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  justify-self: end;
}

.room-climate-element-settings-dialog-target-temperature-heating-setting-value-color {
  color: var(--temperature-heating-color);
}

.room-climate-element-settings-dialog-target-temperature-cooling-setting-value-color {
  color: var(--temperature-cooling-color);
}

@media (min-width: 700px) {
  .room-climate-element-settings-dialog-container {
    width: 680px;
    max-height: 520px;
  }

  .room-climate-element-settings-dialog-title {
    grid-column: 1 / span 3;
    text-align: center;
  }

  .room-climate-element-settings-dialog-content {
    grid-template-rows: 40px 90px 60px auto;
  }

  .room-climate-element-settings-dialog-info-box-container {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }

  .room-climate-element-settings-dialog-alarms-container {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
  }

  .room-climate-element-settings-dialog-heating-valve-container {
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
  }

  .room-climate-element-settings-dialog-target-temperatures-settings-container {
    grid-template-columns: 1fr 48px 1fr;
    grid-template-rows: 1fr;
  }

  .room-climate-element-settings-dialog-target-temperatures-cooling-containter {
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
  }
}
</style>
