<template>
  <div class="appearance-selector-container">
    <div v-for="(appearance, index) in getAppearances" :key="appearance" :value="appearance">
      <div v-if="this.selectedAppearance === appearance" :class="getBoxStyle(null, index)">
        <div class="appearance-selector-selected-box-text">{{ $t(appearance) }}</div>
        <div class="appearance-selector-selected-box-icon">
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/checkmark/dark-theme/checkmark.svg" />
          <img v-else src="@/assets/checkmark/light-theme/checkmark.svg" />
        </div>
      </div>
      <div
        v-else
        :class="getBoxStyle(appearance, index)"
        @mousedown.stop.passive="pressedAppearance = appearance"
        @mouseup.stop.passive="pressedAppearance = null"
        @mouseleave.stop.passive="pressedAppearance = null"
        @touchstart.stop.passive="pressedAppearance = appearance"
        @touchend.stop.passive="pressedAppearance = null"
        @touchcancel.stop.passive="pressedAppearance = null"
        @click.stop.passive="boxClicked(appearance)"
      >
        <div class="appearance-selector-not-selected-box-text">
          {{ $t(appearance) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AppearanceSelector',
  data() {
    return {
      selectedAppearance: null,
      pressedAppearance: null
    }
  },
  beforeMount() {
    this.selectedAppearance = this.getSelectedAppearance
  },
  computed: {
    ...mapGetters(['getAppearances', 'getSelectedAppearance', 'getUsedTheme'])
  },
  methods: {
    ...mapMutations(['setAppearance']),
    getBoxStyle(appearance, index) {
      let retVal = 'appearance-selector-box'

      if (index === 0) {
        retVal += ' appearance-selector-box-first'
      }

      if (index === this.getAppearances.length - 1) {
        retVal += ' appearance-selector-box-last'
      }

      if (appearance) {
        if (this.pressedAppearance === appearance) {
          retVal = retVal + ' appearance-selector-pressed-box'
        } else {
          retVal = retVal + ' appearance-selector-not-pressed-box'
        }
      }

      return retVal
    },
    boxClicked(appearance) {
      this.selectedAppearance = appearance
      this.setAppearance(appearance)
    }
  }
}
</script>

<style scoped>
.appearance-selector-container {
  display: flex;
  align-content: stretch;
  flex-direction: column;
}

.appearance-selector-box-first {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.appearance-selector-box-last {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.appearance-selector-box {
  height: 60px;
  width: auto;
  background-color: var(--background-color-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 2px 0;
}

.appearance-selector-selected-box-text {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-secondary);
  margin: 0 0 0 20px;
}

.appearance-selector-not-selected-box-text {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-light-gray);
  margin: 0 0 0 20px;
}

.appearance-selector-selected-box-icon {
  margin: 0 20px 0 0;
}

.appearance-selector-pressed-box {
  background-color: var(--pressed-background-color);
}

.appearance-selector-not-pressed-box {
  background-color: var(--background-color-primary);
}
</style>
