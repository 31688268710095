/**
 * The class RoomRegulations stores an array containing IDs of control elements assigned to
 * the room regulations (e.g. [ '4347560D-215E-4F98-B2B4-2FB56E2A6BD4',
 *                              'BA07B16A-8156-4A87-90EE-74A1FC72B517' ] ).
 */
class RoomRegulations {
  /**
   * The constructor of this class stores the given array containing the IDs of all control
   * elements assigned to the room regulations. The constructor throws an error if the given
   * array containing the IDs of all control elements is not valid.
   *
   * @param {Array<string>} idsOfControlElements Array containing IDs of control elements assigned to room regulations
   */
  constructor(idsOfControlElements) {
    this.idsOfControlElements = []

    if (idsOfControlElements instanceof Array) {
      if (idsOfControlElements.every(controlElementId => !!controlElementId)) {
        if (idsOfControlElements.every(controlElementId => typeof controlElementId === 'string')) {
          this.addIdsOfControlElements(idsOfControlElements)
        } else {
          throw new Error('At least one entry of idsOfControlElement is not of type string')
        }
      } else {
        throw new Error('At least one entry of idsOfControlElement is not valid')
      }
    } else {
      throw new Error('idsOfControlElement is not an instance of Array')
    }
  }

  /**
   * This method adds the given IDs of control elements to the array containing all
   * IDs of control elements. This method guarantees that there are no duplicated
   * IDs are added to the array containing all IDs of control elements.
   *
   * @param {Array<string>} idsOfControlElements Array containing IDs of control elements assigned to room regualations
   */
  addIdsOfControlElements(idsOfControlElements) {
    idsOfControlElements.forEach(controlElementId => {
      const controlElementIdExists = this.idsOfControlElements.find(
        tmpControlElementId => tmpControlElementId === controlElementId
      )

      if (!controlElementIdExists) {
        this.idsOfControlElements.push(controlElementId)
      } else {
        console.log(controlElementId, ' already exists in room regulations')
      }
    })
  }
}

export default RoomRegulations
