/**
 * The class GlobalTimers stores an array containing IDs of control elements assigned to
 * the global timers (e.g. [ '47872CF4-4E4F-441E-B78A-970783B849EF',
 *                           '2E648047-39C9-47C5-8E1C-6D11858B0537' ] ).
 */
class GlobalTimers {
  /**
   * The constructor of this class stores the given array containing the IDs of all control
   * elements assigned to the global timers. The constructor throws an error if the given
   * array containing the IDs of all control elements is not valid.
   *
   * @param {Array<string>} idsOfControlElements Array containing IDs of control elements assigned to global timers
   */
  constructor(idsOfControlElements) {
    if (idsOfControlElements instanceof Array) {
      if (idsOfControlElements.every(controlElementId => !!controlElementId)) {
        if (idsOfControlElements.every(controlElementId => typeof controlElementId === 'string')) {
          this.idsOfControlElements = idsOfControlElements
        } else {
          throw new Error('At least one entry of idsOfControlElement is not of type string')
        }
      } else {
        throw new Error('At least one entry of idsOfControlElement is not valid')
      }
    } else {
      throw new Error('idsOfControlElement is not an instance of Array')
    }
  }
}

export default GlobalTimers
