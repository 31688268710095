import ControlElementBase from '@/entities/controlelements/base/controlelementbase'

/**
 * The class ControlElementRoomClimate stores the following information of a control element of
 * type 'roomclimate':
 *
 *  - ID of control element (e.g. '9579BBAC-2227-4AD4-A914-B1505374A6EF')
 *  - Name of control element (e.g. 'Living Room')
 *  - Assignments of control element (an array containing objects of type 'Assignment')
 *  - Information if control element is enabled or not
 *  - Information if control element is favorite or not
 *
 * The property rerender shall be incremented after a property (e.g. new value) has changed.
 */
class ControlElementRoomClimate extends ControlElementBase {
  /**
   * The constructor of this class stores the given ID, name, assignments, isEnabled and isFavorite.
   *
   * @param {string} id ID of control element
   * @param {string} name Name of control element
   * @param {Array<Assignment>} assignments Assignments of control element
   * @param {boolean} isEnabled Information if control element is enabled or not
   * @param {boolean} isFavorite Information if control element is favorite or not
   */
  constructor(id, name, assignments, isEnabled, isFavorite) {
    super(id, name, 'roomclimate', assignments, isEnabled, isFavorite)

    this.thermostatData = null
    this.alarmState = null
  }

  updateThermostatData(thermostatData) {
    let changedData = false

    if (this.thermostatData != null && thermostatData != null) {
      // Check 'Operating State' has changed
      changedData ||= this.hasOperatingStateChanged(this.thermostatData.operatingState, thermostatData.operatingState)

      // Check 'Valve State' has changed
      changedData ||= this.hasValveStateChanged(this.thermostatData.valveState, thermostatData.valveState)

      // Check if 'Actual Temperature Mean' has changed
      changedData ||= this.hasTemperatureChanged(
        this.thermostatData.actualTemperatureMean,
        thermostatData.actualTemperatureMean
      )

      // Check if 'Set Temperature Heating' has changed
      changedData ||= this.hasTemperatureChanged(
        this.thermostatData.setTemperatureHeating,
        thermostatData.setTemperatureHeating
      )

      // Check if 'Set Temperature Cooling' has changed
      changedData ||= this.hasTemperatureChanged(
        this.thermostatData.setTemperatureCooling,
        thermostatData.setTemperatureCooling
      )

      // Check if 'Night Setback Temperature Heating' has changed
      changedData ||= this.hasTemperatureChanged(
        this.thermostatData.nightSetbackTemperatureHeating,
        thermostatData.nightSetbackTemperatureHeating
      )

      // Check if 'Night Setback Temperature Cooling' has changed
      changedData ||= this.hasTemperatureChanged(
        this.thermostatData.nightSetbackTemperatureCooling,
        thermostatData.nightSetbackTemperatureCooling
      )

      // Check if 'Absence Setback Temperature Heating' has changed
      changedData ||= this.hasTemperatureChanged(
        this.thermostatData.absenceSetbackTemperatureHeating,
        thermostatData.absenceSetbackTemperatureHeating
      )

      // Check if 'Absence Setback Temperature Cooling' has changed
      changedData ||= this.hasTemperatureChanged(
        this.thermostatData.absenceSetbackTemperatureCooling,
        thermostatData.absenceSetbackTemperatureCooling
      )
    } else {
      changedData = true
    }

    if (changedData) {
      this.thermostatData = thermostatData
      this.rerender = this.rerender + 1
    }

    return changedData
  }

  updateAlarmState(alarmState) {
    let changedData = false

    if (this.alarmState != null && alarmState != null) {
      if (this.alarmState != alarmState) {
        changedData = true
      }
    } else {
      changedData = true
    }

    if (changedData) {
      this.alarmState = alarmState
      this.rerender = this.rerender + 1
    }

    return changedData
  }

  hasOperatingStateChanged(storedOperatingState, newOperatingState) {
    let changedData = false

    if (storedOperatingState != newOperatingState) {
      changedData = true
    }

    return changedData
  }

  hasValveStateChanged(storedValveState, newValveState) {
    let changedData = false

    if (storedValveState != newValveState) {
      changedData = true
    }

    return changedData
  }

  hasTemperatureChanged(storedTemperature, newTemperature) {
    let changedData = false

    if (storedTemperature != null && newTemperature != null) {
      if (storedTemperature.value !== newTemperature.value) {
        changedData = true
      }

      if (storedTemperature.unit !== newTemperature.unit) {
        changedData = true
      }
    } else {
      changedData = true
    }

    return changedData
  }

  /**
   * This static method returns a clone of the given 'Control Element'. This method returns null
   * if the given 'Control Element' is not from type 'roomclimate'.
   *
   * @param {ControlElementRoomClimate} controlElement 'Control Element' to be cloned
   * @returns Cloned 'Control Element'
   */
  static clone(controlElement) {
    let clonedControlElement = null

    if (controlElement.type === 'roomclimate') {
      clonedControlElement = new ControlElementRoomClimate(
        controlElement.id,
        controlElement.name,
        controlElement.assignments,
        controlElement.isEnabled,
        controlElement.isFavorite
      )
    }

    return clonedControlElement
  }

  /**
   * This method copies the following properties of the given 'Control Element':
   *
   *  - Name
   *  - Assignments
   *
   * The property rerender is incremented (even there is no change). This method does not copy
   * when if the given 'Control Element' is not from type 'roomclimate'.
   *
   * @param {ControlElementRoomClimate} controlElement 'Control Element' to be copied
   */
  update(controlElement) {
    if (controlElement.type === 'roomclimate') {
      this.name = controlElement.name
      this.assignments = controlElement.assignments

      this.rerender = this.rerender + 1
    }
  }
}

export default ControlElementRoomClimate
