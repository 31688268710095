/**
 * The class LogEntriesAccessor is used for accessing the 'Log Entries' stored
 * in the appropriate object store (IndexedDB).
 */
class LogEntriesAccessor {
  /**
   * The constructor of this class stores the references to the database and to
   * the object store containing the 'Log Entries'.
   *
   * @param {any} database Reference to database.
   * @param {any} objectStore Reference to object store containing 'Log Entries'.
   */
  constructor(database, objectStore) {
    this.database = database
    this.objectStore = objectStore
  }

  /**
   * This method writes the given 'Log Entry' to the object store.
   *
   *  - Writing succeed: Returns empty Promise resolve.
   *  - Writing failed:  Returns Promise reject containing error message.
   *
   * @param {any} logEntry 'Log Entry' to be stored to object store.
   * @returns Promise containing result.
   */
  async write(logEntry) {
    return new Promise((resolve, reject) => {
      if (this.database != null && this.objectStore != null) {
        // Database and object store are valid

        // Create transaction for writing 'Log Entry'
        const transaction = this.database.transaction(this.objectStore, 'readwrite')

        // Get object store for writing 'Log Entry'
        const objectStore = transaction.objectStore(this.objectStore)

        // Put 'Log Entry' to object store
        objectStore.put(logEntry)

        /**
         * This event handler is called when writing 'Log Entry' to the object
         * store succeed. It returns an empty Promise resolve.
         */
        transaction.oncomplete = () => {
          resolve()
        }

        /**
         * This event handler is called when writing 'Log Entry' to the object
         * store failed. This event handler returns a Promise reject containing
         * the error message.
         *
         * @param {Event} event Generated by objectStore.put()
         */
        transaction.onerror = event => {
          const errorMessage = `Error: Writing log entry failed! (${event.target.error})`
          reject(errorMessage)
        }
      } else {
        // Database and/or object store are invalid
        const errorMessage = 'Error: Writing log entry failed! Invalid database or object store.'
        reject(errorMessage)
      }
    })
  }

  /**
   * This method reads all 'Log Entries' from the object store.
   *
   *  - Reading succeed: Returns Promise resolve containing stored 'Log Entries'.
   *  - Reading failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async read() {
    return new Promise((resolve, reject) => {
      if (this.database != null && this.objectStore != null) {
        // Database and object store are valid

        // Create transaction for reading 'Log Entries'
        const transaction = this.database.transaction(this.objectStore, 'readonly')

        // Get object store for reading 'Log Entries'
        const objectStore = transaction.objectStore(this.objectStore)

        // Get 'Log Entries' from object store
        const request = objectStore.getAll()

        /**
         * This event handler is called when reading the 'Log Entries' from the
         * object store succeed. This event handler returns a Promise resolve
         * containing the 'Log Entries'.
         *
         * @param {Event} event Generated by objectStore.getAll()
         */
        request.onsuccess = event => {
          let logEntries = []

          // Get 'Log Entries' stored in event
          if (event.target.result.length > 0) {
            logEntries = event.target.result
          }

          resolve(logEntries)
        }

        /**
         * This event handler is called when reading the 'Log Entries' from the
         * object store failed. This event handler returns a Promise reject
         * containing the error message.
         *
         * @param {Event} event Generated by objectStore.getAll()
         */
        request.onerror = event => {
          const errorMessage = `Error: Reading log entries failed! (${event.target.error})`
          reject(errorMessage)
        }
      } else {
        // Database and/or object store are invalid
        const errorMessage = 'Error: Reading log entries failed! Invalid database or object store.'
        reject(errorMessage)
      }
    })
  }

  /**
   * This method deletes all 'Log Entries' from the object store.
   *
   *  - Deleting succeed: Returns empty Promise resolve.
   *  - Deleting failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async clear() {
    return new Promise((resolve, reject) => {
      if (this.database != null && this.objectStore != null) {
        // Database and object store are valid

        // Create transaction for deleting 'Log Entries'
        const transaction = this.database.transaction(this.objectStore, 'readwrite')

        // Get object store for deleting 'Log Entries'
        const objectStore = transaction.objectStore(this.objectStore)

        // Delete 'Log Entries' from object store
        const request = objectStore.clear()

        /**
         * This event handler is called when deleting 'Log Entries' from the object
         * store succeed. It returns an empty Promise resolve.
         */
        transaction.oncomplete = () => {
          resolve()
        }

        /**
         * This event handler is called when deleting 'Log Entries' from the object
         * store failed. This event handler returns a Promise reject containing the
         * error message.
         *
         * @param {Event} event Generated by objectStore.clear()
         */
        request.onerror = event => {
          const errorMessage = `Error: Deleting log entries failed! (${event.target.error})`
          reject(errorMessage)
        }
      } else {
        // Database and/or object store are invalid
        const errorMessage = 'Error: Deleting log entries failed! Invalid database or object store.'
        reject(errorMessage)
      }
    })
  }

  /**
   * This method keeps the number of maximum log entries in the object
   * store 'logentries'.
   *
   *  - Keeping succeed: Returns empty Promise resolve.
   *  - Keeping failed:  Returns Promise reject containing error message.
   *
   * @param {Number} maxCount Maximum number of log entries.
   * @returns Promise containing result.
   */
  async keepNumberOfMaximumLogEntries(maxCount) {
    return new Promise((resolve, reject) => {
      // Create transaction for deleting 'Log Entries'
      const transaction = this.database.transaction(this.objectStore, 'readwrite')

      // Get object store for deleting 'Log Entries'
      const objectStore = transaction.objectStore(this.objectStore)

      // Get number of 'Log Entries' stored in object store 'logentries'
      const countRequest = objectStore.count()

      /**
       * This event handler is called when the count request succeed. It deletes
       * the oldest 'Log Entries' stored in the object store 'logentries'.
       */
      countRequest.onsuccess = () => {
        // Calculate number of log entries to delete
        const numberOfLogEntriesToDelete = countRequest.result - maxCount

        if (numberOfLogEntriesToDelete > 0) {
          // Get cursor for object store 'logentries'
          const index = objectStore.index('id')
          const cursor = index.openCursor()

          /**
           * This event handler is called when opening the cursor succeed.
           * It deletes the oldest 'Log Entries' stored in the object store
           * 'logentries'.
           *
           * @param {Event} event Generated by index.openCursor()
           */
          cursor.onsuccess = event => {
            // Get IDs for range
            const lower = event.target.result.value.id
            const upper = lower + numberOfLogEntriesToDelete - 1

            // Delete oldest 'Log Entries'
            const keyRangeValue = IDBKeyRange.bound(lower, upper)
            objectStore.delete(keyRangeValue)
          }
        }
      }

      /**
       * This event handler is called when deleting the oldest 'Log Entries'
       * from the object store succeed. It returns an empty Promise resolve.
       */
      transaction.oncomplete = () => {
        resolve()
      }

      /**
       * This event handler is called when deleting the oldest 'Log Entries'
       * from the object store failed. This event handler returns a Promise
       * reject containing the error message.
       *
       * @param {Event} event Generated by objectStore.delete()
       */
      transaction.onerror = event => {
        const errorMessage = `Error: Deleting oldest log entries failed! (${event.target.error})`
        reject(errorMessage)
      }
    })
  }
}

export default LogEntriesAccessor
