<template>
  <div
    class="blind-element-container"
    :class="isPressed ? 'blind-element-pressed-background' : 'blind-element-not-pressed-background'"
    :rerender="rerender"
  >
    <toggle-switch
      v-show="isEditModeEnabled"
      class="blind-element-toggle-switch"
      :enable="isEnabled()"
      @toggleEvent="enableControlElement"
    />
    <div
      v-show="isLocked"
      class="blind-element-locked"
      @mousedown.stop.passive="isPressed = false"
      @mouseup.stop.passive="isPressed = false"
      @mouseleave.stop.passive="isPressed = false"
      @touchstart.stop.passive="isPressed = false"
      @touchend.stop.passive="isPressed = false"
      @touchcancel.stop.passive="isPressed = false"
      @click.stop.passive
    >
      <img src="@/assets/widgets/blindelement/locked.svg" />
    </div>
    <div class="blind-element-title">
      {{ controlElement.name }}
    </div>
    <div
      class="blind-element-favorite"
      @mousedown.stop.passive="isPressed = false"
      @mouseup.stop.passive="isPressed = false"
      @mouseleave.stop.passive="isPressed = false"
      @touchstart.stop.passive="isPressed = false"
      @touchend.stop.passive="isPressed = false"
      @touchcancel.stop.passive="isPressed = false"
      @click.stop.passive="clickOnFavoriteStar"
    >
      <div v-show="isFavoriteStarEnabled">
        <div v-show="isFavorite">
          <img v-show="getUsedTheme === 'dark-theme'" src="@/assets/favorite/dark-theme/favoritestar-active.svg" />
          <img v-show="getUsedTheme === 'light-theme'" src="@/assets/favorite/light-theme/favoritestar-active.svg" />
        </div>
        <div v-show="!isFavorite">
          <img v-show="getUsedTheme === 'dark-theme'" src="@/assets/favorite/dark-theme/favoritestar-inactive.svg" />
          <img v-show="getUsedTheme === 'light-theme'" src="@/assets/favorite/light-theme/favoritestar-inactive.svg" />
        </div>
      </div>
    </div>
    <div class="blind-element-current-value">{{ blindPosition }}%</div>
    <slider
      class="blind-element-slider"
      :usedTheme="getUsedTheme"
      :animateSliderThumb="animate"
      :locked="isLocked"
      :initialValue="blindPosition"
      :valueTouchAreaLeft="0"
      :valueTouchAreaRight="100"
      :iconTouchAreaLeftLightMode="iconBlindUpLightMode"
      :iconTouchAreaRightLightMode="iconBlindDownLightMode"
      :iconTouchAreaLeftDarkMode="iconBlindUpDarkMode"
      :iconTouchAreaRightDarkMode="iconBlindDownDarkMode"
      @pressedEvent="setIsPressed"
      @valueChangedEvent="updateChangedBlindPosition"
      @valueReleasedEvent="updateReleasedBlindPosition"
    />
    <blind-element-settings
      class="blind-element-settings"
      :locked="isLocked"
      :slatsAngle="slatsAngle"
      @slatsAngleChangedEvent="updateSlatsAngle"
      @showSettingsDialogEvent="activateSettingsMode"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Slider from '@/ui/components/controlelements/common/Slider.vue'
import ToggleSwitch from '@/ui/components/widgets/ToggleSwitch.vue'
import BlindElementSettings from '@/ui/components/controlelements/blindelement/BlindElementSettings.vue'

export default {
  name: 'BlindElement',
  components: { Slider, ToggleSwitch, BlindElementSettings },
  props: {
    controlElementId: {
      type: String,
      required: true
    }
  },
  emits: ['showSettingsDialogEvent'],
  data() {
    return {
      currentAction: null,
      relativePositionChangedLocally: false,
      targetRelativePosition: 0,
      actualRelativePosition: 0,
      relativeTiltChangedLocally: false,
      targetRelativeTilt: 0,
      actualRelativeTilt: 0,
      countRerender: null,
      controlElement: null,
      animate: false,
      blindIsMoving: false,
      slatsAreMoving: false,
      lowerLimitEnabled: false,
      cleaningLockEnabled: false,
      weatherLockEnabled: false,
      lockoutProtectionEnabled: false,
      manuallyOverriddenEnabled: false,
      isPressed: false,
      isFavorite: false,
      iconBlindDownLightMode: require('@/assets/widgets/blindelement/light-theme/blind-down.svg'),
      iconBlindUpLightMode: require('@/assets/widgets/blindelement/light-theme/blind-up.svg'),
      iconBlindDownDarkMode: require('@/assets/widgets/blindelement/dark-theme/blind-down.svg'),
      iconBlindUpDarkMode: require('@/assets/widgets/blindelement/dark-theme/blind-up.svg')
    }
  },
  beforeMount() {
    // Register control element in Vuex store to get feedback if
    // its property 'rerender' has changed
    this.registerControlElement()

    // Initialize 'Blind Element'
    this.initialize()
  },
  beforeUnmount() {
    // Unregister control element from Vuex store
    this.deregisterControlElement()
  },
  computed: {
    ...mapGetters(['isFavoriteStarEnabled', 'isEditModeEnabled', 'getCurrentControlElement', 'getUsedTheme']),
    rerender() {
      if (this.countRerender !== this.controlElement.rerender) {
        this.update()
      }

      return this.controlElement.rerender
    },
    isLocked() {
      return (
        this.lowerLimitEnabled ||
        this.cleaningLockEnabled ||
        this.weatherLockEnabled ||
        this.lockoutProtectionEnabled ||
        this.manuallyOverriddenEnabled
      )
    },
    blindPosition() {
      let blindPosition

      if (this.relativePositionChangedLocally) {
        blindPosition = Math.round(this.targetRelativePosition / 10)
      } else {
        blindPosition = Math.round(this.actualRelativePosition / 10)
      }

      return blindPosition
    },
    slatsAngle() {
      let slatsAngle

      if (this.relativeTiltChangedLocally) {
        slatsAngle = Math.round(90 - 0.09 * this.targetRelativeTilt)
      } else {
        slatsAngle = Math.round(90 - 0.09 * this.actualRelativeTilt)
      }

      return slatsAngle
    }
  },
  methods: {
    ...mapMutations(['addCurrentControlElement', 'removeCurrentControlElement']),
    setIsPressed(isPressed) {
      this.isPressed = isPressed
    },
    isEnabled() {
      let isEnabled = false

      if (this.controlElement != null) {
        isEnabled = this.controlElement.isEnabled
      }

      return isEnabled
    },
    enableControlElement(enabled) {
      const controlElement = this.$appManager.getControlElement(this.controlElementId)

      if (controlElement != null) {
        controlElement.isEnabled = enabled

        // Update control element in database
        this.$appManager.updateControlElement(controlElement)
      }
    },
    registerControlElement() {
      // Get control element from 'App Manager'
      const controlElement = this.$appManager.getControlElement(this.controlElementId)

      // Add control element to Vuex store
      this.addCurrentControlElement(controlElement)

      // Add to module list
      this.$appManager.addToModuleList(controlElement)
    },
    deregisterControlElement() {
      // Remove control element from Vuex store
      this.removeCurrentControlElement(this.controlElement)

      // Remove from module list
      this.$appManager.removeFromModuleList(this.controlElement)
    },
    initialize() {
      // Get control element from Vuex store to get feedback if
      // its property 'rerender' has changed
      this.controlElement = this.getCurrentControlElement(this.controlElementId)

      // Update 'Blind Element' with information from control element
      this.update()
    },
    update() {
      this.countRerender = this.controlElement.rerender
      this.isFavorite = this.controlElement.isFavorite
      this.currentAction = this.controlElement.currentAction
      this.actualRelativePosition = this.controlElement.relativePosition
      this.actualRelativeTilt = this.controlElement.relativeTilt
      this.lowerLimitEnabled = this.controlElement.lowerLimitEnabled
      this.cleaningLockEnabled = this.controlElement.cleaningLockEnabled
      this.weatherLockEnabled = this.controlElement.weatherLockEnabled
      this.lockoutProtectionEnabled = this.controlElement.lockoutProtectionEnabled
      this.manuallyOverriddenEnabled = this.controlElement.manuallyOverriddenEnabled

      if (this.relativePositionChangedLocally || this.relativeTiltChangedLocally) {
        if (this.actualRelativePosition == this.targetRelativePosition) {
          this.blindIsMoving = false
          this.relativePositionChangedLocally = false
        }

        if (this.actualRelativeTilt == this.targetRelativeTilt) {
          this.slatsAreMoving = false
          this.relativeTiltChangedLocally = false
        }

        this.animate = this.blindIsMoving || this.slatsAreMoving
      } else {
        switch (this.currentAction) {
          case 'movingDown':
          case 'movingDownAndTilting':
          case 'movingUp':
          case 'movingUpAndTilting':
            this.animate = true
            break
          default:
            this.animate = false
            break
        }

        this.targetRelativePosition = this.actualRelativePosition
        this.targetRelativeTilt = this.actualRelativeTilt
      }
    },
    clickOnFavoriteStar() {
      if (this.isEditModeEnabled) {
        this.activateEditMode()
      } else {
        this.toggleFavorite()
      }
    },
    activateEditMode() {
      this.$emit('showPropertiesDialogEvent', this.controlElement)
    },
    activateSettingsMode() {
      this.$emit('showSettingsDialogEvent', this.controlElement)
    },
    toggleFavorite() {
      this.isFavorite = !this.isFavorite
      this.$appManager.setAsFavorite(this.controlElementId, this.isFavorite)

      // Update control element in database
      // (Why is controlElement a proxy object? Because of Vuex?)
      const tmpControlElement = JSON.parse(JSON.stringify(this.controlElement))
      this.$appManager.updateControlElement(tmpControlElement)
    },
    updateChangedBlindPosition(value) {
      // Set flag 'Relative Position Changed Locally'
      this.relativePositionChangedLocally = true

      // Calculate 'Relative Position'
      this.targetRelativePosition = Math.round(value * 10)
    },
    updateReleasedBlindPosition(value) {
      // Set flag 'Relative Position Changed Locally'
      this.relativePositionChangedLocally = true

      // Calculate 'Relative Position'
      this.targetRelativePosition = Math.round(value * 10)

      if (this.actualRelativePosition != this.targetRelativePosition) {
        // Slider shall animate until target position is reached
        this.blindIsMoving = true
        this.animate = true

        // Set value via 'Control Element' stored in 'App Manager'
        // to send it to Innoxel Master 3
        this.$appManager.setRelativePosition(this.controlElementId, this.targetRelativePosition)
      }
    },
    updateSlatsAngle(value) {
      // Set flag 'Relative Tilt Changed Locally'
      this.relativeTiltChangedLocally = true

      // Calculate 'Relative Tilt'
      this.targetRelativeTilt = Math.round(1000 - value / 0.09)

      if (this.actualRelativeTilt != this.targetRelativeTilt) {
        // Slider shall animate until target tilt is reached
        this.slatsAreMoving = true
        this.animate = true

        // Set value via 'Control Element' stored in 'App Manager'
        // to send it to Innoxel Master 3
        this.$appManager.setRelativeTilt(this.controlElementId, this.targetRelativeTilt)
      }
    }
  }
}
</script>

<style scoped>
.blind-element-container {
  display: grid;
  grid-template-columns: 48px 228px 48px;
  grid-template-rows: 48px 62px 48px;
  align-items: center;
  justify-items: center;
  width: 324px;
  height: 158px;
  border-radius: 14px;
}

.blind-element-not-pressed-background {
  background-color: var(--background-controlelement);
}

.blind-element-pressed-background {
  background-color: var(--pressed-background-color);
}

.blind-element-toggle-switch {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  align-self: start;
  justify-self: start;
  margin: 5px 0px 0px 5px;
}

.blind-element-locked {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  margin: -4px 0 0 -4px;
}

.blind-element-title {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: var(--text-color-primary);
}

.blind-element-favorite {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  margin: -4px 0 0 4px;
}

.blind-element-current-value {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  margin: 45px 0 0 0;
  font-family: OpenSans;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  color: var(--text-color-quaternary);
}

.blind-element-slider {
  grid-column: 1 / span 3;
  grid-row: 2 / span 1;
  justify-self: stretch;
}

.blind-element-settings {
  grid-column: 1 / span 3;
  grid-row: 3 / span 1;
  align-self: stretch;
  justify-self: stretch;
  margin: 0 5px 5px 5px;
}
</style>
