import AppManager from '@/services/appmanager/appmanager'
import ConfigurationManager from '@/services/configurationmanager/configurationmanager'
import ModuleListManager from '@/services/modulelistmanager/modulelistmanager'
import SoapInterfaceManager from '@/services/soapinterfacemanager/soapinterfacemanager'
import StorageManager from '@/services/storagemanager/storagemanager'

export default {
  install: (app, option) => {
    // Create 'App Manager'
    const appManager = new AppManager()

    // Create and initialize 'Configuration Manager'
    const configurationManager = new ConfigurationManager()
    configurationManager.setAppManager(appManager)

    // Create 'Module List Manager'
    const moduleListManager = new ModuleListManager()

    // Create 'SOAP Interface Manager'
    const soapInterfaceManager = new SoapInterfaceManager()

    // Create 'Storage Manager'
    const storageManager = new StorageManager()

    // Initialize 'App Manager'
    appManager.setConfigurationManager(configurationManager)
    appManager.setModuleListManager(moduleListManager)
    appManager.setSoapInterfaceManager(soapInterfaceManager)
    appManager.setStorageManager(storageManager)

    // Define 'App Manager' as global property
    app.config.globalProperties.$appManager = appManager
  }
}
