<template>
  <div v-if="isCollapsed" class="dropdown-collapsed-background dropdown-collapsed-container">
    <div
      class="dropdown-collapsed"
      :class="dropdownIsActivated ? 'dropdown-collapsed-pressed' : 'dropdown-collapsed-not-pressed'"
      @click.stop.passive="expand"
      @mousedown.stop.passive="dropdownIsActivated = true"
      @mouseup.stop.passive="dropdownIsActivated = false"
      @mouseleave.stop.passive="dropdownIsActivated = false"
      @touchstart.stop.passive="dropdownIsActivated = true"
      @touchend.stop.passive="dropdownIsActivated = false"
      @touchcancel.stop.passive="dropdownIsActivated = false"
    >
      <img v-if="usedTheme === 'dark-theme'" src="@/assets/dropdown/dark-theme/dropdown-arrow.svg" />
      <img v-else src="@/assets/dropdown/light-theme/dropdown-arrow.svg" />
      <span class="dropdown-collapsed-item-text">{{ selectedItem.name }}</span>
    </div>
  </div>
  <div v-else class="dropdown-expanded-background dropdown-expanded-container">
    <div class="dropdown-expanded">
      <div class="dropdown-expanded-button-area">
        <div
          @mousedown.stop.passive="closeButtonIsActivated = true"
          @mouseup.stop.passive="closeButtonIsActivated = false"
          @mouseleave.stop.passive="closeButtonIsActivated = false"
          @touchstart.stop.passive="closeButtonIsActivated = true"
          @touchend.stop.passive="closeButtonIsActivated = false"
          @touchcancel.stop.passive="closeButtonIsActivated = false"
        >
          <img
            v-show="closeButtonIsActivated"
            src="@/assets/dropdown/btn-close-down.svg"
            @mouseup.stop.passive="collapse"
          />
          <img v-show="!closeButtonIsActivated" src="@/assets/dropdown/btn-close-normal.svg" />
        </div>
      </div>
      <div class="dropdown-expanded-item-list">
        <div v-for="(item, index) in items" :key="item.id" class="dropdown-expanded-item-background">
          <div v-if="selectedItem.id === item.id" class="dropdown-expanded-selected-item">
            <div class="dropdown-expanded-selected-item-text">{{ item.name }}</div>
          </div>
          <div
            v-else
            :class="
              itemIsPressed === index
                ? 'dropdown-expanded-not-selected-item-pressed'
                : 'dropdown-expanded-not-selected-item'
            "
            @mousedown.stop.passive="itemIsPressed = index"
            @mouseup.stop.passive="itemIsPressed = false"
            @mouseleave.stop.passive="itemIsPressed = false"
            @touchstart.stop.passive="itemIsPressed = index"
            @touchend.stop.passive="itemIsPressed = false"
            @touchcancel.stop.passive="itemIsPressed = false"
            @click.stop.passive="select(item)"
          >
            <div class="dropdown-expanded-not-selected-item-text">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    usedTheme: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    },
    selectedItemId: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['collapsedEvent', 'expandedEvent', 'itemChangedEvent'],
  data() {
    return {
      isCollapsed: true,
      dropdownIsActivated: false,
      closeButtonIsActivated: false,
      itemIsPressed: false,
      selectedItem: { id: '', name: '' }
    }
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      if (this.items != null && this.items.length > 0) {
        let selectedItemSet = false

        if (this.selectedItemId != null) {
          const selectedItem = this.items.find(item => item.id === this.selectedItemId)

          if (selectedItem != null) {
            this.selectedItem = selectedItem
            selectedItemSet = true
          }
        }

        if (!selectedItemSet) {
          this.selectedItem = this.items[0]
        }

        this.$emit('itemChangedEvent', this.selectedItem)
      }
    },
    expand() {
      this.isCollapsed = false
      this.$emit('expandedEvent')
    },
    collapse() {
      this.isCollapsed = true
      this.$emit('collapsedEvent')
    },
    select(item) {
      if (this.selectedItem.id !== item.id) {
        this.selectedItem = item
        this.isCollapsed = true
        this.$emit('collapsedEvent')
        this.$emit('itemChangedEvent', item)
      }
    }
  }
}
</script>

<style scoped>
.dropdown-collapsed-background {
  background-color: var(--background-color-primary);
}

.dropdown-collapsed-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-collapsed {
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  margin: 0 40px;
  padding: 0 10px;
  border-radius: 18px;
}

.dropdown-collapsed-not-pressed {
  background-color: var(--dropdown-background-color);
}

.dropdown-collapsed-pressed {
  background-color: var(--dropdown-pressed-background-color);
}

.dropdown-collapsed-item-text {
  padding: 0 0 0 10px;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: bold;
  color: var(--text-color-primary);
}

.dropdown-expanded-background {
  background-color: var(--background-color-primary);
}

.dropdown-expanded-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.dropdown-expanded {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px auto 20px;
  height: 80vh;
  background-color: var(--background-color-primary);
  z-index: 1;
}

.dropdown-expanded-button-area {
  justify-self: center;
  align-self: center;
}

.dropdown-expanded-item-list {
  overflow-y: auto;
}

.dropdown-expanded-item-background {
  height: 40px;
}

.dropdown-expanded-not-selected-item {
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 18px;
  margin: 0 40px;
  padding: 0 12px;
}

.dropdown-expanded-not-selected-item-pressed {
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 18px;
  margin: 0 40px;
  padding: 0 12px;
  background-color: var(--dropdown-pressed-background-color);
}

.dropdown-expanded-not-selected-item-text {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.dropdown-expanded-selected-item {
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 18px;
  margin: 0 40px;
  padding: 0 12px;
  background-color: var(--dropdown-background-color);
}

.dropdown-expanded-selected-item-text {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: bold;
  color: var(--text-color-primary);
}

@media (min-width: 600px) {
  .dropdown-collapsed {
    margin: 0 15px;
  }

  .dropdown-expanded {
    position: absolute;
    grid-template-rows: 54px auto 18px;
    width: 270px;
    height: 94vh;
    border-radius: 16px;
    margin: 10px 0 0 15px;
  }

  .dropdown-expanded-button-area {
    justify-self: start;
    align-self: start;
    margin: 2px;
  }

  .dropdown-expanded-not-selected-item {
    margin: 0 15px;
  }

  .dropdown-expanded-not-selected-item-pressed {
    margin: 0 15px;
  }

  .dropdown-expanded-selected-item {
    margin: 0 15px;
  }
}
</style>
