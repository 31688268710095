<template>
  <div
    class="room-climate-container"
    :class="isPressed ? 'room-climate-pressed-background' : 'room-climate-not-pressed-background'"
    :rerender="rerender"
    @mousedown.stop.passive="isPressed = true"
    @mouseup.stop.passive="isPressed = false"
    @mouseleave.stop.passive="isPressed = false"
    @touchstart.stop.passive="isPressed = true"
    @touchend.stop.passive="isPressed = false"
    @touchcancel.stop.passive="isPressed = false"
    @click.stop.passive="clickOnRoomClimateElement"
  >
    <toggle-switch
      v-show="isEditModeEnabled"
      class="room-climate-toggle-switch"
      :enable="isEnabled()"
      @toggleEvent="enableControlElement"
    />
    <div v-show="isAlarmAvailable" class="room-climate-alarm-icon">
      <img v-if="getUsedTheme === 'dark-theme'" :src="iconAlarmsDarkMode" />
      <img v-else :src="iconAlarmsLightMode" />
    </div>
    <div class="room-climate-title">
      {{ controlElement.name }}
    </div>
    <div
      class="room-climate-favorite"
      @mousedown.stop.passive="isPressed = false"
      @mouseup.stop.passive="isPressed = false"
      @mouseleave.stop.passive="isPressed = false"
      @touchstart.stop.passive="isPressed = false"
      @touchend.stop.passive="isPressed = false"
      @touchcancel.stop.passive="isPressed = false"
      @click.stop.passive="clickOnFavoriteStar"
    >
      <div v-show="isFavoriteStarEnabled">
        <div v-if="isFavorite">
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/favorite/dark-theme/favoritestar-active.svg" />
          <img v-else src="@/assets/favorite/light-theme/favoritestar-active.svg" />
        </div>
        <div v-else>
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/favorite/dark-theme/favoritestar-inactive.svg" />
          <img v-else src="@/assets/favorite/light-theme/favoritestar-inactive.svg" />
        </div>
      </div>
    </div>
    <div class="room-climate-current-temperature">
      <div class="room-climate-current-temperature-value">{{ actualRoomTemperature }}</div>
      <div class="room-climate-current-temperature-unit">°C</div>
    </div>
    <div class="room-climate-target-temperature">{{ targetRoomTemperature }}</div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ToggleSwitch from '@/ui/components/widgets/ToggleSwitch.vue'
import i18n from '@/i18n'

export default {
  name: 'RoomClimateElement',
  components: { ToggleSwitch },
  props: {
    controlElementId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      countRerender: null,
      controlElement: null,
      operatingState: null,
      valveState: null,
      actualTemperatureMean: null,
      setTemperatureHeating: null,
      setTemperatureCooling: null,
      nightSetbackTemperatureHeating: null,
      nightSetbackTemperatureCooling: null,
      absenceSetbackTemperatureHeating: null,
      absenceSetbackTemperatureCooling: null,
      isPressed: false,
      isFavorite: false,
      alarmState: null,
      iconAlarmsLightMode: require('@/assets/widgets/roomclimateelement/light-theme/alarms.svg'),
      iconAlarmsDarkMode: require('@/assets/widgets/roomclimateelement/dark-theme/alarms.svg')
    }
  },
  beforeMount() {
    // Register control element in Vuex store to get feedback if
    // its property 'rerender' has changed
    this.registerControlElement()

    // Initialize 'Room Climate Element'
    this.initialize()
  },
  beforeUnmount() {
    // Unregister control element from Vuex store
    this.deregisterControlElement()
  },
  computed: {
    ...mapGetters(['isFavoriteStarEnabled', 'isEditModeEnabled', 'getCurrentControlElement', 'getUsedTheme']),
    rerender() {
      if (this.countRerender !== this.controlElement.rerender) {
        this.update()
      }

      return this.controlElement.rerender
    },
    actualRoomTemperature() {
      let actualRoomTemperature = '--.-'

      if (this.actualTemperatureMean != null) {
        if (this.actualTemperatureMean.unit === '°C') {
          actualRoomTemperature = this.actualTemperatureMean.value
        }
      }

      return actualRoomTemperature
    },
    targetRoomTemperature() {
      let label = i18n.global.t('noSetpoint')
      let setpoint = ''
      let temperature = '--.-'
      let unit = '°C'

      if (this.operatingState != null) {
        switch (this.operatingState) {
          case 'heating':
            label = i18n.global.t('normalDay')
            setpoint = i18n.global.t('heatingTarget')
            temperature = this.getTemperature(this.setTemperatureHeating)
            break
          case 'cooling':
            label = i18n.global.t('normalDay')
            setpoint = i18n.global.t('coolingTarget')
            temperature = this.getTemperature(this.setTemperatureCooling)
            break
          case 'nightSetbackHeating':
            label = i18n.global.t('nightReduction')
            setpoint = i18n.global.t('heatingTarget')
            temperature = this.getTemperature(this.nightSetbackTemperatureHeating)
            break
          case 'nightSetbackCooling':
            label = i18n.global.t('nightReduction')
            setpoint = i18n.global.t('coolingTarget')
            temperature = this.getTemperature(this.nightSetbackTemperatureCooling)
            break
          case 'absenceSetbackHeating':
            label = i18n.global.t('absent')
            setpoint = i18n.global.t('heatingTarget')
            temperature = this.getTemperature(this.absenceSetbackTemperatureHeating)
            break
          case 'absenceSetbackCooling':
            label = i18n.global.t('absent')
            setpoint = i18n.global.t('coolingTarget')
            temperature = this.getTemperature(this.absenceSetbackTemperatureCooling)
            break
          case 'heatUpHeating':
            label = i18n.global.t('heatingUp')
            setpoint = i18n.global.t('heatingTarget')
            temperature = this.getTemperature(this.setTemperatureHeating)
            break
          case 'heatUpCooling':
            label = i18n.global.t('coolingDown')
            setpoint = i18n.global.t('coolingTarget')
            temperature = this.getTemperature(this.setTemperatureCooling)
            break
        }
      }

      const targetRoomTemperature = `${label} | ${setpoint} ${temperature}${unit}`

      return targetRoomTemperature
    },
    isAlarmAvailable() {
      let alarmAvailable = false

      if (this.alarmState != null) {
        if (this.alarmState != 'nothing') {
          alarmAvailable = true
        }
      }

      return alarmAvailable
    }
  },
  methods: {
    ...mapMutations(['addCurrentControlElement', 'removeCurrentControlElement']),
    isEnabled() {
      let isEnabled = false

      if (this.controlElement != null) {
        isEnabled = this.controlElement.isEnabled
      }

      return isEnabled
    },
    enableControlElement(enabled) {
      const controlElement = this.$appManager.getControlElement(this.controlElementId)

      if (controlElement != null) {
        controlElement.isEnabled = enabled

        // Update control element in database
        this.$appManager.updateControlElement(controlElement)
      }
    },
    registerControlElement() {
      // Get control element from 'App Manager'
      const controlElement = this.$appManager.getControlElement(this.controlElementId)

      // Add control element to Vuex store
      this.addCurrentControlElement(controlElement)

      // Add to module list
      this.$appManager.addToModuleList(controlElement)
    },
    deregisterControlElement() {
      // Remove control element from Vuex store
      this.removeCurrentControlElement(this.controlElement)

      // Remove from module list
      this.$appManager.removeFromModuleList(this.controlElement)
    },
    initialize() {
      // Get control element from Vuex store to get feedback if
      // its property 'rerender' has changed
      this.controlElement = this.getCurrentControlElement(this.controlElementId)

      // Update 'Room Climate Element' with information from control element
      this.update()
    },
    update() {
      this.countRerender = this.controlElement.rerender
      this.isFavorite = this.controlElement.isFavorite

      if (this.controlElement.thermostatData != null) {
        this.operatingState = this.controlElement.thermostatData.operatingState
        this.valveState = this.controlElement.thermostatData.valveState
        this.actualTemperatureMean = this.controlElement.thermostatData.actualTemperatureMean
        this.setTemperatureHeating = this.controlElement.thermostatData.setTemperatureHeating
        this.setTemperatureCooling = this.controlElement.thermostatData.setTemperatureCooling
        this.nightSetbackTemperatureHeating = this.controlElement.thermostatData.nightSetbackTemperatureHeating
        this.nightSetbackTemperatureCooling = this.controlElement.thermostatData.nightSetbackTemperatureCooling
        this.absenceSetbackTemperatureHeating = this.controlElement.thermostatData.absenceSetbackTemperatureHeating
        this.absenceSetbackTemperatureCooling = this.controlElement.thermostatData.absenceSetbackTemperatureCooling
      } else {
        this.operatingState = null
        this.valveState = null
        this.actualTemperatureMean = null
        this.setTemperatureHeating = null
        this.setTemperatureCooling = null
        this.nightSetbackTemperatureHeating = null
        this.nightSetbackTemperatureCooling = null
        this.absenceSetbackTemperatureHeating = null
        this.absenceSetbackTemperatureCooling = null
      }

      this.alarmState = this.controlElement.alarmState
    },
    getTemperature(temperature) {
      let tmpTemperature = '--.-'

      if (temperature != null) {
        if (temperature.value != null && temperature.unit != null) {
          if (temperature.unit === '°C') {
            tmpTemperature = Number(temperature.value).toFixed(1)
          }
        }
      }

      return tmpTemperature
    },
    clickOnFavoriteStar() {
      if (this.isEditModeEnabled) {
        this.activateEditMode()
      } else {
        this.toggleFavorite()
      }
    },
    clickOnRoomClimateElement() {
      this.$emit('showSettingsDialogEvent', this.controlElement)
    },
    activateEditMode() {
      this.$emit('showPropertiesDialogEvent', this.controlElement)
    },
    toggleFavorite() {
      this.isFavorite = !this.isFavorite
      this.$appManager.setAsFavorite(this.controlElementId, this.isFavorite)

      // Update control element in database
      // (Why is controlElement a proxy object? Because of Vuex?)
      const tmpControlElement = JSON.parse(JSON.stringify(this.controlElement))
      this.$appManager.updateControlElement(tmpControlElement)
    }
  }
}
</script>

<style scoped>
.room-climate-container {
  display: grid;
  grid-template-columns: 48px 228px 48px;
  grid-template-rows: 48px 62px 48px;
  align-items: center;
  justify-items: center;
  width: 324px;
  height: 158px;
  border-radius: 14px;
}

.room-climate-not-pressed-background {
  background-color: var(--background-controlelement);
}

.room-climate-pressed-background {
  background-color: var(--pressed-background-color);
}

.room-climate-toggle-switch {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  align-self: start;
  justify-self: start;
  margin: 5px 0px 0px 5px;
}

.room-climate-alarm-icon {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
}

.room-climate-title {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: var(--text-color-primary);
}

.room-climate-favorite {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  margin: -4px 0 0 4px;
}

.room-climate-target-temperature {
  grid-column: 1 / span 3;
  grid-row: 3 / span 1;
  font-family: OpenSans;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  color: var(--text-color-quaternary);
}

.room-climate-current-temperature {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  display: grid;
  grid-template-columns: 132px 6px 90px;
  grid-template-rows: 56px;
  align-items: baseline;
}
.room-climate-current-temperature-value {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 37px;
  font-weight: 600;
  text-align: center;
  color: var(--text-color-primary);
}

.room-climate-current-temperature-unit {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  justify-self: start;
  font-family: OpenSans;
  font-size: 27px;
  font-weight: 600;
  text-align: center;
  color: var(--text-color-primary);
}
</style>
