<template>
  <div class="ip-camera-container">
    <img
      class="ip-camera-video"
      :id="id"
      :width="videoWidth"
      :height="videoHeight"
      alt="IP Camera"
      :src="getIpCameraSource"
      :key="rerender"
    />
    <div class="ip-camera-control-elements">
      <div
        v-if="isVideoPlaying == false"
        class="ip-camera-refresh-button"
        :class="isRefreshButtonPressed ? 'ip-camera-refresh-button-pressed-background' : ''"
        @mousedown.stop.passive="isRefreshButtonPressed = true"
        @mouseup.stop.passive="isRefreshButtonPressed = false"
        @mouseleave.stop.passive="isRefreshButtonPressed = false"
        @touchstart.stop.passive="isRefreshButtonPressed = true"
        @touchend.stop.passive="isRefreshButtonPressed = false"
        @touchcancel.stop.passive="isRefreshButtonPressed = false"
        @click.stop.passive="clickOnRefreshButton"
      >
        <svg class="ip-camera-refresh-button-icon" height="18" width="18">
          <path d="M 17,9 A 6 6 0 1 1 11,4" style="stroke: white; fill: none; stroke-width: 1.5px" />
          <path d="M 17,4 L 11,1 L 11,7 Z" style="stroke: white; fill: white; stroke-width: 1.5px" />
        </svg>
        <div class="ip-camera-refresh-button-timestamp">{{ getRefreshLiveImageTimestamp }}</div>
      </div>
      <div v-if="isVideoPlaying == false" class="ip-camera-control-elements-separator" />
      <div
        v-if="isVideoPlaying == false"
        class="ip-camera-play-button"
        :class="isPlayButtonPressed ? 'ip-camera-play-button-pressed-background' : ''"
        @mousedown.stop.passive="isPlayButtonPressed = true"
        @mouseup.stop.passive="isPlayButtonPressed = false"
        @mouseleave.stop.passive="isPlayButtonPressed = false"
        @touchstart.stop.passive="isPlayButtonPressed = true"
        @touchend.stop.passive="isPlayButtonPressed = false"
        @touchcancel.stop.passive="isPlayButtonPressed = false"
        @click.stop.passive="clickOnPlayButton"
      >
        <svg class="ip-camera-play-button-icon" height="18" width="18">
          <polygon points="0.0,1.0 16.0,8.5 0.0,17.0" style="fill: white; stroke-width: 0px" />
        </svg>
        <div class="ip-camera-play-button-text">Live</div>
      </div>
      <div
        v-if="isVideoPlaying == true"
        class="ip-camera-stop-button"
        :class="isStopButtonPressed ? 'ip-camera-stop-button-pressed-background' : ''"
        @mousedown.stop.passive="isStopButtonPressed = true"
        @mouseup.stop.passive="isStopButtonPressed = false"
        @mouseleave.stop.passive="isStopButtonPressed = false"
        @touchstart.stop.passive="isStopButtonPressed = true"
        @touchend.stop.passive="isStopButtonPressed = false"
        @touchcancel.stop.passive="isStopButtonPressed = false"
        @click.stop.passive="clickOnStopButton"
      >
        <svg class="ip-camera-stop-button-icon" height="18" width="18">
          <rect x="0" y="2.0" width="14.0" height="14.0" style="fill: white; stroke-width: 0px" />
        </svg>
        <div class="ip-camera-stop-button-text">Stop</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IpCamera',
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    width: {
      type: Number,
      required: false,
      default: 640
    },
    height: {
      type: Number,
      required: false,
      default: 480
    },
    liveImageUrl: {
      type: String,
      required: false,
      default: null
    },
    liveVideoUrl: {
      type: String,
      required: false,
      default: null
    },
    stopRunningVideo: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      rerender: 0,
      videoWidth: this.width,
      videoHeight: this.height,
      extendedLiveImageUrl: this.liveImageUrl,
      extendedLiveVideoUrl: this.liveVideoUrl,
      isRefreshButtonPressed: false,
      isPlayButtonPressed: false,
      isStopButtonPressed: false,
      refreshLiveImageTimerInterval: null,
      refreshLiveImageTimestamp: null,
      isVideoPlaying: false,
      timestampStartPlayingVideo: null,
      sampleSelect: false,
      sampleImageA: null,
      sampleImageB: null
    }
  },
  beforeMount() {
    // Start 'Refresh Live Image Timer'
    this.startRefreshLiveImageTimer()
  },
  mounted() {
    this.emitter.on('stopAllRunningVideosGlobalEvent', () => {
      // Stop playing video if running
      this.stopPlayingVideo('App', 'Stop all running videos event')
    })

    window.addEventListener('visibilitychange', this.handleVisibilityChange)
  },
  unmounted() {
    window.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },
  beforeUnmount() {
    // Stop 'Refresh Live Image Timer'
    this.stopRefreshLiveImageTimer()

    // Stop playing video if running
    this.stopPlayingVideo('App', 'Unmount component')
  },
  watch: {
    width() {
      this.videoWidth = this.width
    },
    height() {
      this.videoHeight = this.height
    },
    stopRunningVideo() {
      // Stop playing video if running
      this.stopPlayingVideo('App', 'Stopped by another component')
    }
  },
  computed: {
    getIpCameraSource() {
      if (this.isVideoPlaying) {
        return this.extendedLiveVideoUrl
      } else {
        return this.extendedLiveImageUrl
      }
    },
    getRefreshLiveImageTimestamp() {
      return this.refreshLiveImageTimestamp
    }
  },
  methods: {
    handleVisibilityChange() {
      if (document.hidden) {
        this.stopPlayingVideo('App', 'WebApp is hidden')
      }
    },
    clickOnRefreshButton() {
      this.getLiveImage()
    },
    clickOnPlayButton() {
      this.playVideo()
    },
    clickOnStopButton() {
      this.stopPlayingVideo('User')
    },
    playVideo() {
      if (!this.isVideoPlaying) {
        // All running videos must be stopped first to run this video
        this.emitter.emit('stopAllRunningVideosGlobalEvent')

        // Stop 'Refresh Live Image Timer'
        this.stopRefreshLiveImageTimer()

        // Create log entry
        const message = 'Start playing video: ' + this.name
        this.storeLogEntry(message)

        // Get current date/time
        const dateTime = new Date()

        // Add current time to 'Live Video URL'
        this.extendedLiveVideoUrl = `${this.liveVideoUrl}`

        // Store timestamp 'Start Playing Video'
        this.timestampStartPlayingVideo = dateTime

        // Set flag 'Is Video Playing'
        this.isVideoPlaying = true

        // Start 'Broken Stream Detector' in 1000 milliseconds
        this.startBrokenStreamDetector(1000)
      }
    },
    stopPlayingVideo(stoppedBy, reason) {
      if (this.isVideoPlaying) {
        // Attention: This is a workaround for WebKit based browsers. These browsers
        // don't stop downloading resources even there is a different URL assigned to
        // the video component (URL gets stuck in the cache). The connection to the
        // proxy server is still alive until the connection timeout occurs in INNOXEL
        // Master 3. This is a bug in WebKit (bug #6656).
        window.stop()

        // Reset flag 'Is Video Playing'
        this.isVideoPlaying = false

        // Calculate duration in seconds of playing video
        const timestampStop = new Date()
        const duration = (timestampStop - this.timestampStartPlayingVideo) / 1000

        // Create log entry
        let message = `${stoppedBy} stops playing video: ${this.name} (duration: ${duration}s)`

        if (reason != null) {
          message = `${message}; Reason: ${reason}`
        }

        // Store log entry
        this.storeLogEntry(message)

        // Reset timestamp 'Start Playing Video'
        this.timestampStartPlayingVideo = null

        // Reset variables used for detecting broken stream
        this.sampleSelect = false
        this.sampleImageA = null
        this.sampleImageB = null

        // Force reload of HTML element <img><img/>
        this.rerender += 1

        // Start 'Refresh Live Image Timer'
        this.startRefreshLiveImageTimer()
      }
    },
    startRefreshLiveImageTimer() {
      if (!this.isVideoPlaying) {
        // Define timer interval [ms]
        const interval = 120000

        // Stop running 'Refresh Live Image Timer'
        this.stopRefreshLiveImageTimer()

        // Get 'Live Image'
        this.getLiveImage()

        // Run 'Refresh Live Image Timer'
        this.refreshLiveImageTimerInterval = setInterval(() => {
          this.getLiveImage()
        }, interval)
      }
    },
    stopRefreshLiveImageTimer() {
      // Stop running 'Refresh Live Image Timer'
      if (this.refreshLiveImageTimerInterval != null) {
        clearInterval(this.refreshLiveImageTimerInterval)
        this.refreshLiveImageTimerInterval = null
      }
    },
    getLiveImage() {
      if (!this.isVideoPlaying) {
        // Get current date/time
        const dateTime = new Date()

        // Add current time to 'Live Image URL'
        this.extendedLiveImageUrl = `${this.liveImageUrl}&rnd=${dateTime.getTime()}`

        // Get hours and minutes of current date/time
        let timeHours = String(dateTime.getHours()).padStart(2, '0')
        let timeMinutes = String(dateTime.getMinutes().toString()).padStart(2, '0')

        // Update 'Refresh Live Image Timestamp'
        this.refreshLiveImageTimestamp = timeHours + ':' + timeMinutes
      }
    },
    startBrokenStreamDetector(timerInterval) {
      setTimeout(() => {
        this.runBrokenStreamDetector()
      }, timerInterval)
    },
    runBrokenStreamDetector() {
      try {
        if (this.isVideoPlaying) {
          // Variable declarations
          const imageWidth = 24
          const imageHeight = 24

          // Get HTML element containing 'IP Video'
          const htmlElement = document.getElementById(this.id)

          // Create canvas to store current image of 'IP Video'
          const image = document.createElement('canvas')
          image.width = imageWidth
          image.height = imageHeight

          // Copy part of current image of 'IP Video' into canvas element
          //
          // Syntax of (image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
          //    image:    source image                     -> htmlElement
          //    sx:       x-position of source image       -> 64
          //    sy:       y-position of source image       -> 64
          //    sWidth:   width of source image            -> imageWidth (24)
          //    sHeight:  height of source image           -> imageHeight (24)
          //    dx:       x-position of destination image  -> 0
          //    dy:       y-position of destination image  -> 0
          //    dWidth:   width of destination image       -> imageWidth (24)
          //    dHeight:  height of destination image      -> imageHeight (24)
          const context = image.getContext('2d')
          context.drawImage(htmlElement, 64, 64, imageWidth, imageHeight, 0, 0, imageWidth, imageHeight)

          // Get 'Sample Image A' or 'Sample Image B' stored in canvas element
          if (!this.sampleSelect) {
            this.sampleImageA = context.getImageData(0, 0, imageWidth, imageHeight)
          } else {
            this.sampleImageB = context.getImageData(0, 0, imageWidth, imageHeight)
          }

          if (this.sampleImageA === null && this.sampleImageB === null) {
            // Both 'Sample Images' contain null --> Something went wrong

            // Stop playing video
            this.stopPlayingVideo('Broken Stream Detector', 'Both sample images are invalid')

            // Leave method
            return
          }

          if (this.sampleImageB === null) {
            // 'Sample Image B' contains null in first loop

            // Revert 'Sample Select' for next loop
            this.sampleSelect = !this.sampleSelect

            // Start 'Broken Stream Detector' in 1000 milliseconds
            // to handle next loop
            this.startBrokenStreamDetector(1000)

            // Leave method
            return
          }

          if (this.sampleImageA.data.length !== this.sampleImageB.data.length) {
            // Length of 'Sample Image A' und 'Sample Image B' are
            // different --> Something went wrong

            // Stop playing video
            this.stopPlayingVideo('Broken Stream Detector', 'Length sample images are different')

            // Leave method
            return
          }

          // Compare 'Sample Image A' with 'Sample Image B' to detect a broken stream
          for (let i = 0; i < this.sampleImageA.data.length; i++) {
            if (this.sampleImageA.data[i] !== this.sampleImageB.data[i]) {
              // A difference between 'Sample Image A' and 'Sample Image B'
              // is detected --> Video is still streaming

              // Revert 'Sample Select' for next loop
              this.sampleSelect = !this.sampleSelect

              // Start 'Broken Stream Detector' in 1000 milliseconds
              // to handle next loop
              this.startBrokenStreamDetector(1000)

              // Leave method
              return
            }
          }

          // 'Sample Image A' and 'Sample Image B' contain completely the
          // same data --> Stop playing video
          this.stopPlayingVideo('Broken Stream Detector', 'Detected broken stream')
        }
      } catch (error) {
        // Something went wrong

        // Stop playing video
        this.stopPlayingVideo('Broken Stream Detector', error)
      }
    },
    storeLogEntry(message) {
      this.$appManager.storeLogEntry({ timestamp: new Date(), message: message })
    }
  }
}
</script>

<style scoped>
.ip-camera-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
}

.ip-camera-video {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  max-width: 100%;
  height: auto;
}

.ip-camera-control-elements {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  margin: 0 0 5px 5px;
  width: auto;
  height: 28px;
  opacity: 0.78;
  border-width: 0;
  border-radius: 14px;
  background: var(--ip-video-control-elements-background-color);
  display: grid;
}

.ip-camera-refresh-button {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  width: 72px;
  height: 28px;
  display: grid;
  align-content: center;
  justify-content: center;
  grid-template-columns: 18px 44px 2px;
  grid-template-rows: 28px;
}

.ip-camera-refresh-button-pressed-background {
  background: var(--ip-video-control-elements-button-pressed-color);
  border: solid 2px var(--ip-video-control-elements-background-color);
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}

.ip-camera-refresh-button-icon {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
  justify-self: center;
}

.ip-camera-refresh-button-timestamp {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  color: var(--ip-video-control-elements-button-text-color);
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  align-self: center;
  justify-self: end;
}

.ip-camera-control-elements-separator {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  width: 1px;
  height: 20px;
  background-color: var(--ip-video-control-elements-button-text-color);
  margin: 4px 0;
}

.ip-camera-play-button {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  width: 72px;
  height: 28px;
  display: grid;
  align-content: center;
  justify-content: center;
  grid-template-columns: 4px 18px 40px;
  grid-template-rows: 28px;
}

.ip-camera-play-button-pressed-background {
  background: var(--ip-video-control-elements-button-pressed-color);
  border: solid 2px var(--ip-video-control-elements-background-color);
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}

.ip-camera-play-button-icon {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
  justify-self: center;
}

.ip-camera-play-button-text {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  color: var(--ip-video-control-elements-button-text-color);
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  align-self: center;
  justify-self: center;
}

.ip-camera-stop-button {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  width: 72px;
  height: 28px;
  display: grid;
  align-content: center;
  justify-content: center;
  grid-template-columns: 20px 34px;
  grid-template-rows: 28px;
}

.ip-camera-stop-button-pressed-background {
  background: var(--ip-video-control-elements-button-pressed-color);
  border: solid 2px var(--ip-video-control-elements-background-color);
  border-radius: 14px;
}

.ip-camera-stop-button-icon {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
  justify-self: center;
}

.ip-camera-stop-button-text {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  color: var(--ip-video-control-elements-button-text-color);
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  align-self: center;
  justify-self: center;
}
</style>
