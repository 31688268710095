class WeatherData {
  constructor() {
    this.temperatureData = null
    this.precipitationData = null
    this.windData = null
    this.brightnessData = null
  }

  setTemperatureData(temperatureData) {
    this.temperatureData = temperatureData
  }

  setPrecipitationData(precipitationData) {
    this.precipitationData = precipitationData
  }

  setWindData(windData) {
    this.windData = windData
  }

  setBrightnessData(brightnessData) {
    this.brightnessData = brightnessData
  }
}

export default WeatherData
