<template>
  <div class="blind-element-slats-control-container">
    <div
      :class="getStyleForButtonMinus"
      @mousedown.stop.passive="handlePressedButtonMinus"
      @mouseup.stop.passive="handleReleasedButtonMinus"
      @mouseleave.stop.passive="handleReleasedButtonMinus"
      @touchstart.stop.passive="handlePressedButtonMinus"
      @touchend.stop.passive="handleReleasedButtonMinus"
      @touchcancel.stop.passive="handleReleasedButtonMinus"
      @click.stop.passive="decrementSlatsAngle"
    >
      <div :class="getStyleForHorizontalLineOfButtonMinus" />
    </div>
    <div
      :class="getStyleForButtonPlus"
      @mousedown.stop.passive="handlePressedButtonPlus"
      @mouseup.stop.passive="handleReleasedButtonPlus"
      @mouseleave.stop.passive="handleReleasedButtonPlus"
      @touchstart.stop.passive="handlePressedButtonPlus"
      @touchend.stop.passive="handleReleasedButtonPlus"
      @touchcancel.stop.passive="handleReleasedButtonPlus"
      @click.stop.passive="incrementSlatsAngle"
    >
      <div :class="getStyleForHorizontalLineOfButtonPlus" />
      <div :class="getStyleForVerticalLineOfButtonPlus" />
    </div>
    <div class="blind-element-slats-control-circle-container">
      <div class="blind-element-slats-control-outer-circle" />
      <div class="blind-element-slats-control-inner-circle">
        <div class="blind-element-slats-control-circle-segment" :style="getStyleForCircleSegment"></div>
      </div>
      <div class="blind-element-slats-control-slat" :style="getStyleForSlat" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlindElementSlatsControl',
  props: {
    locked: {
      type: Boolean,
      required: false,
      default: false
    },
    initialSlatsAngle: {
      type: Number,
      required: true
    },
    maximumSlatsAngle: {
      type: Number,
      required: false,
      default: 90
    }
  },
  emits: ['valueChangedEvent', 'valueReleasedEvent'],
  data() {
    return {
      slatsAngle: this.initialSlatsAngle,
      isButtonMinusPressed: false,
      isButtonPlusPressed: false,
      longButtonPressedTimer: null,
      decrementSlatsAngleTimer: null,
      incrementSlatsAngleTimer: null,
      minimumButtonPressDuration: 750
    }
  },
  watch: {
    initialSlatsAngle() {
      this.slatsAngle = this.initialSlatsAngle
    }
  },
  computed: {
    getStyleForButtonMinus() {
      let style = 'blind-element-slats-control-button blind-element-slats-control-button-minus'

      if (this.slatsAngle === 0) {
        style += ' blind-element-slats-control-button-minus-disabled'
      } else {
        style += ' blind-element-slats-control-button-minus-enabled'
        style += this.isButtonMinusPressed ? ' blind-element-slats-control-pressed-background' : ''
      }

      return style
    },
    getStyleForHorizontalLineOfButtonMinus() {
      let style = 'blind-element-slats-control-button-line-horizontal'

      if (this.slatsAngle === 0) {
        style += ' blind-element-slats-control-button-line-horizontal-disabled'
      } else {
        style += ' blind-element-slats-control-button-line-horizontal-enabled'
      }

      return style
    },
    getStyleForButtonPlus() {
      let style = 'blind-element-slats-control-button blind-element-slats-control-button-plus'

      if (this.slatsAngle === 90) {
        style += ' blind-element-slats-control-button-plus-disabled'
      } else {
        style += ' blind-element-slats-control-button-plus-enabled'
        style += this.isButtonPlusPressed ? ' blind-element-slats-control-pressed-background' : ''
      }

      return style
    },
    getStyleForHorizontalLineOfButtonPlus() {
      let style = 'blind-element-slats-control-button-line-horizontal'

      if (this.slatsAngle === 90) {
        style += ' blind-element-slats-control-button-line-horizontal-disabled'
      } else {
        style += ' blind-element-slats-control-button-line-horizontal-enabled'
      }

      return style
    },
    getStyleForVerticalLineOfButtonPlus() {
      let style = 'blind-element-slats-control-button-line-vertical'

      if (this.slatsAngle === 90) {
        style += ' blind-element-slats-control-button-line-vertical-disabled'
      } else {
        style += ' blind-element-slats-control-button-line-vertical-enabled'
      }

      return style
    },
    getStyleForSlat() {
      return { transform: 'rotate(' + (90 - this.slatsAngle) + 'deg)' }
    },
    getStyleForCircleSegment() {
      const currentAngle = this.slatsAngle
      return { transform: 'rotate(270deg) skew(' + currentAngle + 'deg)' }
    }
  },
  methods: {
    handlePressedButtonMinus() {
      if (!this.locked) {
        this.isButtonMinusPressed = true

        if (!this.longButtonPressedTimer) {
          this.longButtonPressedTimer = setTimeout(
            this.runTimerForDecrementingSlatsAngle,
            this.minimumButtonPressDuration
          )
        }
      }
    },
    handleReleasedButtonMinus() {
      this.isButtonMinusPressed = false

      if (this.longButtonPressedTimer) {
        clearTimeout(this.longButtonPressedTimer)
        this.longButtonPressedTimer = null
      }

      if (this.decrementSlatsAngleTimer) {
        clearInterval(this.decrementSlatsAngleTimer)
        this.decrementSlatsAngleTimer = null
        this.$emit('valueReleasedEvent', this.slatsAngle)
      }
    },
    handlePressedButtonPlus() {
      if (!this.locked) {
        this.isButtonPlusPressed = true

        if (!this.longButtonPressedTimer) {
          this.longButtonPressedTimer = setTimeout(
            this.runTimerForIncrementingSlatsAngle,
            this.minimumButtonPressDuration
          )
        }
      }
    },
    handleReleasedButtonPlus() {
      this.isButtonPlusPressed = false

      if (this.longButtonPressedTimer) {
        clearTimeout(this.longButtonPressedTimer)
        this.longButtonPressedTimer = null
      }

      if (this.incrementSlatsAngleTimer) {
        clearInterval(this.incrementSlatsAngleTimer)
        this.incrementSlatsAngleTimer = null
        this.$emit('valueReleasedEvent', this.slatsAngle)
      }
    },
    runTimerForDecrementingSlatsAngle() {
      if (!this.decrementSlatsAngleTimer) {
        this.decrementSlatsAngleTimer = setInterval(() => {
          this.decrementSlatsAngle()
        }, 50)
      }
    },
    runTimerForIncrementingSlatsAngle() {
      if (!this.incrementSlatsAngleTimer) {
        this.incrementSlatsAngleTimer = setInterval(() => {
          this.incrementSlatsAngle()
        }, 50)
      }
    },
    decrementSlatsAngle() {
      if (!this.locked) {
        if (this.slatsAngle > 0) {
          this.slatsAngle = this.slatsAngle - 1
          this.$emit('valueChangedEvent', this.slatsAngle)

          if (!this.isButtonMinusPressed) {
            this.$emit('valueReleasedEvent', this.slatsAngle)
          }
        }

        if (this.decrementSlatsAngleTimer && this.slatsAngle === 0) {
          clearInterval(this.decrementSlatsAngleTimer)
          this.decrementSlatsAngleTimer = null
          this.$emit('valueReleasedEvent', this.slatsAngle)
        }
      }
    },
    incrementSlatsAngle() {
      if (!this.locked) {
        if (this.slatsAngle < this.maximumSlatsAngle) {
          this.slatsAngle = this.slatsAngle + 1
          this.$emit('valueChangedEvent', this.slatsAngle)

          if (!this.isButtonPlusPressed) {
            this.$emit('valueReleasedEvent', this.slatsAngle)
          }
        }

        if (this.incrementSlatsAngleTimer && this.slatsAngle === this.maximumSlatsAngle) {
          clearInterval(this.incrementSlatsAngleTimer)
          this.incrementSlatsAngleTimer = null
          this.$emit('valueReleasedEvent', this.slatsAngle)
        }
      }
    }
  }
}
</script>

<style scoped>
.blind-element-slats-control-container {
  display: grid;
  grid-template-columns: 48px 30px 96px 30px 48px;
  grid-template-rows: 24px 48px 24px;
  align-items: center;
  justify-items: center;
}

.blind-element-slats-control-pressed-background {
  background-color: var(--blind-element-slats-control-button-pressed-color);
}

.blind-element-slats-control-button {
  align-self: stretch;
  justify-self: stretch;
  border-radius: 9px;
  display: grid;
  align-items: center;
  justify-items: center;
}

.blind-element-slats-control-button-minus {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
}

.blind-element-slats-control-button-minus-enabled {
  border: solid 1px var(--blind-element-slats-control-button-enabled-color);
}

.blind-element-slats-control-button-minus-disabled {
  border: solid 1px var(--blind-element-slats-control-button-disabled-color);
}

.blind-element-slats-control-button-plus {
  grid-column: 5 / span 1;
  grid-row: 2 / span 1;
}

.blind-element-slats-control-button-plus-enabled {
  border: solid 1px var(--blind-element-slats-control-button-enabled-color);
}

.blind-element-slats-control-button-plus-disabled {
  border: solid 1px var(--blind-element-slats-control-button-disabled-color);
}

.blind-element-slats-control-button-line-horizontal {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  width: 16px;
  height: 2px;
}

.blind-element-slats-control-button-line-horizontal-enabled {
  background-color: var(--blind-element-slats-control-button-line-enabled-color);
}

.blind-element-slats-control-button-line-horizontal-disabled {
  background-color: var(--blind-element-slats-control-button-line-disabled-color);
}

.blind-element-slats-control-button-line-vertical {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  width: 2px;
  height: 16px;
}

.blind-element-slats-control-button-line-vertical-enabled {
  background-color: var(--blind-element-slats-control-button-line-enabled-color);
}

.blind-element-slats-control-button-line-vertical-disabled {
  background-color: var(--blind-element-slats-control-button-line-disabled-color);
}

.blind-element-slats-control-circle-container {
  grid-column: 3 / span 1;
  grid-row: 1 / span 3;
  display: grid;
  grid-template-columns: 48px 48px;
  grid-template-rows: 48px 48px;
  align-items: stretch;
  justify-items: stretch;
}

.blind-element-slats-control-outer-circle {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: solid 2px var(--blind-element-slats-control-outer-circle-color);
}

.blind-element-slats-control-inner-circle {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  align-self: center;
  justify-self: center;
  width: 90%;
  height: 90%;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}

.blind-element-slats-control-circle-segment {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vw;
  background-color: var(--blind-element-slats-control-circle-segment-color);
  transform-origin: 0 0;
}

.blind-element-slats-control-slat {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  align-self: center;
  justify-self: center;
  width: 4px;
  height: 100px;
  border-radius: 2px;
  background-color: var(--border-color-primary);
}
</style>
