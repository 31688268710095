import Area from '@/entities/area/area'
import Room from '@/entities/room/room'
import ControlElementBase from '@/entities/controlelements/base/controlelementbase'
import Global from '@/entities/global/global'

/**
 * The class Home stores the following project specific information:
 *
 *  - Array containing all available areas
 *  - Array containing all available rooms
 *  - Array containing all available control elements
 *  - Object containing global functions, global timers and global scenes
 */
class Home {
  /**
   * The constructor of this class initializes empty arrays for storing areas, rooms and
   * control elements. It also initiailze an empty variable for storing global functions,
   * global timers and global scenes.
   */
  constructor() {
    this.areas = []
    this.rooms = []
    this.controlElements = []
    this.global = null
  }

  /**
   * This method adds the given area to the array storing all areas. The given area is
   * ignored if it is already stored in the array. This method throws an error if the
   * given area is not an instance of Area.
   *
   * @param {Area} area Area to be added to array containing all areas
   */
  addArea(area) {
    if (area instanceof Area) {
      const areaAlreadyExists = this.areas.find(tmpArea => tmpArea.id === area.id)

      if (!areaAlreadyExists) {
        this.areas.push(area)
      }
    } else {
      throw new Error('area is not an instance of Area')
    }
  }

  /**
   * This method adds the given room to the array storing all rooms. The given room is
   * ignored if it is already stored in the array. This method throws an error if the
   * given room is not an instance of Room.
   *
   * @param {Room} room Room to be added to array containing all rooms
   */
  addRoom(room) {
    if (room instanceof Room) {
      const roomAlreadyExists = this.rooms.find(tmpRoom => tmpRoom.id === room.id)

      if (!roomAlreadyExists) {
        this.rooms.push(room)
      }
    } else {
      throw new Error('room is not an instance of Room')
    }
  }

  /**
   * This method adds the given control element to the array storing all control elements.
   * The given control element is ignored if it is already stored in the array. This method
   * throws an error if the given control element is not an instance of ControlElement.
   *
   * @param {ControlElementBase} controlElement Control Element to be added to array containing all control elements
   */
  addControlElement(controlElement) {
    if (controlElement instanceof ControlElementBase) {
      const controlElementExists = this.controlElements.find(
        tmpControlElement => tmpControlElement.id === controlElement.id
      )

      if (!controlElementExists) {
        this.controlElements.push(controlElement)
      }
    } else {
      throw new Error('controlElement is not an instance of ControlElement')
    }
  }

  /**
   * This method stores the given global containing all global functions, global timers and global
   * scenes. This method throws an error if the given global is not an instance of Global.
   *
   * @param {Global} global Global containing all global functions, global timers and global scenes
   */
  addGlobal(global) {
    if (global instanceof Global) {
      this.global = global
    } else {
      throw new Error('global is not an instance of Global')
    }
  }
}

export default Home
