<template>
  <div>
    <div v-if="title" class="control-elements-title-container">
      <div class="control-elements-title">{{ title }}</div>
    </div>
    <div class="control-elements-container">
      <div
        class="control-elements-grid-gap"
        v-for="controlElementId in idsOfEnabledControlElements"
        :key="controlElementId"
        :class="getTypeSpecificClass(controlElementId)"
      >
        <switch-element
          v-if="showControlElement(controlElementId, 'switch')"
          :controlElementId="controlElementId"
          @showPropertiesDialogEvent="showPropertiesDialog"
        />
        <dimmer-element
          v-else-if="showControlElement(controlElementId, 'dimmer')"
          :controlElementId="controlElementId"
        />
        <blind-element
          v-else-if="showControlElement(controlElementId, 'blind')"
          :controlElementId="controlElementId"
          @showSettingsDialogEvent="showBlindElementSettingsDialog"
        />
        <room-climate-element
          v-else-if="showControlElement(controlElementId, 'roomclimate')"
          :controlElementId="controlElementId"
          @showSettingsDialogEvent="showRoomClimateElementSettingsDialog"
        />
        <ip-camera-element
          v-else-if="showControlElement(controlElementId, 'ipcamera')"
          :controlElementId="controlElementId"
          :stopPlayingVideo="isIpCameraShownAsFullScreen"
          @showAsFullScreenEvent="showIpCameraAsFullScreen"
        />
      </div>
      <switch-element-properties-dialog
        v-if="isPropertiesDialogShown"
        :controlElementId="controlElementId"
        @okEvent="saveAndClosePropertiesDialog"
        @cancelEvent="closePropertiesDialog"
      />
      <blind-element-settings-dialog
        v-if="isBlindElementSettingsDialogShown"
        :controlElementId="controlElementId"
        @doneEvent="closeDialog"
      />
      <room-climate-element-settings-dialog
        v-if="isRoomClimateElementSettingsDialogShown"
        :controlElementId="controlElementId"
        @doneEvent="closeDialog"
      />
      <ip-camera-element-full-screen
        v-if="isIpCameraShownAsFullScreen"
        :controlElementId="controlElementId"
        @doneEvent="closeDialog"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import SwitchElement from '@/ui/components/controlelements/switchelement/SwitchElement.vue'
import SwitchElementPropertiesDialog from '@/ui/components/controlelements/switchelement/SwitchElementPropertiesDialog.vue'
import DimmerElement from '@/ui/components/controlelements/dimmerelement/DimmerElement.vue'
import BlindElement from '@/ui/components/controlelements/blindelement/BlindElement.vue'
import BlindElementSettingsDialog from '@/ui/components/controlelements/blindelement/BlindElementSettingsDialog.vue'
import RoomClimateElement from '@/ui/components/controlelements/roomclimateelement/RoomClimateElement.vue'
import RoomClimateElementSettingsDialog from '@/ui/components/controlelements/roomclimateelement/RoomClimateElementSettingsDialog.vue'
import IpCameraElement from '@/ui/components/controlelements/ipcameraelement/IpCameraElement.vue'
import IpCameraElementFullScreen from '@/ui/components/controlelements/ipcameraelement/IpCameraElementFullScreen.vue'

export default {
  name: 'ControlElements',
  components: {
    SwitchElement,
    SwitchElementPropertiesDialog,
    DimmerElement,
    BlindElement,
    BlindElementSettingsDialog,
    RoomClimateElement,
    RoomClimateElementSettingsDialog,
    IpCameraElement,
    IpCameraElementFullScreen
  },
  props: {
    idsOfControlElements: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    },
    title: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      idsOfEnabledControlElements: this.idsOfControlElements,
      isPropertiesDialogShown: false,
      isBlindElementSettingsDialogShown: false,
      isRoomClimateElementSettingsDialogShown: false,
      isIpCameraShownAsFullScreen: false,
      controlElementId: null
    }
  },
  watch: {
    idsOfControlElements: function () {
      this.idsOfEnabledControlElements = []

      this.idsOfControlElements.forEach(idOfControlElement => {
        if (this.isEnabled(idOfControlElement)) {
          this.idsOfEnabledControlElements.push(idOfControlElement)
        }
      })
    }
  },
  computed: {
    ...mapGetters(['isEditModeEnabled'])
  },
  methods: {
    ...mapMutations(['enableModalModus', 'updateCurrentControlElement']),
    showControlElement(controlElementId, type) {
      let showControlElement = false

      showControlElement = this.isTypeOfControlElement(controlElementId, type) && this.isEnabled(controlElementId)

      return showControlElement
    },
    getTypeSpecificClass(controlElementId) {
      let typeSpecificClass = ''
      const type = this.$appManager.getTypeOfControlElement(controlElementId)

      switch (type) {
        case 'switch':
          typeSpecificClass = 'control-elements-one-cell'
          break
        case 'dimmer':
          typeSpecificClass = 'control-elements-two-cells'
          break
        case 'blind':
          typeSpecificClass = 'control-elements-two-cells'
          break
        case 'roomclimate':
          typeSpecificClass = 'control-elements-two-cells'
          break
        case 'ipcamera':
          typeSpecificClass = 'control-elements-four-cells'
          break
        default:
          typeSpecificClass = ''
          break
      }

      return typeSpecificClass
    },
    isTypeOfControlElement(controlElementId, type) {
      let typeMatched = false

      const tmpType = this.$appManager.getTypeOfControlElement(controlElementId)

      if (tmpType === type) {
        typeMatched = true
      }

      return typeMatched
    },
    isEnabled(controlElementId) {
      let isEnabled = false

      if (this.isEditModeEnabled) {
        isEnabled = true
      } else {
        isEnabled = this.$appManager.isControlElementEnabled(controlElementId)
      }

      return isEnabled
    },
    showPropertiesDialog(controlElement) {
      if (controlElement != null) {
        this.enableModalModus(true)
        this.isPropertiesDialogShown = true
        this.controlElementId = controlElement.id
      }
    },
    saveAndClosePropertiesDialog(controlElement) {
      // Update control element via Vuex store to force
      // an update of the appropriate 'Switch Element'
      this.updateCurrentControlElement(controlElement)

      // Update control element in database
      // (Why is controlElement a proxy object? Because of Vuex?)
      const tmpControlElement = JSON.parse(JSON.stringify(controlElement))
      this.$appManager.updateControlElement(tmpControlElement)

      this.enableModalModus(false)
      this.isPropertiesDialogShown = false
      this.controlElementId = null
    },
    closePropertiesDialog() {
      this.enableModalModus(false)
      this.isPropertiesDialogShown = false
      this.controlElementId = null
    },
    showBlindElementSettingsDialog(controlElement) {
      if (controlElement != null) {
        this.enableModalModus(true)
        this.isBlindElementSettingsDialogShown = true
        this.controlElementId = controlElement.id
      }
    },
    showRoomClimateElementSettingsDialog(controlElement) {
      if (controlElement != null) {
        this.enableModalModus(true)
        this.isRoomClimateElementSettingsDialogShown = true
        this.controlElementId = controlElement.id
      }
    },
    showIpCameraAsFullScreen(controlElement) {
      if (controlElement != null) {
        this.enableModalModus(true)
        this.isIpCameraShownAsFullScreen = true
        this.controlElementId = controlElement.id
      }
    },
    closeDialog() {
      this.enableModalModus(false)
      this.isBlindElementSettingsDialogShown = false
      this.isRoomClimateElementSettingsDialogShown = false
      this.isIpCameraShownAsFullScreen = false
      this.controlElementId = null
    }
  }
}
</script>

<style scoped>
.control-elements-title-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 166px);
  grid-template-rows: auto;
  align-content: start;
  justify-content: center;
  padding: 4px;
}

.control-elements-title {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: bold;
  color: var(--text-color-secondary);
  white-space: nowrap;
  margin: 16px 0px 6px 4px;
}

.control-elements-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 166px);
  grid-template-rows: repeat(auto-fit, 166px);
  align-content: start;
  justify-content: center;
  padding: 4px;
}

.control-elements-grid-gap {
  margin: 4px;
}

.control-elements-one-cell {
  grid-column: auto / span 1;
  grid-row: auto / span 1;
}

.control-elements-two-cells {
  grid-column: auto / span 2;
  grid-row: auto / span 1;
}

.control-elements-four-cells {
  grid-column: auto / span 2;
  grid-row: auto / span 2;
}
</style>
