<template>
  <div class="rooms-container">
    <div v-show="showLeftSeparator">
      <img
        v-if="getUsedTheme === 'dark-theme'"
        src="@/assets/separator/dark-theme/fine-line-header.svg"
        class="rooms-left-separator"
      />
      <img v-else src="@/assets/separator/light-theme/fine-line-header.svg" class="rooms-left-separator" />
    </div>
    <div class="rooms-inner-container">
      <img v-show="showLeftSeparator" src="@/assets/separator/shadow-header.svg" class="rooms-left-separator-shadow" />
      <sliding-menu
        :items="rooms"
        :selectedItemId="currentRoomId"
        @itemChangedEvent="changeRoom"
        @scrollbarEnabledEvent="scrollbarEnabled"
        @scrollbarDisabledEvent="scrollbarDisabled"
        @scrollbarPositionChangedEvent="scrollbarPositionChanged"
        class="sliding-menu"
      />
      <img
        v-show="showRightSeparator"
        src="@/assets/separator/shadow-header.svg"
        class="rooms-right-separator-shadow"
      />
    </div>
    <div v-show="showRightSeparator">
      <img
        v-if="getUsedTheme === 'dark-theme'"
        src="@/assets/separator/dark-theme/fine-line-header.svg"
        class="rooms-right-separator"
      />
      <img v-else src="@/assets/separator/light-theme/fine-line-header.svg" class="rooms-right-separator" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import SlidingMenu from '@/ui/components/widgets/SlidingMenu.vue'

export default {
  name: 'Rooms',
  components: { SlidingMenu },
  props: {
    rooms: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    },
    showSeparator: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['roomChangedEvent'],
  data() {
    return {
      currentRoomId: null,
      isScrollbarEnabled: false,
      moreRoomsOnLeftSide: false,
      moreRoomsOnRightSide: false,
      smallWidth: false
    }
  },
  beforeMount() {
    this.currentRoomId = this.getIdOfCurrentRoom
  },
  mounted() {
    const media = window.matchMedia('(min-width: 600px)')

    this.setFlagSmallWidth(media)

    media.onchange = () => {
      this.setFlagSmallWidth(media)
    }
  },
  computed: {
    ...mapGetters(['isPlaceholderForWebUiMenuEnabled', 'getIdOfCurrentRoom', 'getUsedTheme']),
    showLeftSeparator() {
      let enableSeparator = false

      if (this.showSeparator && !this.smallWidth) {
        enableSeparator = this.moreRoomsOnLeftSide && this.isScrollbarEnabled
      }

      return enableSeparator
    },
    showRightSeparator() {
      let enableSeparator = false

      if (this.isPlaceholderForWebUiMenuEnabled) {
        if (this.showSeparator) {
          enableSeparator = this.moreRoomsOnRightSide && this.isScrollbarEnabled && !this.smallWidth
        } else {
          enableSeparator = this.moreRoomsOnRightSide && this.isScrollbarEnabled
        }
      }

      return enableSeparator
    }
  },
  methods: {
    ...mapMutations(['setIdOfCurrentRoom']),
    setFlagSmallWidth(x) {
      this.smallWidth = !x.matches
    },
    changeRoom(room) {
      if (room != null) {
        this.setIdOfCurrentRoom(room.id)
      } else {
        this.setIdOfCurrentRoom(null)
      }

      this.$emit('roomChangedEvent', room)
    },
    scrollbarEnabled() {
      this.isScrollbarEnabled = true
    },
    scrollbarDisabled() {
      this.isScrollbarEnabled = false
      this.moreRoomsOnLeftSide = false
      this.moreRoomsOnRightSide = false
    },
    scrollbarPositionChanged(scrollbarPosition) {
      if (this.isScrollbarEnabled) {
        if (scrollbarPosition < 5) {
          this.moreRoomsOnLeftSide = false
        } else {
          this.moreRoomsOnLeftSide = true
        }

        if (scrollbarPosition > 95) {
          this.moreRoomsOnRightSide = false
        } else {
          this.moreRoomsOnRightSide = true
        }
      } else {
        this.moreRoomsOnLeftSide = false
        this.moreRoomsOnRightSide = false
      }
    }
  }
}
</script>

<style scoped>
.rooms-container {
  display: flex;
  align-items: center;
  height: 100%;
  background-color: var(--background-color-primary);
  overflow: auto;
}

.rooms-inner-container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--background-color-primary);
  overflow: auto;
}

.rooms-right-separator {
  display: block;
}

.rooms-right-separator-shadow {
  display: block;
  position: absolute;
  right: 57px;
  transform: scaleX(-1);
}

.sliding-menu {
  height: 100%;
}

@media (min-width: 600px) {
  .rooms-left-separator {
    display: block;
  }

  .rooms-left-separator-shadow {
    display: block;
    position: absolute;
  }
}
</style>
