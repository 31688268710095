<template>
  <div class="wind-element-container">
    <img
      v-if="getUsedTheme === 'dark-theme'"
      class="wind-element-icon"
      src="@/assets/widgets/windelement/dark-theme/wind.svg"
    />
    <img v-else class="wind-element-icon" src="@/assets/widgets/windelement/light-theme/wind.svg" />
    <div class="wind-element-label-wind">{{ $t('wind') }}</div>
    <div class="wind-element-value-wind">{{ windSpeed }}</div>
    <div class="wind-element-unit-wind">km/h</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WindElement',
  props: {
    windData: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    ...mapGetters(['getUsedTheme']),
    windSpeed() {
      let windSpeed = '-'

      if (this.windData != null) {
        if (this.windData.windSpeedUnit === 'm/s') {
          windSpeed = this.windData.windSpeedValue * 3.6
          windSpeed = Number(windSpeed).toFixed(1)
        }
      }

      return windSpeed
    }
  }
}
</script>

<style scoped>
.wind-element-container {
  display: grid;
  grid-template-columns: 40px auto auto 44px;
  grid-template-rows: 40px auto;
  align-items: baseline;
}

.wind-element-icon {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  align-self: stretch;
  margin: 6px 12px 10px 4px;
}

.wind-element-label-wind {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  justify-self: start;
  font-family: OpenSans;
  font-size: 24px;
  font-weight: 600;
  color: var(--text-color-primary);
}

.wind-element-value-wind {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 24px;
  font-weight: 600;
  color: var(--text-color-primary);
}

.wind-element-unit-wind {
  grid-column: 4 / span 1;
  grid-row: 1 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

@media (min-width: 800px) {
  .wind-element-container {
    grid-template-columns: 50px auto 65px;
    grid-template-rows: 50px 50px auto;
  }

  .wind-element-label-wind {
    grid-column: 2 / span 2;
    font-size: 32px;
  }

  .wind-element-value-wind {
    grid-column: 1 / span 2;
    grid-row: 2 / span 1;
    font-size: 32px;
  }

  .wind-element-unit-wind {
    grid-column: 3 / span 1;
    grid-row: 2 / span 1;
    font-size: 22px;
  }
}
</style>
