import ControlElementBase from '@/entities/controlelements/base/controlelementbase'

/**
 * The class ControlElementWeather stores the following information of a control element of type 'weather':
 *
 *  - ID of control element (e.g. '2FFF0722-1E40-48F3-9850-1A12030CD8F4')
 *  - Name of control element (e.g. 'Weather Station')
 *  - Assignments of control element (an array containing objects of type 'Assignment')
 *  - Information if control element is enabled or not
 *  - Information if control element is favorite or not
 *
 * The property rerender shall be incremented after a property (e.g. new temperature) has changed.
 */
class ControlElementWeather extends ControlElementBase {
  /**
   * The constructor of this class stores the given ID, name, assignments, isEnabled and isFavorite.
   *
   * @param {string} id ID of control element
   * @param {string} name Name of control element
   * @param {Array<Assignment>} assignments Assignments of control element
   * @param {boolean} isEnabled Information if control element is enabled or not
   * @param {boolean} isFavorite Information if control element is favorite or not
   */
  constructor(id, name, assignments, isEnabled, isFavorite) {
    super(id, name, 'weather', assignments, isEnabled, isFavorite)

    this.weatherData = null
  }

  updateWeatherData(weatherData) {
    let changedData = false

    if (this.weatherData != null && weatherData != null) {
      changedData = this.updateTemperatureData(weatherData.temperatureData) || changedData
      changedData = this.updatePrecipitationData(weatherData.precipitationData) || changedData
      changedData = this.updateWindData(weatherData.windData) || changedData
      changedData = this.updateBrightnessData(weatherData.brightnessData) || changedData
    } else {
      changedData = true
    }

    if (changedData) {
      this.weatherData = weatherData
      this.rerender = this.rerender + 1
    }

    return changedData
  }

  updateTemperatureData(temperatureData) {
    let changedData = false

    if (this.weatherData.temperatureData != null && temperatureData != null) {
      if (this.weatherData.temperatureData.temperatureAirValue !== temperatureData.temperatureAirValue) {
        changedData = true
      }

      if (this.weatherData.temperatureData.temperatureAirUnit !== temperatureData.temperatureAirUnit) {
        changedData = true
      }

      if (this.weatherData.temperatureData.temperatureAirFeltValue !== temperatureData.temperatureAirFeltValue) {
        changedData = true
      }

      if (this.weatherData.temperatureData.temperatureAirFeltUnit !== temperatureData.temperatureAirFeltUnit) {
        changedData = true
      }
    } else {
      changedData = true
    }

    return changedData
  }

  updatePrecipitationData(precipitationData) {
    let changedData = false

    if (this.weatherData.precipitationData != null && precipitationData != null) {
      if (this.weatherData.precipitationData.precipitationValue !== precipitationData.precipitationValue) {
        changedData = true
      }

      if (this.weatherData.precipitationData.precipitationUnit !== precipitationData.precipitationUnit) {
        changedData = true
      }
    } else {
      changedData = true
    }

    return changedData
  }

  updateWindData(windData) {
    let changedData = false

    if (this.weatherData.windData != null && windData != null) {
      if (this.weatherData.windData.windSpeedValue !== windData.windSpeedValue) {
        changedData = true
      }

      if (this.weatherData.windData.windSpeedUnit !== windData.windSpeedUnit) {
        changedData = true
      }
    } else {
      changedData = true
    }

    return changedData
  }

  updateBrightnessData(brightnessData) {
    let changedData = false

    if (this.weatherData.brightnessData != null && brightnessData != null) {
      if (this.weatherData.brightnessData.sunBrightnessEastValue !== brightnessData.sunBrightnessEastValue) {
        changedData = true
      }

      if (this.weatherData.brightnessData.sunBrightnessEastUnit !== brightnessData.sunBrightnessEastUnit) {
        changedData = true
      }

      if (this.weatherData.brightnessData.sunBrightnessSouthValue !== brightnessData.sunBrightnessSouthValue) {
        changedData = true
      }

      if (this.weatherData.brightnessData.sunBrightnessSouthUnit !== brightnessData.sunBrightnessSouthUnit) {
        changedData = true
      }

      if (this.weatherData.brightnessData.sunBrightnessWestValue !== brightnessData.sunBrightnessWestValue) {
        changedData = true
      }

      if (this.weatherData.brightnessData.sunBrightnessWestUnit !== brightnessData.sunBrightnessWestUnit) {
        changedData = true
      }

      if (this.weatherData.brightnessData.sunTwilightValue !== brightnessData.sunTwilightValue) {
        changedData = true
      }

      if (this.weatherData.brightnessData.sunTwilightUnit !== brightnessData.sunTwilightUnit) {
        changedData = true
      }
    } else {
      changedData = true
    }

    return changedData
  }
}

export default ControlElementWeather
