<template>
  <div class="temperature-element-container">
    <img
      v-if="getUsedTheme === 'dark-theme'"
      class="temperature-element-icon"
      src="@/assets/widgets/temperatureelement/dark-theme/temperature.svg"
    />
    <img
      v-else
      class="temperature-element-icon"
      src="@/assets/widgets/temperatureelement/light-theme/temperature.svg"
    />
    <div class="temperature-element-label-temperature-air">{{ $t('temperature') }}</div>
    <div class="temperature-element-value-temperature-air">{{ temperatureAir }}</div>
    <div class="temperature-element-unit-temperature-air">°C</div>
    <div class="temperature-element-label-temperature-air-felt">{{ $t('temperatureFelt') }}:</div>
    <div class="temperature-element-value-temperature-air-felt">{{ temperatureAirFelt }}</div>
    <div class="temperature-element-unit-temperature-air-felt">°C</div>
    <div class="temperature-element-label-precipitation">{{ $t('precipitation') }}:</div>
    <div class="temperature-element-value-precipitation">{{ precipitation }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from '@/i18n'

export default {
  name: 'TemperatureElement',
  props: {
    temperatureData: {
      type: Object,
      required: false,
      default: null
    },
    precipitationData: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    ...mapGetters(['getUsedTheme']),
    temperatureAir() {
      let temperatureAir = '-'

      if (this.temperatureData != null) {
        if (this.temperatureData.temperatureAirUnit === '°C') {
          temperatureAir = this.temperatureData.temperatureAirValue
        }
      }

      return temperatureAir
    },
    temperatureAirFelt() {
      let temperatureAirFelt = '-'

      if (this.temperatureData != null) {
        if (this.temperatureData.temperatureAirFeltUnit === '°C') {
          temperatureAirFelt = this.temperatureData.temperatureAirFeltValue
        }
      }

      return temperatureAirFelt
    },
    precipitation() {
      let precipitation = '-'

      if (this.precipitationData != null) {
        if (this.precipitationData.precipitationValue != null) {
          switch (this.precipitationData.precipitationValue) {
            case 'yes':
              precipitation = i18n.global.t('yes')
              break
            case 'dry':
              precipitation = i18n.global.t('dry')
              break
            case 'rain':
              precipitation = i18n.global.t('rain')
              break
            case 'hail':
              precipitation = i18n.global.t('hail')
              break
            case 'snow':
              precipitation = i18n.global.t('snow')
              break
            default:
              precipitation = '-'
              break
          }
        }
      }

      return precipitation
    }
  }
}
</script>

<style scoped>
.temperature-element-container {
  display: grid;
  grid-template-columns: 40px auto auto 22px;
  grid-template-rows: 40px 30px 30px auto;
  align-items: baseline;
}

.temperature-element-icon {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  align-self: stretch;
  margin: 6px 12px 10px 4px;
}

.temperature-element-label-temperature-air {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  justify-self: start;
  font-family: OpenSans;
  font-size: 24px;
  font-weight: 600;
  color: var(--text-color-primary);
}

.temperature-element-value-temperature-air {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 24px;
  font-weight: 600;
  color: var(--text-color-primary);
}

.temperature-element-unit-temperature-air {
  grid-column: 4 / span 1;
  grid-row: 1 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.temperature-element-label-temperature-air-felt {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  align-self: end;
  justify-self: start;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.temperature-element-value-temperature-air-felt {
  grid-column: 3 / span 1;
  grid-row: 2 / span 1;
  align-self: end;
  justify-self: end;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: bold;
  color: var(--text-color-primary);
}

.temperature-element-unit-temperature-air-felt {
  grid-column: 4 / span 1;
  grid-row: 2 / span 1;
  align-self: end;
  justify-self: end;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.temperature-element-label-precipitation {
  grid-column: 2 / span 1;
  grid-row: 3 / span 1;
  align-self: end;
  justify-self: start;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.temperature-element-value-precipitation {
  grid-column: 3 / span 2;
  grid-row: 3 / span 1;
  align-self: end;
  justify-self: end;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: bold;
  color: var(--text-color-primary);
}

@media (min-width: 800px) {
  .temperature-element-container {
    grid-template-columns: 50px auto 10px 20px;
    grid-template-rows: 50px 50px 30px 30px auto;
  }

  .temperature-element-label-temperature-air {
    grid-column: 2 / span 3;
    font-size: 32px;
  }

  .temperature-element-value-temperature-air {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
    font-size: 32px;
  }

  .temperature-element-unit-temperature-air {
    grid-column: 3 / span 2;
    grid-row: 2 / span 1;
    font-size: 22px;
  }

  .temperature-element-label-temperature-air-felt {
    grid-column: 2 / span 1;
    grid-row: 3 / span 1;
  }

  .temperature-element-value-temperature-air-felt {
    grid-column: 2 / span 2;
    grid-row: 3 / span 1;
  }

  .temperature-element-unit-temperature-air-felt {
    grid-column: 4 / span 1;
    grid-row: 3 / span 1;
  }

  .temperature-element-label-precipitation {
    grid-column: 2 / span 1;
    grid-row: 4 / span 1;
  }

  .temperature-element-value-precipitation {
    grid-column: 3 / span 2;
    grid-row: 4 / span 1;
  }
}
</style>
