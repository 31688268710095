<template>
  <div class="more-view-container">
    <div class="more-view-header">{{ $t('more') }}</div>
    <div v-show="isPlaceholderForWebUiMenuEnabled" class="more-view-placeholder-webui-menu" />
    <div id="scrollable-more-view" class="more-view-main-container">
      <div class="more-view-title more-view-title-settings">{{ $t('settings') }}</div>
      <div class="more-view-setting-box more-view-show-symbol-favorites more-view-not-pressed-setting-box">
        <div class="more-view-setting-text">
          {{ $t('showFavoritesIcon') }}
        </div>
        <toggle-switch
          class="more-view-setting-toggle-switch"
          :enable="isFavoriteStarEnabled"
          @toggleEvent="enableFavoriteStar"
        />
      </div>
      <div class="more-view-setting-box more-view-setting-placeholder-webui-menu more-view-not-pressed-setting-box">
        <div class="more-view-setting-text">{{ $t('placeholderForWebUi') }}</div>
        <toggle-switch
          class="more-view-setting-toggle-switch"
          :enable="isPlaceholderForWebUiMenuEnabled"
          @toggleEvent="enablePlaceholderForWebUiMenu"
        />
      </div>
      <!--div class="more-view-setting-box more-view-activate-configuration-mode more-view-not-pressed-setting-box">
        <div class="more-view-setting-text">
          {{ $t('activateConfigurationMode') }}
        </div>
        <toggle-switch
          class="more-view-setting-toggle-switch"
          :enable="isEditModeEnabled"
          @toggleEvent="enableEditMode"
        />
      </div-->
      <div class="more-view-setting-title more-view-setting-title-appearance">{{ $t('appearance') }}</div>
      <appearance-selector class="more-view-setting-appearance-selector" />
      <div class="more-view-setting-title more-view-setting-title-language">{{ $t('language') }}</div>
      <language-selector class="more-view-setting-language-selector" />
      <div class="more-view-title more-view-title-about">{{ $t('about') }}</div>
      <div class="more-view-setting-box more-view-software-version more-view-not-pressed-setting-box">
        <div class="more-view-setting-text">
          {{ $t('softwareVersion') }}
        </div>
        <div class="more-view-app-version">{{ appVersion }}</div>
      </div>
      <div
        :class="getCssClassForConfigurationInfoBox()"
        @mousedown.stop.passive="boxConfigurationInfoPressed = true"
        @mouseup.stop.passive="boxConfigurationInfoPressed = false"
        @mouseleave.stop.passive="boxConfigurationInfoPressed = false"
        @touchstart.stop.passive="boxConfigurationInfoPressed = true"
        @touchend.stop.passive="boxConfigurationInfoPressed = false"
        @touchcancel.stop.passive="boxConfigurationInfoPressed = false"
        @click.stop.passive="expandOrCollapseConfigurationInfoBox"
      >
        <div class="more-view-setting-text">{{ $t('configuration') }}</div>
        <div v-if="isConfigurationInfoBoxExpanded" class="more-view-setting-box-icon">
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/arrows/dark-theme/arrow-down.svg" />
          <img v-else src="@/assets/arrows/light-theme/arrow-down.svg" />
        </div>
        <div v-else class="more-view-setting-box-icon">
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/arrows/dark-theme/arrow-right.svg" />
          <img v-else src="@/assets/arrows/light-theme/arrow-right.svg" />
        </div>
        <div v-if="isConfigurationInfoBoxExpanded" class="more-view-configuration-information-detailed">
          <div class="more-view-configuration-information-name-label">{{ $t('name') }}:</div>
          <div class="more-view-configuration-information-name-value">{{ configurationName }}</div>
          <div class="more-view-configuration-information-id-label">ID:</div>
          <div class="more-view-configuration-information-id-value">{{ configurationId }}</div>
          <div class="more-view-configuration-information-version-label">{{ $t('version') }}:</div>
          <div class="more-view-configuration-information-version-value">{{ configurationVersion }}</div>
          <div class="more-view-configuration-information-innoxel-setup-version-label">Setup:</div>
          <div class="more-view-configuration-information-innoxel-setup-version-value">{{ innoxelSetupVersion }}</div>
        </div>
      </div>
      <div
        class="more-view-setting-box more-view-setting-logging"
        :class="boxLoggingPressed ? 'more-view-pressed-setting-box' : 'more-view-not-pressed-setting-box'"
        @mousedown.stop.passive="boxLoggingPressed = true"
        @mouseup.stop.passive="boxLoggingPressed = false"
        @mouseleave.stop.passive="boxLoggingPressed = false"
        @touchstart.stop.passive="boxLoggingPressed = true"
        @touchend.stop.passive="boxLoggingPressed = false"
        @touchcancel.stop.passive="boxLoggingPressed = false"
        @click.stop.passive="openLoggingDialog"
      >
        <div class="more-view-setting-text">{{ $t('logging') }}</div>
        <div class="more-view-setting-box-icon">
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/arrows/dark-theme/arrow-right.svg" />
          <img v-else src="@/assets/arrows/light-theme/arrow-right.svg" />
        </div>
      </div>
      <div class="more-view-title more-view-title-websites">{{ $t('webLinks') }}</div>
      <div
        class="more-view-setting-box more-view-setting-old-webapp"
        :class="boxOldWebAppPressed ? 'more-view-pressed-setting-box' : 'more-view-not-pressed-setting-box'"
        @mousedown.stop.passive="boxOldWebAppPressed = true"
        @mouseup.stop.passive="boxOldWebAppPressed = false"
        @mouseleave.stop.passive="boxOldWebAppPressed = false"
        @touchstart.stop.passive="boxOldWebAppPressed = true"
        @touchend.stop.passive="boxOldWebAppPressed = false"
        @touchcancel.stop.passive="boxOldWebAppPressed = false"
        @click.stop.passive="openOldWebApp"
      >
        <div class="more-view-setting-link">{{ $t('innoxelWebAppOldVersion') }}</div>
        <div class="more-view-setting-box-icon">
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/webbrowser/dark-theme/webbrowser.svg" />
          <img v-else src="@/assets/webbrowser/light-theme/webbrowser.svg" />
        </div>
      </div>
      <div
        class="more-view-setting-box more-view-setting-maintenance"
        :class="boxMaintenancePagePressed ? 'more-view-pressed-setting-box' : 'more-view-not-pressed-setting-box'"
        @mousedown.stop.passive="boxMaintenancePagePressed = true"
        @mouseup.stop.passive="boxMaintenancePagePressed = false"
        @mouseleave.stop.passive="boxMaintenancePagePressed = false"
        @touchstart.stop.passive="boxMaintenancePagePressed = true"
        @touchend.stop.passive="boxMaintenancePagePressed = false"
        @touchcancel.stop.passive="boxMaintenancePagePressed = false"
        @click.stop.passive="openMaintenancePage"
      >
        <div class="more-view-setting-link">{{ $t('innoxelMaintenancePage') }}</div>
        <div class="more-view-setting-box-icon">
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/webbrowser/dark-theme/webbrowser.svg" />
          <img v-else src="@/assets/webbrowser/light-theme/webbrowser.svg" />
        </div>
      </div>
      <div
        class="more-view-setting-box more-view-setting-innoxel-website"
        :class="boxInnoxelHomepagePressed ? 'more-view-pressed-setting-box' : 'more-view-not-pressed-setting-box'"
        @mousedown.stop.passive="boxInnoxelHomepagePressed = true"
        @mouseup.stop.passive="boxInnoxelHomepagePressed = false"
        @mouseleave.stop.passive="boxInnoxelHomepagePressed = false"
        @touchstart.stop.passive="boxInnoxelHomepagePressed = true"
        @touchend.stop.passive="boxInnoxelHomepagePressed = false"
        @touchcancel.stop.passive="boxInnoxelHomepagePressed = false"
        @click.stop.passive="openInnoxelHomepage"
      >
        <div class="more-view-setting-link">{{ $t('innoxelHomepage') }}</div>
        <div class="more-view-setting-box-icon">
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/webbrowser/dark-theme/webbrowser.svg" />
          <img v-else src="@/assets/webbrowser/light-theme/webbrowser.svg" />
        </div>
      </div>
      <div
        class="more-view-setting-box more-view-setting-zidatech-website"
        :class="boxZidatechHomepagePressed ? 'more-view-pressed-setting-box' : 'more-view-not-pressed-setting-box'"
        @mousedown.stop.passive="boxZidatechHomepagePressed = true"
        @mouseup.stop.passive="boxZidatechHomepagePressed = false"
        @mouseleave.stop.passive="boxZidatechHomepagePressed = false"
        @touchstart.stop.passive="boxZidatechHomepagePressed = true"
        @touchend.stop.passive="boxZidatechHomepagePressed = false"
        @touchcancel.stop.passive="boxZidatechHomepagePressed = false"
        @click.stop.passive="openZidaTechHomepage"
      >
        <div class="more-view-setting-link">{{ $t('zidaTechHomepage') }}</div>
        <div class="more-view-setting-box-icon">
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/webbrowser/dark-theme/webbrowser.svg" />
          <img v-else src="@/assets/webbrowser/light-theme/webbrowser.svg" />
        </div>
      </div>
      <div class="more-view-title more-view-title-reset">{{ $t('reset') }}</div>
      <div
        class="more-view-setting-box more-view-restart-app"
        :class="boxRestartAppPressed ? 'more-view-pressed-setting-box' : 'more-view-not-pressed-setting-box'"
        @mousedown.stop.passive="boxRestartAppPressed = true"
        @mouseup.stop.passive="boxRestartAppPressed = false"
        @mouseleave.stop.passive="boxRestartAppPressed = false"
        @touchstart.stop.passive="boxRestartAppPressed = true"
        @touchend.stop.passive="boxRestartAppPressed = false"
        @touchcancel.stop.passive="boxRestartAppPressed = false"
        @click.stop.passive="restartApp"
      >
        <div class="more-view-setting-text">{{ $t('restartApp') }}</div>
      </div>
      <div
        class="more-view-setting-box more-view-reload-configuration"
        :class="boxReloadConfigurationPressed ? 'more-view-pressed-setting-box' : 'more-view-not-pressed-setting-box'"
        @mousedown.stop.passive="boxReloadConfigurationPressed = true"
        @mouseup.stop.passive="boxReloadConfigurationPressed = false"
        @mouseleave.stop.passive="boxReloadConfigurationPressed = false"
        @touchstart.stop.passive="boxReloadConfigurationPressed = true"
        @touchend.stop.passive="boxReloadConfigurationPressed = false"
        @touchcancel.stop.passive="boxReloadConfigurationPressed = false"
        @click.stop.passive="reloadConfiguration"
      >
        <div class="more-view-setting-text">{{ $t('reloadConfiguration') }}</div>
        <div class="more-view-setting-box-icon">
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/arrows/dark-theme/arrow-right.svg" />
          <img v-else src="@/assets/arrows/light-theme/arrow-right.svg" />
        </div>
      </div>
      <div
        class="more-view-setting-box more-view-reset-all"
        :class="boxResetAllPressed ? 'more-view-pressed-setting-box' : 'more-view-not-pressed-setting-box'"
        @mousedown.stop.passive="boxResetAllPressed = true"
        @mouseup.stop.passive="boxResetAllPressed = false"
        @mouseleave.stop.passive="boxResetAllPressed = false"
        @touchstart.stop.passive="boxResetAllPressed = true"
        @touchend.stop.passive="boxResetAllPressed = false"
        @touchcancel.stop.passive="boxResetAllPressed = false"
        @click.stop.passive="resetAll"
      >
        <div class="more-view-setting-text">{{ $t('resetAll') }}</div>
        <div class="more-view-setting-box-icon">
          <img v-if="getUsedTheme === 'dark-theme'" src="@/assets/arrows/dark-theme/arrow-right.svg" />
          <img v-else src="@/assets/arrows/light-theme/arrow-right.svg" />
        </div>
      </div>
      <div v-if="showScrolledToBottomSpace" class="more-view-scrolled-to-bottom-space" />
    </div>
    <logging-dialog v-if="isLoggingDialogShown" @doneEvent="closeDialog" />
    <confirm-dialog
      v-if="isConfirmDialogResetAppCompletelyShown"
      title="questionResetAppCompletely"
      message="messageResetAppCompletely"
      textOkButton="yes"
      textCancelButton="no"
      @cancelEvent="closeDialog"
      @okEvent="deleteDatabase"
    />
    <confirm-dialog
      v-if="isConfirmDialogReloadConfigurationShown"
      title="questionReloadConfiguration"
      message="messageReloadConfiguration"
      textOkButton="yes"
      textCancelButton="no"
      @cancelEvent="closeDialog"
      @okEvent="deleteConfiguration"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ToggleSwitch from '@/ui/components/widgets/ToggleSwitch.vue'
import AppearanceSelector from '@/ui/components/widgets/AppearanceSelector.vue'
import LanguageSelector from '@/ui/components/widgets/LanguageSelector.vue'
import LoggingDialog from '@/ui/components/LoggingDialog.vue'
import ConfirmDialog from '@/ui/components/ConfirmDialogs/ConfirmDialog.vue'

export default {
  name: 'MoreView',
  components: { ToggleSwitch, AppearanceSelector, LanguageSelector, LoggingDialog, ConfirmDialog },
  data() {
    return {
      elementScrollableMoreView: null,
      showScrolledToBottomSpace: false,
      boxConfigurationInfoPressed: false,
      boxOldWebAppPressed: false,
      boxMaintenancePagePressed: false,
      boxInnoxelHomepagePressed: false,
      boxZidatechHomepagePressed: false,
      boxLoggingPressed: false,
      boxRestartAppPressed: false,
      boxResetAllPressed: false,
      boxReloadConfigurationPressed: false,
      isLoggingDialogShown: false,
      isConfirmDialogResetAppCompletelyShown: false,
      isConfirmDialogReloadConfigurationShown: false,
      isConfigurationInfoBoxExpanded: false
    }
  },
  mounted() {
    this.elementScrollableMoreView = document.getElementById('scrollable-more-view')

    if (this.elementScrollableMoreView != null) {
      this.elementScrollableMoreView.onscroll = () => {
        this.enableScrolledToBottomSpace()
      }

      this.elementScrollableMoreView.scrollTop = 0
    }
  },
  computed: {
    ...mapGetters([
      'getUsedTheme',
      'getAppVersion',
      'getCommitHash',
      'getConfigurationInformation',
      'getProtocol',
      'getIpAddress',
      'getPort',
      'isFavoriteStarEnabled',
      'isEditModeEnabled',
      'isPlaceholderForWebUiMenuEnabled'
    ]),
    appVersion() {
      let appVersion = ''

      if (this.getCommitHash) {
        appVersion = `${this.getAppVersion} (${this.getCommitHash})`
      } else {
        appVersion = this.getAppVersion
      }

      return appVersion
    },
    configurationName() {
      let configurationName = '-'

      const configurationInformation = this.getConfigurationInformation

      if (configurationInformation != null) {
        if (configurationInformation.name != null) {
          configurationName = `${configurationInformation.name}`
        }
      }

      return configurationName
    },
    configurationId() {
      let configurationId = '-'

      const configurationInformation = this.getConfigurationInformation

      if (configurationInformation != null) {
        if (configurationInformation.configurationId != null) {
          const id = configurationInformation.configurationId.substring(0, 7).toLowerCase()
          configurationId = `${id}`
        }
      }

      return configurationId
    },
    configurationVersion() {
      let configurationVersion = '-'

      const configurationInformation = this.getConfigurationInformation

      if (configurationInformation != null) {
        if (configurationInformation.version != null) {
          configurationVersion = `${configurationInformation.version}`
        }
      }

      return configurationVersion
    },
    innoxelSetupVersion() {
      let innoxelSetupVersion = '-'

      const configurationInformation = this.getConfigurationInformation

      if (configurationInformation != null) {
        if (configurationInformation.setupVersion != null) {
          innoxelSetupVersion = `${configurationInformation.setupVersion}`
        }
      }

      return innoxelSetupVersion
    }
  },
  methods: {
    ...mapMutations([
      'enableFavoriteStar',
      'enableDarkMode',
      'enableEditMode',
      'enablePlaceholderForWebUiMenu',
      'enableModalModus'
    ]),
    enableScrolledToBottomSpace() {
      if (this.elementScrollableMoreView != null) {
        const scrollTop = this.elementScrollableMoreView.scrollTop
        const invisiblePart = this.elementScrollableMoreView.scrollHeight - this.elementScrollableMoreView.clientHeight
        const scrollbarPosition = (scrollTop / invisiblePart) * 100

        this.showScrolledToBottomSpace = scrollbarPosition > 0
      }
    },
    getCssClassForConfigurationInfoBox() {
      let cssClass = 'more-view-configuration-information '

      if (this.isConfigurationInfoBoxExpanded) {
        cssClass += 'more-view-setting-box-configuration-information-expanded '
      } else {
        cssClass += 'more-view-setting-box '
      }

      if (this.boxConfigurationInfoPressed) {
        cssClass += 'more-view-pressed-setting-box'
      } else {
        cssClass += 'more-view-not-pressed-setting-box'
      }

      return cssClass
    },
    expandOrCollapseConfigurationInfoBox() {
      this.isConfigurationInfoBoxExpanded = !this.isConfigurationInfoBoxExpanded
    },
    openOldWebApp() {
      const url = `${this.getProtocol}//${this.getIpAddress}:${this.getPort}/webhome.html`
      window.open(url)
    },
    openMaintenancePage() {
      const url = `${this.getProtocol}//${this.getIpAddress}:${this.getPort}/maintenance/properties.html`
      window.open(url)
    },
    openInnoxelHomepage() {
      window.open('https://www.innoxel.ch')
    },
    openZidaTechHomepage() {
      window.open('https://www.zidatech.ch')
    },
    openLoggingDialog() {
      this.enableModalModus(true)
      this.isLoggingDialogShown = true
    },
    restartApp() {
      this.$appManager.storeLogEntry({
        timestamp: new Date(),
        message: 'App has been restarted by user'
      })
      window.location.reload()
    },
    reloadConfiguration() {
      this.enableModalModus(true)
      this.isConfirmDialogReloadConfigurationShown = true
    },
    deleteConfiguration() {
      this.$appManager.storeLogEntry({
        timestamp: new Date(),
        message: 'Configuration has been reloaded by user'
      })
      this.$appManager.deleteConfiguration()
    },
    resetAll() {
      this.enableModalModus(true)
      this.isConfirmDialogResetAppCompletelyShown = true
    },
    closeDialog() {
      this.enableModalModus(false)
      this.isLoggingDialogShown = false
      this.isConfirmDialogResetAppCompletelyShown = false
      this.isConfirmDialogReloadConfigurationShown = false
    },
    deleteDatabase() {
      this.$appManager.resetStorageManager()
    }
  }
}
</script>

<style scoped>
.more-view-container {
  display: grid;
  grid-template-columns: 1fr 55px;
  grid-template-rows: 50px auto;
  background-color: var(--background-color-secondary);
}

.more-view-header {
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-primary);
  background-color: var(--background-color-primary);
}

.more-view-placeholder-webui-menu {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  align-self: stretch;
  justify-self: stretch;
  border-top: solid var(--background-color-primary) 19px;
  border-right: solid var(--background-color-primary) 13px;
  border-bottom: solid var(--background-color-primary) 5px;
  border-left: solid var(--background-color-primary) 16px;
  background-color: var(--background-color-secondary);
}

.more-view-main-container {
  grid-column: 1 / span 2;
  grid-row: 2 / span 1;
  overflow-y: auto;
  display: grid;
  grid-template-columns: auto minmax(auto, 620px) auto;
  grid-template-rows: repeat(auto);
  padding: 0 15px;
  margin: 1px 0;
}

.more-view-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: bold;
  color: var(--text-color-secondary);
  margin: 0 0 0 5px;
}

.more-view-title-settings {
  height: 60px;
  grid-column: 2 / span 2;
  grid-row: 1 / span 1;
}

.more-view-setting-box {
  grid-column: 2 / span 1;
  height: 60px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 60px;
  align-items: center;
  justify-items: start;
  margin: 0 0 10px 0;
}

.more-view-pressed-setting-box {
  background-color: var(--pressed-background-color);
}

.more-view-not-pressed-setting-box {
  background-color: var(--background-color-primary);
}

.more-view-setting-text {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-secondary);
  margin: 0 0 0 20px;
}

.more-view-setting-box-icon {
  justify-self: end;
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  margin: 6px 20px 0 0;
}

.more-view-setting-link {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--innoxel-blue-color);
  margin: 0 0 0 20px;
}

.more-view-setting-toggle-switch {
  justify-self: end;
  margin: 0 20px 0 0;
}

.more-view-setting-title {
  grid-column: 2 / span 1;
  height: 40px;
  font-family: OpenSans;
  font-size: 12px;
  font-weight: bold;
  color: var(--text-color-quaternary);
  padding: 15px 0 0 5px;
}

.more-view-show-symbol-favorites {
  grid-row: 2 / span 1;
}

.more-view-setting-placeholder-webui-menu {
  grid-row: 3 / span 1;
}

.more-view-activate-configuration-mode {
  grid-row: 4 / span 1;
}

.more-view-setting-title-appearance {
  grid-row: 5 / span 1;
}

.more-view-setting-appearance-selector {
  grid-column: 2 / span 1;
  grid-row: 6 / span 1;
  margin: 0 0 10px 0;
}

.more-view-setting-title-language {
  grid-row: 7 / span 1;
}

.more-view-setting-language-selector {
  grid-column: 2 / span 1;
  grid-row: 8 / span 1;
  margin: 0 0 10px 0;
}

.more-view-title-about {
  height: 60px;
  grid-column: 2 / span 2;
  grid-row: 10 / span 1;
}

.more-view-software-version {
  grid-row: 11 / span 1;
}

.more-view-app-version {
  justify-self: end;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-quaternary);
  margin: 0 20px 0 0;
}

.more-view-configuration-information {
  grid-row: 12 / span 1;
}

.more-view-setting-box-configuration-information-expanded {
  grid-column: 2 / span 1;
  height: 180px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 60px auto;
  align-items: center;
  justify-items: start;
  margin: 0 0 10px 0;
}

.more-view-configuration-information-detailed {
  grid-column: 1 / span 2;
  grid-row: 2 / span 1;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 24px 24px 24px 24px;
  margin: -14px 0 0 45px;
}

.more-view-configuration-information-name-label {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-primary);
  margin: 0 10px 0 0;
}

.more-view-configuration-information-name-value {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.more-view-configuration-information-id-label {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-primary);
  margin: 0 10px 0 0;
}

.more-view-configuration-information-id-value {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.more-view-configuration-information-version-label {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-primary);
  margin: 0 10px 0 0;
}

.more-view-configuration-information-version-value {
  grid-column: 2 / span 1;
  grid-row: 3 / span 1;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.more-view-configuration-information-innoxel-setup-version-label {
  grid-column: 1 / span 1;
  grid-row: 4 / span 1;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-primary);
  margin: 0 10px 0 0;
}

.more-view-configuration-information-innoxel-setup-version-value {
  grid-column: 2 / span 1;
  grid-row: 4 / span 1;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.more-view-setting-logging {
  grid-row: 13 / span 1;
}

.more-view-title-websites {
  height: 60px;
  grid-column: 2 / span 2;
  grid-row: 14 / span 1;
}

.more-view-setting-old-webapp {
  grid-row: 15 / span 1;
}

.more-view-setting-maintenance {
  grid-row: 16 / span 1;
}

.more-view-setting-innoxel-website {
  grid-row: 17 / span 1;
}

.more-view-setting-zidatech-website {
  grid-row: 18 / span 1;
}

.more-view-title-reset {
  height: 60px;
  grid-column: 2 / span 2;
  grid-row: 19 / span 1;
}

.more-view-restart-app {
  grid-row: 20 / span 1;
}

.more-view-reload-configuration {
  grid-row: 21 / span 1;
}

.more-view-reset-all {
  grid-row: 22 / span 1;
}

.more-view-scrolled-to-bottom-space {
  grid-column: 1 / span 3;
  grid-row: 23 / span 1;
  height: 35px;
}
</style>
