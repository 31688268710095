<template>
  <dropdown
    :usedTheme="getUsedTheme"
    :items="areas"
    :selectedItemId="currentAreaId"
    @collapsedEvent="collapse"
    @expandedEvent="expand"
    @itemChangedEvent="changeArea"
  />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Dropdown from '@/ui/components/widgets/Dropdown.vue'

export default {
  name: 'Areas',
  components: { Dropdown },
  props: {
    areas: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    }
  },
  emits: ['collapsedEvent', 'expandedEvent', 'areaChangedEvent'],
  data() {
    return {
      currentAreaId: null
    }
  },
  beforeMount() {
    this.currentAreaId = this.getIdOfCurrentArea
  },
  computed: {
    ...mapGetters(['getIdOfCurrentArea', 'getUsedTheme'])
  },
  methods: {
    ...mapMutations(['setIdOfCurrentArea']),
    expand() {
      this.$emit('expandedEvent')
    },
    collapse() {
      this.$emit('collapsedEvent')
    },
    changeArea(area) {
      this.setIdOfCurrentArea(area.id)
      this.$emit('areaChangedEvent', area)
    }
  }
}
</script>
