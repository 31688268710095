export const home = {
  name: 'Test Configuration',
  configurationId: '0E5D6EA2-5F0E-49A8-84A7-CD9BE569713E',
  version: 4,
  setupVersion: '12.345',
  global: {
    globalFunctions: {
      idsOfControlElements: [
        'F8DB0F94-5502-4CEF-95D3-632D314CEC99',
        '9FFF2491-E25C-4DBF-A9E5-82AFB5F44B7F',
        'F87433EB-F158-4304-B4DE-A90310F7370A'
      ]
    },
    globalTimers: {
      idsOfControlElements: ['4D5BF9C5-337D-433C-819C-C65BE7E9EEBD', 'C98D8BF7-0EFE-42CF-9D2A-EEF5D3315623']
    },
    globalScenes: {
      idsOfControlElements: [
        '2FD24F51-09D2-4BE9-B313-16163AE664AD',
        '8D810AF1-785F-4EE0-996B-26F8EBAE38B4',
        'A5FE6220-E39C-4F0B-9526-B2674B2528F2',
        '440C8081-A54C-476E-97AD-81D1FAD37EA0',
        '5E4E13D5-21D5-4243-90A0-9290D731A416',
        '54ED46B9-8A1F-4DB5-9147-0060C1978488',
        '0096ECAC-4D65-4093-B888-83E3E2E2F562',
        'F9EF66C5-67C8-4E55-9526-882C842E0D02'
      ]
    }
  },
  climate: {
    roomRegulations: {
      idsOfControlElements: [
        'CEC582D0-31EB-45BC-9BF4-39705C638BCE',
        'AF783A57-1090-4953-9B52-EA0642030600',
        'F255EFE9-4464-4CF7-BF96-1D43102FE4E6'
      ]
    }
  },
  areas: [
    {
      id: 'B8F75FBE-CA3B-4BD6-A191-869407D17842',
      name: 'Keller',
      order: 1,
      idsOfRooms: [
        '22623925-9FCB-4127-A105-E722D7B94321',
        '3BE46FEA-6B43-46A0-9E24-D212099F86D3',
        '02DF43B9-0C3D-40F0-B214-ED734F4E6152',
        'D261471E-83DD-4540-B008-28E45303C294',
        'D601B185-D6A9-4AF6-AB55-6866F4845065',
        '97EF45D0-7998-45A3-AD00-55BCB89BCA57'
      ]
    },
    {
      id: 'FDC25D20-67A9-409D-A75D-99137DC3E353',
      name: 'Erdgeschoss',
      order: 2,
      idsOfRooms: [
        '64BB0845-5053-46A0-B5AF-485A0856B0C5',
        '9C3D8960-52E4-4798-AECB-3E5CC136B3A6',
        'C855238A-4C20-4FAF-BDB5-E9D543A10B9F',
        '0C02CD85-9BEA-42EC-89CD-44AA006E472C',
        '9480A4F2-A896-4592-85C8-963967DE5272',
        'AD020A3A-436E-4A7C-8E97-ACB81E1FE4AA',
        'AE3ABE8B-56E4-48B6-8D78-64E4939C1392',
        '6ADB7501-B392-430B-9EF1-729F02EBDB3F'
      ]
    },
    {
      id: '98879109-02F9-4766-B418-570BD906AC64',
      name: 'Obergeschoss',
      order: 3,
      idsOfRooms: [
        'F43F8444-E195-48DE-B370-50C52625F4C6',
        'A77CE28D-EFA5-49A6-82F4-82EAF89CD710',
        '2131C932-7678-4EF7-8816-BD64BBCA5037',
        '843821CC-7BB9-46AF-97C5-7FFE3CFA19AE',
        '43041E70-C003-4A4B-846B-3A0AB948C1B0',
        'BDB5EC74-555F-4E51-9150-DCAAA76C6F78',
        'DF8A592A-07CD-4F2D-B149-29A950889223'
      ]
    },
    {
      id: 'BBA3F66B-2486-447F-B400-0C671C16D23B',
      name: 'Estrich',
      order: 4,
      idsOfRooms: ['52880EC5-E24A-4051-8A6D-56BBD3F8B7F4']
    },
    {
      id: 'ED2B7590-BB31-4C8C-B271-2680845DA3F7',
      name: 'Garage',
      order: 5,
      idsOfRooms: ['B13C87ED-E5BF-49FF-BBF9-1F30E7283D4E']
    },
    {
      id: 'D4B4AE39-D257-401E-8B97-FE051B5D9815',
      name: 'Aussenbereich',
      order: 6,
      idsOfRooms: [
        '8ABC66A2-A75B-4BEA-A736-468939910068',
        '81A48615-5418-4BED-AD6B-CEF47C0B9E7F',
        '1C51BC06-1E0A-4B70-B8B7-6EFEB2B7F62B',
        '57C412FE-200B-4CE4-8980-84C3D5B41840'
      ]
    }
  ],
  rooms: [
    {
      id: '22623925-9FCB-4127-A105-E722D7B94321',
      name: 'Flur',
      idsOfControlElements: [
        'DAFA83D1-7C02-46EA-A5A7-C846730C980C',
        '18C3F21E-8C62-4352-8371-DA89F9417CF4',
        'F6A4FCF0-C3D0-4086-9950-24C85AF8FB52'
      ]
    },
    {
      id: '3BE46FEA-6B43-46A0-9E24-D212099F86D3',
      name: 'Waschküche',
      idsOfControlElements: [
        'D51704FF-CFD7-4E55-9DB5-A8C6C8AC1090',
        'BB83BD9C-CEF4-456B-B54E-6355D6393044',
        'D435313D-0848-4186-B32D-3A181EFA4F76',
        '8134B949-9846-41D4-AB84-874B2F2DFEED'
      ]
    },
    {
      id: '02DF43B9-0C3D-40F0-B214-ED734F4E6152',
      name: 'Trockenraum',
      idsOfControlElements: [
        '22501E3A-A9FB-4E2D-A33C-A0928873E930',
        'CAB78F1C-DA1C-45CE-9991-C9F3A29BFF46',
        'AD2F0C25-8DAB-4A8A-9F99-81A82EDAFF10',
        '1496E1BE-A389-48CD-8369-F0004E672C56'
      ]
    },
    {
      id: 'D261471E-83DD-4540-B008-28E45303C294',
      name: 'Werkstatt',
      idsOfControlElements: [
        'D9470F9B-F69D-488E-98C4-2E8FFCB926ED',
        '7E66F9A3-6699-4250-880E-17C293B4C333',
        '366B350B-1D88-462B-AED9-0AEC2B72E4B4',
        'BDCBBFCF-57BF-46E7-B268-072D4D86B332',
        'B9C2C0BC-805F-4D27-AA74-330EFCD412DE',
        '1CD54FCD-F6EE-448F-995F-436A4399BC57',
        '0BF6B7F3-E501-4DF4-A5B3-0092C482CFD9'
      ]
    },
    {
      id: 'D601B185-D6A9-4AF6-AB55-6866F4845065',
      name: 'Technikraum',
      idsOfControlElements: ['8FA9D41E-AB41-4236-9BB7-21C6632B2B01']
    },
    {
      id: '97EF45D0-7998-45A3-AD00-55BCB89BCA57',
      name: 'Abstellraum',
      idsOfControlElements: [
        'D6098140-90C4-408E-8A9A-0143A54B0AA0',
        'DBDA883A-9D6D-4851-87C5-B2E08675AAD8',
        'FE4CF242-3471-4AF8-89B7-68112C8F5A27'
      ]
    },
    {
      id: '64BB0845-5053-46A0-B5AF-485A0856B0C5',
      name: 'Eingang',
      idsOfControlElements: ['AC80F4FC-92BB-43EF-9CDB-F7B565938C30']
    },
    {
      id: '9C3D8960-52E4-4798-AECB-3E5CC136B3A6',
      name: 'Flur',
      idsOfControlElements: [
        '71F18D1F-3D66-4321-828F-4F2670FF9CF9',
        '9BAD0567-196B-41E9-B608-7DB698AE67EA',
        'D8754464-A168-4926-BDBB-BF6A80627DA3'
      ]
    },
    {
      id: 'C855238A-4C20-4FAF-BDB5-E9D543A10B9F',
      name: 'Gäste-WC',
      idsOfControlElements: [
        'E7218D8C-EBF5-41A4-8E41-37DA56F36CBB',
        'DA1F9A79-22CE-471C-BEF9-4B115DD2C758',
        '33FA0DED-4A2D-4690-B6A5-319142681A4F'
      ]
    },
    {
      id: '0C02CD85-9BEA-42EC-89CD-44AA006E472C',
      name: 'Gäste-Zimmer',
      idsOfControlElements: [
        '247EBBA3-961F-4BD0-A73E-71B5884357F5',
        '92725752-A956-44CF-A7AF-48C01179B1C9',
        '04C0C2BE-558C-416E-8D41-F5C805746538'
      ]
    },
    {
      id: '9480A4F2-A896-4592-85C8-963967DE5272',
      name: 'Reduit',
      idsOfControlElements: ['75BC9D60-CD78-4D7C-87B1-269F3CF22C76', '43DB8412-CD27-4E5C-A989-62FAA4D1C44D']
    },
    {
      id: 'AD020A3A-436E-4A7C-8E97-ACB81E1FE4AA',
      name: 'Küche',
      idsOfControlElements: [
        '5769EDA4-FAFE-49A4-8CC8-8219B22883FC',
        'B9A650B5-1960-4D23-BF32-A9807AD20D43',
        'A5FE6220-E39C-4F0B-9526-B2674B2528F2',
        '8347D3EF-B09E-4D76-8105-9BC78C962EBB',
        '55DDADC8-397E-4CBC-BA20-D0118558BBC9'
      ]
    },
    {
      id: 'AE3ABE8B-56E4-48B6-8D78-64E4939C1392',
      name: 'Essbereich',
      idsOfControlElements: [
        'A5A73364-53B0-4610-9E98-0B67DFC3F55F',
        'B3430253-FB84-4321-849B-38FAD4BF7376',
        '440C8081-A54C-476E-97AD-81D1FAD37EA0',
        '5E4E13D5-21D5-4243-90A0-9290D731A416',
        '153A6DA4-0B4E-4333-94A7-90BD8F1FA944',
        '6F91D9AE-A8C4-46F6-B8BC-D273BAAD86CE',
        '19F7AFFF-0870-4436-B2F9-4F86036452CB',
        'A5F3128D-9EB5-4E2E-A6BA-F11C5B81FC4D',
        '0FA35F2A-DA9B-40B5-8C83-DFDE60713A88',
        'B1F8217A-2205-41B6-9CFF-EF0F48694A97'
      ]
    },
    {
      id: '6ADB7501-B392-430B-9EF1-729F02EBDB3F',
      name: 'Wohnbereich',
      idsOfControlElements: [
        '8239E2D6-0777-441F-97A4-E85433C19A3D',
        '54ED46B9-8A1F-4DB5-9147-0060C1978488',
        '0096ECAC-4D65-4093-B888-83E3E2E2F562',
        '5E4E13D5-21D5-4243-90A0-9290D731A416',
        '72BAE47A-B90D-41AF-9F6A-FA9F3D05F71F',
        'C0A2B2C1-DD99-4F78-926C-01F44AA5037A',
        '5060B0C8-37B4-4E73-808E-5CFC6EEAB5BC',
        'A5F3128D-9EB5-4E2E-A6BA-F11C5B81FC4D',
        '0FA35F2A-DA9B-40B5-8C83-DFDE60713A88',
        'B1F8217A-2205-41B6-9CFF-EF0F48694A97'
      ]
    },
    {
      id: 'F43F8444-E195-48DE-B370-50C52625F4C6',
      name: 'Flur',
      idsOfControlElements: [
        'D5DDE3AA-0FBC-4E3E-A7AD-A8DA9E566EBE',
        '9BAD0567-196B-41E9-B608-7DB698AE67EA',
        '1B6085FD-371A-4F7F-A126-0CE438FC7B47'
      ]
    },
    {
      id: 'A77CE28D-EFA5-49A6-82F4-82EAF89CD710',
      name: 'Bad',
      idsOfControlElements: [
        'AAF3FFD8-5043-4609-B8DB-FAAA5A98A4A2',
        '81C1C43D-1CB7-4C26-BDF4-7FF2A4D052A4',
        '2F31713D-A08D-43CD-AA6A-AE719398B1B1',
        '59CDC491-7C7E-4665-9D33-2C20D9F39FC0'
      ]
    },
    {
      id: '2131C932-7678-4EF7-8816-BD64BBCA5037',
      name: 'Schlafzimmer Eltern',
      idsOfControlElements: [
        '484EEE3A-E4EC-4AA3-BD39-1E6620192FA8',
        '90A7C5D5-6CFD-49E6-A9EF-9F0B6E8B9B75',
        '951C83CB-9F2F-4360-81BA-42F740B540B4',
        '4BF0285D-ADB2-48B1-820A-F857A05596D3'
      ]
    },
    {
      id: '843821CC-7BB9-46AF-97C5-7FFE3CFA19AE',
      name: 'Schlafzimmer Marco',
      idsOfControlElements: [
        '51FAA7D6-31E8-40AD-A814-3E093FD8A4ED',
        '545F214D-F768-4AC0-ACD5-742729492176',
        '41751DF0-E0D1-4C61-9E26-3D38845C865A'
      ]
    },
    {
      id: '43041E70-C003-4A4B-846B-3A0AB948C1B0',
      name: 'Schlafzimmer Laura',
      idsOfControlElements: [
        '0406971E-A282-4A41-9BAB-D439DDA787E9',
        '2DC00B14-D681-4982-9378-E5114FEDC2FF',
        '982F8F83-CCB5-4E3B-A424-A844F848F67B'
      ]
    },
    {
      id: 'BDB5EC74-555F-4E51-9150-DCAAA76C6F78',
      name: 'Büro',
      idsOfControlElements: [
        'AAFBEDCA-0C5B-4E46-B47C-BC6D5DA264EA',
        '8F7C563F-974D-4196-ADC4-6ADE741A0508',
        'C0EBD4AE-7E18-4DC5-B618-95687BD93B60',
        '71A1DB91-543B-4619-A09A-5267AE05BDD8',
        'F9EF66C5-67C8-4E55-9526-882C842E0D02',
        'DF1426BD-3F48-48C2-93EF-992E495FC19B'
      ]
    },
    {
      id: 'DF8A592A-07CD-4F2D-B149-29A950889223',
      name: 'Spielzimmer',
      idsOfControlElements: ['B3EC3A8C-EEDD-4571-A74E-B34849597C6C', '2821C227-BA76-4622-9DDC-0CB7A2F723EC']
    },
    {
      id: '52880EC5-E24A-4051-8A6D-56BBD3F8B7F4',
      name: 'Estrich',
      idsOfControlElements: ['FF456ED0-23E0-45DB-8B8B-B984ECE42C4A']
    },
    {
      id: 'B13C87ED-E5BF-49FF-BBF9-1F30E7283D4E',
      name: 'Garage',
      idsOfControlElements: [
        'AC80F4FC-92BB-43EF-9CDB-F7B565938C30',
        '26F96B31-9C47-466A-B97A-2D1DA61A0A60',
        'DD2E6F83-594E-40CD-BF85-56F98C1F3F8B',
        '8BCD27F5-2981-4885-9A23-D0360F64858D',
        '2AC55BC7-25B9-4AFE-8DA1-92D1C00D1C51'
      ]
    },
    {
      id: '8ABC66A2-A75B-4BEA-A736-468939910068',
      name: 'Garten',
      idsOfControlElements: [
        'C8A8D55A-9AE2-4B39-B483-E92CFC85317B',
        '38DCA5CD-68CB-4F87-BEC1-FB13830AF78B',
        '40A2D6F1-8A3B-4D59-B882-123AC2AB4BD0'
      ]
    },
    {
      id: '81A48615-5418-4BED-AD6B-CEF47C0B9E7F',
      name: 'Terasse',
      idsOfControlElements: [
        'AA3C0FFE-E572-42AC-BCF3-2DCC47CB86BB',
        '01D4135C-0EA8-4266-8DB2-AD2134106326',
        '5B8D554E-420E-44E1-9259-765193142CA7'
      ]
    },
    {
      id: '1C51BC06-1E0A-4B70-B8B7-6EFEB2B7F62B',
      name: 'Pool',
      idsOfControlElements: ['E0258DFE-49E7-4D68-96B3-5839654DC18C', '985E1B38-DAF0-4244-8D98-E940DC7E6A1A']
    },
    {
      id: '57C412FE-200B-4CE4-8980-84C3D5B41840',
      name: 'Zugang Abstellraum',
      idsOfControlElements: ['0851A3C2-D788-412E-AC9A-3548258E233E']
    }
  ],
  controlElements: [
    {
      id: 'DAFA83D1-7C02-46EA-A5A7-C846730C980C',
      name: 'Lampe Treppenhaus',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0133w-ffb500.svg',
        iconInactive: '0134w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0133w-ffb500.svg',
        iconInactive: '0134w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '18C3F21E-8C62-4352-8371-DA89F9417CF4',
      name: 'Deckenlampe Keller',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'F6A4FCF0-C3D0-4086-9950-24C85AF8FB52',
      name: 'WLAN Keller',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0713w-0a4297.svg',
        iconInactive: '0714w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0713w-0a84fe.svg',
        iconInactive: '0714w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'D51704FF-CFD7-4E55-9DB5-A8C6C8AC1090',
      name: 'Licht',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'BB83BD9C-CEF4-456B-B54E-6355D6393044',
      name: 'Belüftung Niedrig',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0917w-0a4297.svg',
        iconInactive: '0917w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0917w-0a84fe.svg',
        iconInactive: '0917w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'D435313D-0848-4186-B32D-3A181EFA4F76',
      name: 'Belüftung Mittel',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0918w-0a4297.svg',
        iconInactive: '0918w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0918w-0a84fe.svg',
        iconInactive: '0918w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '8134B949-9846-41D4-AB84-874B2F2DFEED',
      name: 'Belüftung Hoch',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0919w-0a4297.svg',
        iconInactive: '0919w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0919w-0a84fe.svg',
        iconInactive: '0919w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '22501E3A-A9FB-4E2D-A33C-A0928873E930',
      name: 'Licht',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'CAB78F1C-DA1C-45CE-9991-C9F3A29BFF46',
      name: 'Wäschetrockner Niedrig',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0917w-0a4297.svg',
        iconInactive: '0917w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0917w-0a84fe.svg',
        iconInactive: '0917w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'AD2F0C25-8DAB-4A8A-9F99-81A82EDAFF10',
      name: 'Wäschetrockner Mittel',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0918w-0a4297.svg',
        iconInactive: '0918w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0918w-0a84fe.svg',
        iconInactive: '0918w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '1496E1BE-A389-48CD-8369-F0004E672C56',
      name: 'Wäschetrockner Hoch',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0919w-0a4297.svg',
        iconInactive: '0919w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0919w-0a84fe.svg',
        iconInactive: '0919w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'D9470F9B-F69D-488E-98C4-2E8FFCB926ED',
      name: 'Licht',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '7E66F9A3-6699-4250-880E-17C293B4C333',
      name: 'Belüftung Niedrig',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0917w-0a4297.svg',
        iconInactive: '0917w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0917w-0a84fe.svg',
        iconInactive: '0917w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '366B350B-1D88-462B-AED9-0AEC2B72E4B4',
      name: 'Belüftung Mittel',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0918w-0a4297.svg',
        iconInactive: '0918w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0918w-0a84fe.svg',
        iconInactive: '0918w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'BDCBBFCF-57BF-46E7-B268-072D4D86B332',
      name: 'Belüftung Hoch',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0919w-0a4297.svg',
        iconInactive: '0919w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0919w-0a84fe.svg',
        iconInactive: '0919w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'B9C2C0BC-805F-4D27-AA74-330EFCD412DE',
      name: 'Bohrmaschine',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0712w-0a4297.svg',
        iconInactive: '0711w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0712w-0a84fe.svg',
        iconInactive: '0711w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '1CD54FCD-F6EE-448F-995F-436A4399BC57',
      name: 'Drehbank',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0712w-0a4297.svg',
        iconInactive: '0711w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0712w-0a84fe.svg',
        iconInactive: '0711w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '0BF6B7F3-E501-4DF4-A5B3-0092C482CFD9',
      name: 'Ladegerät Handstaubsauger',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0708w-0a4297.svg',
        iconInactive: '0708w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0708w-0a84fe.svg',
        iconInactive: '0708w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '8FA9D41E-AB41-4236-9BB7-21C6632B2B01',
      name: 'Licht',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'D6098140-90C4-408E-8A9A-0143A54B0AA0',
      name: 'Licht',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'DBDA883A-9D6D-4851-87C5-B2E08675AAD8',
      name: 'Elektroroller Diana laden',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0708w-0a4297.svg',
        iconInactive: '0708w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0708w-0a84fe.svg',
        iconInactive: '0708w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'FE4CF242-3471-4AF8-89B7-68112C8F5A27',
      name: 'Elektroroller Erich laden',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0708w-0a4297.svg',
        iconInactive: '0708w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0708w-0a84fe.svg',
        iconInactive: '0708w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'AC80F4FC-92BB-43EF-9CDB-F7B565938C30',
      name: 'Aussenlicht',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0129w-ffb500.svg',
        iconInactive: '0130w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0129w-ffb500.svg',
        iconInactive: '0130w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '71F18D1F-3D66-4321-828F-4F2670FF9CF9',
      name: 'Deckenlampe Garderobe',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '9BAD0567-196B-41E9-B608-7DB698AE67EA',
      name: 'Lampe Treppenhaus',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0133w-ffb500.svg',
        iconInactive: '0134w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0133w-ffb500.svg',
        iconInactive: '0134w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'D8754464-A168-4926-BDBB-BF6A80627DA3',
      name: 'WLAN Erdgeschoss',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0713w-0a4297.svg',
        iconInactive: '0714w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0713w-0a84fe.svg',
        iconInactive: '0714w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'E7218D8C-EBF5-41A4-8E41-37DA56F36CBB',
      name: 'Deckenlampe',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'DA1F9A79-22CE-471C-BEF9-4B115DD2C758',
      name: 'Lüftung',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0917w-0a4297.svg',
        iconInactive: '0917w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0917w-0a84fe.svg',
        iconInactive: '0917w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '33FA0DED-4A2D-4690-B6A5-319142681A4F',
      name: 'Store',
      type: 'blind',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: '247EBBA3-961F-4BD0-A73E-71B5884357F5',
      name: 'Deckenlampe',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '92725752-A956-44CF-A7AF-48C01179B1C9',
      name: 'Nachttisch',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0109w-ffb500.svg',
        iconInactive: '0110w-7d7d7d.svg',
        statusTextActive: 'Ein',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0109w-ffb500.svg',
        iconInactive: '0110w-ffffff.svg',
        statusTextActive: 'Ein',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '04C0C2BE-558C-416E-8D41-F5C805746538',
      name: 'Store',
      type: 'blind',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: '75BC9D60-CD78-4D7C-87B1-269F3CF22C76',
      name: 'Licht',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '43DB8412-CD27-4E5C-A989-62FAA4D1C44D',
      name: 'Ladegerät Handstaubsauger',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0708w-0a4297.svg',
        iconInactive: '0708w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0708w-0a84fe.svg',
        iconInactive: '0708w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '5769EDA4-FAFE-49A4-8CC8-8219B22883FC',
      name: 'Deckenlampe',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'B9A650B5-1960-4D23-BF32-A9807AD20D43',
      name: 'Lüftung',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0917w-0a4297.svg',
        iconInactive: '0917w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0917w-0a84fe.svg',
        iconInactive: '0917w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'A5FE6220-E39C-4F0B-9526-B2674B2528F2',
      name: 'Kochen',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0811w-0a4297.svg',
        iconInactive: '0811w-7d7d7d.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0811w-0a84fe.svg',
        iconInactive: '0811w-ffffff.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '8347D3EF-B09E-4D76-8105-9BC78C962EBB',
      name: 'Kaffeemaschine',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0809w-0a4297.svg',
        iconInactive: '0809w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0809w-0a84fe.svg',
        iconInactive: '0809w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '55DDADC8-397E-4CBC-BA20-D0118558BBC9',
      name: 'Store',
      type: 'blind',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: 'A5A73364-53B0-4610-9E98-0B67DFC3F55F',
      name: 'Esstisch',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0105w-ffb500.svg',
        iconInactive: '0106w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0105w-ffb500.svg',
        iconInactive: '0106w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'B3430253-FB84-4321-849B-38FAD4BF7376',
      name: 'Essen',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0808w-0a4297.svg',
        iconInactive: '0808w-7d7d7d.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0808w-0a84fe.svg',
        iconInactive: '0808w-ffffff.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '440C8081-A54C-476E-97AD-81D1FAD37EA0',
      name: 'Candle Dinner',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0814w-0a4297.svg',
        iconInactive: '0814w-7d7d7d.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0814w-0a84fe.svg',
        iconInactive: '0814w-ffffff.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '5E4E13D5-21D5-4243-90A0-9290D731A416',
      name: 'Cheminée',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0815w-0a4297.svg',
        iconInactive: '0815w-7d7d7d.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0815w-0a84fe.svg',
        iconInactive: '0815w-ffffff.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '153A6DA4-0B4E-4333-94A7-90BD8F1FA944',
      name: 'Ständerlampe Essbereich',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0113w-ffb500.svg',
        iconInactive: '0114w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0113w-ffb500.svg',
        iconInactive: '0114w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '6F91D9AE-A8C4-46F6-B8BC-D273BAAD86CE',
      name: 'Gemälde Mona Lisa',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0117w-ffb500.svg',
        iconInactive: '0118w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0117w-ffb500.svg',
        iconInactive: '0118w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '19F7AFFF-0870-4436-B2F9-4F86036452CB',
      name: 'Store West',
      type: 'blind',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: 'A5F3128D-9EB5-4E2E-A6BA-F11C5B81FC4D',
      name: 'Store Front West',
      type: 'blind',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: '0FA35F2A-DA9B-40B5-8C83-DFDE60713A88',
      name: 'Store Front Mitte',
      type: 'blind',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: 'B1F8217A-2205-41B6-9CFF-EF0F48694A97',
      name: 'Store Front Ost',
      type: 'blind',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: '8239E2D6-0777-441F-97A4-E85433C19A3D',
      name: 'Deckenlampe',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '54ED46B9-8A1F-4DB5-9147-0060C1978488',
      name: 'Lesen',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0805w-0a4297.svg',
        iconInactive: '0805w-7d7d7d.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0805w-0a84fe.svg',
        iconInactive: '0805w-ffffff.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '0096ECAC-4D65-4093-B888-83E3E2E2F562',
      name: 'TV Abend',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0807w-0a4297.svg',
        iconInactive: '0807w-7d7d7d.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0807w-0a84fe.svg',
        iconInactive: '0807w-ffffff.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '72BAE47A-B90D-41AF-9F6A-FA9F3D05F71F',
      name: 'Gemälde Der Schrei',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0117w-ffb500.svg',
        iconInactive: '0118w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0117w-ffb500.svg',
        iconInactive: '0118w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'C0A2B2C1-DD99-4F78-926C-01F44AA5037A',
      name: 'Ständerlampe Sofa',
      type: 'dimmer',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: '5060B0C8-37B4-4E73-808E-5CFC6EEAB5BC',
      name: 'Store Ost',
      type: 'blind',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: 'D5DDE3AA-0FBC-4E3E-A7AD-A8DA9E566EBE',
      name: 'Deckenlampe',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '1B6085FD-371A-4F7F-A126-0CE438FC7B47',
      name: 'WLAN Obergeschoss',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0713w-0a4297.svg',
        iconInactive: '0714w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0713w-0a84fe.svg',
        iconInactive: '0714w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'AAF3FFD8-5043-4609-B8DB-FAAA5A98A4A2',
      name: 'Deckenlampe',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '81C1C43D-1CB7-4C26-BDF4-7FF2A4D052A4',
      name: 'Lüftung',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0917w-0a4297.svg',
        iconInactive: '0917w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0917w-0a84fe.svg',
        iconInactive: '0917w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '2F31713D-A08D-43CD-AA6A-AE719398B1B1',
      name: 'Handtuch-Trockner',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0702w-0a4297.svg',
        iconInactive: '0701w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0702w-0a84fe.svg',
        iconInactive: '0701w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '59CDC491-7C7E-4665-9D33-2C20D9F39FC0',
      name: 'Store',
      type: 'blind',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: '484EEE3A-E4EC-4AA3-BD39-1E6620192FA8',
      name: 'Deckenlampe',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '90A7C5D5-6CFD-49E6-A9EF-9F0B6E8B9B75',
      name: 'Nachttisch Diana',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0109w-ffb500.svg',
        iconInactive: '0110w-7d7d7d.svg',
        statusTextActive: 'Ein',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0109w-ffb500.svg',
        iconInactive: '0110w-ffffff.svg',
        statusTextActive: 'Ein',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '951C83CB-9F2F-4360-81BA-42F740B540B4',
      name: 'Nachttisch Erich',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0109w-ffb500.svg',
        iconInactive: '0110w-7d7d7d.svg',
        statusTextActive: 'Ein',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0109w-ffb500.svg',
        iconInactive: '0110w-ffffff.svg',
        statusTextActive: 'Ein',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '4BF0285D-ADB2-48B1-820A-F857A05596D3',
      name: 'Store',
      type: 'blind',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: '51FAA7D6-31E8-40AD-A814-3E093FD8A4ED',
      name: 'Deckenlampe',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '545F214D-F768-4AC0-ACD5-742729492176',
      name: 'Nachttisch Marco',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0109w-ffb500.svg',
        iconInactive: '0110w-7d7d7d.svg',
        statusTextActive: 'Ein',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0109w-ffb500.svg',
        iconInactive: '0110w-ffffff.svg',
        statusTextActive: 'Ein',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '41751DF0-E0D1-4C61-9E26-3D38845C865A',
      name: 'Store',
      type: 'blind',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: '0406971E-A282-4A41-9BAB-D439DDA787E9',
      name: 'Deckenlampe',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '2DC00B14-D681-4982-9378-E5114FEDC2FF',
      name: 'Nachttisch Laura',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0109w-ffb500.svg',
        iconInactive: '0110w-7d7d7d.svg',
        statusTextActive: 'Ein',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0109w-ffb500.svg',
        iconInactive: '0110w-ffffff.svg',
        statusTextActive: 'Ein',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '982F8F83-CCB5-4E3B-A424-A844F848F67B',
      name: 'Store',
      type: 'blind',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: 'AAFBEDCA-0C5B-4E46-B47C-BC6D5DA264EA',
      name: 'Deckenlampe',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '8F7C563F-974D-4196-ADC4-6ADE741A0508',
      name: 'Tischlampe',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0109w-ffb500.svg',
        iconInactive: '0110w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0109w-ffb500.svg',
        iconInactive: '0110w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'C0EBD4AE-7E18-4DC5-B618-95687BD93B60',
      name: 'Klimaanlage',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0921w-0a4297.svg',
        iconInactive: '0921w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0921w-0a84fe.svg',
        iconInactive: '0921w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '71A1DB91-543B-4619-A09A-5267AE05BDD8',
      name: 'Ventilator',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0920w-0a4297.svg',
        iconInactive: '0920w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0920w-0a84fe.svg',
        iconInactive: '0920w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'F9EF66C5-67C8-4E55-9526-882C842E0D02',
      name: 'Meeting',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0813w-0a4297.svg',
        iconInactive: '0813w-7d7d7d.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0813w-0a84fe.svg',
        iconInactive: '0813w-ffffff.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'DF1426BD-3F48-48C2-93EF-992E495FC19B',
      name: 'Store',
      type: 'blind',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: 'B3EC3A8C-EEDD-4571-A74E-B34849597C6C',
      name: 'Deckenlampe',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '2821C227-BA76-4622-9DDC-0CB7A2F723EC',
      name: 'Store',
      type: 'blind',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: 'FF456ED0-23E0-45DB-8B8B-B984ECE42C4A',
      name: 'Licht',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '26F96B31-9C47-466A-B97A-2D1DA61A0A60',
      name: 'Licht Garage',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'DD2E6F83-594E-40CD-BF85-56F98C1F3F8B',
      name: 'Tesla laden',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0712w-0a4297.svg',
        iconInactive: '0711w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0712w-0a84fe.svg',
        iconInactive: '0711w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '8BCD27F5-2981-4885-9A23-D0360F64858D',
      name: 'Garagetor öffnen',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0201w-0a4297.svg',
        iconInactive: '0201w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0201w-0a84fe.svg',
        iconInactive: '0201w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '2AC55BC7-25B9-4AFE-8DA1-92D1C00D1C51',
      name: 'Garagetor schliessen',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0202w-0a4297.svg',
        iconInactive: '0202w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0202w-0a84fe.svg',
        iconInactive: '0202w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'C8A8D55A-9AE2-4B39-B483-E92CFC85317B',
      name: 'Beleuchtung Garten',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0702w-0a4297.svg',
        iconInactive: '0701w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0702w-0a84fe.svg',
        iconInactive: '0701w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '38DCA5CD-68CB-4F87-BEC1-FB13830AF78B',
      name: 'Sprinkler-Anlage',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '1001w-0a4297.svg',
        iconInactive: '1001w-7d7d7d.svg',
        statusTextActive: 'Timer ein',
        statusTextInactive: 'Timer aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '1001w-0a84fe.svg',
        iconInactive: '1001w-ffffff.svg',
        statusTextActive: 'Timer ein',
        statusTextInactive: 'Timer aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '40A2D6F1-8A3B-4D59-B882-123AC2AB4BD0',
      name: 'Licht Gartenhaus',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0101w-ffb500.svg',
        iconInactive: '0102w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'AA3C0FFE-E572-42AC-BCF3-2DCC47CB86BB',
      name: 'Deckenlampe',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0121w-ffb500.svg',
        iconInactive: '0122w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '01D4135C-0EA8-4266-8DB2-AD2134106326',
      name: 'Markise ab',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0214w-0a4297.svg',
        iconInactive: '0214w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0214w-0a84fe.svg',
        iconInactive: '0214w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '5B8D554E-420E-44E1-9259-765193142CA7',
      name: 'Markise auf',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0213w-0a4297.svg',
        iconInactive: '0213w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0213w-0a84fe.svg',
        iconInactive: '0213w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'E0258DFE-49E7-4D68-96B3-5839654DC18C',
      name: 'Beleuchtung Pool',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0125w-ffb500.svg',
        iconInactive: '0126w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0125w-ffb500.svg',
        iconInactive: '0126w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '985E1B38-DAF0-4244-8D98-E940DC7E6A1A',
      name: 'Wasserpumpe',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0702w-0a4297.svg',
        iconInactive: '0701w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0702w-0a84fe.svg',
        iconInactive: '0701w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '0851A3C2-D788-412E-AC9A-3548258E233E',
      name: 'Licht',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0133w-ffb500.svg',
        iconInactive: '0134w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0133w-ffb500.svg',
        iconInactive: '0134w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#ffb500',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'F8DB0F94-5502-4CEF-95D3-632D314CEC99',
      name: 'Alarmanlage',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      confirmations: [
        {
          message: 'Alarmanlage wirklich ausschalten?',
          keyOk: 'Ja',
          keyAbort: 'Nein',
          transition: 'on:off'
        },
        {
          message: 'Alarmanlage wirklich einschalten?',
          keyOk: 'Ja',
          keyAbort: 'Nein',
          transition: 'off:on'
        }
      ],
      configurationLightMode: {
        iconActive: '0502w-0a4297.svg',
        iconInactive: '0501w-7d7d7d.svg',

        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0502w-0a84fe.svg',
        iconInactive: '0501w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '9FFF2491-E25C-4DBF-A9E5-82AFB5F44B7F',
      name: 'Haus schliessen',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0505w-0a4297.svg',
        iconInactive: '0506w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0505w-0a84fe.svg',
        iconInactive: '0506w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'F87433EB-F158-4304-B4DE-A90310F7370A',
      name: 'Panik',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0509w-0a4297.svg',
        iconInactive: '0509w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0509w-0a84fe.svg',
        iconInactive: '0509w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '4D5BF9C5-337D-433C-819C-C65BE7E9EEBD',
      name: 'Beleuchtung Garten',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      confirmations: [
        {
          message: 'Beleuchtung Garten wirklich ein- oder ausschalten?',
          keyOk: 'Ja',
          keyAbort: 'Nein',
          transition: 'all'
        }
      ],
      configurationLightMode: {
        iconActive: '1001w-0a4297.svg',
        iconInactive: '1001w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '1001w-0a84fe.svg',
        iconInactive: '1001w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'C98D8BF7-0EFE-42CF-9D2A-EEF5D3315623',
      name: 'Beleuchtung Haus',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '1001w-0a4297.svg',
        iconInactive: '1001w-7d7d7d.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '1001w-0a84fe.svg',
        iconInactive: '1001w-ffffff.svg',
        statusTextActive: 'An',
        statusTextInactive: 'Aus',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '2FD24F51-09D2-4BE9-B313-16163AE664AD',
      name: 'Nacht-Modus',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0816w-0a4297.svg',
        iconInactive: '0816w-7d7d7d.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0816w-0a84fe.svg',
        iconInactive: '0816w-ffffff.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: '8D810AF1-785F-4EE0-996B-26F8EBAE38B4',
      name: 'Reinigungs-Modus',
      type: 'switch',
      isEnabled: true,
      isFavorite: false,
      configurationLightMode: {
        iconActive: '0812w-0a4297.svg',
        iconInactive: '0812w-7d7d7d.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a4297',
        statusTextColorInactive: '#7d7d7d'
      },
      configurationDarkMode: {
        iconActive: '0812w-0a84fe.svg',
        iconInactive: '0812w-ffffff.svg',
        statusTextActive: 'Szene',
        statusTextInactive: 'Szene',
        statusTextColorActive: '#0a84fe',
        statusTextColorInactive: '#ffffff'
      }
    },
    {
      id: 'AD8819BF-0BB3-B14F-9768-259B0DAE872E',
      name: 'Wetter',
      type: 'weather',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: 'CEC582D0-31EB-45BC-9BF4-39705C638BCE',
      name: 'Essbereich',
      type: 'roomclimate',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: 'AF783A57-1090-4953-9B52-EA0642030600',
      name: 'Wohnbereich',
      type: 'roomclimate',
      isEnabled: true,
      isFavorite: false
    },
    {
      id: 'F255EFE9-4464-4CF7-BF96-1D43102FE4E6',
      name: 'Küche',
      type: 'roomclimate',
      isEnabled: true,
      isFavorite: false
    }
  ]
}
