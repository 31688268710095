class PrecipitationData {
  constructor() {
    this.precipitationValue = null
    this.precipitationUnit = null
  }

  setPrecipitation(value, unit) {
    this.precipitationValue = value
    this.precipitationUnit = unit
  }
}

export default PrecipitationData
