<template>
  <div
    class="button-minus-container"
    :class="isButtonPressed ? 'button-minus-pressed-background' : ''"
    @mousedown.stop.passive="this.isButtonPressed = true"
    @mouseup.stop.passive="this.isButtonPressed = false"
    @mouseleave.stop.passive="this.isButtonPressed = false"
    @touchstart.stop.passive="this.isButtonPressed = true"
    @touchend.stop.passive="this.isButtonPressed = false"
    @touchcancel.stop.passive="this.isButtonPressed = false"
    @click.stop.passive="buttonMinusClicked"
  >
    <img v-if="getUsedTheme === 'dark-theme'" :src="iconButtonMinusDarkMode" />
    <img v-else :src="iconButtonMinusLightMode" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ButtonMinus',
  emits: ['buttonClickedEvent'],
  data() {
    return {
      isButtonPressed: false,
      iconButtonMinusLightMode: require('@/assets/widgets/roomclimateelement/light-theme/button-minus.svg'),
      iconButtonMinusDarkMode: require('@/assets/widgets/roomclimateelement/dark-theme/button-minus.svg')
    }
  },
  computed: {
    ...mapGetters(['getUsedTheme'])
  },
  methods: {
    buttonMinusClicked() {
      this.$emit('buttonClickedEvent')
    }
  }
}
</script>

<style scoped>
.button-minus-container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
}

.button-minus-pressed-background {
  border-radius: 7px;
  background-color: var(--pressed-background-color);
}
</style>
