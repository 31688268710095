class RoomRegulationsUpdater {
  constructor() {
    this.appManager = null
    this.logChanges = true
  }

  setAppManager(appManager) {
    this.appManager = appManager
  }

  /**
   * This method updates the room regulations stored in the database.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @param {Boolean} reset Use true if 'WebApp Configuration' is completely new
   */
  async update(webAppConfiguration, reset) {
    if (this.appManager != null) {
      // Variable containing room regulations to be stored in database
      let roomRegulations = null

      if (reset) {
        // 'WebApp Configuration' is completely new

        // Get all room regulations stored in 'WebApp Configuration'
        if (webAppConfiguration.climate != null) {
          if (webAppConfiguration.climate.roomRegulations != null) {
            if (webAppConfiguration.climate.roomRegulations.idsOfControlElements != null) {
              roomRegulations = {
                id: 1,
                idsOfControlElements: webAppConfiguration.climate.roomRegulations.idsOfControlElements
              }
            }
          }
        }
      } else {
        // 'WebApp Configuration' is updated

        // Get all room regulations stored in 'WebApp Configuration'
        // and log all changes made to room regulations
        roomRegulations = await this.getUpdatedRoomRegulations(webAppConfiguration)
      }

      // Delete all room regulations stored in database
      await this.appManager.deleteRoomRegulations()

      if (roomRegulations) {
        // Store new/changed room regulations into database
        await this.appManager.storeRoomRegulations(roomRegulations)
      }
    }
  }

  /**
   * This method updates the room regulations in the database and also logs the changes.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @returns Array containing rooms to be stored in database
   */
  async getUpdatedRoomRegulations(webAppConfiguration) {
    // Variable containing 'Room Regulation IDs' to be stored in database
    let roomRegulationIds = []

    // Get 'Room Regulation IDs' stored in database
    let storedRoomRegulationIds = []

    const storedRoomRegulations = await this.appManager.getRoomRegulations()

    if (storedRoomRegulations != null) {
      if (storedRoomRegulations.idsOfControlElements != null) {
        storedRoomRegulationIds = storedRoomRegulations.idsOfControlElements
      }
    }

    // Get 'Room Regulation IDs' stored in 'WebApp Configuration'
    let roomRegulationIdsInWebAppConfiguration = []

    if (webAppConfiguration.climate != null) {
      if (webAppConfiguration.climate.roomRegulations != null) {
        if (webAppConfiguration.climate.roomRegulations.idsOfControlElements != null) {
          roomRegulationIdsInWebAppConfiguration = webAppConfiguration.climate.roomRegulations.idsOfControlElements
        }
      }
    }

    storedRoomRegulationIds.forEach(storedRoomRegulationId => {
      // Check if 'Stored Room Regulation ID' is also available in 'WebApp Configuration'
      const roomRegulationIdInWebAppConfiguration = roomRegulationIdsInWebAppConfiguration.find(
        element => element === storedRoomRegulationId
      )

      if (roomRegulationIdInWebAppConfiguration) {
        // 'Stored Room Regulation ID' is also available in 'WebApp Configuration'

        // Add 'Room Regulation ID' as defined in 'WebApp Configuration' to
        // variable containing 'Room Regulation IDs' to be stored into database
        roomRegulationIds.push(storedRoomRegulationId)
      } else {
        // 'Stored Room Regulation ID' is not available in 'WebApp Configuration'

        if (this.logChanges) {
          // Log changes made to room regulations

          // Get ID of room regulation (short version)
          const shortRoomRegulationId = this.getShortId(storedRoomRegulationId)

          // Define message describing removing room regulation
          const message = `Room regulation removed (Room regulation ID: ${shortRoomRegulationId})`

          // Store log entry in database
          this.storeLogEntry(message)
        }
      }
    })

    roomRegulationIdsInWebAppConfiguration.forEach(roomRegulationIdInWebAppConfiguration => {
      // Check if 'Room Regulation ID in WebApp Configuration' is also available
      // in 'Stored Room Regulation IDs'
      const roomRegulationIdInStoredRoomRegualtionIds = storedRoomRegulationIds.find(
        element => element === roomRegulationIdInWebAppConfiguration
      )

      if (!roomRegulationIdInStoredRoomRegualtionIds) {
        // 'Room Regulation ID in WebApp Configuration' is not available in
        // 'Stored Room Regulation IDs'

        // Add 'Room Regulation ID' as defined in 'WebApp Configuration' to
        // variable containing room regulation IDs to be stored into database
        roomRegulationIds.push(roomRegulationIdInWebAppConfiguration)

        if (this.logChanges) {
          // Log changes made to room regulations

          // Get ID of room regulation (short version)
          const shortRoomRegulationId = this.getShortId(roomRegulationIdInWebAppConfiguration)

          // Define message describing adding room regulation ID
          const message = `Room regulation added (Room regulation ID: ${shortRoomRegulationId})`

          // Store log entry in database
          this.storeLogEntry(message)
        }
      }
    })

    const roomRegulations = {
      id: 1,
      idsOfControlElements: roomRegulationIds
    }

    return roomRegulations
  }

  /**
   * This method returns the first sevent characters of the given ID.
   *
   * @param {String} id ID as UUID
   * @returns ID containing first seven characters
   */
  getShortId(id) {
    let shortId = ''

    if (id) {
      shortId = id.substring(0, 7).toLowerCase()
    }

    return shortId
  }

  /**
   * This method creates a log entry containing the current time and the given
   * message. The log entry is stored into the database.
   *
   * @param {String} message Message used for log entry
   */
  storeLogEntry(message) {
    this.appManager.storeLogEntry({ timestamp: new Date(), message: message })
  }
}

export default RoomRegulationsUpdater
