import RoomRegulations from '../roomregulations/roomregulations'

/**
 * The class Climate stores the following information:
 *
 *  - Object containing room regulations (see also class RoomRegulations)
 *
 */
class Climate {
  /**
   * The constructor of this class stores the given objects containing the room regulations.
   * The constructor throws an error if the room regulations is not valid.
   *
   * @param {RoomRegulations} roomRegulations Object containing room regulations
   */
  constructor(roomRegulations) {
    // Check and store room regulations
    if (roomRegulations) {
      if (roomRegulations instanceof RoomRegulations) {
        this.roomRegulations = roomRegulations
      } else {
        throw new Error('roomRegulations is not an instance of RoomRegulations')
      }
    } else {
      this.roomRegulations = null
    }
  }
}

export default Climate
