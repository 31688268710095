class GlobalTimersUpdater {
  constructor() {
    this.appManager = null
    this.logChanges = true
  }

  setAppManager(appManager) {
    this.appManager = appManager
  }

  /**
   * This method updates the global timers stored in the database.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @param {Boolean} reset Use true if 'WebApp Configuration' is completely new
   */
  async update(webAppConfiguration, reset) {
    if (this.appManager != null) {
      // Variable containing global timers to be stored in database
      let globalTimers = null

      if (reset) {
        // 'WebApp Configuration' is completely new

        // Get all global timers stored in 'WebApp Configuration'
        if (webAppConfiguration.global != null) {
          if (webAppConfiguration.global.globalTimers != null) {
            if (webAppConfiguration.global.globalTimers.idsOfControlElements != null) {
              globalTimers = {
                id: 1,
                idsOfControlElements: webAppConfiguration.global.globalTimers.idsOfControlElements
              }
            }
          }
        }
      } else {
        // 'WebApp Configuration' is updated

        // Get all global timers stored in 'WebApp Configuration'
        // and log all changes made to global timers
        globalTimers = await this.getUpdatedGlobalTimers(webAppConfiguration)
      }

      // Delete all global timers stored in database
      await this.appManager.deleteGlobalTimers()

      if (globalTimers) {
        // Store new/changed global timers into database
        await this.appManager.storeGlobalTimers(globalTimers)
      }
    }
  }

  /**
   * This method updates the global timers in the database and also logs the changes.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @returns Array containing globals to be stored in database
   */
  async getUpdatedGlobalTimers(webAppConfiguration) {
    // Variable containing 'Global Timers IDs' to be stored in database
    let globalTimersIds = []

    // Get 'Global Timers IDs' stored in database
    let storedGlobalTimersIds = []

    const storedGlobalTimers = await this.appManager.getGlobalTimers()

    if (storedGlobalTimers != null) {
      if (storedGlobalTimers.idsOfControlElements != null) {
        storedGlobalTimersIds = storedGlobalTimers.idsOfControlElements
      }
    }

    // Get 'Global Timers IDs' stored in 'WebApp Configuration'
    let globalTimersIdsInWebAppConfiguration = []

    if (webAppConfiguration.global != null) {
      if (webAppConfiguration.global.globalTimers != null) {
        if (webAppConfiguration.global.globalTimers.idsOfControlElements != null) {
          globalTimersIdsInWebAppConfiguration = webAppConfiguration.global.globalTimers.idsOfControlElements
        }
      }
    }

    storedGlobalTimersIds.forEach(storedGlobalTimersId => {
      // Check if 'Stored Global Timers ID' is also available in 'WebApp Configuration'
      const globalTimersIdInWebAppConfiguration = globalTimersIdsInWebAppConfiguration.find(
        element => element === storedGlobalTimersId
      )

      if (globalTimersIdInWebAppConfiguration) {
        // 'Stored Global Timers ID' is also available in 'WebApp Configuration'

        // Add 'Global Timers ID' as defined in 'WebApp Configuration' to
        // variable containing 'Global Timers IDs' to be stored into database
        globalTimersIds.push(storedGlobalTimersId)
      } else {
        // 'Stored Global Timers ID' is not available in 'WebApp Configuration'

        if (this.logChanges) {
          // Log changes made to global timers

          // Get ID of global timer (short version)
          const shortGlobalTimersId = this.getShortId(storedGlobalTimersId)

          // Define message describing removing global timer
          const message = `Global timer removed (Global timer ID: ${shortGlobalTimersId})`

          // Store log entry in database
          this.storeLogEntry(message)
        }
      }
    })

    globalTimersIdsInWebAppConfiguration.forEach(globalTimersIdInWebAppConfiguration => {
      // Check if 'Global Timers ID in WebApp Configuration' is also available
      // in 'Stored Global Timers IDs'
      const globalTimersIdInStoredGlobalRegualtionIds = storedGlobalTimersIds.find(
        element => element === globalTimersIdInWebAppConfiguration
      )

      if (!globalTimersIdInStoredGlobalRegualtionIds) {
        // 'Global Timers ID in WebApp Configuration' is not available in
        // 'Stored Global Timers IDs'

        // Add 'Global Timers ID' as defined in 'WebApp Configuration' to
        // variable containing global timer IDs to be stored into database
        globalTimersIds.push(globalTimersIdInWebAppConfiguration)

        if (this.logChanges) {
          // Log changes made to global timers

          // Get ID of global timer (short version)
          const shortGlobalTimersId = this.getShortId(globalTimersIdInWebAppConfiguration)

          // Define message describing adding global timer ID
          const message = `Global timer added (Global timer ID: ${shortGlobalTimersId})`

          // Store log entry in database
          this.storeLogEntry(message)
        }
      }
    })

    const globalTimers = {
      id: 1,
      idsOfControlElements: globalTimersIds
    }

    return globalTimers
  }

  /**
   * This method returns the first seven characters of the given ID.
   *
   * @param {String} id ID as UUID
   * @returns ID containing first seven characters
   */
  getShortId(id) {
    let shortId = ''

    if (id) {
      shortId = id.substring(0, 7).toLowerCase()
    }

    return shortId
  }

  /**
   * This method creates a log entry containing the current time and the given
   * message. The log entry is stored into the database.
   *
   * @param {String} message Message used for log entry
   */
  storeLogEntry(message) {
    this.appManager.storeLogEntry({ timestamp: new Date(), message: message })
  }
}

export default GlobalTimersUpdater
