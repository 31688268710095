import GlobalFunctions from '../globalfunctions/globalfunctions'
import GlobalTimers from '../globaltimers/globaltimers'
import GlobalScenes from '../globalscenes/globalscenes'

/**
 * The class Global stores the following information:
 *
 *  - Object containing global functions (see also class GlobalFunctions)
 *  - Object containing global timers (see also class GlobalTimers)
 *  - Object containing global scenes (see also class GlobalScenes)
 *
 */
class Global {
  /**
   * The constructor of this class stores the given objects containing global functions,
   * global timers and global scenes. The constructor throws an error if the given global
   * function, global timers or global scenes is not valid.
   *
   * @param {GlobalFunctions} globalFunctions Object containing global functions
   * @param {GlobalTimers} globalTimers Object containing global timers
   * @param {GlobalScenes} globalScenes Object containing global scenes
   */
  constructor(globalFunctions, globalTimers, globalScenes) {
    // Check and store global functions
    if (globalFunctions) {
      if (globalFunctions instanceof GlobalFunctions) {
        this.globalFunctions = globalFunctions
      } else {
        throw new Error('globalFunctions is not an instance of GlobalFunctions')
      }
    } else {
      this.globalFunctions = null
    }

    // Check and store global timers
    if (globalTimers) {
      if (globalTimers instanceof GlobalTimers) {
        this.globalTimers = globalTimers
      } else {
        throw new Error('globalTimers is not an instance of GlobalTimers')
      }
    } else {
      this.globalTimers = null
    }

    // Check and store global screnes
    if (globalScenes) {
      if (globalScenes instanceof GlobalScenes) {
        this.globalScenes = globalScenes
      } else {
        throw new Error('globalScenes is not an instance of GlobalScenes')
      }
    } else {
      this.globalScenes = null
    }
  }
}

export default Global
