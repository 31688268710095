/**
 * The class Confirmation stores the following information about a confirmation:
 *
 *  - Message
 *  - Text for key 'OK'
 *  - Text for key 'Abort'
 *  - Transition
 *
 */
class Confirmation {
  /**
   * The constructor of this class stores the given message, the text for key 'OK',
   * the text for key 'Abort' and the transition of the confirmation. The constructor
   * throws an error if the given message, text for key 'OK', text for key 'Abort'
   * or transition is not valid.
   *
   * @param {string} message Message for confirmation dialog
   * @param {string} keyOk Text for key 'OK' of confirmation dialog
   * @param {string} keyAbort Text for key 'Abort' of confirmation dialog
   * @param {string} transition Transition (all, on:off, off:on)
   */
  constructor(message, keyOk, keyAbort, transition) {
    // Check and store message
    if (message) {
      if (typeof message === 'string') {
        this.message = message
      } else {
        throw new Error('message is not of type string')
      }
    } else {
      throw new Error('message is not valid')
    }

    // Check and store keyOk
    if (keyOk) {
      if (typeof keyOk === 'string') {
        this.keyOk = keyOk
      } else {
        throw new Error('keyOk is not of type string')
      }
    } else {
      throw new Error('keyOk is not valid')
    }

    // Check and store keyAbort
    if (keyAbort) {
      if (typeof keyAbort === 'string') {
        this.keyAbort = keyAbort
      } else {
        throw new Error('keyAbort is not of type string')
      }
    } else {
      throw new Error('keyAbort is not valid')
    }

    // Check and store transition
    if (transition) {
      if (typeof transition === 'string') {
        this.transition = transition
      } else {
        throw new Error('transition is not of type string')
      }
    } else {
      throw new Error('transition is not valid')
    }
  }
}

export default Confirmation
