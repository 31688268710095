<template>
  <div class="climate-view-container">
    <div class="climate-view-header">{{ $t('climate') }}</div>
    <div v-show="isPlaceholderForWebUiMenuEnabled" class="climate-view-placeholder-webui-menu" />
    <div v-show="isConnectionLost" class="climate-view-connection-lost">
      <connection-lost @tryToReconnectEvent="tryToReconnect" />
    </div>
    <div id="scrollable-climate-view" v-show="!isConnectionLost" class="climate-view-main">
      <div v-if="isWeatherAvailable()">
        <weather-element :controlElementId="controlElementId" />
      </div>
      <div v-if="idsOfRoomClimateElements.length > 0">
        <control-elements :idsOfControlElements="idsOfRoomClimateElements" :title="getTextForRoomRegulation" />
        <div v-if="showScrolledToBottomSpace" class="climate-view-scrolled-to-bottom-space" />
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n'
import { mapGetters } from 'vuex'
import WeatherElement from '@/ui/components/controlelements/weatherelement/WeatherElement.vue'
import ControlElements from '@/ui/components/ControlElements.vue'
import ConnectionLost from '@/ui/components/ConnectionLost.vue'

export default {
  name: 'ClimateView',
  components: { WeatherElement, ControlElements, ConnectionLost },
  emits: ['tryToReconnectEvent'],
  data() {
    return {
      elementScrollableClimateView: null,
      showScrolledToBottomSpace: false,
      // Todo: Use generated ID for weather
      controlElementId: 'AD8819BF-0BB3-B14F-9768-259B0DAE872E',
      idsOfRoomClimateElements: []
    }
  },
  beforeMount() {
    this.idsOfRoomClimateElements = this.$appManager.getIdsOfRoomClimateElements()
  },
  mounted() {
    this.$appManager.getLatestStates()

    this.elementScrollableClimateView = document.getElementById('scrollable-climate-view')

    if (this.elementScrollableClimateView != null) {
      this.elementScrollableClimateView.onscroll = () => {
        this.enableScrolledToBottomSpace()
      }

      this.elementScrollableClimateView.scrollTop = 0
    }
  },
  computed: {
    ...mapGetters(['isPlaceholderForWebUiMenuEnabled', 'isConnectionLost']),
    getTextForRoomRegulation() {
      return i18n.global.t('roomRegulation')
    }
  },
  methods: {
    enableScrolledToBottomSpace() {
      if (this.elementScrollableClimateView != null) {
        const scrollTop = this.elementScrollableClimateView.scrollTop
        const invisiblePart =
          this.elementScrollableClimateView.scrollHeight - this.elementScrollableClimateView.clientHeight
        const scrollbarPosition = (scrollTop / invisiblePart) * 100

        this.showScrolledToBottomSpace = scrollbarPosition > 0
      }
    },
    isWeatherAvailable() {
      let isAvailabe = false

      const controlElement = this.$appManager.getControlElement(this.controlElementId)

      if (controlElement != null) {
        isAvailabe = true
      }

      return isAvailabe
    },
    tryToReconnect() {
      this.$emit('tryToReconnectEvent')
    }
  }
}
</script>

<style scoped>
.climate-view-container {
  display: grid;
  grid-template-columns: 1fr 55px;
  grid-template-rows: 50px auto auto;
  background-color: var(--background-color-secondary);
}

.climate-view-header {
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-primary);
  background-color: var(--background-color-primary);
}

.climate-view-placeholder-webui-menu {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  align-self: stretch;
  justify-self: stretch;
  border-top: solid var(--background-color-primary) 19px;
  border-right: solid var(--background-color-primary) 13px;
  border-bottom: solid var(--background-color-primary) 5px;
  border-left: solid var(--background-color-primary) 16px;
  background-color: var(--background-color-secondary);
}

.climate-view-connection-lost {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  align-self: center;
}

.climate-view-main {
  grid-column: 1 / span 2;
  grid-row: 2 / span 1;
  overflow-y: auto;
  margin: 1px 0;
}

.climate-view-scrolled-to-bottom-space {
  grid-column: 1 / span 2;
  grid-row: 3 / span 1;
  height: 35px;
}
</style>
