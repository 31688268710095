<template>
  <div class="logging-dialog-container" :rerender="rerender">
    <div class="logging-dialog-title">{{ $t('logging') }}</div>
    <div
      class="logging-dialog-button-done"
      :class="isButtonDonePressed ? 'logging-dialog-button-done-pressed' : 'logging-dialog-button-done-not-pressed'"
      @mousedown.stop.passive="isButtonDonePressed = true"
      @mouseup.stop.passive="isButtonDonePressed = false"
      @mouseleave.stop.passive="isButtonDonePressed = false"
      @touchstart.stop.passive="isButtonDonePressed = true"
      @touchend.stop.passive="isButtonDonePressed = false"
      @touchcancel.stop.passive="isButtonDonePressed = false"
      @click.stop.passive="buttonDoneClicked"
    >
      {{ $t('done') }}
    </div>
    <div class="logging-dialog-content">
      <div class="logging-dialog-log-entries-containter">
        <div v-for="(logEntry, index) in logEntries" :key="logEntry.id">
          <div v-if="isNewDate(index)" class="logging-dialog-log-entries-date">{{ getDate(logEntry) }}</div>
          <div class="logging-dialog-log-entry">
            <div class="logging-dialog-log-entry-time">{{ getTime(logEntry) }}</div>
            <div class="logging-dialog-log-entry-id">{{ logEntry.id }}</div>
            <div class="logging-dialog-log-entry-message">{{ getMessage(logEntry) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="logEntries.length > 0"
      class="logging-dialog-button-delete-all-log-entries"
      :class="
        isButtonDeleteAllLogEntriesPressed
          ? 'logging-dialog-button-delete-all-log-entries-pressed'
          : 'logging-dialog-button-delete-all-log-entries-not-pressed'
      "
      @mousedown.stop.passive="isButtonDeleteAllLogEntriesPressed = true"
      @mouseup.stop.passive="isButtonDeleteAllLogEntriesPressed = false"
      @mouseleave.stop.passive="isButtonDeleteAllLogEntriesPressed = false"
      @touchstart.stop.passive="isButtonDeleteAllLogEntriesPressed = true"
      @touchend.stop.passive="isButtonDeleteAllLogEntriesPressed = false"
      @touchcancel.stop.passive="isButtonDeleteAllLogEntriesPressed = false"
      @click.stop.passive="buttonDeleteAllLogEntriesClicked"
    >
      {{ $t('deleteAllLogEntries') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoggingDialog',
  emits: ['doneEvent'],
  data() {
    return {
      logEntries: [],
      rerender: 0,
      isButtonDonePressed: false,
      isButtonDeleteAllLogEntriesPressed: false
    }
  },
  async mounted() {
    await this.getLogEntries()
  },
  methods: {
    async getLogEntries() {
      // Get all 'Log Entries' stored in IndexedDB
      const tmpLogEntries = await this.$appManager.getStoredLogEntries()

      // Reverse list of 'Log Entries'
      this.logEntries = tmpLogEntries.reverse()
    },
    isNewDate(index) {
      let isNewDate = false

      if (index == 0) {
        isNewDate = true
      }

      if (index > 0 && index < this.logEntries.length) {
        const date1 = this.logEntries[index - 1].timestamp.toDateString()
        const date2 = this.logEntries[index].timestamp.toDateString()

        if (date1 != date2) {
          isNewDate = true
        }
      }

      return isNewDate
    },
    getDate(logEntry) {
      let date = ''

      if (logEntry != null) {
        if (logEntry.timestamp != null) {
          const day = String(logEntry.timestamp.getDate()).padStart(2, '0')
          const month = String(logEntry.timestamp.getMonth() + 1).padStart(2, '0')
          const year = logEntry.timestamp.getFullYear()

          date = `${day}.${month}.${year}`
        }
      }

      return date
    },
    getTime(logEntry) {
      let time = ''

      if (logEntry != null) {
        if (logEntry.timestamp != null) {
          const hours = String(logEntry.timestamp.getHours()).padStart(2, '0')
          const minutes = String(logEntry.timestamp.getMinutes()).padStart(2, '0')
          const seconds = String(logEntry.timestamp.getSeconds()).padStart(2, '0')
          const milliseconds = String(logEntry.timestamp.getMilliseconds()).padStart(3, '0')

          time = `${hours}:${minutes}:${seconds}.${milliseconds}`
        }
      }

      return time
    },
    getMessage(logEntry) {
      let message = ''

      if (logEntry != null) {
        if (logEntry.message != null) {
          message = logEntry.message
        }
      }
      return message
    },
    buttonDoneClicked() {
      this.$emit('doneEvent')
    },
    async buttonDeleteAllLogEntriesClicked() {
      await this.$appManager.deleteLogEntries()
      await this.getLogEntries()
      this.rerender = this.rerender + 1
    }
  }
}
</script>

<style scoped>
.logging-dialog-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 340px;
  height: 90%;
  border-radius: 16px;
  background-color: var(--background-color-primary);
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 64px auto 52px;
  justify-items: stretch;
  align-items: center;
}

.logging-dialog-title {
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
  font-family: OpenSans;
  font-size: 17px;
  font-weight: 600;
  text-align: start;
  color: var(--text-color-primary);
  margin: 0 0 0 24px;
}

.logging-dialog-button-done {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: 600;
  margin: 0 24px 0 0;
}

.logging-dialog-button-done-not-pressed {
  color: var(--innoxel-blue-color);
}

.logging-dialog-button-done-pressed {
  color: var(--innoxel-blue-color-pressed);
}

.logging-dialog-content {
  grid-column: 1 / span 3;
  grid-row: 2 / span 1;
  justify-self: stretch;
  align-self: stretch;
  padding: 0 24px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  align-content: start;
  overflow-y: auto;
}

.logging-dialog-log-entries-containter {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  align-items: stretch;
  justify-items: stretch;
}

.logging-dialog-log-entries-date {
  grid-column: auto;
  grid-row: auto;
  font-family: OpenSans;
  font-weight: bold;
  font-size: 15px;
  color: var(--text-color-primary);
  margin: 10px 0;
}

.logging-dialog-log-entry {
  grid-column: auto;
  grid-row: auto;
  background-color: var(--background-color-secondary);
  margin: 4px 0;
  padding: 4px 4px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
}

.logging-dialog-log-entry-time {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  font-family: OpenSans;
  font-size: 12px;
  font-weight: 600;
  text-align: start;
  color: var(--text-color-primary);
  margin: 0 0 3px 0;
}

.logging-dialog-log-entry-id {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  font-family: OpenSans;
  font-size: 12px;
  font-weight: 600;
  text-align: end;
  color: var(--text-color-primary);
  margin: 0 0 3px 0;
}

.logging-dialog-log-entry-message {
  grid-column: 1 / span 2;
  grid-row: 2 / span 1;
  font-family: OpenSans;
  font-size: 12px;
  font-weight: 600;
  text-align: start;
  color: var(--text-color-primary);
  white-space: pre-wrap;
}

.logging-dialog-button-delete-all-log-entries {
  grid-column: 1 / span 3;
  grid-row: 3 / span 1;
  justify-self: center;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: 600;
  margin: 0 18px 0 0;
}

.logging-dialog-button-delete-all-log-entries-not-pressed {
  color: var(--innoxel-blue-color);
}

.logging-dialog-button-delete-all-log-entries-pressed {
  color: var(--innoxel-blue-color-pressed);
}

@media (min-width: 700px) {
  .logging-dialog-container {
    width: 680px;
    max-height: 520px;
  }

  .logging-dialog-title {
    grid-column: 1 / span 3;
    text-align: center;
  }
}
</style>
