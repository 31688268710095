<template>
  <div class="license-key-view-container">
    <img
      v-if="getUsedTheme === 'dark-theme'"
      class="license-key-view-logo"
      src="@/assets/splashscreen/dark-theme/innoxel-logo-white.svg"
    />
    <img v-else class="license-key-view-logo" src="@/assets/splashscreen/light-theme/innoxel-logo-black.svg" />
    <div class="license-key-view-input-field-container">
      <div class="license-key-view-label">Bitte geben Sie den Lizenz-Schlüssel ein:</div>
      <input
        class="license-key-view-input-field"
        placeholder="XXXX-XXXX-XXXX-XXXX-XXXX-XXXX-XXXX"
        v-model="licenseKey"
      />
      <div
        v-if="licenseKeyIsValid"
        class="license-key-view-apply-button"
        :class="
          isButtonApplyPressed ? 'license-key-view-apply-button-pressed' : 'license-key-view-apply-button-not-pressed'
        "
        @mousedown.stop.passive="isButtonApplyPressed = true"
        @mouseup.stop.passive="isButtonApplyPressed = false"
        @mouseleave.stop.passive="isButtonApplyPressed = false"
        @touchstart.stop.passive="isButtonApplyPressed = true"
        @touchend.stop.passive="isButtonApplyPressed = false"
        @touchcancel.stop.passive="isButtonApplyPressed = false"
        @click.stop.passive="buttonApplyClicked"
      >
        Übernehmen
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LicenseKeyView',
  data() {
    return {
      protocol: window.location.protocol,
      ipAddress: window.location.hostname,
      port: window.location.port,
      licenseKey: '',
      isButtonApplyPressed: false
    }
  },
  computed: {
    ...mapGetters(['getUsedTheme']),
    licenseKeyIsValid() {
      return this.licenseKey.match(
        /^[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}$/
      )
    }
  },
  methods: {
    buttonApplyClicked() {
      const url = `${this.protocol}//${this.ipAddress}:${this.port}/maintenance/setlicensekey?licensekey=${this.licenseKey}`
      window.open(url, '_self')
    }
  }
}
</script>

<style scoped>
.license-key-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: var(--background-color-primary);
  position: fixed;
}

.license-key-view-logo {
  width: 300px;
}

.license-key-view-input-field-container {
  display: grid;
  grid-template-columns: 300px;
  grid-template-rows: 25px 25px 40px;
  margin: 50px 0 0 0;
}

.license-key-view-label {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  align-self: center;
  justify-self: center;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  color: var(--text-color-primary);
}

.license-key-view-input-field {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: black;
}

.license-key-view-apply-button {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
  align-self: center;
  justify-self: center;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: 600;
}

.license-key-view-apply-button-not-pressed {
  color: var(--innoxel-blue-color);
}

.license-key-view-apply-button-pressed {
  color: var(--innoxel-blue-color-pressed);
}
</style>
