<template>
  <div class="brightness-element-container">
    <img
      v-if="getUsedTheme === 'dark-theme'"
      class="brightness-element-icon"
      src="@/assets/widgets/brightnesselement/dark-theme/brightness.svg"
    />
    <img v-else class="brightness-element-icon" src="@/assets/widgets/brightnesselement/light-theme/brightness.svg" />
    <div class="brightness-element-label-brightness">{{ $t('brightness') }}</div>
    <div class="brightness-element-label-east">{{ $t('east') }}:</div>
    <div class="brightness-element-value-east">{{ sunBrightnessEast }}</div>
    <div class="brightness-element-unit-east">kLux</div>
    <div class="brightness-element-label-south">{{ $t('south') }}:</div>
    <div class="brightness-element-value-south">{{ sunBrightnessSouth }}</div>
    <div class="brightness-element-unit-south">kLux</div>
    <div class="brightness-element-label-west">{{ $t('west') }}:</div>
    <div class="brightness-element-value-west">{{ sunBrightnessWest }}</div>
    <div class="brightness-element-unit-west">kLux</div>
    <div class="brightness-element-label-twilight">{{ $t('twilight') }}:</div>
    <div v-if="sunTwilightAsValue" class="brightness-element-value-twilight">{{ sunTwilightValue }}</div>
    <div v-if="sunTwilightAsValue" class="brightness-element-unit-twilight">Lux</div>
    <div v-else class="brightness-element-value-as-text-twilight">{{ sunTwilightText }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from '@/i18n'

export default {
  name: 'BrightnessElement',
  props: {
    brightnessData: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    ...mapGetters(['getUsedTheme']),
    sunBrightnessEast() {
      let sunBrightnessEast = '-'

      if (this.brightnessData != null) {
        if (this.brightnessData.sunBrightnessEastUnit === 'Lux') {
          sunBrightnessEast = 0.001 * Number(this.brightnessData.sunBrightnessEastValue)
          sunBrightnessEast = Number(sunBrightnessEast).toFixed(1)
        }
      }

      return sunBrightnessEast
    },
    sunBrightnessSouth() {
      let sunBrightnessSouth = '-'
      if (this.brightnessData != null) {
        if (this.brightnessData.sunBrightnessSouthUnit === 'Lux') {
          sunBrightnessSouth = 0.001 * Number(this.brightnessData.sunBrightnessSouthValue)
          sunBrightnessSouth = Number(sunBrightnessSouth).toFixed(1)
        }
      }

      return sunBrightnessSouth
    },
    sunBrightnessWest() {
      let sunBrightnessWest = '-'
      if (this.brightnessData != null) {
        if (this.brightnessData.sunBrightnessWestUnit === 'Lux') {
          sunBrightnessWest = 0.001 * Number(this.brightnessData.sunBrightnessWestValue)
          sunBrightnessWest = Number(sunBrightnessWest).toFixed(1)
        }
      }

      return sunBrightnessWest
    },
    sunTwilightAsValue() {
      let sunTwilightAsValue = false

      if (this.brightnessData != null) {
        if (this.brightnessData.sunTwilightValue != null && this.brightnessData.sunTwilightUnit != null) {
          if (this.brightnessData.sunTwilightUnit === 'Lux') {
            const sunTwilightValue = Number(this.brightnessData.sunTwilightValue)

            if (sunTwilightValue >= 1 && sunTwilightValue < 245) {
              sunTwilightAsValue = true
            }
          }
        }
      }

      return sunTwilightAsValue
    },
    sunTwilightValue() {
      let sunTwilight = '-'

      if (this.brightnessData != null) {
        if (this.brightnessData.sunTwilightValue != null && this.brightnessData.sunTwilightUnit != null) {
          if (this.brightnessData.sunTwilightUnit === 'Lux') {
            const sunTwilightValue = Number(this.brightnessData.sunTwilightValue)

            // The following checks will be used when different icons
            // for the sun twilight are shown (see old web app)
            if (sunTwilightValue >= 1 && sunTwilightValue < 5) {
              sunTwilight = Number(sunTwilightValue).toFixed(0)
            }

            if (sunTwilightValue >= 5 && sunTwilightValue < 240) {
              sunTwilight = Number(sunTwilightValue).toFixed(0)
            }

            if (sunTwilightValue >= 240 && sunTwilightValue < 245) {
              sunTwilight = Number(sunTwilightValue).toFixed(0)
            }
          }
        }
      }

      return sunTwilight
    },
    sunTwilightText() {
      let sunTwilight = '-'

      if (this.brightnessData != null) {
        if (this.brightnessData.sunTwilightValue != null && this.brightnessData.sunTwilightUnit != null) {
          if (this.brightnessData.sunTwilightUnit === 'Lux') {
            const sunTwilightValue = Number(this.brightnessData.sunTwilightValue)

            if (sunTwilightValue < 1) {
              sunTwilight = i18n.global.t('night')
            }

            if (sunTwilightValue >= 245) {
              sunTwilight = i18n.global.t('day')
            }
          }
        }
      }

      return sunTwilight
    }
  }
}
</script>

<style scoped>
.brightness-element-container {
  display: grid;
  grid-template-columns: 40px auto 50px 36px;
  grid-template-rows: 40px 25px 25px 25px 5px 25px auto;
  align-items: baseline;
}

.brightness-element-icon {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  align-self: stretch;
  margin: 6px 12px 10px 4px;
}

.brightness-element-label-brightness {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  justify-self: start;
  font-family: OpenSans;
  font-size: 24px;
  font-weight: 600;
  color: var(--text-color-primary);
}

.brightness-element-label-east {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.brightness-element-value-east {
  grid-column: 3 / span 1;
  grid-row: 2 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: bold;
  color: var(--text-color-primary);
}

.brightness-element-unit-east {
  grid-column: 4 / span 1;
  grid-row: 2 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.brightness-element-label-south {
  grid-column: 2 / span 1;
  grid-row: 3 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.brightness-element-value-south {
  grid-column: 3 / span 1;
  grid-row: 3 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: bold;
  color: var(--text-color-primary);
}

.brightness-element-unit-south {
  grid-column: 4 / span 1;
  grid-row: 3 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.brightness-element-label-west {
  grid-column: 2 / span 1;
  grid-row: 4 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.brightness-element-value-west {
  grid-column: 3 / span 1;
  grid-row: 4 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: bold;
  color: var(--text-color-primary);
}

.brightness-element-unit-west {
  grid-column: 4 / span 1;
  grid-row: 4 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.brightness-element-label-twilight {
  grid-column: 1 / span 2;
  grid-row: 6 / span 1;
  align-self: end;
  justify-self: end;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.brightness-element-value-twilight {
  grid-column: 3 / span 1;
  grid-row: 6 / span 1;
  align-self: end;
  justify-self: end;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: bold;
  color: var(--text-color-primary);
}

.brightness-element-unit-twilight {
  grid-column: 4 / span 1;
  grid-row: 6 / span 1;
  align-self: end;
  justify-self: end;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.brightness-element-value-as-text-twilight {
  grid-column: 3 / span 2;
  grid-row: 6 / span 1;
  align-self: end;
  justify-self: end;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: bold;
  color: var(--text-color-primary);
}

@media (min-width: 800px) {
  .brightness-element-container {
    grid-template-columns: 50px auto 50px 38px;
    grid-template-rows: 50px 25px 25px 25px 10px 25px auto;
  }

  .brightness-element-label-brightness {
    grid-column: 2 / span 3;
    font-size: 32px;
  }
}
</style>
