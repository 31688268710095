<template>
  <div
    class="range-slider-container"
    @mousedown.stop.passive
    @mouseup.stop.passive
    @mouseleave.stop.passive="valueReleased"
    @touchstart.stop.passive
    @touchend.stop.passive="valueReleased"
    @touchcancel.stop.passive
    @click.stop.passive
  >
    <input
      class="range-slider-input"
      :disabled="locked"
      type="range"
      min="0"
      max="100"
      step="1"
      v-model="sliderValue"
      @input="valueChanged"
    />
    <div v-if="animated" class="range-slider-animation-container" :style="styleAnimatedSliderThumb">
      <div class="range-slider-animation"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RangeSlider',
  props: {
    value: {
      type: Number,
      required: false,
      default: 0
    },
    animateSliderThumb: {
      type: Boolean,
      required: false,
      default: false
    },
    locked: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['valueChangedEvent', 'valueReleasedEvent'],
  data() {
    return {
      sliderValue: this.value,
      animated: this.animateSliderThumb
    }
  },
  mounted() {
    this.valueChanged()
  },
  watch: {
    value() {
      this.sliderValue = this.value
    },
    animateSliderThumb() {
      this.animated = this.animateSliderThumb
    }
  },
  computed: {
    styleAnimatedSliderThumb() {
      const levtValue = this.sliderValue
      const negativeLeftValue = -1 * levtValue
      const retVal = `left: ${levtValue}%; transform: translate(${negativeLeftValue}%);`
      return retVal
    }
  },
  methods: {
    valueChanged() {
      this.$emit('valueChangedEvent', parseInt(this.sliderValue))
    },
    valueReleased() {
      this.$emit('valueReleasedEvent', parseInt(this.sliderValue))
    }
  }
}
</script>

<style scoped>
.range-slider-container {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.range-slider-input {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  border-radius: 1px;
  background-color: var(--range-slider-line-color);
}

.range-slider-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: solid 3px var(--range-slider-thumb-line-color);
  background-color: var(--range-slider-thumb-fill-color);
}

.range-slider-input::-moz-range-thumb {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: solid 3px var(--range-slider-thumb-line-color);
  background-color: var(--range-slider-thumb-fill-color);
  box-sizing: border-box;
}

.range-slider-animation-container {
  position: absolute;
  width: 32px;
  height: 32px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  pointer-events: none;
}

.range-slider-animation {
  background-color: rgba(0, 0, 0, 0.3);
  height: 4px;
  width: 4px;
  border-radius: 50%;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
  }

  50% {
    box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.3);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
  }
}
</style>
