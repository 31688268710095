const settings = {
  state: {
    favoriteStarEnabled: true,
    appearances: ['light', 'dark', 'system'],
    selectedAppearance: 'light',
    usedTheme: 'light-theme',
    placeholderForWebUiMenuEnabled: false,
    editModeEnabled: false,
    languages: [
      { name: 'Deutsch', code: 'de' },
      { name: 'Français (Beta)', code: 'fr' },
      { name: 'Italiano (Beta)', code: 'it' },
      { name: 'English (Beta)', code: 'en' }
    ],
    selectedLanguageCode: 'de'
  },
  getters: {
    isFavoriteStarEnabled: state => state.favoriteStarEnabled,
    getAppearances: state => state.appearances,
    getSelectedAppearance: state => state.selectedAppearance,
    getUsedTheme: state => state.usedTheme,
    isEditModeEnabled: state => state.editModeEnabled,
    isPlaceholderForWebUiMenuEnabled: state => state.placeholderForWebUiMenuEnabled,
    getLanguages: state => state.languages,
    getSelectedLanguageCode: state => state.selectedLanguageCode
  },
  mutations: {
    enableFavoriteStar(state, enable) {
      state.favoriteStarEnabled = enable
    },
    setAppearance(state, appearance) {
      state.selectedAppearance = appearance
    },
    setUsedTheme(state, theme) {
      state.usedTheme = theme
    },
    enableEditMode(state, enable) {
      state.editModeEnabled = enable
    },
    enablePlaceholderForWebUiMenu(state, enable) {
      state.placeholderForWebUiMenuEnabled = enable
    },
    setLanguageCode(state, code) {
      state.selectedLanguageCode = code
    }
  }
}

export default settings
