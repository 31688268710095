/**
 * The class ConfigurationInformationAccessor is used for accessing the 'Configuration
 * Information' stored in the appropriate object store (IndexedDB).
 */
class ConfigurationInformationAccessor {
  /**
   * The constructor of this class stores the references to the database and to
   * the object store containing the 'Configuration Information'.
   *
   * @param {any} database Reference to database.
   * @param {any} objectStore Reference to object store containing 'Configuration Information'.
   */
  constructor(database, objectStore) {
    this.database = database
    this.objectStore = objectStore
  }

  /**
   * This method writes the given 'Configuration Information' to the object store. 'Configuration
   * Information' with the same ID are overwritten.
   *
   *  - Writing succeed: Returns empty Promise resolve.
   *  - Writing failed:  Returns Promise reject containing error message.
   *
   * @param {any} configurationInformation 'Configuration Information' to be stored to object store.
   * @returns Promise containing result.
   */
  async write(configurationInformation) {
    return new Promise((resolve, reject) => {
      if (this.database != null && this.objectStore != null) {
        // Database and object store are valid

        // Create transaction for writing 'Configuration Information'
        const transaction = this.database.transaction(this.objectStore, 'readwrite')

        // Get object store for writing 'Configuration Information'
        const objectStore = transaction.objectStore(this.objectStore)

        // Put 'Configuration Information' to object store
        objectStore.put(configurationInformation)

        /**
         * This event handler is called when writing 'Configuration Information'
         * to the object store succeed. It returns an empty Promise resolve.
         */
        transaction.oncomplete = () => {
          resolve()
        }

        /**
         * This event handler is called when writing 'Configuration Information' to the
         * object store failed. This event handler returns a Promise reject containing
         * the error message.
         *
         * @param {Event} event Generated by objectStore.put()
         */
        transaction.onerror = event => {
          const errorMessage = `Error: Writing configuration information failed! (${event.target.error})`
          reject(errorMessage)
        }
      } else {
        // Database and/or object store are invalid
        const errorMessage = 'Error: Writing configuration information failed! Invalid database or object store.'
        reject(errorMessage)
      }
    })
  }

  /**
   * This method reads the 'Configuration Information' from the object store.
   *
   *  - Reading succeed: Returns Promise resolve containing stored 'Configuration Information'.
   *  - Reading failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async read() {
    return new Promise((resolve, reject) => {
      if (this.database != null && this.objectStore != null) {
        // Database and object store are valid

        // Create transaction for reading 'Configuration Information'
        const transaction = this.database.transaction(this.objectStore, 'readonly')

        // Get object store for reading 'Configuration Information'
        const objectStore = transaction.objectStore(this.objectStore)

        // Get 'Configuration Information' from object store
        const request = objectStore.getAll()

        /**
         * This event handler is called when reading the 'Configuration Information'
         * from the object store succeed. This event handler returns a Promise resolve
         * containing the 'Configuration Information'.
         *
         * @param {Event} event Generated by objectStore.getAll()
         */
        request.onsuccess = event => {
          let configurationInformation = null

          // Get 'Configuration Information' stored in event
          if (event.target.result.length > 0) {
            configurationInformation = event.target.result[0]
          }

          resolve(configurationInformation)
        }

        /**
         * This event handler is called when reading the 'Configuration Information'
         * from the object store failed. This event handler returns a Promise reject
         * containing the error message.
         *
         * @param {Event} event Generated by objectStore.getAll()
         */
        request.onerror = event => {
          const errorMessage = `Error: Reading configuration information failed! (${event.target.error})`
          reject(errorMessage)
        }
      } else {
        // Database and/or object store are invalid
        const errorMessage = 'Error: Reading configuration information failed! Invalid database or object store.'
        reject(errorMessage)
      }
    })
  }
}

export default ConfigurationInformationAccessor
