<template>
  <div class="global-view-container">
    <div class="global-view-header">{{ $t('global') }}</div>
    <div v-show="isPlaceholderForWebUiMenuEnabled" class="global-view-placeholder-webui-menu" />
    <div v-show="isConnectionLost" class="global-view-connection-lost">
      <connection-lost @tryToReconnectEvent="tryToReconnect" />
    </div>
    <div id="scrollable-global-view" v-show="!isConnectionLost" class="global-view-main">
      <div v-if="idsOfGlobalTimers.length > 0">
        <control-elements :idsOfControlElements="idsOfGlobalTimers" :title="getTextForTimers" />
      </div>
      <div v-if="idsOfGlobalScenes.length > 0">
        <control-elements :idsOfControlElements="idsOfGlobalScenes" :title="getTextForScenes" />
      </div>
      <div v-if="idsOfGlobalFunctions.length > 0">
        <control-elements :idsOfControlElements="idsOfGlobalFunctions" :title="getTextForFunctions" />
      </div>
      <div v-if="showScrolledToBottomSpace" class="global-view-scrolled-to-bottom-space" />
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n'
import { mapGetters } from 'vuex'
import ControlElements from '@/ui/components/ControlElements.vue'
import ConnectionLost from '@/ui/components/ConnectionLost.vue'

export default {
  name: 'GlobalView',
  components: { ControlElements, ConnectionLost },
  emits: ['tryToReconnectEvent'],
  data() {
    return {
      elementScrollableGlobalView: null,
      showScrolledToBottomSpace: false,
      idsOfGlobalTimers: [],
      idsOfGlobalScenes: [],
      idsOfGlobalFunctions: []
    }
  },
  beforeMount() {
    this.idsOfGlobalTimers = this.$appManager.getIdsOfGlobalTimers()
    this.idsOfGlobalScenes = this.$appManager.getIdsOfGlobalScenes()
    this.idsOfGlobalFunctions = this.$appManager.getIdsOfGlobalFunctions()
  },
  mounted() {
    this.$appManager.getLatestStates()

    this.elementScrollableGlobalView = document.getElementById('scrollable-global-view')

    if (this.elementScrollableGlobalView != null) {
      this.elementScrollableGlobalView.onscroll = () => {
        this.enableScrolledToBottomSpace()
      }

      this.elementScrollableGlobalView.scrollTop = 0
    }
  },
  computed: {
    ...mapGetters(['isPlaceholderForWebUiMenuEnabled', 'isConnectionLost']),
    getTextForTimers() {
      return i18n.global.t('timers')
    },
    getTextForScenes() {
      return i18n.global.t('scenes')
    },
    getTextForFunctions() {
      return i18n.global.t('functions')
    }
  },
  methods: {
    enableScrolledToBottomSpace() {
      if (this.elementScrollableGlobalView != null) {
        const scrollTop = this.elementScrollableGlobalView.scrollTop
        const invisiblePart =
          this.elementScrollableGlobalView.scrollHeight - this.elementScrollableGlobalView.clientHeight
        const scrollbarPosition = (scrollTop / invisiblePart) * 100

        this.showScrolledToBottomSpace = scrollbarPosition > 0
      }
    },
    tryToReconnect() {
      this.$emit('tryToReconnectEvent')
    }
  }
}
</script>

<style scoped>
.global-view-container {
  display: grid;
  grid-template-columns: 1fr 55px;
  grid-template-rows: 50px auto auto;
  background-color: var(--background-color-secondary);
}

.global-view-header {
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-primary);
  background-color: var(--background-color-primary);
}

.global-view-placeholder-webui-menu {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  align-self: stretch;
  justify-self: stretch;
  border-top: solid var(--background-color-primary) 19px;
  border-right: solid var(--background-color-primary) 13px;
  border-bottom: solid var(--background-color-primary) 5px;
  border-left: solid var(--background-color-primary) 16px;
  background-color: var(--background-color-secondary);
}

.global-view-connection-lost {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  align-self: center;
}

.global-view-main {
  grid-column: 1 / span 2;
  grid-row: 2 / span 1;
  overflow-y: auto;
  margin: 1px 0;
}

.global-view-scrolled-to-bottom-space {
  grid-column: 1 / span 2;
  grid-row: 3 / span 1;
  height: 35px;
}
</style>
