<template>
  <div class="weather-element-container" :rerender="rerender">
    <temperature-element
      class="weather-element-temperature-element"
      :temperatureData="temperatureData"
      :precipitationData="precipitationData"
    />
    <wind-element class="weather-element-wind-element" :windData="windData" />
    <brightness-element class="weather-element-brightness-element" :brightnessData="brightnessData" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import TemperatureElement from '@/ui/components/widgets/TemperatureElement.vue'
import WindElement from '@/ui/components/widgets/WindElement.vue'
import BrightnessElement from '@/ui/components/widgets/BrightnessElement.vue'

export default {
  name: 'WeatherElement',
  components: { TemperatureElement, WindElement, BrightnessElement },
  props: {
    controlElementId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      countRerender: null,
      controlElement: null,
      temperatureData: null,
      precipitationData: null,
      windData: null,
      brightnessData: null
    }
  },
  beforeMount() {
    // Register control element in Vuex store to get feedback if
    // its property 'rerender' has changed
    this.registerControlElement()

    // Initialize 'Weather Element'
    this.initialize()
  },
  beforeUnmount() {
    // Unregister control element from Vuex store
    this.deregisterControlElement()
  },
  computed: {
    ...mapGetters(['getCurrentControlElement']),
    rerender() {
      if (this.countRerender !== this.controlElement.rerender) {
        this.update()
      }

      return this.controlElement.rerender
    }
  },
  methods: {
    ...mapMutations(['addCurrentControlElement', 'removeCurrentControlElement']),
    registerControlElement() {
      // Get control element from 'App Manager'
      const controlElement = this.$appManager.getControlElement(this.controlElementId)

      // Add control element to Vuex store
      this.addCurrentControlElement(controlElement)

      // Add to module list
      this.$appManager.addToModuleList(controlElement)
    },
    deregisterControlElement() {
      // Remove control element from Vuex store
      this.removeCurrentControlElement(this.controlElement)

      // Remove from module list
      this.$appManager.removeFromModuleList(this.controlElement)
    },
    initialize() {
      // Get control element from Vuex store to get feedback if
      // its property 'rerender' has changed
      this.controlElement = this.getCurrentControlElement(this.controlElementId)

      // Update 'Weather Element' with information from control element
      this.update()
    },
    update() {
      this.countRerender = this.controlElement.rerender

      if (this.controlElement.weatherData != null) {
        this.temperatureData = this.controlElement.weatherData.temperatureData
        this.precipitationData = this.controlElement.weatherData.precipitationData
        this.windData = this.controlElement.weatherData.windData
        this.brightnessData = this.controlElement.weatherData.brightnessData
      } else {
        this.temperatureData = null
        this.precipitationData = null
        this.windData = null
        this.brightnessData = null
      }
    }
  }
}
</script>

<style scoped>
.weather-element-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto auto;
  grid-row-gap: 25px;
  justify-items: stretch;
  background-color: var(--background-color-primary);
  padding: 10px 0 20px 0;
}

.weather-element-temperature-element {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
}

.weather-element-wind-element {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
}

.weather-element-brightness-element {
  grid-column: 2 / span 1;
  grid-row: 3 / span 1;
}

@media (min-width: 800px) {
  .weather-element-container {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    justify-items: center;
    align-items: start;
  }

  .weather-element-temperature-element {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
  }

  .weather-element-wind-element {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
  }

  .weather-element-brightness-element {
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
  }
}
</style>
