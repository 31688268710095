<template>
  <div class="ip-camera-element-full-screen-container" :rerender="rerender">
    <ip-camera
      class="ip-camera-element-full-screen-ip-camera"
      :id="getIpCameraId"
      :name="controlElement.name"
      :width="getWidth"
      :height="getHeight"
      :liveImageUrl="getLiveImageUrl"
      :liveVideoUrl="getLiveVideoUrl"
      :stopRunningVideo="getStopRunningVideo"
    />
    <div
      class="ip-camera-element-full-screen-button-scale-down"
      :class="isPressed ? 'ip-camera-element-full-screen-button-pressed-background' : ''"
      @mousedown.stop.passive="isPressed = true"
      @mouseup.stop.passive="isPressed = false"
      @mouseleave.stop.passive="isPressed = false"
      @touchstart.stop.passive="isPressed = true"
      @touchend.stop.passive="isPressed = false"
      @touchcancel.stop.passive="isPressed = false"
      @click.stop.passive="clickOnButtonScaleDown"
    >
      <img
        v-if="getUsedTheme === 'dark-theme'"
        class="ip-camera-element-full-screen-icon-scale-down"
        src="@/assets/widgets/ipvideoelement/dark-theme/scale-down.svg"
      />
      <img
        v-else
        class="ip-camera-element-full-screen-icon-scale-down"
        src="@/assets/widgets/ipvideoelement/light-theme/scale-down.svg"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IpCamera from '@/ui/components/widgets/IpCamera.vue'

export default {
  name: 'IpCameraElementFullScreen',
  components: { IpCamera },
  props: {
    controlElementId: {
      type: String,
      required: true
    }
  },
  emits: ['doneEvent'],
  data() {
    return {
      viewportWidth: window.innerWidth,
      viewportHeight: window.innerHeight,
      aspectRatio: 4 / 3,
      countRerender: null,
      controlElement: null,
      liveImageUrl: null,
      liveVideoUrl: null,
      isPressed: false,
      resizeTimer: null,
      stopRunningVideo: 0
    }
  },
  beforeMount() {
    // Initialize 'IP Camera Element Full Screen'
    this.initialize()
  },
  mounted() {
    window.addEventListener('resize', this.resizeHandler)
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  computed: {
    ...mapGetters(['getCurrentControlElement', 'getUsedTheme', 'getProtocol', 'getIpAddress', 'getPort']),
    rerender() {
      if (this.countRerender !== this.controlElement.rerender) {
        this.update()
      }

      return this.controlElement.rerender
    },
    getWidth() {
      let width

      if (this.viewportWidth / this.viewportHeight >= this.aspectRatio) {
        width = this.viewportHeight * this.aspectRatio
      } else {
        width = this.viewportWidth
      }

      return width
    },
    getHeight() {
      let height

      if (this.viewportWidth / this.viewportHeight >= this.aspectRatio) {
        height = this.viewportHeight
      } else {
        height = this.viewportWidth / this.aspectRatio
      }

      return height
    },
    getLiveImageUrl() {
      return this.liveImageUrl
    },
    getLiveVideoUrl() {
      return this.liveVideoUrl
    },
    getStopRunningVideo() {
      return this.stopRunningVideo
    },
    getIpCameraId() {
      return `${this.controlElementId}_fullscreen`
    }
  },
  methods: {
    initialize() {
      // Get control element from Vuex store to get feedback if
      // its property 'rerender' has changed
      this.controlElement = this.getCurrentControlElement(this.controlElementId)

      // Update 'IP Camera Element Full Screen' with information from control element
      this.update()
    },
    update() {
      this.countRerender = this.controlElement.rerender

      const baseUrl = `${this.getProtocol}//${this.getIpAddress}:${this.getPort}/proxy`
      const moduleIndex = this.controlElement.assignments[0].moduleIndex
      this.liveImageUrl = `${baseUrl}/jpg/liveimage?moduleindex=${moduleIndex}&resolution=640x480`
      this.liveVideoUrl = `${baseUrl}/mjpg/livevideo?moduleindex=${moduleIndex}&resolution=640x480` //&lifetime=120`
    },
    resizeHandler() {
      this.clearResizeTimer()
      this.runResizeTimer()
    },
    runResizeTimer() {
      let count = 0

      this.resizeTimer = setInterval(() => {
        if (count <= 20) {
          this.updateWidthAndHeight()
          count += 1
        } else {
          this.clearResizeTimer()
        }
      }, 50)
    },
    clearResizeTimer() {
      if (this.resizeTimer != null) {
        clearInterval(this.resizeTimer)
        this.resizeTimer = null
      }
    },
    updateWidthAndHeight() {
      if (this.viewportWidth != window.innerWidth || this.viewportHeight != window.innerHeight) {
        this.viewportWidth = window.innerWidth
        this.viewportHeight = window.innerHeight
      }
    },
    clickOnButtonScaleDown() {
      // Stop running video
      const date = new Date()
      this.stopRunningVideo = date.getTime()

      // Scale down video
      this.$emit('doneEvent')
    }
  }
}
</script>

<style scoped>
.ip-camera-element-full-screen-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background-color: var(--background-color-primary);
  z-index: 1;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  justify-items: stretch;
  align-items: stretch;
}

.ip-camera-element-full-screen-ip-camera {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  align-self: center;
  justify-self: center;
}

.ip-camera-element-full-screen-button-scale-down {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  width: 40px;
  height: 40px;
  border-radius: 9px;
  background: var(--ip-video-control-elements-background-color);
  border: solid 2px #fff;
  opacity: 0.78;
  margin: 0 8px 8px 0;
  display: grid;
  align-items: center;
  justify-items: center;
}

.ip-camera-element-full-screen-button-pressed-background {
  background: var(--ip-video-control-elements-button-pressed-color);
}

.ip-camera-element-full-screen-icon-scale-down {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
}
</style>
