class WindData {
  constructor() {
    this.windSpeedValue = null
    this.windSpeedUnit = null
  }

  setWindSpeed(value, unit) {
    this.windSpeedValue = value
    this.windSpeedUnit = unit
  }
}

export default WindData
