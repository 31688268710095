<template>
  <label class="toggle-switch-container">
    <input type="checkbox" v-model="checked" />
    <span class="toggle-switch-slider"></span>
  </label>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    enable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['toggleEvent'],
  data() {
    return {
      checked: this.enable
    }
  },
  watch: {
    checked() {
      this.$emit('toggleEvent', this.checked)
    }
  }
}
</script>

<style scoped>
.toggle-switch-container {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

.toggle-switch-container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--toggle-switch-background-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-switch-slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: var(--background-color-primary);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle-switch-slider {
  background-color: var(--innoxel-blue-color);
}

input:checked + .toggle-switch-slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}
</style>
