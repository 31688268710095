<template>
  <div class="blind-element-settings-dialog-container" :rerender="rerender">
    <div class="blind-element-settings-dialog-title">
      {{ controlElement.name }}
    </div>
    <div
      class="blind-element-settings-dialog-button"
      :class="
        isButtonDonePressed
          ? 'blind-element-settings-dialog-button-pressed'
          : 'blind-element-settings-dialog-button-not-pressed'
      "
      @mousedown.stop.passive="isButtonDonePressed = true"
      @mouseup.stop.passive="isButtonDonePressed = false"
      @mouseleave.stop.passive="isButtonDonePressed = false"
      @touchstart.stop.passive="isButtonDonePressed = true"
      @touchend.stop.passive="isButtonDonePressed = false"
      @touchcancel.stop.passive="isButtonDonePressed = false"
      @click.stop.passive="buttonDoneClicked"
    >
      {{ $t('done') }}
    </div>
    <div class="blind-element-settings-dialog-content">
      <div
        class="blind-element-settings-dialog-lock-container"
        :class="isLocked ? 'blind-element-settings-dialog-lock-enabled' : 'blind-element-settings-dialog-lock-disabled'"
      >
        <img
          v-show="isLocked && getUsedTheme === 'dark-theme'"
          class="blind-element-settings-dialog-icon-warning"
          src="@/assets/widgets/blindelement/dark-theme/warning-active.svg"
        />
        <img
          v-show="!isLocked && getUsedTheme === 'dark-theme'"
          class="blind-element-settings-dialog-icon-warning"
          src="@/assets/widgets/blindelement/dark-theme/warning-inactive.svg"
        />
        <img
          v-show="isLocked && getUsedTheme === 'light-theme'"
          class="blind-element-settings-dialog-icon-warning"
          src="@/assets/widgets/blindelement/light-theme/warning-active.svg"
        />
        <img
          v-show="!isLocked && getUsedTheme === 'light-theme'"
          class="blind-element-settings-dialog-icon-warning"
          src="@/assets/widgets/blindelement/light-theme/warning-inactive.svg"
        />
        <div
          v-show="isLocked"
          class="blind-element-settings-dialog-text-lock blind-element-settings-dialog-text-lock-active"
        >
          {{ getTextForActiveLock }}
        </div>
        <div
          v-show="!isLocked"
          class="blind-element-settings-dialog-text-lock blind-element-settings-dialog-text-lock-not-active"
        >
          {{ $t('noOptionsActive') }}
        </div>
      </div>
      <div class="blind-element-settings-dialog-current-blind-position">{{ blindPosition }}%</div>
      <slider
        class="blind-element-settings-dialog-slider"
        :usedTheme="getUsedTheme"
        :animateSliderThumb="animate"
        :locked="isLocked"
        :initialValue="blindPosition"
        :valueTouchAreaLeft="0"
        :valueTouchAreaRight="100"
        :iconTouchAreaLeftLightMode="iconBlindUpLightMode"
        :iconTouchAreaRightLightMode="iconBlindDownLightMode"
        :iconTouchAreaLeftDarkMode="iconBlindUpDarkMode"
        :iconTouchAreaRightDarkMode="iconBlindDownDarkMode"
        @valueChangedEvent="updateChangedBlindPosition"
        @valueReleasedEvent="updateReleasedBlindPosition"
      />
      <div class="blind-element-settings-dialog-current-slats-angle">{{ slatsAngle }}°</div>
      <blind-element-slats-control
        class="blind-element-settings-slats-control"
        :locked="isLocked"
        :initialSlatsAngle="slatsAngle"
        :maximumSlatsAngle="90"
        @valueChangedEvent="updateChangedSlatsAngle"
        @valueReleasedEvent="updateReleasedSlatsAngle"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from '@/i18n'
import Slider from '@/ui/components/controlelements/common/Slider.vue'
import BlindElementSlatsControl from '@/ui/components/controlelements/blindelement/BlindElementSlatsControl.vue'

export default {
  name: 'BlindElementSettingsDialog',
  components: { Slider, BlindElementSlatsControl },
  props: {
    controlElementId: {
      type: String,
      required: true
    }
  },
  emits: ['doneEvent'],
  data() {
    return {
      currentAction: null,
      relativePositionChangedLocally: false,
      targetRelativePosition: 0,
      actualRelativePosition: 0,
      relativeTiltChangedLocally: false,
      targetRelativeTilt: 0,
      actualRelativeTilt: 0,
      controlElement: null,
      animate: false,
      blindIsMoving: false,
      slatsAreMoving: false,
      lowerLimitEnabled: false,
      cleaningLockEnabled: false,
      weatherLockEnabled: false,
      lockoutProtectionEnabled: false,
      manuallyOverriddenEnabled: false,
      isButtonDonePressed: false,
      iconBlindDownLightMode: require('@/assets/widgets/blindelement/light-theme/blind-down.svg'),
      iconBlindUpLightMode: require('@/assets/widgets/blindelement/light-theme/blind-up.svg'),
      iconBlindDownDarkMode: require('@/assets/widgets/blindelement/dark-theme/blind-down.svg'),
      iconBlindUpDarkMode: require('@/assets/widgets/blindelement/dark-theme/blind-up.svg')
    }
  },
  beforeMount() {
    // Initialize 'Blind Element'
    this.initialize()
  },
  computed: {
    ...mapGetters(['getCurrentControlElement', 'getUsedTheme']),
    rerender() {
      if (this.countRerender !== this.controlElement.rerender) {
        this.update()
      }

      return this.controlElement.rerender
    },
    isLocked() {
      return (
        this.lowerLimitEnabled ||
        this.cleaningLockEnabled ||
        this.weatherLockEnabled ||
        this.lockoutProtectionEnabled ||
        this.manuallyOverriddenEnabled
      )
    },
    getTextForActiveLock() {
      let text

      if (this.manuallyOverriddenEnabled) {
        text = i18n.global.t('manuallyOverridden')
      } else {
        text = i18n.global.t('activeOptions') + ':'

        if (this.cleaningLockEnabled) {
          text = text + '\n - ' + i18n.global.t('cleaningLock')
        }

        if (this.weatherLockEnabled) {
          text = text + '\n - ' + i18n.global.t('weatherLock')
        }

        if (this.lockoutProtectionEnabled) {
          text = text + '\n - ' + i18n.global.t('lockoutProtection')
        }

        if (this.lowerLimitEnabled) {
          text = text + '\n - ' + i18n.global.t('driveLimitation')
        }
      }

      return text
    },
    blindPosition() {
      let blindPosition

      if (this.relativePositionChangedLocally) {
        blindPosition = Math.round(this.targetRelativePosition / 10)
      } else {
        blindPosition = Math.round(this.actualRelativePosition / 10)
      }

      return blindPosition
    },
    slatsAngle() {
      let slatsAngle

      if (this.relativeTiltChangedLocally) {
        const convertedRelativeTilt = (this.targetRelativeTilt - 1000) * -1
        slatsAngle = Math.round(convertedRelativeTilt * 0.09)
      } else {
        const convertedRelativeTilt = (this.actualRelativeTilt - 1000) * -1
        slatsAngle = Math.round(convertedRelativeTilt * 0.09)
      }

      return slatsAngle
    }
  },
  methods: {
    initialize() {
      // Get control element from Vuex store to get feedback if
      // its property 'rerender' has changed
      this.controlElement = this.getCurrentControlElement(this.controlElementId)

      // Update 'Blind Element' with information from control element
      this.update()
    },
    update() {
      this.countRerender = this.controlElement.rerender
      this.currentAction = this.controlElement.currentAction
      this.actualRelativePosition = this.controlElement.relativePosition
      this.actualRelativeTilt = this.controlElement.relativeTilt
      this.lowerLimitEnabled = this.controlElement.lowerLimitEnabled
      this.cleaningLockEnabled = this.controlElement.cleaningLockEnabled
      this.weatherLockEnabled = this.controlElement.weatherLockEnabled
      this.lockoutProtectionEnabled = this.controlElement.lockoutProtectionEnabled
      this.manuallyOverriddenEnabled = this.controlElement.manuallyOverriddenEnabled

      if (this.relativePositionChangedLocally || this.relativeTiltChangedLocally) {
        if (this.actualRelativePosition == this.targetRelativePosition) {
          this.blindIsMoving = false
          this.relativePositionChangedLocally = false
        }

        if (this.relativeTiltChangedLocally) {
          const difference = Math.abs(Number(this.actualRelativeTilt) - Number(this.targetRelativeTilt))

          if (difference <= 1) {
            this.slatsAreMoving = false
            this.relativeTiltChangedLocally = false
            this.targetRelativeTilt = this.actualRelativeTilt
          }
        }

        this.animate = this.blindIsMoving || this.slatsAreMoving
      } else {
        switch (this.currentAction) {
          case 'movingDown':
          case 'movingDownAndTilting':
          case 'movingUp':
          case 'movingUpAndTilting':
            this.animate = true
            break
          default:
            this.animate = false
            break
        }

        this.targetRelativePosition = this.actualRelativePosition
        this.targetRelativeTilt = this.actualRelativeTilt
      }
    },
    buttonDoneClicked() {
      this.$emit('doneEvent')
    },
    updateChangedBlindPosition(value) {
      // Set flag 'Relative Position Changed Locally'
      this.relativePositionChangedLocally = true

      // Calculate 'Relative Position'
      this.targetRelativePosition = Math.round(value * 10)
    },
    updateReleasedBlindPosition(value) {
      // Set flag 'Relative Position Changed Locally'
      this.relativePositionChangedLocally = true

      // Calculate 'Relative Position'
      this.targetRelativePosition = Math.round(value * 10)

      if (this.actualRelativePosition != this.targetRelativePosition) {
        // Slider shall animate until target position is reached
        this.blindIsMoving = true
        this.animate = true

        // Set value via 'Control Element' stored in 'App Manager'
        // to send it to Innoxel Master 3
        this.$appManager.setRelativePosition(this.controlElementId, this.targetRelativePosition)
      }
    },
    updateChangedSlatsAngle(value) {
      this.relativeTiltChangedLocally = true
      this.targetRelativeTilt = Math.round(1000 - value / 0.09)
    },
    updateReleasedSlatsAngle(value) {
      // Set flag 'Relative Tilt Changed Locally'
      this.relativeTiltChangedLocally = true

      // Calculate 'Relative Tilt'
      this.targetRelativeTilt = Math.round(1000 - value / 0.09)

      if (this.actualRelativeTilt != this.targetRelativeTilt) {
        // Slider shall animate until target tilt is reached
        this.slatsAreMoving = true
        this.animate = true

        // Set value via 'Control Element' stored in 'App Manager'
        // to send it to Innoxel Master 3
        this.$appManager.setRelativeTilt(this.controlElementId, this.targetRelativeTilt)
      }
    }
  }
}
</script>

<style scoped>
.blind-element-settings-dialog-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 340px;
  height: 90%;
  border-radius: 16px;
  background-color: var(--background-color-primary);
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 64px auto 16px;
  justify-items: stretch;
  align-items: center;
}

.blind-element-settings-dialog-title {
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
  font-family: OpenSans;
  font-size: 17px;
  font-weight: 600;
  text-align: start;
  color: var(--text-color-primary);
  margin: 0 0 0 24px;
}

.blind-element-settings-dialog-button {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  justify-self: end;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: 600;
  margin: 0 24px 0 0;
}

.blind-element-settings-dialog-button-not-pressed {
  color: var(--innoxel-blue-color);
}

.blind-element-settings-dialog-button-pressed {
  color: var(--innoxel-blue-color-pressed);
}

.blind-element-settings-dialog-content {
  grid-column: 1 / span 3;
  grid-row: 2 / span 1;
  justify-self: stretch;
  align-self: start;
  padding: 0 24px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 60px 60px 50px 120px;
  overflow-y: auto;
}

.blind-element-settings-dialog-lock-container {
  grid-column: 1 / span 3;
  grid-row: 1 / span 1;
  display: grid;
  grid-template-columns: 18px 54px auto 12px;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
  border-radius: 14px;
  padding: 12px 0;
}

.blind-element-settings-dialog-lock-enabled {
  background-color: var(--blind-element-settings-dialog-lock-enabled-color);
}

.blind-element-settings-dialog-lock-disabled {
  background-color: var(--blind-element-settings-dialog-lock-disabled-color);
}

.blind-element-settings-dialog-icon-warning {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  justify-self: start;
  align-self: center;
}

.blind-element-settings-dialog-text-lock {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  justify-self: start;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: 600;
  white-space: pre-wrap;
}

.blind-element-settings-dialog-text-lock-active {
  color: var(--blind-element-settings-dialog-text-lock-active-color);
}

.blind-element-settings-dialog-text-lock-not-active {
  color: var(--blind-element-settings-dialog-text-lock-not-active-color);
}

.blind-element-settings-dialog-current-blind-position {
  grid-column: 1 / span 3;
  grid-row: 2 / span 1;
  align-self: end;
  justify-self: center;
  font-family: OpenSans;
  font-size: 13px;
  font-weight: 600;
  color: var(--text-color-quaternary);
}

.blind-element-settings-dialog-slider {
  grid-column: 1 / span 3;
  grid-row: 3 / span 1;
}

.blind-element-settings-dialog-current-slats-angle {
  grid-column: 1 / span 3;
  grid-row: 4 / span 1;
  align-self: end;
  justify-self: center;
  font-family: OpenSans;
  font-size: 13px;
  font-weight: bold;
  color: var(--text-color-quaternary);
}

.blind-element-settings-slats-control {
  grid-column: 1 / span 3;
  grid-row: 5 / span 1;
  align-self: center;
  justify-self: center;
}

@media (min-width: 700px) {
  .blind-element-settings-dialog-container {
    width: 680px;
    max-height: 520px;
  }

  .blind-element-settings-dialog-title {
    grid-column: 1 / span 3;
    text-align: center;
  }

  .blind-element-settings-dialog-slider {
    margin: 0 20px;
  }
}
</style>
