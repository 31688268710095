//***********************************************************************************************************************************************************
// NOXnetSoapScript.js
// ===================
//
/// <summary>
///
/// Revision:  2.2.0.0
/// Date:      23.06.2021
/// Autor:     J�rn Rohrbach
/// Company:   ZidaTech AG, Fabrikstrasse 9, 4614 H�gendorf, Schweiz
/// Website:   www.innoxel.ch / www.zidatech.ch
///
/// Diese Javascript Bibliothek stellt Funktionen bereit, mit denen SOAP Actions an einen INNOXEL Master 3 gesendet werden k�nnen. Die Antworten werden als
/// SOAP Responses an frei definierbare Callback Funktionen zur�ckgegeben. Die Bibliothek stellt dar�berhinaus Funktionen zum Parsen des Inhalts von SOAP
/// Responses bereit.
///
/// Dieses Script ben�tigt Komponenten folgender Scripts:   -
///
/// @licstart  The following is the entire license notice for the JavaScript code in this page. 
/// 
/// Diese Javascript Bibliothek steht zur freien Verf�gung und darf beliebig verwendet oder ver�ndert werden. Die ZidaTech AG �bernimmt f�r diese Bibliothek
/// keine Garantie und es besteht kein Anspruch auf Support. Es besteht auch kein Anspruch auf Kompatibilit�t zu anderen Versionen dieser Bibliothek, oder
/// zu jeglicher Art von Webbrowsern oder JavaScript Interpretern, oder zu jeglicher Art von Hard- und Software von anderen Anbietern. �nderungen, die dem
/// technischen Fortschritt dienen, oder Fehler beheben, sind durch die ZidaTech AG jederzeit ohne Vorank�ndigung m�glich. Diese Bibliothek wird
/// ausschliesslich f�r das Geb�udeautomationssystem INNOXEL von ZidaTech AG zur Verf�gung gestellt, Kompatibilit�t zu anderen Systemen ist nicht 
/// vorgesehen und wird nicht garantiert. Die Verwendung dieser Bibliothek erfolgt ausdr�cklich auf Risiko des Anwenders. Die ZidaTech AG lehnt jegliche 
/// Haftung f�r Sch�den, die als Folge der Verwendung dieser Bibliothek entstehen, ab. Es gelten die allgemeinen Gesch�ftsbedingungen der ZidaTech AG.
/// 
/// Copyright (C) 2016 - 2021 ZidaTech AG
/// 
/// @licend  The above is the entire license notice for the JavaScript code in this page.
///
/// </summary>
//***********************************************************************************************************************************************************

import Area from '@/entities/area/area.js';
import Room from '@/entities/room/room.js';
import ControlElementSwitch from '@/entities/controlelements/switch/controlelementswitch.js';
import ControlElementSwitchConfiguration from '@/entities/controlelements/switch/controlelementswitchconfiguration.js'
import ControlElementDimmer from '@/entities/controlelements/dimmer/controlelementdimmer.js';
import ControlElementWeather from '@/entities/controlelements/weather/controlelementweather.js';
import ControlElementRoomClimate from '@/entities/controlelements/roomclimate/controlelementroomclimate.js'
import ControlElementBlind from '@/entities/controlelements/blind/controlelementblind.js'
import ControlElementIpCamera from '@/entities/controlelements/ipcamera/controlelementipcamera.js'
import Assignment from '@/entities/assignment/assignment.js';
import Confirmation from '@/entities/confirmation/confirmation.js';
import GlobalFunctions from '@/entities/globalfunctions/globalfunctions.js';
import GlobalTimers from '@/entities/globaltimers/globaltimers.js';
import GlobalScenes from '@/entities/globalscenes/globalscenes.js';
import RoomRegulations from '../entities/roomregulations/roomregulations';
import Home from '@/entities/home/home.js';
import Global from '@/entities/global/global.js';
import Climate from '@/entities/climate/climate.js';

//***********************************************************************************************************************************************************
// function NOXnetSoapIsAlive()
// ----------------------------
//
/// <summary>
/// Signalisiert dem Ladescript der zugrundeliegenden Webseite dass dieses Script geladen wurde (Dummy-Funktion).
/// </summary>
//***********************************************************************************************************************************************************

function NOXnetSoapIsAlive()
{
    return true;
}

//***********************************************************************************************************************************************************
// function NOXnetSoapForceReset(uri, callbackOnSuccess, callbackOnFailure, callbackState)
// ---------------------------------------------------------------------------------------
//
/// <summary>
/// Sendet einen SOAP Request, der an einem SOAP Server die Prozedur "forceReset" ausl�st. Diese Prozedur l�st einen Neustart des Servers oder einer 
/// Komponente aus. Wenn der Vorgang vom Server erfolgreich ausgef�hrt wird, wird der SOAP Body aus der Serverantwort an eine R�ckruffunktion �bergeben. 
/// Falls der Request scheitert, wird eine R�ckruffunktion f�r die Fehlerbehandlung aufgerufen. Wird eine R�ckruffunktion nicht ben�tigt, kann dem 
/// entsprechenden Argument auf null �bergeben werden. Wird dem Argument soapUri null �bergeben, wird die URI des SOAP Servers automatisch aus der 
/// Hostadresse des aktuellen Webfensters abgeleitet.
///
/// @requred_rights: Der INNOXEL Master 3 verlangt f�r diesen SOAP Request Administratorrechte.
/// </summary>
/// <remarks>
/// Der INNOXEL Master 3 f�hrt den Neustart sofort aus. Dies kann zur Folge haben, dass der SOAP Request nicht vollst�ndig an den Client gesendet wird. Der
/// Client muss deshalb einen Timeoutmechanismus implementieren muss robust reagieren wenn die Antwort verst�mmelt ankommt.
/// </remarks>
/// <param name="soapUri">URI eines SOAP Servers, oder null wenn die URI aus der Hostadresse des aktuellen Webfensters ermittelt wird.</param>
/// <param name="callbackOnSuccess">R�ckruffunktion f�r die SOAP Response, oder null wenn die SOAP Response nicht ben�tigt wird.</param>
/// <param name="callbackOnFailure">R�ckruffunktion f�r die Fehlerbehandlung, oder null wenn keine Fehlerbehandlung ben�tigt wird.</param>
/// <param name="callbackState">Objekt das der angeforderten R�ckrufmethode mitgegeben wird.</param>
//***********************************************************************************************************************************************************

function NOXnetSoapForceReset(soapUri, callbackOnSuccess, callbackOnFailure, callbackState)
{
    NOXnetSoapRequest(soapUri, 'forceReset', '<u:reset>device</u:reset>', callbackOnSuccess, callbackOnFailure, callbackState);
}

//***********************************************************************************************************************************************************
// function NOXnetSoapForceResetBackupSRAM(uri, callbackOnSuccess, callbackOnFailure, callbackState)
// -------------------------------------------------------------------------------------------------
//
/// <summary>
/// Sendet einen SOAP Request, der an einem SOAP Server die Prozedur "forceReset" mit dem Argument "backupSRAM" ausl�st. Diese Prozedur l�scht den Inhalt
/// des Backup SRAM. Wenn der Vorgang vom Server erfolgreich ausgef�hrt wird, wird der SOAP Body aus der Serverantwort an eine R�ckruffunktion �bergeben. 
/// Falls der Request scheitert, wird eine R�ckruffunktion f�r die Fehlerbehandlung aufgerufen. Wird eine R�ckruffunktion nicht ben�tigt, kann dem 
/// entsprechenden Argument auf null �bergeben werden. Wird dem Argument soapUri null �bergeben, wird die URI des SOAP Servers automatisch aus der 
/// Hostadresse des aktuellen Webfensters abgeleitet.
///
/// @requred_rights: Der INNOXEL Master 3 verlangt f�r diesen SOAP Request Administratorrechte.
/// </summary>
/// <remarks>
/// Aus dem Inhalt des Backup SRAM kann der INNOXEL Master 3 den Systemzustand vor einem Neustart, f�r alle Baugruppenkan�le die die Option "Zustand wie
/// vorher" aktiviert haben, wieder herstellen. Wird dieser Speicher gel�scht, wird allen betreffenden Baugruppenkan�len der Zustand "Aus" als letzter
/// aktueller Zustand zugewiesen.
/// </remarks>
/// <param name="soapUri">URI eines SOAP Servers, oder null wenn die URI aus der Hostadresse des aktuellen Webfensters ermittelt wird.</param>
/// <param name="callbackOnSuccess">R�ckruffunktion f�r die SOAP Response, oder null wenn die SOAP Response nicht ben�tigt wird.</param>
/// <param name="callbackOnFailure">R�ckruffunktion f�r die Fehlerbehandlung, oder null wenn keine Fehlerbehandlung ben�tigt wird.</param>
/// <param name="callbackState">Objekt das der angeforderten R�ckrufmethode mitgegeben wird.</param>
//***********************************************************************************************************************************************************

function NOXnetSoapForceResetBackupSRAM(soapUri, callbackOnSuccess, callbackOnFailure, callbackState)
{
    NOXnetSoapRequest(soapUri, 'forceReset', '<u:reset>backupSRAM</u:reset>', callbackOnSuccess, callbackOnFailure, callbackState);
}

//***********************************************************************************************************************************************************
// function NOXnetSoapGetDateTime(soapUri, callbackOnSuccess, callbackOnFailure, callbackState)
// --------------------------------------------------------------------------------------------
//
/// <summary>
/// Sendet einen SOAP Request, der an einem SOAP Server die Prozedur "getDateTime" ausl�st. Diese Prozedur fragt die aktuelle Systemzeit des Servers ab. Wenn
/// der Vorgang vom Server erfolgreich ausgef�hrt wird, wird der SOAP Body aus der Serverantwort an eine R�ckruffunktion �bergeben. Falls der Request 
/// scheitert, wird eine R�ckruffunktion f�r die Fehlerbehandlung aufgerufen. Wird eine R�ckruffunktion nicht ben�tigt, kann dem entsprechenden Argument null
/// �bergeben werden. Wird dem Argument soapUri null �bergeben, wird die URI des SOAP Servers automatisch aus der Hostadresse des aktuellen Webfensters 
/// abgeleitet.
///
/// @requred_rights: Der INNOXEL Master 3 f�hrt diesen SOAP Request f�r alle registrierten Benutzer aus.
/// </summary>
/// <param name="soapUri">URI eines SOAP Servers, oder null wenn die URI aus der Hostadresse des aktuellen Webfensters ermittelt wird.</param>
/// <param name="callbackOnSuccess">R�ckruffunktion f�r die SOAP Response, oder null wenn die SOAP Response nicht ben�tigt wird.</param>
/// <param name="callbackOnFailure">R�ckruffunktion f�r die Fehlerbehandlung, oder null wenn keine Fehlerbehandlung ben�tigt wird.</param>
/// <param name="callbackState">Objekt das der angeforderten R�ckrufmethode mitgegeben wird.</param>
//***********************************************************************************************************************************************************

function NOXnetSoapGetDateTime(soapUri, callbackOnSuccess, callbackOnFailure, callbackState)
{
    NOXnetSoapRequest(soapUri, 'getDateTime', null, callbackOnSuccess, callbackOnFailure, callbackState);
}

//***********************************************************************************************************************************************************
// function NOXnetSoapSetDateTime(dateTime, soapUri, callbackOnSuccess, callbackOnFailure, callbackState)
// ------------------------------------------------------------------------------------------------------
//
/// <summary>
/// Sendet einen SOAP Request, der an einem SOAP Server die Prozedur "setDateTime" ausl�st. Diese Prozedur setzt die Systemzeit des Servers auf das Datum und 
/// die Uhrzeit die im Argument dateTime mitgegeben wird. Wenn der Vorgang vom Server erfolgreich ausgef�hrt wird, wird der SOAP Body aus der Serverantwort 
/// an eine R�ckruffunktion �bergeben. Falls der Request scheitert, wird eine R�ckruffunktion f�r die Fehlerbehandlung aufgerufen. Wird eine R�ckruffunktion 
/// nicht ben�tigt, kann dem entsprechenden Argument auf null �bergeben werden. Wird dem Argument soapUri null �bergeben, wird die URI des SOAP Servers 
/// automatisch aus der Hostadresse des aktuellen Webfensters abgeleitet.
///
/// @requred_rights: Der INNOXEL Master 3 verlangt f�r diesen SOAP Request Administratorrechte.
/// </summary>
/// <param name="dateTime">JavaScript Date Objekt das ein Datum und eine Uhrzeit festlegt.</param>
/// <param name="soapUri">URI eines SOAP Servers, oder null wenn die URI aus der Hostadresse des aktuellen Webfensters ermittelt wird.</param>
/// <param name="callbackOnSuccess">R�ckruffunktion f�r die SOAP Response, oder null wenn die SOAP Response nicht ben�tigt wird.</param>
/// <param name="callbackOnFailure">R�ckruffunktion f�r die Fehlerbehandlung, oder null wenn keine Fehlerbehandlung ben�tigt wird.</param>
/// <param name="callbackState">Objekt das der angeforderten R�ckrufmethode mitgegeben wird.</param>
//***********************************************************************************************************************************************************

function NOXnetSoapSetDateTime(dateTime, soapUri, callbackOnSuccess, callbackOnFailure, callbackState)
{
    var dateString = new String();
    dateString += dateTime.getDate() < 10 ? '0' + dateTime.getDate() + '.' : dateTime.getDate() + '.';
    dateString += dateTime.getMonth() < 9 ? '0' + (dateTime.getMonth() + 1) + '.' : (dateTime.getMonth() + 1) + '.';
    dateString += dateTime.getFullYear();

    var timeString = new String();
    timeString += dateTime.getHours() < 10 ? '0' + dateTime.getHours() + ':' : dateTime.getHours() + ':';
    timeString += dateTime.getMinutes() < 10 ? '0' + dateTime.getMinutes() + ':' : dateTime.getMinutes() + ':';
    timeString += dateTime.getSeconds() < 10 ? '0' + dateTime.getSeconds() : dateTime.getSeconds();

    var args = '<u:date format="day.month.year">' + dateString + '</u:date>\r\n';
    args += '<u:time format="hour:minute:second">' + timeString + '</u:time>\r\n';
    args += '<u:dayOfWeek />\r\n';
    args += '<u:isDaylightSavingTime />\r\n';
    args += '<u:isLeapYear />\r\n';

    NOXnetSoapRequest(soapUri, 'setDateTime', args, callbackOnSuccess, callbackOnFailure, callbackState);
}

//***********************************************************************************************************************************************************
// function NOXnetSoapGetDeviceIdentityList(soapUri, callbackOnSuccess, callbackOnFailure, callbackState)
// ------------------------------------------------------------------------------------------------------
//
/// <summary>
/// Sendet einen SOAP Request, der an einem SOAP Server die Prozedur "getDeviceIdentityList" ausl�st. Diese Prozedur fragt die Identit�t des Servers ab. Die
/// Identit�t enth�lt eine UUID sowie weitere Informationen die den Server beschreiben. Wenn der Vorgang vom Server erfolgreich ausgef�hrt wird, wird der 
/// SOAP Body aus der Serverantwort an eine R�ckruffunktion �bergeben. Falls der Request scheitert, wird eine R�ckruffunktion f�r die Fehlerbehandlung 
/// aufgerufen. Wird eine R�ckruffunktion nicht ben�tigt, kann dem entsprechenden Argument auf null �bergeben werden. Wird dem Argument soapUri null 
/// �bergeben, wird die URI des SOAP Servers automatisch aus der Hostadresse des aktuellen Webfensters abgeleitet.
/// </summary>
/// <param name="soapUri">URI eines SOAP Servers, oder null wenn die URI aus der Hostadresse des aktuellen Webfensters ermittelt wird.</param>
/// <param name="callbackOnSuccess">R�ckruffunktion f�r die SOAP Response, oder null wenn die SOAP Response nicht ben�tigt wird.</param>
/// <param name="callbackOnFailure">R�ckruffunktion f�r die Fehlerbehandlung, oder null wenn keine Fehlerbehandlung ben�tigt wird.</param>
/// <param name="callbackState">Objekt das der angeforderten R�ckrufmethode mitgegeben wird.</param>
//***********************************************************************************************************************************************************

function NOXnetSoapGetDeviceIdentityList(soapUri, callbackOnSuccess, callbackOnFailure, callbackState)
{
    NOXnetSoapRequest(soapUri, 'getDeviceIdentityList', null, callbackOnSuccess, callbackOnFailure, callbackState);
}

//***********************************************************************************************************************************************************
// function NOXnetSoapGetDeviceStateList(uri, callbackOnSuccess, callbackOnFailure, callbackState)
// -----------------------------------------------------------------------------------------------
//
/// <summary>
/// Sendet einen SOAP Request, der an einem SOAP Server die Prozedur "getDeviceStateList" ausl�st. Diese Prozedur fragt am Server die aktuellen 
/// Betriebszust�nde von internen Hardwarekomponenten ab. Wenn der Vorgang vom Server erfolgreich ausgef�hrt wird, wird der SOAP Body aus der Antwort an eine 
/// R�ckruffunktion �bergeben. Falls der Request scheitert, wird eine R�ckruffunktion f�r die Fehlerbehandlung aufgerufen. Wird eine R�ckruffunktion nicht 
/// ben�tigt, kann dem entsprechenden Argument auf null �bergeben werden. Wird dem Argument soapUri null �bergeben, wird die URI des SOAP Servers automatisch
/// aus der Hostadresse des aktuellen Webfensters abgeleitet.
/// </summary>
/// <param name="soapUri">URI eines SOAP Servers, oder null wenn die URI aus der Hostadresse des aktuellen Webfensters ermittelt wird.</param>
/// <param name="callbackOnSuccess">R�ckruffunktion f�r die SOAP Response, oder null wenn die SOAP Response nicht ben�tigt wird.</param>
/// <param name="callbackOnFailure">R�ckruffunktion f�r die Fehlerbehandlung, oder null wenn keine Fehlerbehandlung ben�tigt wird.</param>
/// <param name="callbackState">Objekt das der angeforderten R�ckrufmethode mitgegeben wird.</param>
//***********************************************************************************************************************************************************

function NOXnetSoapGetDeviceStateList(soapUri, callbackOnSuccess, callbackOnFailure, callbackState)
{
    NOXnetSoapRequest(soapUri, 'getDeviceStateList', null, callbackOnSuccess, callbackOnFailure, callbackState);
}

//***********************************************************************************************************************************************************
// function NOXnetSoapGetDeviceVersionList(uri, callbackOnSuccess, callbackOnFailure, callbackState)
// -------------------------------------------------------------------------------------------------
//
/// <summary>
/// Sendet einen SOAP Request, der an einem SOAP Server die Prozedur "getDeviceVersionList" ausl�st. Diese Prozedur fragt am Server die aktuellen Versionen
/// von allen relevanten Hard- und Firmwarekomponenten ab. Wenn der Vorgang vom Server erfolgreich ausgef�hrt wird, wird der SOAP Body aus der Antwort an 
/// eine R�ckruffunktion �bergeben. Falls der Request scheitert, wird eine R�ckruffunktion f�r die Fehlerbehandlung aufgerufen. Wird eine R�ckruffunktion 
/// nicht ben�tigt, kann dem entsprechenden Argument auf null �bergeben werden. Wird dem Argument soapUri null �bergeben, wird die URI des SOAP Servers 
/// automatisch aus der Hostadresse des aktuellen Webfensters abgeleitet.
/// </summary>
/// <param name="soapUri">URI eines SOAP Servers, oder null wenn die URI aus der Hostadresse des aktuellen Webfensters ermittelt wird.</param>
/// <param name="callbackOnSuccess">R�ckruffunktion f�r die SOAP Response, oder null wenn die SOAP Response nicht ben�tigt wird.</param>
/// <param name="callbackOnFailure">R�ckruffunktion f�r die Fehlerbehandlung, oder null wenn keine Fehlerbehandlung ben�tigt wird.</param>
/// <param name="callbackState">Objekt das der angeforderten R�ckrufmethode mitgegeben wird.</param>
//***********************************************************************************************************************************************************

function NOXnetSoapGetDeviceVersionList(soapUri, callbackOnSuccess, callbackOnFailure, callbackState)
{
    NOXnetSoapRequest(soapUri, 'getDeviceVersionList', null, callbackOnSuccess, callbackOnFailure, callbackState);
}

//***********************************************************************************************************************************************************
// function NOXnetSoapGetUserInterfaceLayout(uri, callbackOnSuccess, callbackOnFailure, callbackState)
// ---------------------------------------------------------------------------------------------------
//
/// <summary>
/// Sendet einen SOAP Request, der an einem SOAP Server die Prozedur "getGetUserInterfaceLayout" ausl�st. Diese Prozedur ruft am Server einen XML-Deskriptor
/// ab der das Layout f�r die Benutzeroberfl�che einer Web-App festlegt.
/// </summary>
/// <param name="soapUri">URI eines SOAP Servers, oder null wenn die URI aus der Hostadresse des aktuellen Webfensters ermittelt wird.</param>
/// <param name="callbackOnSuccess">R�ckruffunktion f�r die SOAP Response, oder null wenn die SOAP Response nicht ben�tigt wird.</param>
/// <param name="callbackOnFailure">R�ckruffunktion f�r die Fehlerbehandlung, oder null wenn keine Fehlerbehandlung ben�tigt wird.</param>
/// <param name="callbackState">Objekt das der angeforderten R�ckrufmethode mitgegeben wird.</param>
//***********************************************************************************************************************************************************

export function NOXnetSoapGetUserInterfaceLayout(soapUri, callbackOnSuccess, callbackOnFailure, callbackState)
{
    NOXnetSoapRequest(soapUri, 'getUserInterfaceLayout', null, callbackOnSuccess, callbackOnFailure, callbackState);
}

//***********************************************************************************************************************************************************
// function NOXnetSoapGetIdentity(moduleList, soapUri, callbackOnSuccess, callbackOnFailure)
// -----------------------------------------------------------------------------------------
//
/// <summary>
/// Sendet einen SOAP Request, der an einem SOAP Server die Prozedur "getIdentity" ausl�st. Diese Prozedur fragt die Identit�t von physikalischen oder
/// virtuellen NOXnet Baugruppen ab. Die Identit�t enth�lt Informationen �ber den Typ und die Benennung einer Baugruppe. Wenn der Vorgang vom Server 
/// erfolgreich ausgef�hrt wird, wird die SOAP Response an eine R�ckruffunktion �bergeben. Falls der Request scheitert, wird eine R�ckruffunktion 
/// f�r die Fehlerbehandlung aufgerufen. Wird eine R�ckruffunktion nicht ben�tigt, kann dem entsprechenden Argument null �bergeben werden. Wird dem Argument 
/// soapUri null �bergeben, wird die URI des SOAP Servers automatisch aus der Hostadresse des aktuellen Webfensters abgeleitet. Dem Argument moduleList 
/// muss ein Array �bergeben werden, das eine Auflistung von 

/// das die zwei Eigenschaften "class" und "index" enth�lt. Diese Eigenschaften adressieren die Baugruppe die abgefragt
/// werden soll.  
/// </summary>
/// <param name="moduleList">Objekt das die Baugruppe adressiert, deren Identit�t abgefragt wird.</param>
/// <param name="soapUri">URI eines SOAP Servers, oder null wenn die URI aus der Hostadresse des aktuellen Webfensters ermittelt wird.</param>
/// <param name="callbackOnSuccess">R�ckruffunktion f�r die SOAP Response, oder null wenn die SOAP Response nicht ben�tigt wird.</param>
/// <param name="callbackOnFailure">R�ckruffunktion f�r die Fehlerbehandlung, oder null wenn keine Fehlerbehandlung ben�tigt wird.</param>
/// <param name="callbackState">Objekt das der jeweiligen R�ckrufmethode �bergeben wird.</param>
/// <returns>Boolean true wenn der Vorgang erfolgreich ausgef�hrt wurde.</returns>
//***********************************************************************************************************************************************************

function NOXnetSoapGetIdentity(moduleList, uri, callbackOnSuccess, callbackOnFailure, callbackState)
{
    var args = NOXnetSoapPackModuleList(moduleList);

    if (args == null)
    {
        args = '<u:bootId />\r\n'
                  + '<u:stateId />\r\n';
    }
    else
    {
        args = '<u:bootId />\r\n'
                  + '<u:stateId />\r\n'
                  + args;
    }

    NOXnetSoapRequest(uri, 'getIdentity', args, callbackOnSuccess, callbackOnFailure, callbackState);

    return true;
}

//***********************************************************************************************************************************************************
// function NOXnetSoapGetState(moduleList, soapUri, callbackOnSuccess, callbackOnFailure)
// --------------------------------------------------------------------------------------
//
/// <summary>
/// Sendet einen SOAP Request, der an einem SOAP Server die Prozedur "getState" ausl�st. Diese Prozedur fragt den aktuellen Zustand einer physikalischen oder
/// virtuellen NOXnet Baugruppe ab. Wenn der Vorgang vom Server erfolgreich ausgef�hrt wird, wird der SOAP Body aus der Antwort an eine R�ckruffunktion 
/// �bergeben. Falls der Request scheitert, wird eine R�ckruffunktion f�r die Fehlerbehandlung aufgerufen. Wird eine R�ckruffunktion nicht ben�tigt, kann dem 
/// entsprechenden Argument null �bergeben werden. Wird dem Argument soapUri null �bergeben, wird die URI des SOAP Servers automatisch aus der Hostadresse
/// des aktuellen Webfensters abgeleitet.  
/// </summary>
/// <param name="moduleList">Liste die die Baugruppen adressiert, deren Zustand abgefragt wird.</param>
/// <param name="soapUri">URI eines SOAP Servers, oder null wenn die URI aus der Hostadresse des aktuellen Webfensters ermittelt wird.</param>
/// <param name="callbackOnSuccess">R�ckruffunktion f�r die SOAP Response, oder null wenn die SOAP Response nicht ben�tigt wird.</param>
/// <param name="callbackOnFailure">R�ckruffunktion f�r die Fehlerbehandlung, oder null wenn keine Fehlerbehandlung ben�tigt wird.</param>
/// <param name="callbackState">Objekt das der jeweiligen R�ckrufmethode �bergeben wird.</param>
/// <returns>Boolean true wenn der Vorgang erfolgreich ausgef�hrt wurde.</returns>
//***********************************************************************************************************************************************************

function NOXnetSoapGetState(moduleList, soapUri, callbackOnSuccess, callbackOnFailure, callbackState)
{
    var args = NOXnetSoapPackModuleList(moduleList);

    if (args == null)
    {
        args = '<u:bootId />\r\n'
                  + '<u:stateId />\r\n';
    }
    else
    {
        args = '<u:bootId />\r\n'
                  + '<u:stateId />\r\n'
                  + args;
    }

    NOXnetSoapRequest(soapUri, 'getState', args, callbackOnSuccess, callbackOnFailure, callbackState);

    return true;
}

//***********************************************************************************************************************************************************
// function NOXnetSoapSetState(module, soapUri, callbackOnSuccess, callbackOnFailure)
// ----------------------------------------------------------------------------------
//
/// <summary>
/// Sendet einen SOAP Request, der an einem SOAP Server die Prozedur "setState" ausl�st. Diese Prozedur l�st an einer physikalischen oder virtuellen NOXnet
/// Baugruppe eine Zustands�nderung aus. Wenn der Vorgang vom Server erfolgreich ausgef�hrt wird, wird der SOAP Body der Antwort an eine R�ckruffunktion 
/// �bergeben. Falls der Request scheitert, wird eine R�ckruffunktion f�r die Fehlerbehandlung aufgerufen. Wird eine R�ckruffunktion nicht ben�tigt, kann dem
/// entsprechenden Argument null �bergeben werden. Wird dem Argument soapUri null �bergeben, wird die URI des SOAP Servers automatisch aus der Hostadresse
/// des aktuellen Webfensters abgeleitet. 
/// </summary>
/// <param name="moduleList">Liste die die Baugruppen adressiert, deren Zustand ge�ndert wird.</param>
/// <param name="soapUri">URI eines SOAP Servers, oder null wenn die URI aus der Hostadresse des aktuellen Webfensters ermittelt wird.</param>
/// <param name="callbackOnSuccess">R�ckruffunktion f�r die SOAP Response, oder null wenn die SOAP Response nicht ben�tigt wird.</param>
/// <param name="callbackOnFailure">R�ckruffunktion f�r die Fehlerbehandlung, oder null wenn keine Fehlerbehandlung ben�tigt wird.</param>
/// <param name="callbackState">Objekt das der jeweiligen R�ckrufmethode �bergeben wird.</param>
/// <returns>Boolean true wenn der Vorgang erfolgreich ausgef�hrt wurde.</returns>
//***********************************************************************************************************************************************************
export function NOXnetSoapSetState(moduleList, soapUri, callbackOnSuccess, callbackOnFailure, callbackState)
{
    var args = moduleList; //NOXnetSoapPackModuleList(moduleList);

    if (args == null)
    {
        return false;
    }

    args = '<u:bootId />\r\n'
              + '<u:stateId />\r\n'
              + args;

    NOXnetSoapRequest(soapUri, 'setState', args, callbackOnSuccess, callbackOnFailure, callbackState);

    return true;
}

//***********************************************************************************************************************************************************
// function NOXnetSoapGetWeather(uri, callbackOnSuccess, callbackOnFailure)
// ------------------------------------------------------------------------
//
/// <summary>
/// Sendet einen SOAP Request, der an einem SOAP Server die Prozedur "getWeather" ausl�st. Diese Prozedur fragt am Server die aktuellen Wetterdaten ab. Wenn
/// der Vorgang vom Server erfolgreich ausgef�hrt wird, wird der SOAP Body der Antwort an eine R�ckruffunktion �bergeben. Falls der Request scheitert, wird 
/// eine R�ckruffunktion f�r die Fehlerbehandlung aufgerufen. Wird eine R�ckruffunktion nicht ben�tigt, kann dem entsprechenden Argument auf null �bergeben 
/// werden. Wird dem Argument soapUri null �bergeben, wird die URI des SOAP Servers automatisch aus der Hostadresse des aktuellen Webfensters abgeleitet.
/// </summary>
/// <param name="soapUri">URI eines SOAP Servers, oder null wenn die URI aus der Hostadresse des aktuellen Webfensters ermittelt wird.</param>
/// <param name="callbackOnSuccess">R�ckruffunktion f�r die SOAP Response, oder null wenn die SOAP Response nicht ben�tigt wird.</param>
/// <param name="callbackOnFailure">R�ckruffunktion f�r die Fehlerbehandlung, oder null wenn keine Fehlerbehandlung ben�tigt wird.</param>
//***********************************************************************************************************************************************************

function NOXnetSoapGetWeather(soapUri, callbackOnSuccess, callbackOnFailure)
{
    var args = '<module class="masterWeatherModule" index="all" />\r\n';

    NOXnetSoapRequest(soapUri, 'getState', args, callbackOnSuccess, callbackOnFailure);
}

//***********************************************************************************************************************************************************
// function NOXnetSoapPackAction(soapAction, soapArguments)
// --------------------------------------------------------
//
/// <summary>
/// Kombiniert eine SOAP Action und ihre Argumente zu einem SOAP Body.
/// </summary>
/// <param name="soapAction">Bezeichner f�r eine SOAP Action.</param>
/// <param name="soapArguments">Argumente.</param>
/// <returns>SOAP Body, oder null wenn der Vorgang nicht erfolgreich ausgef�hrt werden konnte.</returns>
//***********************************************************************************************************************************************************

function NOXnetSoapPackAction(soapAction, soapArguments)
{
    try
    {
        if (soapArguments === null)
        {
            return '<u:' + soapAction + ' xmlns:u="' + NOXnetSoapServiceNamespace() + '" />';
        }
        else
        {
            return '<u:' + soapAction + ' xmlns:u="' + NOXnetSoapServiceNamespace() + '">' + soapArguments + '</u:' + soapAction + '>';
        }
    }
    catch (exception)
    {
        return null;
    }
}

//***********************************************************************************************************************************************************
// function NOXnetSoapPackModuleList(moduleList)
// ---------------------------------------------
//
/// <summary>
/// Erzeugt aus einer Liste die Modulobjekte enth�lt eine XML-Struktur.
/// </summary>
/// <param name="moduleList">Liste die Modulobjekte enth�lt.</param>
/// <returns>XML-Struktur, oder null wenn der Vorgang nicht erfolgreich ausgef�hrt werden konnte.</returns>
//***********************************************************************************************************************************************************

function NOXnetSoapPackModuleList(moduleList)
{
    try
    {
        if (typeof (moduleList) !== 'object' || moduleList === null)
        {
            return null;
        }

        if (moduleList.constructor !== Array)
        {
            return null;
        }

        var args = '<u:moduleList>\r\n';

        for (var listIndex = 0; listIndex < moduleList.length; listIndex++)
        {
            var module = moduleList[listIndex];

            if (typeof (module) !== 'object' || module === null)
            {
                return null;
            }

            /**** EXPERIMENTAL 1.5.1.15 BEGIN ****/

            //if (typeof (module.class) !== 'string' || module.class === null)
            //{
            //    return null;
            //}

            /***** EXPERIMENTAL 1.5.1.15 END *****/

            switch (module.class)
            {
                case 'upnpDevice':

                    if (typeof (module.host) !== 'string' || module.host === null)
                    {
                        return null;
                    }

                    if (typeof (module.
                        urn) !== 'string' || module.urn === null)
                    {
                        return null;
                    }

                    if (typeof (module.uuid) !== 'string' || module.uuid === null)
                    {
                        return null;
                    }

                    args += '<u:upnpDevice host="' + module.host + '" ' + 'urn="' + module.urn + '" ' + 'uuid="' + module.uuid + '" />\r\n';
                    break;

                default:

                    /**** EXPERIMENTAL 1.5.1.15 BEGIN ****/

                    if (module.class != null)
                    {
                        if (module.args == null)
                        {
                            args += '<u:module class="' + module.class + '" ' + 'index="' + module.index + '" />\r\n';
                        }
                        else
                        {
                            args += '<u:module class="' + module.class + '" ' + 'index="' + module.index + '">\r\n' + module.args + '</u:module>\r\n';
                        }
                    }
                    else
                    {
                        args += module.args;
                    }
                    break;

                    /***** EXPERIMENTAL 1.5.1.15 END *****/

                    //if (module.args == null)
                    //{
                    //    args += '<u:module class="' + module.class + '" ' + 'index="' + module.index + '" />\r\n';
                    //}
                    //else
                    //{
                    //    args += '<u:module class="' + module.class + '" ' + 'index="' + module.index + '">\r\n' + module.args + '</u:module>\r\n';
                    //}
                    //break;
            }
        }

        return args + '</u:moduleList>\r\n';
    }
    catch (exception)
    {
        return null;
    }
}

//***********************************************************************************************************************************************************
// function NOXnetSoapPackEnevelope(soapBody)
// ------------------------------------------
//
/// <summary>
/// Verpackt einen SOAP Body in einen SOAP Envelope.
/// </summary>
/// <param name="soapBody">SOAP Body der in den SOAP Envelope eingepackt wird.</param>
//***********************************************************************************************************************************************************

function NOXnetSoapPackEnevelope(soapBody)
{
    try
    {
        return '<?xml version="1.0" encoding="utf-8"?>' +
               '<s:Envelope xmlns:s="' + NOXnetSoapUriEnvelope() + '" s:encodingStyle="' + NOXnetSoapUriEncoding() + '">' +
                 '<s:Body>' +
                    soapBody +
                 '</s:Body>' +
               '</s:Envelope>';
    }
    catch (exception)
    {
        return null;
    }
}

//***********************************************************************************************************************************************************
// function NOXnetSoapUnpackEnvelope(soapEnvelope)
// -----------------------------------------------
//
/// <summary>
/// Zieht eine SOAP Action Response aus einem SOAP Envelope und gibt diese zur�ck. Wenn der SOAP Envelope keine Action Response enth�lt, oder der Vorgang
/// scheitert, gibt die Funktion null zur�ck.
/// </summary>
/// <param name="soapEnvelope">SOAP Envelope der ge�ffnet wird.</param>
//***********************************************************************************************************************************************************

function NOXnetSoapUnpackEnvelope(soapResponse)
{
    try
    {
        // TODO: XML-Header �berpr�fen
        // TODO: Envelope Namespace und Encoding-Style �berpr�fen.

        //**********************************************************************************************************
        // SOAP-Envelope eingrenzen und isolieren. Da es sich hierbei um das XML-Wurzelelement handelt, erfolgt dies
        // noch ohne XML-Parserfunktionen.

        var indexES = soapResponse.indexOf(':Envelope');

        if (NOXnetXmlIsValidNameEndChar(soapResponse, indexES + 9) === false)
        {
            return null;
        }

        indexES = soapResponse.lastIndexOf('<', indexES);

        if (indexES < 0)
        {
            return null;
        }

        var indexEE = soapResponse.lastIndexOf(':Envelope');

        if (NOXnetXmlIsValidNameEndChar(soapResponse, indexEE + 9) === false)
        {
            return null;
        }

        indexEE = soapResponse.lastIndexOf('</', indexEE);

        if (indexEE < 0)
        {
            return null;
        }

        indexEE = NOXnetXmlIndexOfTagEnd(soapResponse, indexEE);

        if (indexEE < 0)
        {
            return null;
        }

        var xmlEnvelope = soapResponse.substring(indexES, indexEE);

        //**********************************************************************************************************
        // SOAP-Body und anschliessend die SOAP-Action isolieren. Wenn kein SOAP-Body gefunden wird, dann kann der
        // Vorgang nicht erfolgreich abgeschlossen werden.

        var xmlBody = NOXnetXmlElement(xmlEnvelope, 'Body');

        if (xmlBody == null)
        {
            return null;
        }

        return NOXnetXmlValue(xmlBody);
    }

    //**************************************************************************************************************
    // Ausnahmen abfangen und den Vorgang abbrechen.

    catch (exception)
    {
        return null;
    }
}

//***********************************************************************************************************************************************************
// function NOXnetSoapRequest(soapUri, soapAction, soapArguments, callbackOnSuccess, callbackOnFailure)
// ----------------------------------------------------------------------------------------------------
//
/// <summary>
/// L�st �ber AJAX einen SOAP Request aus und �bergibt die Response an eine R�ckruffunktion. Falls der Vorgang scheitert, wird eine R�ckruffunktion f�r die
/// Fehlerbehandlung ausgel�st. 
/// </summary>
/// <param name="soapUri">URI des SOAP Servers, oder null wenn die URI aus der Hostadresse des aktuellen Webfensters ermittelt wird.</param>
/// <param name="soapAction">Bezeichner der SOAP Action die mit diesem Request aufgerufen wird.</param>
/// <param name="soapArguments">Argumente die im SOAP Body des Request mitgesendet werden.</param>
/// <param name="callbackOnSuccess">R�ckruffunktion, die aufgerufen wird wenn die SOAP Action erfolgreich abgeschlossen wird, oder null wenn kein R�ckruf 
/// erfolgen soll.</param>
/// <param name="callbackOnFailure">R�ckruffunktion, die aufgerufen wird wenn ein Fehler auftritt, oder null wenn kein R�ckruf erfolgen soll.</param>
/// <param name="callbackState">Objekt das der jeweiligen R�ckrufmethode �bergeben wird.</param>
//***********************************************************************************************************************************************************

function NOXnetSoapRequest(soapUri, soapAction, soapArguments, callbackOnSuccess, callbackOnFailure, callbackState)
{
    // ...
    if (soapUri === null)
    {
        soapUri = window.location.protocol + '//' + window.location.host + '/control';
    }

    // ...
    if (soapAction === null)
    {
        return;
    }

    // ...
    var soapUrn = NOXnetSoapServiceNamespace() + '#' + soapAction;
    var soapBody = NOXnetSoapPackAction(soapAction, soapArguments);
    var soapEnvelope = NOXnetSoapPackEnevelope(soapBody);

    // ...
    var ajax = new XMLHttpRequest();

    ajax.open('POST', soapUri, true);
    ajax.setRequestHeader('SOAPACTION', soapUrn);
    ajax.setRequestHeader('CONTENT-TYPE', 'text/xml');

    ajax.onreadystatechange = function ()
    {
        if (this.readyState == 4)
        {
            if (this.status == 200)
            {
                //TODO: Verify Service URN in Response and callbackOnFailure if wrong

                if (callbackOnSuccess !== null)
                {
                    callbackOnSuccess(callbackState, NOXnetSoapUnpackEnvelope(this.responseText));
                }
            }
            else
            {
                if (callbackOnFailure !== null)
                {
                    callbackOnFailure(callbackState, this.statusText);
                }
            }
        }
    }

    ajax.send(soapEnvelope);
}

//***********************************************************************************************************************************************************
// function NOXnetSoapUriEnvelope()
// --------------------------------
//
/// <summary>
/// Gibt eine URI zur�ck, die festlegt nach welchem Schema eine SOAP Nachricht aufgebaut ist. Dieses Schema ist Bestandteil der SOAP Spezifikation.
/// </summary>
//***********************************************************************************************************************************************************

function NOXnetSoapUriEnvelope()
{
    return 'http://schemas.xmlsoap.org/soap/envelope/';
}

//***********************************************************************************************************************************************************
// function NOXnetSoapUriEncoding()
// --------------------------------
//
/// <summary>
/// Gibt eine URI zur�ck, die festlegt nach welchem Schema eine SOAP Nachricht codiert ist. Dieses Schema ist Bestandteil der SOAP Spezifikation.
/// </summary>
//***********************************************************************************************************************************************************

function NOXnetSoapUriEncoding()
{
    return 'http://schemas.xmlsoap.org/soap/encoding/';
}

//***********************************************************************************************************************************************************
// function NOXnetSoapServiceNamespace()
// -------------------------------------
//
/// <summary>
/// Gibt eine URN zur�ck, die auf den UPnP Service verweist, der die Prozeduren f�r die Bearbeitung von SOAP Nachrichten im INNOXEL Master 3 bereitstellt.
/// </summary>
//***********************************************************************************************************************************************************

function NOXnetSoapServiceNamespace()
{
    return 'urn:innoxel-ch:service:noxnetRemote:1';
}

//***********************************************************************************************************************************************************
// function NOXnetXmlAttribute(xmlElement, attributeName)
// ------------------------------------------------------
//
/// <summary>
/// Durchsucht ein XML Element nach einem Attribut und gibt die Attributeigenschaft zur�ck. Wenn das Attribut nicht gefunden wird, gibt die Funktion null 
/// zur�ck.  
/// </summary>
/// <remarks>
/// Ein Attribut darf in einem XML Element nur einmal vorkommen. Deshalb beendet diese Funktion die Suche nach dem ersten Vorkommen des gesuchten Attributs. 
/// </remarks>
/// <param name="xmlElement">XML Element das durchsucht wird.</param>
/// <param name="attributeName">Name des angeforderten XML Attributs.</param>
/// <returns></returns>
//***********************************************************************************************************************************************************

function NOXnetXmlAttribute(xmlElement, xmlName)
{
    try
    {
        //**********************************************************************************************************
        // Der Vorgang kann nur ausgef�hrt werden, wenn die Argumente xmlElement und attributeName den Typ "string" 
        // aufweisen und definiert sind.

        if (typeof (xmlElement) != 'string' || xmlElement == null)
        {
            return null;
        }

        if (typeof (xmlName) != 'string' || xmlName == null)
        {
            return null;
        }

        //**********************************************************************************************************
        // Wenn das XML Element keine Attribute enth�lt kann der Vorgang beendet werden. Dies ist der Fall wenn nach
        // dem Elementnamen eine Endsignatur folgt. Wenn das XML Element nicht mit einem Starttag beginnt, oder nach
        // dem Elementnamen keine g�ltigen Zeichen gefunden werden, liegt eine Syntaxverletzung vor.

        if (!NOXnetXmlIsStartTag(xmlElement, 0))
        {
            return null;
        }

        var indexS = NOXnetXmlIndexOfNameEnd(xmlElement, 1);

        if (indexS < 0)
        {
            return null;
        }

        var indexS = NOXnetXmlIndexOfNonWhiteSpace(xmlElement, indexS);

        if (indexS < 0)
        {
            return null;
        }

        if (NOXnetXmlStartsWith(xmlElement, '>', indexS) || NOXnetXmlStartsWith(xmlElement, '/>', indexS))
        {
            return null;
        }

        //**********************************************************************************************************
        // Attribute absuchen und mit dem angeforderten Attributnamen vergleichen. Wenn das Ende des Starttags
        // erreicht ist, kann die Suche abgebrochen werden.

        var indexV = NOXnetXmlIndexOfTagEnd(xmlElement, 0);

        if (indexV < 0)
        {
            return null;
        }

        while (indexS >= 0 && indexS < indexV)
        {
            var indexE = NOXnetXmlIndexOfNameEnd(xmlElement, indexS);

            if (indexE < 0)
            {
                return null;
            }

            var indexQ = indexE;

            var unicodePoint = xmlElement.charCodeAt(indexQ);

            while (unicodePoint != 0x22 && unicodePoint != 0x27)
            {
                if (!NOXnetXmlIsValidWhiteSpaceChar(unicodePoint) && unicodePoint != 0x3D)
                {
                    return null;
                }

                if (++indexQ >= xmlElement.length)
                {
                    return null;
                }

                var unicodePoint = xmlElement.charCodeAt(indexQ);
            }

            if (xmlElement.substring(indexS, indexE) == xmlName)
            {
                indexE = NOXnetXmlSkipQuotedText(xmlElement, indexQ);

                return xmlElement.substring(indexQ + 1, indexE - 1);
            }

            indexS = NOXnetXmlSkipQuotedText(xmlElement, indexQ);
            indexS = NOXnetXmlIndexOfNonWhiteSpace(xmlElement, indexS);
        }

        return null;
    }

    //**************************************************************************************************************
    // Wenn eine Ausnahme auftritt gibt die Funktion null zur�ck.

    catch (exception)
    {
        return null;
    }
}

//***********************************************************************************************************************************************************
// function NOXnetXmlElement(xmlContainer, xmlName)
// ------------------------------------------------
//
/// <summary>
/// Ruft das erste untergeordnete XML Element mit dem angegebenen Namen ab. Wenn das gesuchte Element nicht gefunden wird, gibt die Funktion null zur�ck.
/// Wenn die Funktion eine Syntaxverletzung feststellt, wird der Vorgang abgebrochen und die Funktion gibt null zur�ck. Diese Funktion f�hrt eine 
/// Typenpr�fung durch. Ausnahmen werden abgefangen und der Vorgang in diesem Fall mit null beendet.
/// </summary>
/// <param name="xmlContainer">Container der XML formatierten Text enth�lt.</param>
/// <param name="xmlName">Name des angeforderten XML Elements.</param>
/// <returns>XML Element, oder null wenn dieses nicht gefunden wird oder der Vorgang nicht erfolgreich abgeschlossen werden kann.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlElement(xmlContainer, xmlName)
{
    try
    {
        //**********************************************************************************************************
        // Der Vorgang kann nur ausgef�hrt werden wenn die Argumente definiert und vom Datentyp String sind.

        if (xmlContainer == null || typeof (xmlContainer) != 'string')
        {
            return null;
        }

        if (xmlName == null || typeof (xmlName) != 'string')
        {
            return null;
        }

        //**********************************************************************************************************
        // Zun�chst wird der XML-Content (d.h. der Inhalt) des �bergebenen XML-Elements eingegrenzt. Dabei ist zu
        // ber�cksichtigen, dass vor dem XML-Element eine beliebige Anzahl LWS-Zeichen stehen darf. Falls der
        // Inhalt leer ist, dann kann der Vorgang umgehend beendet werden.

        // TODO: Funktion xmlContainer.indexOf('<', 0) durch NOXnetXmlSkipLWS() ersetzen.
        var indexXS = xmlContainer.indexOf('<', 0);

        if (indexXS < 0)
        {
            return null;
        }

        var indexXE = NOXnetXmlSkipElement(xmlContainer, indexXS, 0);

        if (indexXE < 0)
        {
            return null;
        }

        indexXS = NOXnetXmlIndexOfTagEnd(xmlContainer, indexXS);

        if (indexXS == indexXE)
        {
            return null;
        }

        indexXE = xmlContainer.lastIndexOf('</', indexXE);

        //**********************************************************************************************************
        // Alle Unterelemente im XML-Content durchgehen bis ein Element mit dem gesuchten Namen gefunden wird. Zur
        // Sicherheit wird die Suchschlaufe nach einer maximalen Anzahl Uml�ufe abgebrochen.

        var indexYS = xmlContainer.indexOf('<', indexXS);
        var indexYE = 0;
        var indexNS = indexYS + 1;
        var indexNE = 0;
        var indexPD = 0;
        var loopMax = 0;

        while (indexYS >= 0 && indexYS < indexXE && indexYS < xmlContainer.length && loopMax++ < 2048)
        {
            if (NOXnetXmlIsStartTag(xmlContainer, indexYS) === true)
            {
                //**************************************************************************************************
                // XML-End-Tag f�r das aktuelle Unterelement suchen. Falls kein XML-End-Tag gefunden wird, dann muss
                // der Vorgang abgebrochen werden weil dies eine Syntaxverletzung darstellt. 

                indexYE = NOXnetXmlSkipElement(xmlContainer, indexYS, 0);

                if (indexYE < 0)
                {
                    return null;
                }

                //**************************************************************************************************
                // XML-Namen isolieren und mit dem gesuchten vergleichen. Sind diese identisch, dann wird das
                // aktuelle Unterelement isoliert und in die Elementeliste �bernommen. Wenn der XML-Name nicht
                // isoliert werden kann, dann liegt eine Syntaxverletzung vor und der Vorgang wird abgebrochen.
                // Dem XML-Namen kann ein mit einem Doppelpunkt abgetrennter Namensraumpr�fix verangestellt sein.
                // Dieser muss f�r den Namensvergleich ausgeblendet werden.

                indexNE = NOXnetXmlIndexOfNameEnd(xmlContainer, indexNS);

                if (indexNE < 0)
                {
                    return null;
                }

                if (indexPD >= 0 && indexPD < indexNE)
                {
                    //**********************************************************************************************
                    // Der Doppelpunkt wird zur Geschwindigkeitsoptimierung nur dann gesucht, wenn es sinn macht.
                    // Falls die aktuelle Suche keinen Treffer ergibt, kann in allen nachfolgenden F�llen auf die
                    // Suche verzichtet werden. Falls die aktuelle Suche einen Treffer weit hinter dem XML-Namen
                    // ergibt, braucht das Doppelpunkt erst wieder gesucht zu werden wenn der Parser diese Position
                    // erreicht oder �berschritten hat.

                    indexPD = xmlContainer.indexOf(':', indexNS);
                }

                if (indexPD > indexNS && indexPD < indexNE)
                {
                    indexNS = indexPD + 1;
                }

                if (xmlContainer.substring(indexNS, indexNE) == xmlName)
                {
                    return xmlContainer.substring(indexYS, indexYE);
                }
            }
            else
            {
                indexYE = indexYS + 1;
            }

            indexYS = xmlContainer.indexOf('<', indexYE);
            indexNS = indexYS + 1;
        }
        return null;
    }

    //**************************************************************************************************************
    // Ausnahmen abfangen und den Vorgang abbrechen.

    catch (exception)
    {
        return null;
    }
}

//***********************************************************************************************************************************************************
// function NOXnetXmlElements(xmlContainer, xmlName)
// -------------------------------------------------
//
/// <summary>
/// Ruft alle unergeordneten XML-Elemente mit dem angegebenen Namen ab und gibt diese in einem Array zur�ck. Wenn als Namen null �bergeben wird, gibt die
/// Funktion alle unergeordneten XML-Elemente zur�ck. Wenn kein Element mit dem gesuchten Namen gefunden wird, oder das XML-Element leer ist, gibt die 
/// Funktion ein leeres Array zur�ck. Wenn die Funktion eine Syntaxverletzung feststellt, wird der Vorgang abgebrochen und die Funktion gibt null zur�ck. 
/// Diese Funktion f�hrt eine Typenpr�fung durch. Ausnahmen werden abgefangen und der Vorgang in diesem Fall mit null beendet.
/// </summary>
/// <param name="xmlContainer">Container der XML formatierten Text enth�lt.</param>
/// <param name="xmlName">Name der angeforderten XML-Unterelemente, oder null wenn alle XML-Unterelemente angefordert werden.</param>
/// <returns>Array mit XML Elementen, oder null wenn der Vorgang nicht erfolgreich abgeschlossen werden kann.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlElements(xmlContainer, xmlName)
{
    try
    {
        //**********************************************************************************************************
        // Der Vorgang kann nur ausgef�hrt werden wenn die Argumente definiert und vom Datentyp String sind.

        if (xmlContainer == null || typeof (xmlContainer) != 'string')
        {
            return null;
        }

        if (xmlName != null)
        {
            if (typeof (xmlName) != 'string')
            {
                return null;
            }
        }

        var xmlElements = [];

        //**********************************************************************************************************
        // Zun�chst wird der XML-Content (d.h. der Inhalt) des �bergebenen XML-Elements eingegrenzt. Dabei ist zu
        // ber�cksichtigen, dass vor dem XML-Element eine beliebige Anzahl LWS-Zeichen stehen darf. Falls der
        // Inhalt leer ist, dann kann der Vorgang umgehend beendet werden.

        // TODO: Funktion xmlContainer.indexOf('<', 0) durch NOXnetXmlSkipLWS() ersetzen.
        var indexXS = xmlContainer.indexOf('<', 0);

        if (indexXS < 0)
        {
            return null;
        }

        var indexXE = NOXnetXmlSkipElement(xmlContainer, indexXS, 0);

        if (indexXE < 0)
        {
            return null;
        }

        indexXS = NOXnetXmlIndexOfTagEnd(xmlContainer, indexXS);

        if (indexXS == indexXE)
        {
            return xmlElements;
        }

        indexXE = xmlContainer.lastIndexOf('</', indexXE);

        //**********************************************************************************************************
        // Alle Unterelemente im XML-Content durchgehen und alle gesuchten Unterelemente in die Elementliste
        // �bernehmen. Zur Sicherheit wird die Suchschlaufe nach einer maximalen Anzahl Uml�ufe abgebrochen.

        var indexYS = xmlContainer.indexOf('<', indexXS);
        var indexYE = 0;
        var indexNS = indexYS + 1;
        var indexNE = 0;
        var indexPD = 0;
        var loopMax = 0;

        while (indexYS >= 0 && indexYS < indexXE && indexYS < xmlContainer.length && loopMax++ < 2048)
        {
            if (NOXnetXmlIsStartTag(xmlContainer, indexYS) === true)
            {
                //**************************************************************************************************
                // XML-End-Tag f�r das aktuelle Unterelement suchen. Falls kein XML-End-Tag gefunden wird, dann muss
                // der Vorgang abgebrochen werden weil dies eine Syntaxverletzung darstellt. 

                indexYE = NOXnetXmlSkipElement(xmlContainer, indexYS, 0);

                if (indexYE < 0)
                {
                    return null;
                }

                //**************************************************************************************************
                // XML-Namen isolieren und mit dem gesuchten vergleichen. Sind diese identisch, dann wird das
                // aktuelle Unterelement isoliert und in die Elementeliste �bernommen. Wenn der XML-Name nicht
                // isoliert werden kann, dann liegt eine Syntaxverletzung vor und der Vorgang wird abgebrochen.
                // Dem XML-Namen kann ein mit einem Doppelpunkt abgetrennter Namensraumpr�fix verangestellt sein.
                // Dieser muss f�r den Namensvergleich ausgeblendet werden.

                indexNE = NOXnetXmlIndexOfNameEnd(xmlContainer, indexNS);

                if (indexNE < 0)
                {
                    return null;
                }

                if (indexPD >= 0 && indexPD < indexNE)
                {
                    //**********************************************************************************************
                    // Der Doppelpunkt wird zur Geschwindigkeitsoptimierung nur dann gesucht, wenn es sinn macht.
                    // Falls die aktuelle Suche keinen Treffer ergibt, kann in allen nachfolgenden F�llen auf die
                    // Suche verzichtet werden. Falls die aktuelle Suche einen Treffer weit hinter dem XML-Namen
                    // ergibt, braucht das Doppelpunkt erst wieder gesucht zu werden wenn der Parser diese Position
                    // erreicht oder �berschritten hat.

                    indexPD = xmlContainer.indexOf(':', indexNS);
                }

                if (indexPD > indexNS && indexPD < indexNE)
                {
                    indexNS = indexPD + 1;
                }

                if (xmlName === null || xmlContainer.substring(indexNS, indexNE) == xmlName)
                {
                    xmlElements.push(xmlContainer.substring(indexYS, indexYE));
                }
            }
            else
            {
                indexYE = indexYS + 1;
            }

            indexYS = xmlContainer.indexOf('<', indexYE);
            indexNS = indexYS + 1;
        }
        return xmlElements;
    }

    //**************************************************************************************************************
    // Ausnahmen abfangen und den Vorgang abbrechen.

    catch (exception)
    {
        return null;
    }
}

//***********************************************************************************************************************************************************
// function NOXnetXmlName(xmlElement)
// ----------------------------------
//
/// <summary>
/// Ruft den Namen eines XML Elements ab und gibt diesen als String zur�ck. Wenn dem Namen ein Namensraumpr�fix vorangestellt ist, dann wird dieses entfernt
/// und der Name ohne den Pr�fix zur�ckgegeben. Wenn die Funktion eine Syntaxverletzung feststellt, wird der Vorgang abgebrochen und die Funktion gibt null 
/// zur�ck. Diese Funktion f�hrt eine Typenpr�fung durch. Ausnahmen werden abgefangen und der Vorgang in diesem Fall mit null beendet.
/// </summary>
/// <param name="xmlElement">XML Element.</param>
/// <returns>XML Namen, oder null wenn der Vorgang nicht erfolgreich abgeschlossen werden kann.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlName(xmlElement)
{
    try
    {
        //**********************************************************************************************************
        // Der Vorgang kann nur ausgef�hrt werden wenn das Argument definiert und vom Datentyp String sind.

        if (xmlElement == null || typeof (xmlElement) != 'string')
        {
            return null;
        }

        //**********************************************************************************************************
        // Wenn das �bergebene XML-Element nicht mit einem Tag-Startzeichen beginnt, dann wird der Vorgang nicht
        // ausgef�hrt.

        if (NOXnetXmlIsStartTag(xmlElement, 0) === false)
        {
            return null;
        }

        //**********************************************************************************************************
        // Ende des XML-Namens suchen. Anschliessend pr�fen ob vor dem XML-Namen ein XML-Namensraumpr�fix steht.
        // Wenn ja wird dieser ausgeblendet.

        var indexNE = NOXnetXmlIndexOfNameEnd(xmlElement, 1);

        if (indexNE < 0)
        {
            return null;
        }

        var indexPD = xmlElement.indexOf(':', 1);

        if (indexPD < 0 || indexPD > indexNE)
        {
            return xmlElement.substring(1, indexNE);
        }
        else
        {
            return xmlElement.substring(indexPD + 1, indexNE);
        }
    }

    //**************************************************************************************************************
    // Ausnahmen abfangen und den Vorgang abbrechen.

    catch (exception)
    {
        return null;
    }
}

//***********************************************************************************************************************************************************
// function NOXnetXmlValue(xmlElement)
// -----------------------------------
//
/// <summary>
/// Ruft den Inhalt eines XML Elements ab und gibt diesen als String zur�ck. Wenn das XML Element leer ist gibt die Funktion null zur�ck. Wenn die Funktion 
/// eine Syntaxverletzung feststellt, wird der Vorgang abgebrochen und die Funktion gibt null zur�ck. Diese Funktion f�hrt eine Typenpr�fung durch. Ausnahmen
/// werden abgefangen und der Vorgang in diesem Fall mit null beendet.
/// </summary>
/// <param name="xmlElement">XML Element.</param>
/// <returns>Elementinhalt, oder null wenn das Element leer ist oder der Vorgang nicht erfolgreich abgeschlossen werden kann.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlValue(xmlElement)
{
    try
    {
        //**********************************************************************************************************
        // Der Vorgang kann nur ausgef�hrt werden wenn das Argument definiert und vom Datentyp String ist.

        if (xmlElement == null || typeof (xmlElement) != 'string')
        {
            return null;
        }

        //**********************************************************************************************************
        // Zun�chst wird der XML-Content (d.h. der Inhalt) des �bergebenen XML-Elements eingegrenzt. Dabei ist zu
        // ber�cksichtigen, dass vor dem XML-Element eine beliebige Anzahl LWS-Zeichen stehen darf. Falls der
        // Inhalt leer ist, dann kann der Vorgang umgehend beendet werden.

        // TODO: Funktion xmlContainer.indexOf('<', 0) durch NOXnetXmlSkipLWS() ersetzen.
        var indexXS = xmlElement.indexOf('<', 0);

        if (indexXS < 0)
        {
            return null;
        }

        var indexXE = NOXnetXmlSkipElement(xmlElement, indexXS);

        if (indexXE < 0)
        {
            return null;
        }

        indexXS = NOXnetXmlIndexOfTagEnd(xmlElement, indexXS);

        if (indexXS == indexXE)
        {
            return null;
        }

        //**********************************************************************************************************
        // Ein XML-Element kann Text oder eine untergeordnete XML-Struktur enthalten. Das Tag-Startzeichen muss im
        // Text zwingend als Escapesequenz codiert sein. Wenn also innerhalb des Start- und End-Tags ein 
        // Tag - Startzeichen gefunden wird, dann handelt es sich beim Inhalt um eine untergeordnete XML-Struktur. 
        // Dabei ist zu ber�cksichtigen, dass diese f�hrende LWS-Zeichen enthalten darf.

        var indexYS = xmlElement.indexOf('<', indexXS);
        var indexYE = xmlElement.lastIndexOf('</', indexXE);

        if (indexYS < 0 || indexYE < 0 || indexYE < indexYS)
        {
            return null;
        }

        if (indexYS == indexYE)
        {
            return xmlElement.substring(indexXS, indexYE);
        }
        else
        {
            return xmlElement.substring(indexYS, indexYE);
        }
    }

    //**************************************************************************************************************
    // Ausnahmen abfangen und den Vorgang abbrechen.

    catch (exception)
    {
        return null;
    }
}

//***********************************************************************************************************************************************************
// function NOXnetXmlSkipComment(xmlContainer, startIndex)
// -------------------------------------------------------
//
/// <summary>
/// Gibt den Index des ersten Zeichens nach dem XML Kommentar zur�ck, der an der Position startIndex beginnt. Wenn startIndex nicht auf das �ffnende Kleiner-
/// Als-Zeichen eines XML Elements zeigt, gibt die Funktion startIndex zur�ck. Wenn startIndex kleiner 0 oder gr�sser als die Anzahl Zeichen im xmlContainer
/// ist, oder die Endsignatur des Kommentars nicht gefunden wird, gibt die Funktion -1 zur�ck. Die Funktion f�ngt Ausnahmen ab und gibt in diesem Fall -1 
/// zur�ck.
/// </summary>
/// <param name="xmlContainer">Container der XML formatierten Inhalt enth�lt.</param>
/// <param name="startIndex">Index auf das erste Zeichen eines XML Kommentars.</param>
/// <returns>Index auf das erste Zeichen nach dem XML Kommentar, oder -1 wenn der Vorgang scheitert.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlSkipComment(xmlContainer, startIndex)
{
    try
    {
        if (startIndex < 0 || startIndex >= xmlContainer.length)
        {
            return -1;
        }

        if (!NOXnetXmlIsComment(xmlContainer, startIndex))
        {
            return startIndex;
        }

        startIndex = xmlContainer.indexOf('-->', startIndex);

        if (startIndex < 0)
        {
            return -1;
        }
        else
        {
            return startIndex + 3;
        }
    }

    catch (exception)
    {
        return -1;
    }
}

//***********************************************************************************************************************************************************
// function NOXnetXmlSkipElement(xmlContainer, startIndex, recursion)
// ------------------------------------------------------------------
//
/// <summary>
/// Gibt den Index des ersten Zeichens nach dem XML Element zur�ck, das an der Position startIndex beginnt. Wenn startIndex nicht auf das �ffnende Kleiner-
/// Als-Zeichen eines XML Elements zeigt, gibt die Funktion startIndex zur�ck. Wenn startIndex kleiner 0 oder gr�sser als die Anzahl Zeichen im xmlContainer
/// ist, oder das Endtag des Elements nicht gefunden wird, gibt die Funktion -1 zur�ck. Die Funktion f�ngt Ausnahmen ab und gibt in diesem Fall -1 zur�ck.
/// </summary>
/// <remarks>
/// Das �bersprungene XML Element kann Unterelemente mit demselben XML Namen in beliebiger Verschachtelungstiefe enthalten. Diese Funktion wird daher f�r 
/// jedes Unterelement mit demselben XML Namen rekursiv aufgerufen. Damit dies im Fehlerfall nicht zu einem Deadloop f�hrt, ist die Verschachtelungstiefe 
/// auf 32 begrenzt. Wenn dieser Wert erreicht ist, bricht die Funktion ab und gibt -1 zur�ck. Das Argument recursion hat deshalb nur dann eine Bedeutung 
/// wenn sich die Funktion selbst Aufruft. Alle anderen Aufrufer m�ssen diesem Argument den Wert 0 �bergeben.
/// </remarks>
/// <param name="xmlContainer">Container der XML formatierten Inhalt enth�lt.</param>
/// <param name="startIndex">Index auf ein "<" Zeichen eines XML Start Tags.</param>
/// <param name="recursion">Wird diese Funktion nicht durch sich selbst aufgerufen, muss diesem Argument der Wert 0 �bergeben werden.</param>
/// <returns>Index auf das erste Zeichen nach dem XML Element, auf das startIndex zeigt, oder -1 wenn der Vorgang scheitert.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlSkipElement(xmlContainer, startIndex, recursion)
{
    try
    {
        //**********************************************************************************************************
        // Wenn die maximal zul�ssige Rekursionstiefe erreicht ist, oder das Argument startIndex negaitv ist, oder 
        // gr�sser als die Anzahl Zeichen in xmlContainer ist, wird der Vorgang abgebrochen.

        if (recursion < 0 || recursion > 31)
        {
            return -1;
        }

        if (startIndex < 0 || startIndex + 1 >= xmlContainer.length)
        {
            return -1;
        }

        //**********************************************************************************************************
        // Das Argument startIndex muss auf einen Starttag zeigen. Wenn dies nicht der Fall ist, wird der Vorgang 
        // beendet.

        if (NOXnetXmlIsStartTag(xmlContainer, startIndex) === false)
        {
            return startIndex;
        }

        //**********************************************************************************************************
        // XML Start-Tag isolieren und daraus den End-Tag konstruieren. Falls beim Eingrenzen des XML-Namens ein
        // Fehler auftritt wird die Funktion mit einer Fehlermeldung beendet.

        var neIndex = NOXnetXmlIndexOfNameEnd(xmlContainer, startIndex);

        if (neIndex < 0)
        {
            return -1;
        }

        var xmlSTag = xmlContainer.substring(startIndex, neIndex);
        var xmlETag = '</' + xmlSTag.substring(1);

        //**********************************************************************************************************
        // Beginnend beim ersten Zeichen nach dem XML Namen die Tag-End-Signatur '>' suchen. Falls unmittelbar vor
        // der Tag-End-Signatur das Zeichen '/' steht, dann handelt es sich um ein leeres XML Element. In diesem 
        // Fall kann der Index auf das erste Zeichen nach dem XML Element sofort berechnet und der Vorgang 
        // abgeschlossen werden.

        var xsIndex = NOXnetXmlIndexOfTagEnd(xmlContainer, startIndex);

        if (xsIndex < 3)
        {
            return -1;
        }

        if (xmlContainer[xsIndex - 2] == '/')
        {
            return xsIndex;
        }

        //**********************************************************************************************************
        // XML End-Tag suchen. Die Suche muss ber�cksichtigen, dass der Inhalt des aktuellen XML Elements eine 
        // untergeordnete XML Struktur sein kann, die XML Elemente mit demselben Namen in beliebiger 
        // Verschachtelungstiefe enthalten kann. Deshalb werden im Folgenden das XML Start- und End-Tag gesucht. 
        // Wenn vor dem End-Tag ein Start-Tag gefunden wird, dann geh�rt das gefundene End-Tag zu einem
        // untergeordneten XML Element mit demselben Namen. In diesem Fall wird diese Funktion rekursiv aufgerufen
        // um das untergeordnete XML Element zu �berspringen.

        var xeIndex = xsIndex;
        var csIndex = -1;
        var ceIndex = -1;
        var zsIndex = xsIndex;

        var outerLoopMax = 0;
        var innerLoopMax = 0;
        var repeatLoop = false;

        while (xeIndex > startIndex && xeIndex < xmlContainer.length)
        {
            //**********************************************************************************************************
            // F�r den Fall, dass der Vorgang aufgrund eines Fehlers oder einer Syntaxverletzung in dieser while-
            // Schlaufe h�ngen bleibt, wird ein Abbruchz�hler �berwacht.

            if (outerLoopMax++ > 2048)
            {
                return -1;
            }

            //**********************************************************************************************************
            // XML End-Tag ausgehend vom Start-Tag oder der letzten Rekursionsebene suchen. Damit der nachfolgende
            // Codeabschnitt �bersichtlicher ist, wird bereits jetzt der Index auf das erste Zeichen nach dem XML
            // End-Tag bestimmt, obwohl dies unter Umst�nden unn�tz ist. Falls kein End-Tag gefunden wird, dann liegt 
            // eine Syntaxverletzung vor und die Funktion wird abgebrochen.

            xeIndex = xmlContainer.indexOf(xmlETag, xeIndex);

            if (xeIndex < startIndex)
            {
                return -1;
            }

            var xeIndexBehind = NOXnetXmlIndexOfEndTagClosing(xmlContainer, xeIndex + xmlETag.length);

            if (xeIndexBehind < startIndex)
            {
                xeIndex = xeIndex + xmlETag.length
                continue;
            }

            //******************************************************************************************************
            // Falls sich der gefundene XML End-Tag innerhalb eines auskommentierten Bereichs befindet, muss er
            // ignoriert werden. Deshalb wird nachfolgend gepr�ft ob sich die gefundene Position innerhalb einer
            // XML Kommentar-Start- und Kommentar-End-Signatur befindet. Ist dies der Fall wird die Suche nach
            // der Kommentar-End-Signatur wiederholt.

            csIndex = xmlContainer.lastIndexOf('<!--', xeIndex);

            if (csIndex > xsIndex)
            {
                ceIndex = xmlContainer.indexOf('-->', csIndex);

                //**************************************************************************************************
                // Falls nach der XML Kommentar-Start-Signatur keine entsprechende End-Signatur gefunden wird, dann
                // liegt eine Syntaxverletzung vor.

                if (ceIndex < 0)
                {
                    return -1;
                }
            }

            if (csIndex > xsIndex && ceIndex > xeIndex)
            {
                xeIndex = ceIndex + 3;
            }
            else
            {
                //**************************************************************************************************
                // XML Start-Tag vom Anfang des Inhalts oder der letzten Rekursionsebene suchen. Falls kein Start-
                // Tag gefunden wird, oder das gefundene Start-Tag hinter dem End-Tag liegt, bedeutet dies, dass 
                // das gefundene XML End-Tag zum aktuellen XML Element geh�rt. In diesem Fall kann der Vorgang 
                // beendet werden. Andernfalls geh�rt das gefundene XML Start-Tag zu einem Unterelement mit
                // demselben XML Namen und muss daher in einem rekursiven Aufruf �bersprungen werden. 

                innerLoopMax = 0;

                do
                {
                    //**********************************************************************************************
                    // F�r den Fall, dass der Vorgang aufgrund eines Fehlers oder einer Syntaxverletzung in dieser 
                    // do-while-Schlaufe h�ngen bleibt, wird ein Abbruchz�hler �berwacht.

                    if (innerLoopMax++ > 2048)
                    {
                        return -1;
                    }

                    //**********************************************************************************************
                    // XML Start-Tag suchen. Falls kein Start-Tag gefunden wird, oder das gefundene Start-Tag hinter
                    // dem End-Tag liegt, wird der Vorgang beendet.

                    zsIndex = xmlContainer.indexOf(xmlSTag, zsIndex);

                    if (zsIndex < 0 || zsIndex > xeIndex)
                    {
                        return xeIndexBehind;
                    }

                    //**********************************************************************************************
                    // Die Suche kann auch einen Treffer ergeben wenn in der XML Struktur Elemente vorkommen, die
                    // einen XML Namen besitzen deren Anfangssequenz des gesuchten Start-Tags �bereinstimmt. Deshalb
                    // wird gepr�ft ob der gefundenen Sequenz ein regul�res Abschlusszeichen folgt. Falls nicht wird
                    // die Suche hinter der gefundenen Position fortgesetzt.

                    if (NOXnetXmlIsValidNameEndChar(xmlContainer, zsIndex + xmlSTag.length) === true)
                    {
                        //******************************************************************************************
                        // Falls sich dar gefundene XML Start-Tag innerhalb eines auskommentierten Bereichs 
                        // befindet, muss er ignoriert werden. Deshalb wird nachfolgend gepr�ft ob sich die 
                        // gefundene Position innerhalb einer XML Kommentar-Start- und Kommentar-End-Signatur 
                        // befindet. Ist dies der Fall wird die Suche nach der Kommentar-End-Signatur wiederholt.

                        csIndex = xmlContainer.lastIndexOf('<!--', zsIndex);

                        if (csIndex > xsIndex)
                        {
                            ceIndex = xmlContainer.indexOf('-->', csIndex);

                            //**************************************************************************************
                            // Falls nach der XML Kommentar-Start-Signatur keine entsprechende End-Signatur gefunden
                            // wird, dann liegt eine Syntaxverletzung vor.

                            if (ceIndex < 0)
                            {
                                return -1;
                            }
                        }

                        if (csIndex > xsIndex && ceIndex > zsIndex)
                        {
                            zsIndex = ceIndex + 3;
                            repeatLoop = true;
                        }
                        else
                        {
                            xeIndex = NOXnetXmlSkipElement(xmlContainer, zsIndex, recursion + 1);
                            zsIndex = xeIndex;
                            repeatLoop = false;
                        }
                    }
                    else
                    {
                        zsIndex += xmlSTag.length;
                        repeatLoop = true;
                    }
                }
                while (repeatLoop === true);
            }
        }
        return -1;
    }

    //**************************************************************************************************************
    // Ausnahmen abfangen und den Vorgang mit -1 abbrechen. 

    catch (exception)
    {
        return -1;
    }
}

//***********************************************************************************************************************************************************
// function NOXnetXmlSkipQuotedText(xmlContainer, startIndex)
// ----------------------------------------------------------
//
/// <summary>
/// Gibt den Index des ersten Zeichens nach dem durch Anf�hrungszeichen umschlossenen Text zur�ck, der an der Position startIndex beginnt. Wenn startIndex
/// nicht auf ein Zeichen zeigt, das gem�ss XML Spezifikation (Version 1.0 Fifth Edition; 28.11.2008; Abschnitt 2.3) als Anf�hrungszeichen definiert ist, 
/// gibt die Funktion startIndex zur�ck. Wenn startIndex kleiner 0 oder gr�sser als die Anzahl Zeichen im xmlContainer ist, oder das Endtag des Elements 
/// nicht gefunden wird, gibt die Funktion -1 zur�ck. Die Funktion f�ngt Ausnahmen ab und gibt in diesem Fall -1 zur�ck.
/// </summary>
/// <param name="xmlContainer">Container der XML formatierten Inhalt enth�lt.</param>
/// <param name="startIndex">Index auf ein Anf�hrungszeichen das einen Text umschliesst.</param>
/// <returns>Index auf das erste Zeichen nach dem XML Element, auf das startIndex zeigt, oder -1 wenn der Vorgang scheitert.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlSkipQuotedText(xmlContainer, startIndex)
{
    try
    {
        if (startIndex < 0 || startIndex + 1 >= xmlContainer.length)
        {
            return -1;
        }

        var quote = xmlContainer[startIndex];

        if (quote != '"' && quote != '\'')
        {
            return startIndex;
        }

        startIndex = xmlContainer.indexOf(quote, startIndex + 1);

        if (startIndex < 0)
        {
            return -1;
        }
        else
        {
            return startIndex + 1;
        }
    }

    catch (exception)
    {
        return -1;
    }
}

//***********************************************************************************************************************************************************
// function NOXnetXmlIndexOfWhiteSpace(xmlContainer, startIndex)
// -------------------------------------------------------------
//
/// <summary>
/// Gibt den Index des ersten auftretens eines Zeichens zur�ck, das gem�ss XML Spezifikation (Version 1.0 Fifth Edition; 28.11.2008; Abschnitt 2.3) ein 
/// g�ltiges Leerraumzeichen ist. Die Suche beginnt an der Zeichenposition startIndex. Wenn der startIndex kleiner 0 oder gr�sser als die Anzahl Zeichen in 
/// xmlContainer ist, gibt die Funktion -1 zur�ck. Die Funktion f�hrt keine Typenpr�fung durch und f�ngt keine Ausnahmen ab.
/// </summary>
/// <param name="xmlContainer">Container der XML formatierten Text enth�lt.</param>
/// <param name="startIndex">Index auf das erste Zeichen das �berpr�ft wird.</param>
/// <returns>Index auf ein Leerraumzeichen, oder -1 wenn kein Leerraumzeichen gefunden wird.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlIndexOfWhiteSpace(xmlContainer, startIndex)
{
    if (startIndex < 0 || startIndex >= xmlContainer.length)
    {
        return -1;
    }

    var unicodePoint;

    while (startIndex < xmlContainer.length)
    {
        unicodePoint = xmlContainer.charCodeAt(startIndex);

        if (NOXnetXmlIsValidWhiteSpaceChar(unicodePoint))
        {
            return startIndex;
        }

        startIndex++;
    }

    return -1;
}

//***********************************************************************************************************************************************************
// function NOXnetXmlIndexOfNonWhiteSpace(xmlContainer, startIndex)
// ----------------------------------------------------------------
//
/// <summary>
/// Gibt den Index des ersten auftretens eines Zeichens zur�ck, das gem�ss XML Spezifikation (Version 1.0 Fifth Edition; 28.11.2008; Abschnitt 2.3) kein 
/// g�ltiges Leerraumzeichen ist. Die Suche beginnt an der Zeichenposition startIndex. Wenn der startIndex kleiner 0 oder gr�sser als die Anzahl Zeichen in 
/// xmlContainer ist, gibt die Funktion -1 zur�ck. Die Funktion f�hrt keine Typenpr�fung durch und f�ngt keine Ausnahmen ab.
/// </summary>
/// <param name="xmlContainer">Container der XML formatierten Text enth�lt.</param>
/// <param name="startIndex">Index auf das erste Zeichen das �berpr�ft wird.</param>
/// <returns>Index auf ein Zeichen das kein Leerraumzeichen ist, oder -1 wenn nur Leerraumzeichen gefunden werden.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlIndexOfNonWhiteSpace(xmlContainer, startIndex)
{
    if (startIndex < 0 || startIndex >= xmlContainer.length)
    {
        return -1;
    }

    var unicodePoint;

    while (startIndex < xmlContainer.length)
    {
        unicodePoint = xmlContainer.charCodeAt(startIndex);

        if (!NOXnetXmlIsValidWhiteSpaceChar(unicodePoint))
        {
            return startIndex;
        }

        startIndex++;
    }

    return -1;
}

//***********************************************************************************************************************************************************
// function NOXnetXmlIndexOfNameEnd(xmlContainer, startIndex)
// ----------------------------------------------------------
//
/// <summary>
/// Gibt den Index des ersten Zeichens zur�ck das einen XML Element- oder Attributnamen abschliesst. Gem�ss XML Spezifikation (Version 1.0 Fifth Edition; 
/// 28.11.2008; Abschnitt 2.3 und 3.1) kann dies f�r Elementnamen entweder ein Gr�sser-Als-Zeichen oder Leerraumzeichen sein. F�r Attributnamen kann dies
/// ein Leerraumzeichen oder ein Gleichheitszeichen sein. Es ist Aufgabe der aufrufenden Funktion, zu pr�fen ob dass das Abschlusszeichen zum Namentyp passt.
/// Diese Funktion f�ngt keine Ausnahmen ab.
/// </summary>
/// <remarks>
/// Diese Funktion �berpr�ft nicht ob die Zeichen innerhalb des XML Element- oder Attributnamens gem�ss XML Spezifikation g�ltig sind.
/// </remarks>
/// <param name="xmlContainer">Container der XML formatierten Text enth�lt.</param>
/// <param name="startIndex">Index auf eine Zeichenposition wo die Suche startet.</param>
/// <returns>Index auf ein Zeichen das kein Leerraumzeichen ist, oder -1 wenn nur Leerraumzeichen gefunden werden.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlIndexOfNameEnd(xmlContainer, startIndex)
{
    if (startIndex < 0 || startIndex >= xmlContainer.length)
    {
        return -1;
    }

    var unicodePoint;

    while (startIndex < xmlContainer.length)
    {
        unicodePoint = xmlContainer.charCodeAt(startIndex);

        if (unicodePoint == 0x3D || unicodePoint == 0x3E)
        {
            return startIndex;
        }

        if (NOXnetXmlIsValidWhiteSpaceChar(unicodePoint))
        {
            return startIndex;
        }

        startIndex++;
    }

    return -1;
}

//***********************************************************************************************************************************************************
// function NOXnetXmlIndexOfTagEnd(xmlContainer, startIndex)
// ---------------------------------------------------------
//
/// <summary>
/// Gibt den Index des ersten Zeichens nach einem XML Start- oder Endtag zur�ck. Gem�ss XML Spezifikation (Version 1.0 Fifth Edition; 28.11.2008; Abschnitt 
/// 3.1) wird als Tagendzeichen das Gr�sser-Als-Zeichen erwartet. Die Funktion ber�cksichtigt, dass dieses Zeichen innerhalb von Attributwerten vorkommen
/// darf. Diese Funktion f�hrt keine Typenpr�fung durch und f�ngt keine Ausnahmen ab.
/// </summary>
/// <remarks>
/// Diese Funktion �berpr�ft nicht, ob die Zeichen innerhalb des XML Tags gem�ss XML Spezifikation g�ltig sind. Die Funktion k�nnte aber dementsprechend
/// erweitert werden.
/// </remarks>
/// <param name="xmlContainer">Container der XML formatierten Text enth�lt.</param>
/// <param name="startIndex">Index auf eine Zeichenposition wo die Suche startet.</param>
/// <returns>Index auf ein Zeichen das kein Leerraumzeichen ist, oder -1 wenn nur Leerraumzeichen gefunden werden.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlIndexOfTagEnd(xmlContainer, startIndex)
{
    if (startIndex < 0 || startIndex >= xmlContainer.length)
    {
        return -1;
    }

    if (NOXnetXmlIsStartTag(xmlContainer, startIndex) || NOXnetXmlIsEndTag(xmlContainer, startIndex))
    {
        startIndex += 2;

        var indexZ = -1;
        var indexQ = -1;

        do
        {
            indexZ = xmlContainer.indexOf('>', startIndex);

            if (indexZ < 0)
            {
                return -1;
            }

            indexQ = xmlContainer.indexOf('"', startIndex);

            if (indexQ < 0 || indexQ > indexZ)
            {
                indexQ = xmlContainer.indexOf("'", startIndex);

                if (indexQ < 0 || indexQ > indexZ)
                {
                    return indexZ + 1;
                }
            }

            startIndex = NOXnetXmlSkipQuotedText(xmlContainer, indexQ);
        }
        while (startIndex >= 0);
    }

    return -1;
}

//***********************************************************************************************************************************************************
// function NOXnetXmlIndexOfEndTagClosing(xmlContainer, startIndex)
// ----------------------------------------------------------------
//
/// <summary>
/// Pr�ft ob startIndex auf einen g�ltigen XML Endtag zeigt und gibt in diesem Fall den Index nachfolgenden Zeichens zur�ck. Wenn startIndex auf ein LWS
/// Zeichen zeigt, dann wird der startIndex solange schrittweise erh�ht bis ein XML Endtag gefunden wird. Falls startIndex nicht auf einen XML Endtag oder
/// ein LWS zeigt, dann bricht die Funktion den Vorgang ab und gibt -1 zur�ck. Diese Funktion f�hrt keine Typenpr�fung durch und f�ngt keine Ausnahmen ab.
/// <param name="xmlContainer">Container der XML formatierten Text enth�lt.</param>
/// <param name="startIndex">Index auf eine Zeichenposition wo die Suche startet.</param>
/// <returns>Index auf ein Zeichen das kein Leerraumzeichen ist, oder -1 wenn nur Leerraumzeichen gefunden werden.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlIndexOfEndTagClosing(xmlContainer, startIndex)
{
    while (startIndex < xmlContainer.length)
    {
        switch (xmlContainer.charCodeAt(startIndex))
        {
            case 0x3E:
                return startIndex + 1;

            case 0x20:
            case 0x0D:
            case 0x0A:
            case 0x09:
                startIndex++;
                break;

            default:
                return -1;
        }
    }
    return -1;
}

//***********************************************************************************************************************************************************
// function NOXnetXmlIsComment(xmlContainer, index)
// ------------------------------------------------
//
/// <summary>
/// Gibt boolean true zur�ck, wenn der index auf das �ffnende Kleiner-Als-Zeichen eines XML Kommentars zeigt. Wenn der index kleiner 0 oder gr�sser als die 
/// Anzahl Zeichen in xmlContainer ist, gibt die Funktion boolean false zur�ck. Die Funktion f�hrt keine Typenpr�fung durch und f�ngt keine Ausnahmen ab.
/// </summary>
/// <remarks>
/// Diese Funktion erwartet, das der xmlContainer gem�ss der XML Spezifikation (Version 1.0 Fifth Edition; 28.11.2008) formatiert ist. Darin ist festgelegt,
/// dass das �ffnende Kleiner-Als-Zeichen ('<') nur als Markup-Zeichen vorkommen darf. Verletzungen dieser Regel werden von dieser Funktion nicht erkannt und 
/// f�hren zu unbestimmtem Verhalten.
/// </remarks>
/// <param name="xmlContainer">Container der XML formatierten Text enth�lt.</param>
/// <param name="index">Index auf eine Zeichenposition die �berpr�ft wird.</param>
/// <returns>Boolean true wenn der startIndex auf einen XML Kommentar zeigt.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlIsComment(xmlContainer, index)
{
    if (index < 0 || index + 4 >= xmlContainer.length)
    {
        return false;
    }

    return NOXnetXmlStartsWith(xmlContainer, '<!--', index);
}

//***********************************************************************************************************************************************************
// function NOXnetXmlIsEndTag(xmlContainer, index)
// -----------------------------------------------
//
/// <summary>
/// Gibt boolean true zur�ck, wenn der index auf das �ffnende Kleiner-Als-Zeichen eines XML Endtags zeigt. Wenn der index kleiner 0 oder gr�sser als die 
/// Anzahl Zeichen in xmlContainer ist, gibt die Funktion boolean false zur�ck. Die Funktion f�hrt keine Typenpr�fung durch und f�ngt keine Ausnahmen ab.
/// </summary>
/// <remarks>
/// Diese Funktion erwartet, das der xmlContainer gem�ss der XML Spezifikation (Version 1.0 Fifth Edition; 28.11.2008) formatiert ist. Darin ist festgelegt,
/// dass das �ffnende Kleiner-Als-Zeichen ('<') nur als Markup-Zeichen vorkommen darf. Verletzungen dieser Regel werden von dieser Funktion nicht erkannt und 
/// f�hren zu unbestimmtem Verhalten.
/// </remarks>
/// <param name="xmlContainer">Container der XML formatierten Text enth�lt.</param>
/// <param name="index">Index auf eine Zeichenposition die �berpr�ft wird.</param>
/// <returns>Boolean true wenn der startIndex auf einen XML Kommentar zeigt.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlIsEndTag(xmlContainer, index)
{
    if (index < 0 || index + 2 >= xmlContainer.length)
    {
        return false;
    }

    return NOXnetXmlStartsWith(xmlContainer, '</', index);
}

//***********************************************************************************************************************************************************
// function NOXnetXmlIsStartTag(xmlContainer, index)
// -------------------------------------------------
//
/// <summary>
/// Gibt boolean true zur�ck, wenn der index auf das �ffnende Kleiner-Als-Zeichen eines XML Starttags zeigt. Wenn der index kleiner 0 oder gr�sser als die 
/// Anzahl Zeichen in xmlContainer ist, gibt die Funktion boolean false zur�ck. Die Funktion f�hrt keine Typenpr�fung durch und f�ngt keine Ausnahmen ab.
/// </summary>
/// <remarks>
/// Diese Funktion erwartet, das der xmlContainer gem�ss der XML Spezifikation (Version 1.0 Fifth Edition; 28.11.2008) formatiert ist. Darin ist festgelegt,
/// dass das �ffnende Kleiner-Als-Zeichen ('<') nur als Markup-Zeichen vorkommen darf. Verletzungen dieser Regel werden von dieser Funktion nicht erkannt und 
/// f�hren zu unbestimmtem Verhalten.
/// </remarks>
/// <param name="xmlContainer">Container der XML formatierten Text enth�lt.</param>
/// <param name="index">Index auf eine Zeichenposition die �berpr�ft wird.</param>
/// <returns>Boolean true wenn der startIndex auf einen XML Kommentar zeigt.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlIsStartTag(xmlContainer, index)
{
    if (index < 0 || index + 2 >= xmlContainer.length)
    {
        return false;
    }

    if (xmlContainer[index] != '<')
    {
        return false;
    }

    var unicodePoint = xmlContainer.charCodeAt(index + 1);

    if (NOXnetXmlIsValidNameStartChar(unicodePoint))
    {
        return true;
    }
    else
    {
        return false;
    }
}

//***********************************************************************************************************************************************************
// function NOXnetXmlIsValidNameEndChar(xmlContainer, index)
// ---------------------------------------------------------
//
/// <summary>
/// Pr�ft ob index auf ein Zeichen zeigt das Folgezeichen eines XML-Namens g�ltig ist. Dies sind das XML-Endtag sowie alle g�ltigen LWS Zeichen. Wenn index
/// auf einen Backslash zeigt und unmittelbar danach ein XML-Endtag folgt, dann wird dies ebenfalls als g�ltig akzeptiert.
/// <param name="xmlContainer">Container der XML formatierten Text enth�lt.</param>
/// <param name="startIndex">Index auf eine Zeichenposition wo die Suche startet.</param>
/// <returns>Boolean true wenn index auf ein g�ltiges Folgezeichen eines XML-Namens zeigt.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlIsValidNameEndChar(xmlContainer, index)
{
    switch (xmlContainer.charCodeAt(index))
    {
        case 0x2F:
            return xmlContainer.charCodeAt(index + 1) == 0x3E ? true : false;

        case 0x3E:
        case 0x20:
        case 0x0D:
        case 0x0A:
        case 0x09:
            return true;

        default:
            return false;
    }
}

//***********************************************************************************************************************************************************
// function NOXnetXmlIsValidNameStartChar(unicodePoint)
// ----------------------------------------------------
//
/// <summary>
/// Gibt boolean true zur�ck, wenn das als Unicode Codepunkt �bergebene Zeichen gem�ss XML Spezifikation (Version 1.0 Fifth Edition; 28.11.2008; Abschnitt 
/// 2.3) als Zeichen auf der ersten Position eines XML Namens zul�ssig ist. Die Funktion f�hrt keine Typenpr�fung durch und f�ngt keine Ausnahmen ab.
/// </summary>
/// <remarks>
/// Diese Funktion ist gegen�ber der XML Spezifikation stark vereinfacht. Sie pr�ft nur Unicode Codepunkte im Bereich 0x00...0xFF. Der Bereich oberhalb 0xFF 
/// ist f�r die Kommunikation mit dem INNOXEL Master 3 nicht relevant. Die Codepunkte werden in dieser Implementation sequentiell gepr�ft, eine Optimierung 
/// nach dem Konzept der Sukzessiven Approximation ist zu pr�fen. 
/// </remarks>
/// <param name="unicodePoint">Unicode Codepunkt der �berpr�ft wird.</param>
/// <returns>Boolean true wenn der startIndex auf einen XML Kommentar zeigt.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlIsValidNameStartChar(unicodePoint)
{
    if (unicodePoint == 0x3A)
    {
        return true;
    }

    if (unicodePoint >= 0x41 && unicodePoint <= 0x5A)
    {
        return true;
    }

    if (unicodePoint == 0x5F)
    {
        return true;
    }

    if (unicodePoint >= 0x61 && unicodePoint <= 0x7A)
    {
        return true;
    }

    if (unicodePoint >= 0xC0 && unicodePoint <= 0xD6)
    {
        return true;
    }

    if (unicodePoint >= 0xD8 && unicodePoint <= 0xF6)
    {
        return true;
    }

    if (unicodePoint >= 0xF8 && unicodePoint <= 0xFF)
    {
        return true;
    }

    return false;
}

//***********************************************************************************************************************************************************
// function NOXnetXmlIsValidNameChar(unicodePoint)
// -----------------------------------------------
//
/// <summary>
/// Gibt boolean true zur�ck, wenn das als Unicode Codepunkt �bergebene Zeichen gem�ss XML Spezifikation (Version 1.0 Fifth Edition; 28.11.2008; Abschnitt 
/// 2.3) als Zeichen ab der zweiten Position eines XML Namens zul�ssig ist. Die Funktion f�hrt keine Typenpr�fung durch und f�ngt keine Ausnahmen ab.
/// </summary>
/// <remarks>
/// Diese Funktion ist gegen�ber der XML Spezifikation stark vereinfacht. Sie pr�ft nur Unicode Codepunkte im Bereich 0x00...0xFF. Der Bereich oberhalb 0xFF 
/// ist f�r die Kommunikation mit dem INNOXEL Master 3 nicht relevant. Die Codepunkte werden in dieser Implementation sequentiell gepr�ft, eine Optimierung 
/// nach dem Konzept der Sukzessiven Approximation ist zu pr�fen. 
/// </remarks>
/// <param name="unicodePoint">Unicode Codepunkt der �berpr�ft wird.</param>
/// <returns>Boolean true wenn das Zeichen ab der zweiten Position eines XML Namens zul�ssig ist.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlIsValidNameChar(unicodePoint)
{
    if (unicodePoint >= 0x2D && unicodePoint <= 0x2E)
    {
        return true;
    }

    if (unicodePoint >= 0x30 && unicodePoint <= 0x3A)
    {
        return true;
    }

    if (unicodePoint >= 0x41 && unicodePoint <= 0x5A)
    {
        return true;
    }

    if (unicodePoint == 0x5F)
    {
        return true;
    }

    if (unicodePoint >= 0x61 && unicodePoint <= 0x7A)
    {
        return true;
    }

    if (unicodePoint >= 0xC0 && unicodePoint <= 0xD6)
    {
        return true;
    }

    if (unicodePoint >= 0xD8 && unicodePoint <= 0xF6)
    {
        return true;
    }

    if (unicodePoint >= 0xF8 && unicodePoint <= 0xFF)
    {
        return true;
    }

    return false;
}

//***********************************************************************************************************************************************************
// function NOXnetXmlIsValidWhiteSpaceChar(unicodePoint)
// -----------------------------------------------------
//
/// <summary>
/// Gibt boolean true zur�ck, wenn das als Unicode Codepunkt �bergebene Zeichen gem�ss XML Spezifikation (Version 1.0 Fifth Edition; 28.11.2008; Abschnitt 
/// 2.3) ein g�ltiges Leerraumzeichen ist. Die Funktion f�hrt keine Typenpr�fung durch und f�ngt keine Ausnahmen ab.
/// </summary>
/// <param name="unicodePoint">Unicode Codepunkt der �berpr�ft wird.</param>
/// <returns>Boolean true wenn das Zeichen ein Lerraumzeichen ist.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlIsValidWhiteSpaceChar(unicodePoint)
{
    switch (unicodePoint)
    {
        case 0x20:
        case 0x0D:
        case 0x0A:
        case 0x09:
            return true;

        default:
            return false;
    }
}

//***********************************************************************************************************************************************************
// function NOXnetXmlStartsWith(originString, searchString, position)
// ------------------------------------------------------------------
//
/// <summary>
/// Gibt boolean true zur�ck, wenn die Zeichenfolge an einer bestimmten Position in einem String mit einer gesuchten Zeichenkette �bereinstimmt. Wenn das
/// Argument position kleiner 0 oder gr�sser als die Stringl�nge ist, wird der Vorgang mit boolean false abgebrochen. Wenn das Stringende erreicht ist bevor
/// alle Zeichen der gesuchten Zeichenkette gepr�ft wurden, gibt die Funktion boolean false zur�ck. Die Funktion f�hrt Typenpr�fungen durch und bricht im
/// Fehlerfall mit boolean false ab. Die Funktion f�ngt keine Ausnahmen ab.
/// 
/// </summary>
/// <remarks>
/// Diese Funktion substituiert die Javascript Methode string.startsWith(searchString[, position]) die vielen Javascript Implementationen auf Mobilger�ten
/// nicht unterst�tzt wird (Stand M�rz 2016).
/// </remarks>
/// <param name="originString">Zeichenfolge die durchsucht wird.</param>
/// <param name="searchString">Zeichenfolge die gesucht wird.</param>
/// <param name="position">Position an der der Zeichenvergleich beginnt.</param>
/// <returns>Boolean true wenn sich die gesuchte Zeichenfolge an der Position im String befindet.</returns>
//***********************************************************************************************************************************************************

function NOXnetXmlStartsWith(originString, searchString, position)
{
    if (typeof (originString) != 'string' || originString == null)
    {
        return false;
    }

    if (typeof (searchString) != 'string' || searchString == null)
    {
        return false;
    }

    if (position < 0 || position >= originString.length)
    {
        return false;
    }

    if (originString.length == 0 || searchString.length == 0)
    {
        return false;
    }

    for (var indexS = 0; indexS < searchString.length; indexS++)
    {
        if (originString[position] != searchString[indexS])
        {
            return false;
        }

        position++;

        if (position >= originString.length)
        {
            return false;
        }
    }

    return true;
}

//***********************************************************************************************************************************************************
// End of NOXnetSoapScript.js
//***********************************************************************************************************************************************************

function GetControlElements(xmlElementSectionList, ignoreRoomRegulations)
{
    // <section>
    //   <title>Taster Eingang</title>
    //   <control type="button" id="968585C7-3CA8-F949-9D26-A92D9CC9A751">
    //     <title>Korridor</title>
    //     <icon source="icon_feedback_20000_#.png" states="0;4">
	//       <style mode="light" state="active" filename="0101w-ffb500.svg" text="An" textcolor="#ffb500"/>
	//       <style mode="light" state="inactive" filename="0102w-7d7d7d.svg" text="Aus" textcolor="#7d7d7d"/>
	//       <style mode="dark" state="active" filename="0101w-ffb500.svg" text="An" textcolor="#ffb500"/>
	//       <style mode="dark" state="inactive" filename="0102w-ffffff.svg" text="Aus" textcolor="#ffffff"/>
	//     </icon>
    //     <assignmentList>
    //       <assignment moduleClass="masterInModule" moduleIndex="0" channelClass="masterInModuleChannel" channelIndex="2" direction="tx"/>
    //       <assignment moduleClass="masterOutModule" moduleIndex="0" channelClass="masterOutModuleChannel" channelIndex="7" direction="rx"/>
    //     </assignmentList>
    //   </control>
    //   <control type="button" id="FEBB3575-9DCB-4AE0-B4C1-2E6A707EAD07">
    //     <title>Aussen</title>
    //     <icon source="icon_feedback_20000_#.png" states="0;4">
	//       <style mode="light" state="active" filename="0101w-ffb500.svg" text="An" textcolor="#ffb500"/>
	//       <style mode="light" state="inactive" filename="0102w-7d7d7d.svg" text="Aus" textcolor="#7d7d7d"/>
	//       <style mode="dark" state="active" filename="0101w-ffb500.svg" text="An" textcolor="#ffb500"/>
	//       <style mode="dark" state="inactive" filename="0102w-ffffff.svg" text="Aus" textcolor="#ffffff"/>
	//     </icon>
    //     <assignmentList>
    //       <assignment moduleClass="masterInModule" moduleIndex="0" channelClass="masterInModuleChannel" channelIndex="3" direction="tx"/>
    //       <assignment moduleClass="masterOutModule" moduleIndex="0" channelClass="masterOutModuleChannel" channelIndex="5" direction="rx"/>
    //     </assignmentList>
    //   </control>
    // </section>

    const controlElements = [];

    if (xmlElementSectionList !== null)
    {
        for (let i = 0; i < xmlElementSectionList.length; i++)
        {
            // Get XML element of <section></section>
            let xmlElementSection = xmlElementSectionList[i];
            
            if (xmlElementSection !== null)
            {
                // Get all XML elements <control></control> stored in <section></section>
                let xmlElementControlList = NOXnetXmlElements(xmlElementSection, 'control');

                // <control type="button" id="968585C7-3CA8-F949-9D26-A92D9CC9A751">
                //   <title>Korridor</title>
                //   <icon source="icon_feedback_20000_#.png" states="0;4">
                //     <style mode="light" state="active" filename="0101w-ffb500.svg" text="An" textcolor="#ffb500"/>
                //     <style mode="light" state="inactive" filename="0102w-7d7d7d.svg" text="Aus" textcolor="#7d7d7d"/>
                //     <style mode="dark" state="active" filename="0101w-ffb500.svg" text="An" textcolor="#ffb500"/>
                //     <style mode="dark" state="inactive" filename="0102w-ffffff.svg" text="Aus" textcolor="#ffffff"/>
                //   </icon>
                //   <assignmentList>
                //     <assignment moduleClass="masterInModule" moduleIndex="0" channelClass="masterInModuleChannel" channelIndex="2" direction="tx"/>
                //     <assignment moduleClass="masterOutModule" moduleIndex="0" channelClass="masterOutModuleChannel" channelIndex="7" direction="rx"/>
                //   </assignmentList>
                // </control>
                // <control type="button" id="FEBB3575-9DCB-4AE0-B4C1-2E6A707EAD07">
                //   <title>Aussen</title>
                //   <icon source="icon_feedback_20000_#.png" states="0;4">
                //     <style mode="light" state="active" filename="0101w-ffb500.svg" text="An" textcolor="#ffb500"/>
                //     <style mode="light" state="inactive" filename="0102w-7d7d7d.svg" text="Aus" textcolor="#7d7d7d"/>
                //     <style mode="dark" state="active" filename="0101w-ffb500.svg" text="An" textcolor="#ffb500"/>
                //     <style mode="dark" state="inactive" filename="0102w-ffffff.svg" text="Aus" textcolor="#ffffff"/>
                //   </icon>
                //   <assignmentList>
                //     <assignment moduleClass="masterInModule" moduleIndex="0" channelClass="masterInModuleChannel" channelIndex="3" direction="tx"/>
                //     <assignment moduleClass="masterOutModule" moduleIndex="0" channelClass="masterOutModuleChannel" channelIndex="5" direction="rx"/>
                //   </assignmentList>
                // </control>
                
                if (xmlElementControlList !== null)
                {
                    for (let j = 0; j < xmlElementControlList.length; j++)
                    {
                        // Get XML element of <control></control>
                        let xmlElementControl = xmlElementControlList[j];

                        // Get control element
                        let controlElement = GetControlElement(xmlElementControl, ignoreRoomRegulations)

                        if (controlElement !== null)
                        {
                            controlElements.push(controlElement);
                        }
                    }
                }
            }
        }
    }

    return controlElements;
}

function GetControlElement(xmlElementControl, ignoreRoomRegulations)
{
    // xmlElementControl should be:
    //
    // <control type="button" id="968585C7-3CA8-F949-9D26-A92D9CC9A751">
    //   <title>Korridor</title>
    //   <icon source="icon_feedback_20000_#.png" states="0;4">
    //     <style mode="light" state="active" filename="0101w-ffb500.svg" text="An" textcolor="#ffb500"/>
    //     <style mode="light" state="inactive" filename="0102w-7d7d7d.svg" text="Aus" textcolor="#7d7d7d"/>
    //     <style mode="dark" state="active" filename="0101w-ffb500.svg" text="An" textcolor="#ffb500"/>
    //     <style mode="dark" state="inactive" filename="0102w-ffffff.svg" text="Aus" textcolor="#ffffff"/>
    //   </icon>
    //   <assignmentList>
    //     <assignment moduleClass="masterInModule" moduleIndex="0" channelClass="masterInModuleChannel" channelIndex="2" direction="tx"/>
    //     <assignment moduleClass="masterOutModule" moduleIndex="0" channelClass="masterOutModuleChannel" channelIndex="7" direction="rx"/>
    //   </assignmentList>
    // </control>

    // Define return value
    let controlElement = null;

    // TODO: Check if <control>

    // Get type of control element
    let type = NOXnetXmlAttribute(xmlElementControl, 'type');

    switch (type)
    {
        case "button":
            controlElement = GetControlElementButton(xmlElementControl);
            break;
        case "slider":
            controlElement = GetControlElementSlider(xmlElementControl);
            break;
        case "roomClimateControl":
            if (ignoreRoomRegulations) {
                controlElement = null
            } else {
                controlElement = GetControlElementRoomClimateControl(xmlElementControl);
            }
            break;
        case "blind":
            controlElement = GetControlElementBlind(xmlElementControl);
            break;
        case "ipcamControl":
            controlElement = GetControlElementIpCamera(xmlElementControl);
            break;
        default:
            break;
    }

    return controlElement;
}

function GetControlElementButton(xmlElementControl)
{
    // Get all XML elements stored in <control></control>
    let xmlElementContentOfControl = NOXnetXmlElements(xmlElementControl, null);

    // <title>Korridor</title>
    // <icon source="icon_feedback_20000_#.png" states="0;4">
    //   <style mode="light" state="active" filename="0101w-ffb500.svg" text="An" textcolor="#ffb500"/>
    //   <style mode="light" state="inactive" filename="0102w-7d7d7d.svg" text="Aus" textcolor="#7d7d7d"/>
    //   <style mode="dark" state="active" filename="0101w-ffb500.svg" text="An" textcolor="#ffb500"/>
    //   <style mode="dark" state="inactive" filename="0102w-ffffff.svg" text="Aus" textcolor="#ffffff"/>
    // </icon>
    // <assignmentList>
    //   <assignment moduleClass="masterInModule" moduleIndex="0" channelClass="masterInModuleChannel" channelIndex="2" direction="tx"/>
    //   <assignment moduleClass="masterOutModule" moduleIndex="0" channelClass="masterOutModuleChannel" channelIndex="7" direction="rx"/>
    // </assignmentList>

    let id = null;
    let name = null;
    let assignments = null;
    let confirmations = null;

    // Set 'Unknown' as default
    let iconActiveLightMode = 'unknown-on-0a4297.svg';
    let iconInactiveLightMode = 'unknown-off-7d7d7d.svg';
    let statusTextActiveLightMode = 'Aktiv';
    let statusTextInactiveLightMode = 'Nicht aktiv';
    let statusTextColorActiveLightMode = '#0a4297';
    let statusTextColorInactiveLightMode = '#7d7d7d';
    let iconActiveDarkMode = 'unknown-on-0a84fe.svg';
    let iconInactiveDarkMode = 'unknown-off-ffffff.svg';
    let statusTextActiveDarkMode = 'Aktiv';
    let statusTextInactiveDarkMode = 'Nicht aktiv';
    let statusTextColorActiveDarkMode = '#0a84fe';
    let statusTextColorInactiveDarkMode = '#ffffff';

    for (let n = 0; n < xmlElementContentOfControl.length; n++)
    {
        // Get XML element
        let xmlElement = xmlElementContentOfControl[n];

        // Get name of XML tag
        let xmlName = NOXnetXmlName(xmlElement);
        
        switch (xmlName)
        {
            case 'title':
                const encodedName = NOXnetXmlValue(xmlElement);
                name = decodeHtmlEntities(encodedName);
                break;
            case 'icon':
                {
                    // Get all XML elements <style/> stored in <icon></icon>
                    let xmlElementStyleList = NOXnetXmlElements(xmlElement, 'style');
                    
                    if (xmlElementStyleList !== null) {
                        for (let m = 0; m < xmlElementStyleList.length; m++)
                        {
                            // Get XML element of <style/>
                            let xmlElementStyle = xmlElementStyleList[m];

                            // Get attributes of <style />
                            let mode = NOXnetXmlAttribute(xmlElementStyle, 'mode');
                            let state = NOXnetXmlAttribute(xmlElementStyle, 'state');
                            let filename = NOXnetXmlAttribute(xmlElementStyle, 'filename');
                            let text = NOXnetXmlAttribute(xmlElementStyle, 'text');
                            let textcolor = NOXnetXmlAttribute(xmlElementStyle, 'textcolor');

                            if (mode === 'light')
                            {
                                if (state === 'active')
                                {
                                    iconActiveLightMode = filename;
                                    statusTextActiveLightMode = text;
                                    statusTextColorActiveLightMode = textcolor;
                                }
                                else if (state === 'inactive')
                                {
                                    iconInactiveLightMode = filename;
                                    statusTextInactiveLightMode = text;
                                    statusTextColorInactiveLightMode = textcolor;
                                }
                            }
                            else if (mode === 'dark')
                            {
                                if (state === 'active')
                                {
                                    iconActiveDarkMode = filename;
                                    statusTextActiveDarkMode = text;
                                    statusTextColorActiveDarkMode = textcolor;
                                }
                                else if (state === 'inactive')
                                {
                                    iconInactiveDarkMode = filename;
                                    statusTextInactiveDarkMode = text;
                                    statusTextColorInactiveDarkMode = textcolor;
                                }
                            }
                        }
                    }
                }
                break;
            case 'assignmentList':
                assignments = GetAssignmentList(xmlElement);
                break;
            case 'confirmationList':
                confirmations = GetConfirmationList(xmlElement);
                break;
            default:
                break;
        }
    }

    id = NOXnetXmlAttribute(xmlElementControl, 'id');

    // Create new 'Control Element Switch Configuration' for 'Light Mode'
    const configurationLightMode = new ControlElementSwitchConfiguration()
    configurationLightMode.setIconActive(iconActiveLightMode)
    configurationLightMode.setIconInactive(iconInactiveLightMode)
    configurationLightMode.setStatusTextActive(statusTextActiveLightMode)
    configurationLightMode.setStatusTextInactive(statusTextInactiveLightMode)
    configurationLightMode.setStatusTextColorActive(statusTextColorActiveLightMode)
    configurationLightMode.setStatusTextColorInactive(statusTextColorInactiveLightMode)

    // Create new 'Control Element Switch Configuration' for 'Dark Mode'
    const configurationDarkMode = new ControlElementSwitchConfiguration()
    configurationDarkMode.setIconActive(iconActiveDarkMode)
    configurationDarkMode.setIconInactive(iconInactiveDarkMode)
    configurationDarkMode.setStatusTextActive(statusTextActiveDarkMode)
    configurationDarkMode.setStatusTextInactive(statusTextInactiveDarkMode)
    configurationDarkMode.setStatusTextColorActive(statusTextColorActiveDarkMode)
    configurationDarkMode.setStatusTextColorInactive(statusTextColorInactiveDarkMode)

    // Create new 'Control Element Switch'
    const controlElement = new ControlElementSwitch(id, name, assignments, confirmations, true, false)
    controlElement.setConfigurationForLightMode(configurationLightMode)
    controlElement.setConfigurationForDarkMode(configurationDarkMode)

    return controlElement;
}

function GetControlElementSlider(xmlElementControl)
{
    try {
        // Get all XML elements stored in <control></control>
        let xmlElementContentOfControl = NOXnetXmlElements(xmlElementControl, null);

        // <title>Keller Spot</title>
        // <subtitle>Showroom -Entrée</subtitle>
        // <icon source="icon_feedback_20000_#.png" states="0;4" />
        // <assignmentList>
        //   <assignment moduleClass="masterDimModule" moduleIndex="16" channelIndex="0" direction="rx;tx" />
        // </assignmentList>

        let id = null;
        let name = null;
        let assignments = null;

        for (let n = 0; n < xmlElementContentOfControl.length; n++)
        {
            // Get XML element
            let xmlElement = xmlElementContentOfControl[n];

            // Get name of XML tag
            let xmlName = NOXnetXmlName(xmlElement);
            
            switch (xmlName)
            {
                case 'title':
                    const encodedName = NOXnetXmlValue(xmlElement);
                    name = decodeHtmlEntities(encodedName);
                    break;
                case 'assignmentList':
                    assignments = GetAssignmentList(xmlElement);
                    break;
                default:
                    break;
            }
        }

        id = NOXnetXmlAttribute(xmlElementControl, 'id');

        // Create new 'Control Element Dimmer'
        const controlElement = new ControlElementDimmer(id, name, assignments, true, false);

        return controlElement;
    }
    catch (exception) {
        console.log(exception);
        return null;
    }
}

function GetControlElementRoomClimateControl(xmlElementControl)
{
    try {
        // Get all XML elements stored in <control></control>
        let xmlElementContentOfControl = NOXnetXmlElements(xmlElementControl, null);

        // <title>TH5: Showroom - Essen - Essen</title>
        // <subtitle>Aktuelle Temperatur</subtitle>
        // <assignmentList>
        //   <assignment moduleClass="masterRoomClimateModule" moduleIndex="5" direction="rx;tx" />
        // </assignmentList>

        let id = null;
        let name = null;
        let assignments = null;

        for (let n = 0; n < xmlElementContentOfControl.length; n++)
        {
            // Get XML element
            let xmlElement = xmlElementContentOfControl[n];

            // Get name of XML tag
            let xmlName = NOXnetXmlName(xmlElement);
            
            switch (xmlName)
            {
                case 'title':
                    const encodedName = NOXnetXmlValue(xmlElement);
                    name = decodeHtmlEntities(encodedName);
                    break;
                case 'assignmentList':
                    assignments = GetAssignmentList(xmlElement);
                    break;
                default:
                    break;
            }
        }

        id = NOXnetXmlAttribute(xmlElementControl, 'id');

        // Create new 'Control Element Room Climate'
        const controlElement = new ControlElementRoomClimate(id, name, assignments, true, false);

        return controlElement;
    }
    catch (exception) {
        console.log(exception);
        return null;
    }
}

function GetControlElementBlind(xmlElementControl)
{
    try {
        // Get all XML elements stored in <control></control>
        let xmlElementContentOfControl = NOXnetXmlElements(xmlElementControl, null);

        // <title>Store Essen Nord AUF</title>
        // <subtitle>Showroom -Entrée</subtitle>
        // <assignmentList>
        //   <assignment moduleClass="masterBlindModule" moduleIndex="10"  channelClass="masterBlindModuleChannel" channelIndex="0" direction="rx;tx" />
        // </assignmentList>

        let id = null;
        let name = null;
        let assignments = null;

        for (let n = 0; n < xmlElementContentOfControl.length; n++)
        {
            // Get XML element
            let xmlElement = xmlElementContentOfControl[n];

            // Get name of XML tag
            let xmlName = NOXnetXmlName(xmlElement);
            
            switch (xmlName)
            {
                case 'title':
                    const encodedName = NOXnetXmlValue(xmlElement);
                    name = decodeHtmlEntities(encodedName);
                    break;
                case 'assignmentList':
                    assignments = GetAssignmentList(xmlElement);
                    break;
                default:
                    break;
            }
        }

        id = NOXnetXmlAttribute(xmlElementControl, 'id');

        // Create new 'Control Element Blind'
        const controlElement = new ControlElementBlind(id, name, assignments, true, false)

        return controlElement;
    }
    catch (exception) {
        console.log(exception);
        return null;
    }
}

function GetControlElementIpCamera(xmlElementControl)
{
    try {
        // Get all XML elements stored in <control></control>
        let xmlElementContentOfControl = NOXnetXmlElements(xmlElementControl, null);
        
        // <title>Showroom Hägendorf</title>
        // <subtitle>Kamera</subtitle>
        // <media type="livevideo" lifetime="0" />
        // <assignmentList>
        //   <assignment moduleClass="masterCgiModule" moduleIndex="0" direction="rx;tx"/>
        // </assignmentList>

        let id = null;
        let name = null;
        let assignments = null;

        for (let n = 0; n < xmlElementContentOfControl.length; n++)
        {
            // Get XML element
            let xmlElement = xmlElementContentOfControl[n];

            // Get name of XML tag
            let xmlName = NOXnetXmlName(xmlElement);
            
            switch (xmlName)
            {
                case 'title':
                    const encodedName = NOXnetXmlValue(xmlElement);
                    name = decodeHtmlEntities(encodedName);
                    break;
                case 'assignmentList':
                    assignments = GetAssignmentList(xmlElement);
                    break;
                default:
                    break;
            }
        }

        id = NOXnetXmlAttribute(xmlElementControl, 'id');

        // Create new 'Control Element IP Camera'
        const controlElement = new ControlElementIpCamera(id, name, assignments, true, false)

        return controlElement;
    }
    catch (exception) {
        console.log(exception);
        return null;
    }
}

function GetAssignmentList(xmlElement)
{
    const assignments = [];
    let assignmentList = NOXnetXmlElements(xmlElement, 'assignment');
    
    for (let i = 0; i < assignmentList.length; i++) {
        let assignment = assignmentList[i];

        let moduleClass = NOXnetXmlAttribute(assignment, 'moduleClass');
        let moduleIndex = NOXnetXmlAttribute(assignment, 'moduleIndex');
        let channelClass = NOXnetXmlAttribute(assignment, 'channelClass');
        let channelIndex = NOXnetXmlAttribute(assignment, 'channelIndex');
        let direction = NOXnetXmlAttribute(assignment, 'direction');

        // Some channels do not have a channelClass
        // -->  Generate them channelClass
        if (channelClass == null) {
            channelClass = moduleClass + 'Channel'
        }

        const myAssignment = new Assignment(moduleClass, moduleIndex, channelClass, channelIndex, direction);
        assignments.push(myAssignment)
    }

    return assignments;
}

function GetConfirmationList(xmlElement)
{
    const confirmations = [];
    let confirmationList = NOXnetXmlElements(xmlElement, 'confirmation');
 
    for (let i = 0; i < confirmationList.length; i++) {
        let confirmation = confirmationList[i];

        let message = NOXnetXmlAttribute(confirmation, 'message');
        let keyOk = NOXnetXmlAttribute(confirmation, 'keyOk');
        let keyAbort = NOXnetXmlAttribute(confirmation, 'keyAbort');
        let transition = NOXnetXmlAttribute(confirmation, 'transition');

        const myConfirmation = new Confirmation(message, keyOk, keyAbort, transition);
        confirmations.push(myConfirmation);
    }

    return confirmations;
}

function GetRoomStuff(xmlElementSectionList, home)
{
    const rooms = [];
    
    if (xmlElementSectionList !== null)
    {
        for (let i = 0; i < xmlElementSectionList.length; i++)
        {
            // Get section
            let xmlElementSection = xmlElementSectionList[i];

            switch (NOXnetXmlAttribute(xmlElementSection, 'type'))
            {
                case 'rooms':
                    let xmlElementControlList = NOXnetXmlElements(xmlElementSection, 'control');
                    
                    if (xmlElementControlList !== null)
                    {
                        for (let j = 0; j < xmlElementControlList.length; j++)
                        {
                            let id = NOXnetXmlAttribute(xmlElementControlList[j], 'id');

                            let title;
                            let controlElements;
                            const idsOfControlElements = [];

                            let xmlElementControl = NOXnetXmlElements(xmlElementControlList[j], null);
                            
                            for (let n = 0; n < xmlElementControl.length; n++)
                            {
                                let xmlname = NOXnetXmlName(xmlElementControl[n]);
                                
                                switch (xmlname)
                                {
                                    case 'title':
                                        const encodedTitle = NOXnetXmlValue(xmlElementControl[n]);
                                        title = decodeHtmlEntities(encodedTitle);
                                        break;
                                    case 'subview':
                                        let xmlElementSectionList = NOXnetXmlElements(xmlElementControl[n], null);
                                        controlElements = GetControlElements(xmlElementSectionList, true);
                                        controlElements.forEach(tmpControlElement => {
                                            home.addControlElement(tmpControlElement)
                                            idsOfControlElements.push(tmpControlElement.id)
                                        })
                                        break;
                                    default:
                                        break;
                                }
                            }

                            const room = new Room(id, title, idsOfControlElements);
                            rooms.push(room);
                        }
                    }
                    break;
            }
        }
    }

    return rooms;
}

function GetAreas(xmlElementSection, home)
{
    //const areas = [];
    //const rooms = [];
    
    let xmlElementControlList = NOXnetXmlElements(xmlElementSection, 'control');
    
    if (xmlElementControlList !== null)
    {
        for (let i = 0; i < xmlElementControlList.length; i++)
        {
            let area = null;
            let xmlElement = xmlElementControlList[i];
            let id = NOXnetXmlAttribute(xmlElement, 'id');

            let xmlElementControl = NOXnetXmlElements(xmlElement, null);

            for (let n = 0; n < xmlElementControl.length; n++)
            {
                let xmlname = NOXnetXmlName(xmlElementControl[n]);
          
                switch (xmlname)
                {
                    case 'title':
                        const encodedTitle = NOXnetXmlValue(xmlElementControl[n]);
                        const title = decodeHtmlEntities(encodedTitle);
                        area = new Area(id, title, n+1);
                        home.addArea(area)
                        //areas.push(area);
                        break;
                    case 'subview':
                        let xmlElementSectionList = NOXnetXmlElements(xmlElementControl[n], null);
                        let tmpRooms = GetRoomStuff(xmlElementSectionList, home);
                        tmpRooms.forEach(tmpRoom => {
                            //rooms.push(tmpRoom)
                            home.addRoom(tmpRoom)
                            area.addRoomId(tmpRoom.id);
                        });
                        break;
                    default:
                        break;
                }
            }
        }

        //home.areas = areas;
        //home.rooms = rooms;
    }

    //return areas;
}

function GetGlobal(xmlElementSection, home)
{
    let globalFunctions = null;
    let globalTimers = null;
    let globalScenes = null;
    let roomRegulations = null;
    const idsOfControlElementsOfGlobalFunctions = [];
    const idsOfControlElementsOfGlobalTimers = [];
    const idsOfControlElementsOfGlobalScenes = [];
    const idsOfControlElementsOfRoomRegulations = [];
    
    let xmlElementControlList = NOXnetXmlElements(xmlElementSection, 'control');
    
    if (xmlElementControlList !== null)
    {
        for (let i = 0; i < xmlElementControlList.length; i++)
        {
            let xmlElement = xmlElementControlList[i];
            let subtype = NOXnetXmlAttribute(xmlElement, 'subtype');

            switch (subtype)
            {
                case "functions":
                    {
                        const xmlElementSubview = NOXnetXmlElements(xmlElement, 'subview');
                        
                        if (xmlElementSubview !== null)
                        {
                            const xmlElementGlobalFunctions = NOXnetXmlElements(xmlElementSubview[0], null);
                            const controlElements = GetControlElements(xmlElementGlobalFunctions, true);
                            controlElements.forEach(controlElement => {
                                home.addControlElement(controlElement);
                                idsOfControlElementsOfGlobalFunctions.push(controlElement.id)
                            })
                            globalFunctions = new GlobalFunctions(idsOfControlElementsOfGlobalFunctions);
                        }
                    }
                    break;
                case "timers":
                    {
                        const xmlElementSubview = NOXnetXmlElements(xmlElement, 'subview');
                        
                        if (xmlElementSubview !== null)
                        {
                            const xmlElementGlobalTimers = NOXnetXmlElements(xmlElementSubview[0], null);
                            const controlElements = GetControlElements(xmlElementGlobalTimers, true);
                            controlElements.forEach(controlElement => {
                                idsOfControlElementsOfGlobalTimers.push(controlElement.id)
                                home.addControlElement(controlElement);
                            })
                            globalTimers = new GlobalTimers(idsOfControlElementsOfGlobalTimers);
                        }
                    }
                    break;
                case "scenes":
                    {
                        const xmlElementSubview = NOXnetXmlElements(xmlElement, 'subview');
                        
                        if (xmlElementSubview !== null)
                        {
                            const xmlElementGlobalScenes = NOXnetXmlElements(xmlElementSubview[0], null);
                            const controlElements = GetControlElements(xmlElementGlobalScenes, true);
                            controlElements.forEach(controlElement => {
                                idsOfControlElementsOfGlobalScenes.push(controlElement.id)
                                home.addControlElement(controlElement);
                            })
                            globalScenes = new GlobalScenes(idsOfControlElementsOfGlobalScenes);
                        }
                    }
                    break;
                case "roomregulations":
                    {
                        const xmlElementSubview = NOXnetXmlElements(xmlElement, 'subview');
                        
                        if (xmlElementSubview !== null)
                        {
                            const xmlElementRoomRegulations = NOXnetXmlElements(xmlElementSubview[0], null);    
                            const controlElements = GetControlElements(xmlElementRoomRegulations, false);    
                            controlElements.forEach(controlElement => {
                                idsOfControlElementsOfRoomRegulations.push(controlElement.id)
                                home.addControlElement(controlElement);
                            })
                            roomRegulations = new RoomRegulations(idsOfControlElementsOfRoomRegulations);
                        }
                    }
                    break;
                default:
                    console.log("Unknown: ", subtype);
                    break;
            }
        }
    }

    home.global = new Global(globalFunctions, globalTimers, globalScenes);
    home.climate = new Climate(roomRegulations);
}

function GetHomeStuff(xmlElementView, home)
{
    if (xmlElementView !== null)
    {
        // Get name
        let xmlTitle = NOXnetXmlElement(xmlElementView, 'title');
        
        if (xmlTitle != null)
        {
            const encodedName = NOXnetXmlValue(xmlTitle);
            home.name = decodeHtmlEntities(encodedName);
        }

        let xmlElementSectionList = NOXnetXmlElements(xmlElementView, 'section');

        for (let i = 0; i < xmlElementSectionList.length; i++)
        {
            // Get section
            let xmlElementSection = xmlElementSectionList[i];

            switch (NOXnetXmlAttribute(xmlElementSection, 'type'))
            {
                case 'areas':
                    GetAreas(xmlElementSection, home);
                    break;
                case 'global':
                    GetGlobal(xmlElementSection, home);
                    break;
                default:
                    break;
            }
        }
    }
}

function AddWeatherStuff(xmlElementView, home) {
    if (xmlElementView !== null)
    {
        const id = NOXnetXmlAttribute(xmlElementView, 'id')
        const assignments = [ new Assignment('masterWeatherModule', '0', '', '', '') ]
        const controlElementWeather = new ControlElementWeather(id, 'Weather', assignments, true, false)
        home.addControlElement(controlElementWeather)
    }
}

function decodeHtmlEntities(encodedText) {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = encodedText;
    const decodedText = textarea.value;
	return decodedText;
}

//***********************************************************************************************************************************************************
// NOXnetWebFrameInitializeLayoutSuccessCallback(noxnetWebFrameHandle, soapResponse)
// ---------------------------------------------------------------------------------
//
/// <summary>
/// Nimmt eine SOAP-Respons entgegen, die einen XML-Deskriptor für das Layout einer INNOXEL Master 3 Web-App enthält und generiert daraus eine
/// Benutzeransicht mit den zugehörigen Benutzersteuerelementen.
/// </summary>
/// <param name="noxnetWebFrameHandle">Verweis auf das NOXnetWebFrame-Objekt das den Vorgang ausgelöst hat.</param>
/// <param name="soapResponse">SOAP-Response, die einen XML-Deskriptor für das Layout einer INNOXEL Master 3 Web-App enthält.</param>
//***********************************************************************************************************************************************************

export function NOXnetWebFrameInitializeLayoutSuccessCallback(noxnetWebFrameHandle, soapResponse)
{
    // Get root element of XML file
    let xmlElementRoot = NOXnetXmlElement(soapResponse, 'webappDescriptor');

    if (xmlElementRoot !== null)
    {
        noxnetWebFrameHandle.webAppConfiguration = Parse2(xmlElementRoot);
        noxnetWebFrameHandle.parsed = true;
    }
}

export function Parse(xmlFile)
{
    let xmlElementRoot = abc(xmlFile, 'webappDescriptor')

    if (xmlElementRoot !== null)
    {
        return Parse2(xmlElementRoot);
    }
    else
    {
        return null;
    }
}

export function Parse2(xmlElementRoot)
{
    const home = new Home();

    if (xmlElementRoot !== null)
    {
        // Get 'Configuration ID'
        home.configurationId = NOXnetXmlAttribute(xmlElementRoot, 'configurationId')

        // Get 'Version'
        home.version = NOXnetXmlAttribute(xmlElementRoot, 'version')

        // Get 'Setup Version'
        home.setupVersion = NOXnetXmlAttribute(xmlElementRoot, 'setupVersion')

        // Get all views stored in XML file
        let xmlElementViewList = NOXnetXmlElements(xmlElementRoot, 'view');

        for (let i = 0; i < xmlElementViewList.length; i++)
        {
            // Get view
            let xmlElementView = xmlElementViewList[i];

            switch (NOXnetXmlAttribute(xmlElementView, 'type'))
            {
                case 'energyMetering':
                    break;
                case 'home':
                    GetHomeStuff(xmlElementView, home);
                    break;
                case 'weather':
                    AddWeatherStuff(xmlElementView, home);
                    break;
                default:
                    break; 
            }
        }
    }

    return home;
}

function abc(soapResponse, name)
{
    try
    {
        // TODO: XML-Header �berpr�fen
        // TODO: Envelope Namespace und Encoding-Style �berpr�fen.

        //**********************************************************************************************************
        // SOAP-Envelope eingrenzen und isolieren. Da es sich hierbei um das XML-Wurzelelement handelt, erfolgt dies
        // noch ohne XML-Parserfunktionen.

        var indexES = soapResponse.indexOf(name);

        if (NOXnetXmlIsValidNameEndChar(soapResponse, indexES + name.length) === false)
        {
            return null;
        }

        indexES = soapResponse.lastIndexOf('<', indexES);

        if (indexES < 0)
        {
            return null;
        }

        var indexEE = soapResponse.lastIndexOf(name);

        if (NOXnetXmlIsValidNameEndChar(soapResponse, indexEE + name.length) === false)
        {
            return null;
        }

        indexEE = soapResponse.lastIndexOf('</', indexEE);

        if (indexEE < 0)
        {
            return null;
        }

        indexEE = NOXnetXmlIndexOfTagEnd(soapResponse, indexEE);

        if (indexEE < 0)
        {
            return null;
        }

        var xmlEnvelope = soapResponse.substring(indexES, indexEE);
        return xmlEnvelope;
        //**********************************************************************************************************
        // SOAP-Body und anschliessend die SOAP-Action isolieren. Wenn kein SOAP-Body gefunden wird, dann kann der
        // Vorgang nicht erfolgreich abgeschlossen werden.

        /*var xmlBody = NOXnetXmlElement(xmlEnvelope, 'Body');

        if (xmlBody == null)
        {
            return null;
        }

        return NOXnetXmlValue(xmlBody);*/
    }

    //**************************************************************************************************************
    // Ausnahmen abfangen und den Vorgang abbrechen.

    catch (exception)
    {
        return null;
    }
}