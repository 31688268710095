<template>
  <div class="switch-element-properties-dialog-container">
    <switch-element-props
      class="switch-element-properties-dialog-switch-element"
      :controlElement="controlElement"
      :state="state"
      @changedDataEvent="changeData"
    />
    <div class="switch-element-properties-dialog-toggle-switch">
      <toggle-switch @toggleEvent="changeActiveFlag" />
    </div>
    <div class="switch-element-properties-dialog-icon-list">
      <div
        v-for="icon in icons"
        :key="icon.id"
        :id="icon.id"
        class="switch-element-properties-dialog-icon-container"
        :class="hasIconToBeSelected(icon)"
        @click.stop.passive="iconClicked(icon)"
      >
        <img :src="icon.url" class="switch-element-properties-dialog-icon" />
      </div>
    </div>
    <div class="switch-element-properties-dialog-buttons">
      <div
        class="switch-element-properties-dialog-button"
        :class="
          isButtonOkPressed
            ? 'switch-element-properties-dialog-button-pressed'
            : 'switch-element-properties-dialog-button-not-pressed'
        "
        @mousedown.stop.passive="isButtonOkPressed = true"
        @mouseup.stop.passive="isButtonOkPressed = false"
        @mouseleave.stop.passive="isButtonOkPressed = false"
        @touchstart.stop.passive="isButtonOkPressed = true"
        @touchend.stop.passive="isButtonOkPressed = false"
        @touchcancel.stop.passive="isButtonOkPressed = false"
        @click.stop.passive="buttonOkClicked"
      >
        {{ $t('ok') }}
      </div>
      <div
        class="switch-element-properties-dialog-button"
        :class="
          isButtonCancelPressed
            ? 'switch-element-properties-dialog-button-pressed'
            : 'switch-element-properties-dialog-button-not-pressed'
        "
        @mousedown.stop.passive="isButtonCancelPressed = true"
        @mouseup.stop.passive="isButtonCancelPressed = false"
        @mouseleave.stop.passive="isButtonCancelPressed = false"
        @touchstart.stop.passive="isButtonCancelPressed = true"
        @touchend.stop.passive="isButtonCancelPressed = false"
        @touchcancel.stop.passive="isButtonCancelPressed = false"
        @click.stop.passive="buttonCancelClicked"
      >
        {{ $t('cancel') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SwitchElementProps from '@/ui/components/controlelements/switchelement/SwitchElementProps.vue'
import ToggleSwitch from '@/ui/components/widgets/ToggleSwitch.vue'
import { elementScrollIntoView } from 'seamless-scroll-polyfill'

export default {
  name: 'SwitchElementPropertiesDialog',
  components: { SwitchElementProps, ToggleSwitch },
  props: {
    controlElementId: {
      type: String,
      required: true
    }
  },
  emits: ['okEvent', 'cancelEvent'],
  data() {
    return {
      controlElement: null,
      state: false,
      icons: [],
      selectedIcon: null,
      isButtonOkPressed: false,
      isButtonCancelPressed: false
    }
  },
  beforeMount() {
    this.controlElement = this.$appManager.cloneControlElement(this.controlElementId)
    this.importAllIcons()

    if (this.getUsedTheme === 'dark-theme') {
      this.getSelectedIcon(this.controlElement.configurationDarkMode.iconInactive)
    } else {
      this.getSelectedIcon(this.controlElement.configurationLightMode.iconInactive)
    }
  },
  mounted() {
    this.scrollToSelectedIcon()
  },
  watch: {
    state() {
      if (this.state) {
        if (this.getUsedTheme === 'dark-theme') {
          this.getSelectedIcon(this.controlElement.configurationDarkMode.iconActive)
        } else {
          this.getSelectedIcon(this.controlElement.configurationLightMode.iconActive)
        }
      } else {
        if (this.getUsedTheme === 'dark-theme') {
          this.getSelectedIcon(this.controlElement.configurationDarkMode.iconInactive)
        } else {
          this.getSelectedIcon(this.controlElement.configurationLightMode.iconInactive)
        }
      }

      this.scrollToSelectedIcon()
    }
  },
  computed: {
    ...mapGetters(['getUsedTheme'])
  },
  methods: {
    importAllIcons() {
      let icons = null

      if (this.getUsedTheme === 'dark-theme') {
        icons = require.context('@/assets/icons/dark-theme/', true, /\.svg$/)
      } else {
        icons = require.context('@/assets/icons/light-theme/', true, /\.svg$/)
      }

      icons.keys().forEach((key, index) => {
        // Get URL of icon (e.g. '/img/ceiling-lamp-off.cdafcf86.svg')
        const url = icons(key)

        // Get file name of icon (e.g. 'ceiling-lamp-off.cdafcf86.svg')
        const fileNameWithHashWithExtenstion = url.substring(url.lastIndexOf('/') + 1)

        // Get file name of icon without file extension (e.g. 'ceiling-lamp-off.cdafcf86')
        const fileNameWithHash = fileNameWithHashWithExtenstion.replace(/\.[^/.]+$/, '')

        // Remove hash from file name without file extension (e.g. 'ceiling-lamp-off')
        const fileName = fileNameWithHash.replace(/\.[^/.]+$/, '')

        // Add file extension again to get original file name (e.g. '0106w-7d7d7d.svg')
        const originalFileName = fileName + '.svg'

        // Create new object containing
        //   id
        //   url               (e.g. '/img/0106w-7d7d7d.cdafcf86.svg')
        //   originalFileName  (e.g. '0106w-7d7d7d.svg')
        const icon = { id: index, url: url, originalFileName: originalFileName }

        // Add icon to icon list
        this.icons.push(icon)
      })
    },
    hasIconToBeSelected(icon) {
      return this.selectedIcon === icon ? 'switch-element-properties-dialog-selected-icon' : ''
    },
    getSelectedIcon(icon) {
      this.icons.forEach(tmpIcon => {
        if (tmpIcon.originalFileName === icon) {
          this.selectedIcon = tmpIcon
        }
      })
    },
    scrollToSelectedIcon() {
      if (this.selectedIcon !== null) {
        const selectedIcon = document.getElementById(this.selectedIcon.id)

        if (selectedIcon !== null) {
          elementScrollIntoView(selectedIcon, { behavior: 'auto', inline: 'center' })
        }
      }
    },
    iconClicked(icon) {
      if (this.state) {
        if (this.getUsedTheme === 'dark-theme') {
          this.controlElement.configurationDarkMode.iconActive = icon.originalFileName
        } else {
          this.controlElement.configurationLightMode.iconActive = icon.originalFileName
        }
      } else {
        if (this.getUsedTheme === 'dark-theme') {
          this.controlElement.configurationDarkMode.iconInactive = icon.originalFileName
        } else {
          this.controlElement.configurationLightMode.iconInactive = icon.originalFileName
        }
      }

      this.selectedIcon = icon
    },
    changeActiveFlag(value) {
      this.state = value
    },
    changeData(controlElement) {
      this.controlElement = controlElement
    },
    buttonOkClicked() {
      this.$emit('okEvent', this.controlElement)
    },
    buttonCancelClicked() {
      this.$emit('cancelEvent')
    }
  }
}
</script>

<style scoped>
.switch-element-properties-dialog-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  border-radius: 16px;
  background-color: var(--background-color-primary);
  z-index: 1;
  display: grid;
  grid-template-columns: 158px auto;
  grid-template-rows: 158px auto 50px;
  grid-gap: 10px;
  padding: 10px 10px 0 10px;
}

.switch-element-properties-dialog-switch-element {
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
}

.switch-element-properties-dialog-toggle-switch {
  grid-row: 2 / span 1;
  grid-column: 1 / span 1;
  display: flex;
  justify-content: center;
}

.switch-element-properties-dialog-icon-list {
  grid-row: 1 / span 2;
  grid-column: 2 / span 1;
  border-color: var(--border-color-primary);
  border-style: solid;
  border-width: 1px;
  background-color: var(--background-color-primary);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
}

.switch-element-properties-dialog-icon-container {
  display: flex;
  justify-content: center;
  margin: 0 2px;
}

.switch-element-properties-dialog-icon {
  margin: 8px 0;
}

.switch-element-properties-dialog-selected-icon {
  background-color: var(--background-selected-icon);
}

.switch-element-properties-dialog-buttons {
  grid-column: 1 / span 2;
  grid-row: 3 / span 1;
  display: flex;
  flex-direction: row-reverse;
  margin: 0 2px 0 0;
}

.switch-element-properties-dialog-button {
  height: 50px;
  padding: 18px 20px 0 20px;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.switch-element-properties-dialog-button-not-pressed {
  color: var(--innoxel-blue-color);
}

.switch-element-properties-dialog-button-pressed {
  color: var(--innoxel-blue-color-pressed);
}
</style>
