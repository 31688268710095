import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import mitt from 'mitt'
import appmanager from '@/plugins/appmanager'
import '@/assets/styles/colors.css'

const app = createApp(App)
app.config.globalProperties.emitter = mitt()
app.use(store)
app.use(router)
app.use(i18n)
app.use(appmanager)
app.mount('#app')
