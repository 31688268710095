import AreasAccessor from './areasaccessor/areasaccessor'
import ConfigurationInformationAccessor from './configurationinformationaccessor/configurationinformationaccessor'
import ControlElementsAccessor from './controlelementsaccessor/controlelementsaccessor'
import GlobalFunctionsAccessor from './globalfunctionsaccessor/globalfunctionsaccessor'
import GlobalTimersAccessor from './globalscenesaccessor/globalscenesaccessor'
import GlobalScenesAccessor from './globalscenesaccessor/globalscenesaccessor'
import RoomRegulationsAccessor from './roomregulationsaccessor/roomregulationsaccessor'
import RoomsAccessor from './roomsaccessor/roomsaccessor'
import SettingsAccessor from './settingsaccessor/settingsaccessor'
import LogEntriesAccessor from './logentriesaccessor/logentriesaccessor'

/**
 * The class StorageManager implements the 'Storage Manager' used to store
 * the web app configuration into the database (IndexedDB) offered by the
 * browsers.
 */
class StorageManager {
  /**
   * The constructor of this class defines the database name, the database
   * version, the different object stores, the database (IndexedDB) itself
   * and the different accessors.
   */
  constructor() {
    // Define name of database
    this.databaseName = 'innoxel'

    // Set version of database
    this.databaseVersion = 4

    // Define object store for 'Settings'
    this.objectStoreSettings = 'settings'

    // Define object store for 'Configuration Information'
    this.objectStoreConfigurationInformation = 'configurationinformation'

    // Define object store for 'Global Functions'
    this.objectStoreGlobalFunctions = 'globalfunctions'

    // Define object store for 'Global Timers'
    this.objectStoreGlobalTimers = 'globaltimers'

    // Define object store for 'Global Scenes'
    this.objectStoreGlobalScenes = 'globalscenes'

    // Define object store for 'Room Regulations'
    this.objectStoreRoomRegulations = 'roomregulations'

    // Define object store for 'Areas'
    this.objectStoreAreas = 'areas'

    // Define object store for 'Rooms'
    this.objectStoreRooms = 'rooms'

    // Define object store for 'Control Elements'
    this.objectStoreControlElements = 'controlelements'

    // Define object store for 'Log Entries'
    this.objectStoreLogEntries = 'logentries'

    // Defince reference to database (IndexedDB)
    this.database = null

    // Define reference to 'Settings' accessor
    this.settingsAccessor = null

    // Define reference to 'Configuration Information' accessor
    this.configurationInformationAccessor = null

    // Define reference to 'Global Functions' accessor
    this.globalFunctionsAccessor = null

    // Define reference to 'Global Timers' accessor
    this.globalTimersAccessor = null

    // Define reference to 'Global Scenes' accessor
    this.globalScenesAccessor = null

    // Define reference to 'Areas' accessor
    this.areasAccessor = null

    // Define reference to 'Rooms' accessor
    this.roomsAccessor = null

    // Define reference to 'Control Elements' accessor
    this.controlElementsAccessor = null

    // Define reference to 'Log Entries' accessor
    this.logEntriesAccessor = null
  }

  /**
   * This method initializes the database and the different accessors.
   */
  async initialize() {
    if (this.database == null) {
      // Initialize database
      this.database = await this.initializeDatabase()

      // Initialize 'Settings' accessor
      this.settingsAccessor = new SettingsAccessor(this.database, this.objectStoreSettings)

      // Initiailze 'Configuration Information' accessor
      this.configurationInformationAccessor = new ConfigurationInformationAccessor(
        this.database,
        this.objectStoreConfigurationInformation
      )

      // Initialize 'Global Functions' accessor
      this.globalFunctionsAccessor = new GlobalFunctionsAccessor(this.database, this.objectStoreGlobalFunctions)

      // Initialize 'Global Timers' accessor
      this.globalTimersAccessor = new GlobalTimersAccessor(this.database, this.objectStoreGlobalTimers)

      // Initialize 'Global Scenes' accessor
      this.globalScenesAccessor = new GlobalScenesAccessor(this.database, this.objectStoreGlobalScenes)

      // Initialize 'Room Regulations' accessor
      this.roomRegulationsAccessor = new RoomRegulationsAccessor(this.database, this.objectStoreRoomRegulations)

      // Initialize 'Areas' accessor
      this.areasAccessor = new AreasAccessor(this.database, this.objectStoreAreas)

      // Initialize 'Rooms' accessor
      this.roomsAccessor = new RoomsAccessor(this.database, this.objectStoreRooms)

      // Initialize 'Control Elements' accessor
      this.controlElementsAccessor = new ControlElementsAccessor(this.database, this.objectStoreControlElements)

      // Initialize 'Log Entries' accessor
      this.logEntriesAccessor = new LogEntriesAccessor(this.database, this.objectStoreLogEntries)
    }
  }

  /**
   * This method initializes the database. The following steps are performed:
   *
   *  - Try to open connection to database (IndexedDB)
   *     - Failed  -> Return Promise reject containing error message
   *     - Succeed -> Return Promise resolve containing reference to database
   *
   * This method creates the different object stores used to store the specific
   * web app configuration if a new version of the database needs to be created.
   *
   * @returns {Promise} Reference to database if everything succeeed.
   */
  async initializeDatabase() {
    return new Promise((resolve, reject) => {
      // Try to open connection to database
      const request = window.indexedDB.open(this.databaseName, this.databaseVersion)

      /**
       * This event handler is called when opening the database succeed. This event
       * handler returns a Promise resolve containing the reference to the database.
       *
       * @param {Event} event Generated by window.indexedDB.open()
       */
      request.onsuccess = event => {
        resolve(event.target.result)
      }

      /**
       * This event handler is called when opening the database failed. This event
       * handler returns a Promise reject containing the error message.
       *
       * @param {Event} event Generated by window.indexedDB.open()
       */
      request.onerror = event => {
        const errorMessage = `Error: Opening database failed! (${event.target.error})`
        reject(errorMessage)
      }

      /**
       * This event handler handles the event whereby a new version of the database
       * needs to be created. Either one has not been created before, or a new version
       * number has been submitted via the window.indexedDB.open(). This event handler
       * creates the object stores used to store the specific web app configuration.
       *
       * @param {Event} event Generated by window.indexedDB.open()
       */
      request.onupgradeneeded = event => {
        // Create object store for settings
        const database = event.target.result

        // Create object store for 'Settings'
        if (!database.objectStoreNames.contains(this.objectStoreSettings)) {
          database.createObjectStore(this.objectStoreSettings, { keyPath: 'id' })
        }

        // Create object store for 'Configuration Information'
        if (!database.objectStoreNames.contains(this.objectStoreConfigurationInformation)) {
          database.createObjectStore(this.objectStoreConfigurationInformation, { keyPath: 'id' })
        }

        // Create object store for 'Global Functions'
        if (!database.objectStoreNames.contains(this.objectStoreGlobalFunctions)) {
          database.createObjectStore(this.objectStoreGlobalFunctions, { keyPath: 'id' })
        }

        // Create object store for 'Global Timers'
        if (!database.objectStoreNames.contains(this.objectStoreGlobalTimers)) {
          database.createObjectStore(this.objectStoreGlobalTimers, { keyPath: 'id' })
        }

        // Create object store for 'Global Scenes'
        if (!database.objectStoreNames.contains(this.objectStoreGlobalScenes)) {
          database.createObjectStore(this.objectStoreGlobalScenes, { keyPath: 'id' })
        }

        // Create object store for 'Room Regulations'
        if (!database.objectStoreNames.contains(this.objectStoreRoomRegulations)) {
          database.createObjectStore(this.objectStoreRoomRegulations, { keyPath: 'id' })
        }

        // Create object store for 'Areas'
        if (!database.objectStoreNames.contains(this.objectStoreAreas)) {
          const objectStore = database.createObjectStore(this.objectStoreAreas, { keyPath: 'id' })
          objectStore.createIndex('order', 'order', { unique: false })
        }

        // Create object store for 'Rooms'
        if (!database.objectStoreNames.contains(this.objectStoreRooms)) {
          database.createObjectStore(this.objectStoreRooms, { keyPath: 'id' })
        }

        // Create object store for 'Control Elements'
        if (!database.objectStoreNames.contains(this.objectStoreControlElements)) {
          database.createObjectStore(this.objectStoreControlElements, { keyPath: 'id' })
        }

        // Create object store for 'Log Entries'
        if (!database.objectStoreNames.contains(this.objectStoreLogEntries)) {
          const objectStore = database.createObjectStore(this.objectStoreLogEntries, {
            keyPath: 'id',
            autoIncrement: true
          })
          objectStore.createIndex('id', 'id', { unique: false })
        }
      }
    })
  }

  /**
   * This method deletes the database. The following steps are performed:
   *
   *  - Try to delete database (IndexedDB)
   *     - Failed  -> Return Promise reject containing error message
   *     - Succeed -> Return Promise resolve
   *
   * @returns {Promise} Containing error message if deleting database fails.
   */
  async deleteDatabase() {
    return new Promise((resolve, reject) => {
      // Close database
      if (this.database != null) {
        this.database.close()
      }

      // Try to delete database
      const request = window.indexedDB.deleteDatabase(this.databaseName)

      /**
       * This event handler is called when deleting the database succeed. This event
       * handler returns a Promise resolve.
       */
      request.onsuccess = () => {
        resolve()
      }

      /**
       * This event handler is called when deleting the database failed. This event
       * handler returns a Promise reject containing the error message.
       *
       * @param {Event} event Generated by window.indexedDB.deleteDatabase()
       */
      request.onnerror = event => {
        const errorMessage = `Error: Deleting database failed! (${event.target.error})`
        reject(errorMessage)
      }

      /**
       * This event handler is called when deleting the database failed due to
       * the operation being blocked. This event handler returns a Promise reject
       * containing the error message.
       *
       * @param {Event} event Generated by window.indexedDB.deleteDatabase()
       */
      request.onblocked = event => {
        const errorMessage = `Error: Deleting database failed! (${event.target.error})`
        reject(errorMessage)
      }
    })
  }

  /**
   * This method writes the given 'Settings' to the object store 'settings'.
   * 'Settings' with the same ID are overwritten.
   *
   *  - Writing succeed: Returns empty Promise resolve.
   *  - Writing failed:  Returns Promise reject containing error message.
   *
   * @param {any} settings 'Settings' to be stored to object store.
   * @returns Promise containing result.
   */
  async writeSettings(settings) {
    if (this.settingsAccessor != null) {
      return await this.settingsAccessor.write(settings)
    }
  }

  /**
   * This method reads the 'Settings' from the object store 'settings'.
   *
   *  - Reading succeed: Returns Promise resolve containing stored 'Settings'.
   *  - Reading failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async readSettings() {
    if (this.settingsAccessor != null) {
      return await this.settingsAccessor.read()
    }
  }

  /**
   * This method writes the given 'Configuration Information' to the object store
   * 'configurationinformation'. 'Configuration Information' with the same ID are
   * overwritten.
   *
   *  - Writing succeed: Returns empty Promise resolve.
   *  - Writing failed:  Returns Promise reject containing error message.
   *
   * @param {any} configurationInformation 'Configuration Information' to be stored to object store.
   * @returns Promise containing result.
   */
  async writeConfigurationInformation(configurationInformation) {
    if (this.configurationInformationAccessor != null) {
      return await this.configurationInformationAccessor.write(configurationInformation)
    }
  }

  /**
   * This method reads the 'Configuration Information' from the object store
   * 'configurationinformation'.
   *
   *  - Reading succeed: Returns Promise resolve containing stored 'Configuration Information'.
   *  - Reading failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async readConfigurationInformation() {
    if (this.configurationInformationAccessor != null) {
      return await this.configurationInformationAccessor.read()
    }
  }

  /**
   * This method writes the given 'Global Functions' to the object store
   * 'globalfunctions'. 'Global Functions' with the same ID are overwritten.
   *
   *  - Writing succeed: Returns empty Promise resolve.
   *  - Writing failed:  Returns Promise reject containing error message.
   *
   * @param {any} globalFunctions 'Global Functions' to be stored to object store.
   * @returns Promise containing result.
   */
  async writeGlobalFunctions(globalFunctions) {
    if (this.globalFunctionsAccessor != null) {
      return await this.globalFunctionsAccessor.write(globalFunctions)
    }
  }

  /**
   * This method reads the 'Global Functions' from the object store
   * 'globalfunctions'.
   *
   *  - Reading succeed: Returns Promise resolve containing stored 'Global Functions'.
   *  - Reading failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async readGlobalFunctions() {
    if (this.globalFunctionsAccessor != null) {
      return await this.globalFunctionsAccessor.read()
    }
  }

  /**
   * This method deletes all 'Global Functions' from the object store
   * 'globalfunctions'.
   *
   *  - Deleting succeed: Returns empty Promise resolve.
   *  - Deleting failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async deleteGlobalFunctions() {
    if (this.globalFunctionsAccessor != null) {
      return await this.globalFunctionsAccessor.clear()
    }
  }

  /**
   * This method writes the given 'Global Timers' to the object store
   * 'globaltimers'. 'Global Timers' with the same ID are overwritten.
   *
   *  - Writing succeed: Returns empty Promise resolve.
   *  - Writing failed:  Returns Promise reject containing error message.
   *
   * @param {any} globalTimers 'Global Timers' to be stored to object store.
   * @returns Promise containing result.
   */
  async writeGlobalTimers(globalTimers) {
    if (this.globalTimersAccessor != null) {
      return await this.globalTimersAccessor.write(globalTimers)
    }
  }

  /**
   * This method reads the 'Global Timers' from the object store
   * 'globaltimers'.
   *
   *  - Reading succeed: Returns Promise resolve containing stored 'Global Timers'.
   *  - Reading failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async readGlobalTimers() {
    if (this.globalTimersAccessor != null) {
      return await this.globalTimersAccessor.read()
    }
  }

  /**
   * This method deletes all 'Global Timers' from the object store
   * 'globaltimers'.
   *
   *  - Deleting succeed: Returns empty Promise resolve.
   *  - Deleting failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async deleteGlobalTimers() {
    if (this.globalTimersAccessor != null) {
      return await this.globalTimersAccessor.clear()
    }
  }

  /**
   * This method writes the given 'Global Scenes' to the object store
   * 'globalscenes'. 'Global Scenes' with the same ID are overwritten.
   *
   *  - Writing succeed: Returns empty Promise resolve.
   *  - Writing failed:  Returns Promise reject containing error message.
   *
   * @param {any} globalScenes 'Global Scenes' to be stored to object store.
   * @returns Promise containing result.
   */
  async writeGlobalScenes(globalScenes) {
    if (this.globalScenesAccessor != null) {
      return await this.globalScenesAccessor.write(globalScenes)
    }
  }

  /**
   * This method reads the 'Global Scenes' from the object store
   * 'globalscenes'.
   *
   *  - Reading succeed: Returns Promise resolve containing stored 'Global Scenes'.
   *  - Reading failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async readGlobalScenes() {
    if (this.globalScenesAccessor != null) {
      return await this.globalScenesAccessor.read()
    }
  }

  /**
   * This method deletes all 'Global Scenes' from the object store
   * 'globalscenes'.
   *
   *  - Deleting succeed: Returns empty Promise resolve.
   *  - Deleting failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async deleteGlobalScenes() {
    if (this.globalScenesAccessor != null) {
      return await this.globalScenesAccessor.clear()
    }
  }

  /**
   * This method writes the given 'Room Regulations' to the object store
   * 'roomregulations'. 'Room Regulations' with the same ID are overwritten.
   *
   *  - Writing succeed: Returns empty Promise resolve.
   *  - Writing failed:  Returns Promise reject containing error message.
   *
   * @param {any} roomRegulations 'Room Regulations' to be stored to object store.
   * @returns Promise containing result.
   */
  async writeRoomRegulations(roomRegulations) {
    if (this.roomRegulationsAccessor != null) {
      return await this.roomRegulationsAccessor.write(roomRegulations)
    }
  }

  /**
   * This method reads the 'Room Regulations' from the object store
   * 'roomRegulations'.
   *
   *  - Reading succeed: Returns Promise resolve containing stored 'Room Regulations'.
   *  - Reading failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async readRoomRegulations() {
    if (this.roomRegulationsAccessor != null) {
      return await this.roomRegulationsAccessor.read()
    }
  }

  /**
   * This method deletes all 'Room Regulations' from the object store
   * 'roomRegulations'.
   *
   *  - Deleting succeed: Returns empty Promise resolve.
   *  - Deleting failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async deleteRoomRegulations() {
    if (this.roomRegulationsAccessor != null) {
      return await this.roomRegulationsAccessor.clear()
    }
  }

  /**
   * This method writes the given 'Areas' to the object store 'areas'.
   * 'Areas' with the same ID are overwritten.
   *
   *  - Writing succeed: Returns empty Promise resolve.
   *  - Writing failed:  Returns Promise reject containing error message.
   *
   * @param {any} areas 'Areas' to be stored to object store.
   * @returns Promise containing result.
   */
  async writeAreas(areas) {
    if (this.areasAccessor != null) {
      return await this.areasAccessor.write(areas)
    }
  }

  /**
   * This method reads the 'Areas' from the object store 'areas'.
   *
   *  - Reading succeed: Returns Promise resolve containing stored 'Areas'.
   *  - Reading failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async readAreas() {
    if (this.areasAccessor != null) {
      return await this.areasAccessor.read()
    }
  }

  /**
   * This method deletes all 'Areas' from the object store 'areas'.
   *
   *  - Deleting succeed: Returns empty Promise resolve.
   *  - Deleting failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async deleteAreas() {
    if (this.areasAccessor != null) {
      return await this.areasAccessor.clear()
    }
  }

  /**
   * This method writes the given 'Rooms' to the object store 'rooms'.
   * 'Rooms' with the same ID are overwritten.
   *
   *  - Writing succeed: Returns empty Promise resolve.
   *  - Writing failed:  Returns Promise reject containing error message.
   *
   * @param {any} rooms 'Rooms' to be stored to object store.
   * @returns Promise containing result.
   */
  async writeRooms(rooms) {
    if (this.roomsAccessor != null) {
      return await this.roomsAccessor.write(rooms)
    }
  }

  /**
   * This method reads the 'Rooms' from the object store 'rooms'.
   *
   *  - Reading succeed: Returns Promise resolve containing stored 'Rooms'.
   *  - Reading failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async readRooms() {
    if (this.roomsAccessor != null) {
      return await this.roomsAccessor.readAll()
    }
  }

  /**
   * This method reads the 'Room' using the given roomId from the object store
   * 'rooms'.
   *
   *  - Reading succeed: Returns Promise resolve containing stored 'Room'.
   *  - Reading failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async readRoom(roomId) {
    if (this.roomsAccessor != null) {
      return await this.roomsAccessor.read(roomId)
    }
  }

  /**
   * This method deletes all 'Rooms' from the object store 'rooms'.
   *
   *  - Deleting succeed: Returns empty Promise resolve.
   *  - Deleting failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async deleteRooms() {
    if (this.roomsAccessor != null) {
      return await this.roomsAccessor.clear()
    }
  }

  /**
   * This method writes the given 'Control Elements' to the object store
   * 'controlelements'. 'Control Elements' with the same ID are overwritten.
   *
   *  - Writing succeed: Returns empty Promise resolve.
   *  - Writing failed:  Returns Promise reject containing error message.
   *
   * @param {any} controlElements 'Control Elements' to be stored to object store.
   * @returns Promise containing result.
   */
  async writeControlElements(controlElements) {
    if (this.controlElementsAccessor != null) {
      return await this.controlElementsAccessor.write(controlElements)
    }
  }

  /**
   * This method reads the 'Control Elements' from the object store
   * 'controlelements'.
   *
   *  - Reading succeed: Returns Promise resolve containing stored 'Control Elements'.
   *  - Reading failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async readControlElements() {
    if (this.controlElementsAccessor != null) {
      return await this.controlElementsAccessor.read()
    }
  }

  /**
   * This method deletes all 'Control Elements' from the object store
   * 'controlelements'.
   *
   *  - Deleting succeed: Returns empty Promise resolve.
   *  - Deleting failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async deleteControlElements() {
    if (this.controlElementsAccessor != null) {
      return await this.controlElementsAccessor.clear()
    }
  }

  /**
   * This method updates the given 'Control Element' in the object store
   * 'controlelements'.
   *
   *  - Writing succeed: Returns empty Promise resolve.
   *  - Writing failed:  Returns Promise reject containing error message.
   *
   * @param {any} controlElement 'Control Element' to be updated in object store.
   * @returns Promise containing result.
   */
  async updateControlElement(controlElement) {
    if (this.controlElementsAccessor != null) {
      return await this.controlElementsAccessor.update(controlElement)
    }
  }

  /**
   * This method writes the given 'Log Entry' to the object store 'logentries'.
   *
   *  - Writing succeed: Returns empty Promise resolve.
   *  - Writing failed:  Returns Promise reject containing error message.
   *
   * @param {any} logEntry 'Log Entry' to be stored to object store.
   * @returns Promise containing result.
   */
  async writeLogEntry(logEntry) {
    if (this.logEntriesAccessor != null) {
      return await this.logEntriesAccessor.write(logEntry)
    }
  }

  /**
   * This method reads all 'Log Entries' from the object store 'logentries'.
   *
   *  - Reading succeed: Returns Promise resolve containing stored 'Log Entries'.
   *  - Reading failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async readLogEntries() {
    if (this.logEntriesAccessor != null) {
      return await this.logEntriesAccessor.read()
    }
  }

  /**
   * This method deletes all 'Log Entries' from the object store 'logentries'.
   *
   *  - Deleting succeed: Returns empty Promise resolve.
   *  - Deleting failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async deleteLogEntries() {
    if (this.logEntriesAccessor != null) {
      return await this.logEntriesAccessor.clear()
    }
  }

  /**
   * This method keeps the number of maximum log entries in the object
   * store 'logentries'.
   *
   *  - Keeping succeed: Returns empty Promise resolve.
   *  - Keeping failed:  Returns Promise reject containing error message.
   *
   * @param {Number} maxCount Maximum number of log entries.
   * @returns Promise containing result.
   */
  async keepNumberOfMaximumLogEntries(maxCount) {
    if (this.logEntriesAccessor != null) {
      return await this.logEntriesAccessor.keepNumberOfMaximumLogEntries(maxCount)
    }
  }
}

export default StorageManager
