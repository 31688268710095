<template>
  <div class="slider-container">
    <div
      class="slider-touch-area-left"
      @mousedown.stop.passive="emitPressedEvent(true && !locked)"
      @mouseup.stop.passive="emitPressedEvent(false)"
      @mouseleave.stop.passive="emitPressedEvent(false)"
      @touchstart.stop.passive="emitPressedEvent(true && !locked)"
      @touchend.stop.passive="emitPressedEvent(false)"
      @touchcancel.stop.passive="emitPressedEvent(false)"
      @click.stop.passive="buttonLeftPressed"
    >
      <img v-if="usedTheme === 'dark-theme'" class="slider-icon-touch-area-left" :src="iconTouchAreaLeftDarkMode" />
      <img v-else class="slider-icon-touch-area-left" :src="iconTouchAreaLeftLightMode" />
    </div>
    <range-slider
      class="slider-range-slider"
      :animateSliderThumb="animate"
      :locked="locked"
      :value="value"
      @valueChangedEvent="updateChangedValue"
      @valueReleasedEvent="updateReleasedValue"
    />
    <div
      class="slider-touch-area-right"
      @mousedown.stop.passive="emitPressedEvent(true && !locked)"
      @mouseup.stop.passive="emitPressedEvent(false)"
      @mouseleave.stop.passive="emitPressedEvent(false)"
      @touchstart.stop.passive="emitPressedEvent(true && !locked)"
      @touchend.stop.passive="emitPressedEvent(false)"
      @touchcancel.stop.passive="emitPressedEvent(false)"
      @click.stop.passive="buttonRightPressed"
    >
      <img v-if="usedTheme === 'dark-theme'" class="slider-icon-touch-area-right" :src="iconTouchAreaRightDarkMode" />
      <img v-else class="slider-icon-touch-area-right" :src="iconTouchAreaRightLightMode" />
    </div>
  </div>
</template>

<script>
import RangeSlider from '@/ui/components/widgets/RangeSlider.vue'

export default {
  name: 'Slider',
  components: { RangeSlider },
  props: {
    usedTheme: {
      type: String,
      required: true
    },
    animateSliderThumb: {
      type: Boolean,
      required: false,
      default: false
    },
    locked: {
      type: Boolean,
      required: false,
      default: false
    },
    initialValue: {
      type: Number,
      required: true
    },
    valueTouchAreaLeft: {
      type: Number,
      required: false,
      default: 0
    },
    valueTouchAreaRight: {
      type: Number,
      required: false,
      default: 100
    },
    iconTouchAreaLeftLightMode: {
      type: String,
      required: true
    },
    iconTouchAreaRightLightMode: {
      type: String,
      required: true
    },
    iconTouchAreaLeftDarkMode: {
      type: String,
      required: true
    },
    iconTouchAreaRightDarkMode: {
      type: String,
      required: true
    }
  },
  emits: ['pressedEvent', 'valueChangedEvent', 'valueReleasedEvent'],
  data() {
    return {
      currentValue: this.initialValue,
      animate: this.animateSliderThumb
    }
  },
  watch: {
    animateSliderThumb() {
      this.animate = this.animateSliderThumb
    },
    initialValue() {
      this.currentValue = this.initialValue
    }
  },
  computed: {
    value() {
      return Number(this.currentValue)
    }
  },
  methods: {
    emitPressedEvent(isPressed) {
      this.$emit('pressedEvent', isPressed)
    },
    buttonLeftPressed() {
      if (!this.locked) {
        this.updateChangedValue(this.valueTouchAreaLeft)
        this.updateReleasedValue(this.valueTouchAreaLeft)
      }
    },
    buttonRightPressed() {
      if (!this.locked) {
        this.updateChangedValue(this.valueTouchAreaRight)
        this.updateReleasedValue(this.valueTouchAreaRight)
      }
    },
    updateChangedValue(value) {
      if (this.currentValue != value) {
        this.currentValue = value
        this.$emit('valueChangedEvent', value)
      }
    },
    updateReleasedValue(value) {
      this.$emit('valueReleasedEvent', value)
    }
  }
}
</script>

<style scoped>
.slider-container {
  display: grid;
  grid-template-columns: 62px auto 62px;
  grid-template-rows: 62px;
  align-items: center;
  justify-items: center;
}

.slider-icon-touch-area-left {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
}

.slider-icon-touch-area-right {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
}

.slider-range-slider {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  justify-self: stretch;
}

.slider-touch-area-left {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  align-items: center;
  justify-items: center;
}

.slider-touch-area-right {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  align-items: center;
  justify-items: center;
}
</style>
