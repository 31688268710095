<template>
  <div v-if="showLicenseKeyView">
    <license-key-view />
  </div>
  <div v-else>
    <splash-screen :show="isLoading" />
    <div v-if="!isLoading">
      <main-screen @tryToReconnectEvent="tryToReconnect" />
    </div>
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { mapGetters, mapMutations } from 'vuex'
import LicenseKeyView from '@/ui/views/LicenseKeyView.vue'
import SplashScreen from '@/ui/views/SplashScreen.vue'
import MainScreen from '@/ui/views/MainScreen.vue'
// import Bowser from 'bowser'
// import FauxPas from 'fg-faux-pas/faux-pas'

export default {
  name: 'App',
  components: { LicenseKeyView, SplashScreen, MainScreen },
  data() {
    return {
      settingsLoaded: false,
      showLicenseKeyView: false,
      enableSoapInterface: true,
      protocol: window.location.protocol,
      ipAddress: window.location.hostname,
      port: window.location.port,
      soapServiceNamespace: 'urn:innoxel-ch:service:noxnetRemote:1',
      appIsReadyToUse: false,
      isLoading: true,
      connectionLostTimer: null,
      userTriesToReconnect: false
    }
  },
  created() {
    // Start timer for loading procedure
    this.startTimerForLoadingProcedure()

    // Initialize application
    this.initialize()

    // Start 'WebApp'
    this.startWebApp()
  },
  mounted() {
    window.addEventListener('visibilitychange', this.handleVisibilityChange)
  },
  unmounted() {
    window.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },
  computed: {
    ...mapGetters([
      'isFavoriteStarEnabled',
      'isPlaceholderForWebUiMenuEnabled',
      'getSelectedAppearance',
      'getSelectedLanguageCode',
      'isConnectionLost'
    ])
  },
  watch: {
    isFavoriteStarEnabled() {
      this.updateSettings()
    },
    isPlaceholderForWebUiMenuEnabled() {
      this.updateSettings()
    },
    getSelectedAppearance() {
      this.setTheme()
      this.updateSettings()
    },
    getSelectedLanguageCode() {
      this.updateSettings()
    }
  },
  methods: {
    ...mapMutations([
      'setBrowserType',
      'setProtocol',
      'setIpAddress',
      'setPort',
      'setUsedTheme',
      'setAppearance',
      'enableFavoriteStar',
      'enablePlaceholderForWebUiMenu',
      'setLanguageCode',
      'setConfigurationInformation',
      'setLostConnection',
      'setSoapInterfaceRunning'
    ]),
    async startWebApp() {
      // Check if 'License Key View' has to be shown
      this.showLicenseKeyView = await this.hasLicenseKeyViewToBeShown()

      // Wait until settings are loaded
      while (!this.settingsLoaded) {
        await new Promise(resolve => setTimeout(resolve, 10))
      }

      if (this.showLicenseKeyView) {
        // 'License Key View' has to be shown
        this.appIsReadyToUse = true
      } else {
        // Run 'Web App'
        this.runWebApp()
      }
    },
    async runWebApp() {
      // Wait for some fonts
      await this.waitForFonts()

      // Store browser information (uncomment import statement and the
      // following method implementation to use it)
      // this.storeBrowserInformation()

      // ********************************************************************
      // - Set property 'enableSoapInterface' to false to disable the SOAP
      //   communication between WebApp and INNOXEL Master 3.
      // - Set IP Address and port of INNOXEL Master 3 if this web app is
      //   not hosted on it (works only with browsers with disabled web
      //   security)
      //
      // IMPORTANT: For production code, the following lines must
      //            be disabled!!

      this.enableSoapInterface = false
      // this.ipAddress = '192.168.12.200'
      // this.port = 5001

      // Run faux web fonts detector (uncomment import statement and the
      // following method implementation to use it)
      // this.detectFauxWebFonts()
      // ********************************************************************

      // Store protocol, IP address and port in Vuex store
      this.setProtocol(this.protocol)
      this.setIpAddress(this.ipAddress)
      this.setPort(this.port)

      // Initialize 'SOAP Interface'
      this.initializeSoapInterface()

      // ********************************************************************
      // Choose one of the following options to load the configuration:
      //
      //  1) Load configuration stored in file 'test-configuration.js'
      //  2) Load 'WebAppDescriptorname.xml' stored in folder 'public'
      //  3) Load 'WebAppDescriptorname.xml' stored on INNOXEl Master 3
      //
      // IMPORTANT: For the production code, the option 3) must be used!

      // 1) Load configuration stored in file 'test-configuration.js'
      await this.loadTestConfiguration()

      // 2) Load 'WebAppDescriptorname.xml' stored in folder 'public'
      // await this.loadLocalConfiguration()

      // 3) Load 'WebAppDescriptorname.xml' stored on INNOXEl Master 3
      // await this.loadConfigurationFromMaster()
      // ********************************************************************

      // Add 'Configuration Information' to Vuex store
      await this.addConfigurationInformationToVuex()

      // Run web app
      this.run()
    },
    async hasLicenseKeyViewToBeShown() {
      let licenseKeyViewMustBeShown = false

      // Get router
      const router = useRouter()

      // Get route
      const route = useRoute()

      // Wait until router is ready
      await router.isReady()

      // Check if 'License Key' view must be shown
      if (route.params.catchAll != null) {
        if (route.params.catchAll === 'index.html&enterlicensekey') {
          // 'License Key' view must be shown
          licenseKeyViewMustBeShown = true
        }
      }

      return licenseKeyViewMustBeShown
    },
    async waitForFonts() {
      try {
        await document.fonts.load('400 16px OpenSans')
        await document.fonts.load('600 16px OpenSans')
        await document.fonts.load('700 16px OpenSans')
      } catch {
        // Store log entry
        this.$appManager.storeLogEntry({
          timestamp: new Date(),
          message: 'Waiting for fonts failed!'
        })
      }
    },
    async initialize() {
      // Initialize 'Storage Manager'
      await this.$appManager.initializeStorageManager()

      // Get settings
      let settings = await this.$appManager.getSettings()

      // Write settings if missing
      if (settings == null) {
        settings = await this.updateSettings()
      }

      // Write settings to Vuex store
      this.enableFavoriteStar(settings.favoriteStarEnabled)
      this.enablePlaceholderForWebUiMenu(settings.placeholderForWebUiMenuEnabled)
      this.setAppearance(settings.appearance)
      this.setLanguageCode(settings.languageCode)

      // Set locale
      this.$i18n.locale = settings.languageCode

      // Setting are loaded
      this.settingsLoaded = true
    },
    /*storeBrowserInformation() {
      // See https://github.com/lancedikson/bowser for more information

      let message

      try {
        // Get browser information
        const browserInformation = Bowser.parse(window.navigator.userAgent)

        // Get browser name and browser version
        const browserName = browserInformation.browser.name ? browserInformation.browser.name : ''
        const browserVersion = browserInformation.browser.version ? ` ${browserInformation.browser.version}` : ''
        const browser = `Browser: ${browserName}${browserVersion}`

        // Get engine name and engine version
        const engineName = browserInformation.engine.name ? browserInformation.engine.name : ''
        const engineVersion = browserInformation.engine.version ? ` ${browserInformation.engine.version}` : ''
        const engine = `Engine: ${engineName}${engineVersion}`

        // Get OS name and OS version
        const osName = browserInformation.os.name ? browserInformation.os.name : ''
        const osVersionName = browserInformation.os.versionName ? ` ${browserInformation.os.versionName}` : ''
        let osVersion = browserInformation.os.version ? ` ${browserInformation.os.version}` : ''

        if (browserInformation.os.versionName && browserInformation.os.version) {
          osVersion = ` (${browserInformation.os.version})`
        }

        const os = `OS: ${osName}${osVersionName}${osVersion}`

        // Get platform
        const platformType = browserInformation.platform.type ? browserInformation.platform.type : ''
        const platform = `Platform: ${platformType}`

        // Create log message
        message = `${browser}\n${engine}\n${os}\n${platform}`

        // Store browser type in Vuex store
        this.setBrowserType(browserName)
      } catch {
        message = 'Getting browser information failed!'
      }

      // Store log entry
      this.$appManager.storeLogEntry({
        timestamp: new Date(),
        message: message
      })
    },*/
    async updateSettings() {
      // Create settings
      const settings = {
        id: 1,
        appearance: this.getSelectedAppearance,
        favoriteStarEnabled: this.isFavoriteStarEnabled,
        placeholderForWebUiMenuEnabled: this.isPlaceholderForWebUiMenuEnabled,
        languageCode: this.getSelectedLanguageCode
      }

      // Write settings to 'Storage Manager'
      await this.$appManager.setSettings(settings)

      // Return settings
      return settings
    },
    initializeSoapInterface() {
      // Initialize 'SOAP Interface' in 'App Manager'
      this.$appManager.initializeSoapInterface(
        this.enableSoapInterface,
        this.protocol,
        this.ipAddress,
        this.port,
        this.soapServiceNamespace
      )
    },
    async loadTestConfiguration() {
      // Load 'Web App Configuration' stored in file 'test-configuration.js'
      const testConfiguration = require('@/test-configuration.js')

      // Load 'Web App Configuration' stored in test configuration
      this.appIsReadyToUse = await this.$appManager.loadTestConfiguration(testConfiguration.home)
    },
    async loadLocalConfiguration() {
      // Load 'WebAppDescriptorname.xml' stored in folder 'public'
      this.appIsReadyToUse = await this.$appManager.loadLocalConfiguration()
    },
    async loadConfigurationFromMaster() {
      // Load 'WebAppDescriptorname.xml' stored on INNOXEl Master 3
      this.appIsReadyToUse = await this.$appManager.loadConfigurationFromMasterAsync()
    },
    startTimerForLoadingProcedure() {
      // Define timer for one interval [ms]
      const interval = 1250

      // Set flag 'first loop' to guarantee that at least
      // two intervals are processed -> This keeps the splash
      // screen shown for a minimum time
      let firstLoop = true

      // Run timer interval
      let timerInterval = setInterval(() => {
        if (firstLoop) {
          // First loop is over

          // Reset flag 'first loop'
          firstLoop = false
        } else {
          if (this.appIsReadyToUse) {
            // 'App is ready to use' -> This means
            // the configuration is loaded

            // Reset flag 'is loading'
            this.isLoading = false

            // Clear timer interval
            clearInterval(timerInterval)
          }
        }
      }, interval)
    },
    setTheme() {
      let theme = 'light-theme'

      switch (this.getSelectedAppearance) {
        // Light
        case 'light':
          theme = 'light-theme'
          break
        // Dark
        case 'dark':
          theme = 'dark-theme'
          break
        // System
        default:
          theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark-theme' : 'light-theme'
          break
      }

      document.documentElement.className = theme
      this.setUsedTheme(theme)
    },
    async addConfigurationInformationToVuex() {
      // Get 'Configuration Information' stored in database
      const configurationInformation = await this.$appManager.getConfigurationInformation()

      // Add 'Configuration Information' to Vuex store
      this.setConfigurationInformation(configurationInformation)
    },
    /*detectFauxWebFonts() {
      // See also https://github.com/filamentgroup/faux-pas
      setInterval(() => {
        let fauxPas = new FauxPas(window, {
          console: true,
          highlights: true,
          mismatches: true
        })

        fauxPas.compare()
      }, 1000)
    },*/
    run() {
      this.setLostConnection(false)

      if (this.enableSoapInterface) {
        this.setSoapInterfaceRunning(true)
        this.$appManager.run(this.updateMe, this.updateConnectionStatus)
      } else {
        console.log('WARNING: SOAP Interface is disabled!')
      }
    },
    updateMe(controlElementId) {
      const controlElement = this.$store.getters.getCurrentControlElement(controlElementId)

      if (controlElement != null) {
        controlElement.rerender = controlElement.rerender + 1
      }
    },
    updateConnectionStatus(isConnectionLost) {
      if (this.isConnectionLost != isConnectionLost || this.userTriesToReconnect) {
        this.userTriesToReconnect = false

        this.setLostConnection(isConnectionLost)

        let message = ''

        if (isConnectionLost) {
          message = 'Lost connection to INNOXEL Master 3 device.'

          this.connectionLostTimer = setTimeout(() => {
            this.$appManager.stop()
            this.setSoapInterfaceRunning(false)
            this.connectionLostTimer = null
          }, 20000)
        } else {
          message = 'Connection to INNOXEL Master 3 device established.'

          if (this.connectionLostTimer != null) {
            clearTimeout(this.connectionLostTimer)
            this.connectionLostTimer = null
          }
        }

        this.$appManager.storeLogEntry({
          timestamp: new Date(),
          message: message
        })
      }
    },
    tryToReconnect() {
      this.$appManager.storeLogEntry({
        timestamp: new Date(),
        message: 'User tries to reconnect to INNOXEL Master 3 device.'
      })

      this.userTriesToReconnect = true
      this.run()
    },
    handleVisibilityChange() {
      if (document.hidden) {
        this.$appManager.stop()
        this.setSoapInterfaceRunning(false)
      } else {
        this.run()
      }
    }
  }
}
</script>

<style>
@font-face {
  font-family: OpenSans;
  font-style: normal;
  font-weight: 400;
  src: url('~@/assets/fonts/open-sans-v28-latin-regular.woff2') format('woff2'),
    url('~@/assets/fonts/open-sans-v28-latin-regular.woff') format('woff'),
    url('~@/assets/fonts/open-sans-v28-latin-regular.ttf') format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-style: normal;
  font-weight: 600;
  src: url('~@/assets/fonts/open-sans-v28-latin-600.woff2') format('woff2'),
    url('~@/assets/fonts/open-sans-v28-latin-600.woff') format('woff'),
    url('~@/assets/fonts/open-sans-v28-latin-600.ttf') format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-style: normal;
  font-weight: 700;
  src: url('~@/assets/fonts/open-sans-v28-latin-700.woff2') format('woff2'),
    url('~@/assets/fonts/open-sans-v28-latin-700.woff') format('woff'),
    url('~@/assets/fonts/open-sans-v28-latin-700.ttf') format('truetype');
}

#app {
  font-family: OpenSans;
  cursor: default;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  box-sizing: border-box;
}

body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
}

/* Scrollbar settings for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.37) var(--background-color-primary);
}

/* Scrollbar settings for Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 2px;
}

/* Scrollbar settings for Chrome, Edge and Safari */
*::-webkit-scrollbar-track {
  background-color: var(--background-color-primary);
}

/* Scrollbar settings for Chrome, Edge and Safari */
*::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: rgba(0, 0, 0, 0.37);
}
</style>
