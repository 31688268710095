/**
 * The class Assignment stores the following information about an assignment:
 *
 *  - Module Class (e.g. 'masterOutModule')
 *  - Module Index (e.g. '11')
 *  - Channel Class (e.g. 'masterOutModuleChannel')
 *  - Channel Index (e.g. '1')
 *  - Direction (e.g. 'rx')
 *
 */
class Assignment {
  /**
   * The constructor of this class stores the given module class, module index, channel class,
   * channel index and direction of the assignment. The constructor throws an error if the given
   * module class, module index, channel class, channel index or direction is not valid.
   *
   * @param {string} moduleClass Module class of assignment
   * @param {string} moduleIndex Module index of assignment
   * @param {string} channelClass Channel class of assignment
   * @param {string} channelIndex Channel index of assignment
   * @param {string} direction Direction of assignment
   */
  constructor(moduleClass, moduleIndex, channelClass, channelIndex, direction) {
    // Check and store module class
    if (moduleClass) {
      if (typeof moduleClass === 'string') {
        this.moduleClass = moduleClass
      } else {
        throw new Error('moduleClass is not of type string')
      }
    } else {
      throw new Error('moduleClass is not valid')
    }

    // Check and store module index
    if (moduleIndex) {
      if (typeof moduleIndex === 'string') {
        this.moduleIndex = moduleIndex
      } else {
        throw new Error('moduleIndex is not of type string')
      }
    } else {
      throw new Error('moduleIndex is not valid')
    }

    // Check and store channel class (value null is allowed)
    if (channelClass) {
      if (typeof channelClass === 'string') {
        this.channelClass = channelClass
      } else {
        throw new Error('channelClass is not of type string')
      }
    } else {
      this.channelClass = null
    }

    // Check and store channel index (value null is allowed)
    if (channelIndex) {
      if (typeof channelIndex === 'string') {
        this.channelIndex = channelIndex
      } else {
        throw new Error('channelIndex is not of type string')
      }
    } else {
      this.channelIndex = null
    }

    // Check and store direction (value null is allowed)
    if (direction) {
      if (typeof direction === 'string') {
        this.direction = direction
      } else {
        throw new Error('direction is not of type string')
      }
    } else {
      this.direction = null
    }
  }
}

export default Assignment
