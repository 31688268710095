<template>
  <div class="confirm-dialog-container">
    <div class="confirm-dialog-title">{{ getTitle }}</div>
    <div class="confirm-dialog-message">{{ getMessage }}</div>
    <div class="confirm-dialog-buttons">
      <div
        class="confirm-dialog-button"
        :class="isButtonOkPressed ? 'confirm-dialog-button-pressed' : 'confirm-dialog-button-not-pressed'"
        @mousedown.stop.passive="isButtonOkPressed = true"
        @mouseup.stop.passive="isButtonOkPressed = false"
        @mouseleave.stop.passive="isButtonOkPressed = false"
        @touchstart.stop.passive="isButtonOkPressed = true"
        @touchend.stop.passive="isButtonOkPressed = false"
        @touchcancel.stop.passive="isButtonOkPressed = false"
        @click.stop.passive="buttonOkClicked"
      >
        {{ getTextForButtonOk }}
      </div>
      <div
        class="confirm-dialog-button"
        :class="isButtonCancelPressed ? 'confirm-dialog-button-pressed' : 'confirm-dialog-button-not-pressed'"
        @mousedown.stop.passive="isButtonCancelPressed = true"
        @mouseup.stop.passive="isButtonCancelPressed = false"
        @mouseleave.stop.passive="isButtonCancelPressed = false"
        @touchstart.stop.passive="isButtonCancelPressed = true"
        @touchend.stop.passive="isButtonCancelPressed = false"
        @touchcancel.stop.passive="isButtonCancelPressed = false"
        @click.stop.passive="buttonCancelClicked"
      >
        {{ getTextForButtonCancel }}
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n'

export default {
  name: 'ConfirmDialog',
  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    textOkButton: {
      type: String,
      required: false,
      defalut: ''
    },
    textCancelButton: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['cancelEvent', 'okEvent'],
  data() {
    return {
      isButtonOkPressed: false,
      isButtonCancelPressed: false
    }
  },
  computed: {
    getTitle() {
      let text = ''

      if (this.title) {
        text = i18n.global.t(`${this.title}`)
      }

      return text
    },
    getMessage() {
      let text = ''

      if (this.message) {
        text = i18n.global.t(`${this.message}`)
      }

      return text
    },
    getTextForButtonOk() {
      let text = ''

      if (this.textOkButton) {
        text = i18n.global.t(`${this.textOkButton}`)
      } else {
        text = i18n.global.t('ok')
      }

      return text
    },
    getTextForButtonCancel() {
      let text = ''

      if (this.textCancelButton) {
        text = i18n.global.t(`${this.textCancelButton}`)
      } else {
        text = i18n.global.t('cancel')
      }

      return text
    }
  },
  methods: {
    buttonOkClicked() {
      this.$emit('okEvent')
    },
    buttonCancelClicked() {
      this.$emit('cancelEvent')
    }
  }
}
</script>

<style scoped>
.confirm-dialog-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  height: 200px;
  border-radius: 16px;
  background-color: var(--background-color-primary);
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 50px;
  justify-items: stretch;
  align-items: center;
}

.confirm-dialog-title {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  font-family: OpenSans;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  color: var(--text-color-primary);
  margin: 0 0 10px 0;
}

.confirm-dialog-message {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  align-self: start;
  font-family: OpenSans;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: var(--text-color-primary);
  margin: 0 18px;
}

.confirm-dialog-buttons {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
  display: flex;
  flex-direction: row-reverse;
  margin: 0 12px 0 0;
}

.confirm-dialog-button {
  height: 50px;
  padding: 18px 20px 0 20px;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.confirm-dialog-button-not-pressed {
  color: var(--innoxel-blue-color);
}

.confirm-dialog-button-pressed {
  color: var(--innoxel-blue-color-pressed);
}
</style>
