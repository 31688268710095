class GlobalFunctionsUpdater {
  constructor() {
    this.appManager = null
    this.logChanges = true
  }

  setAppManager(appManager) {
    this.appManager = appManager
  }

  /**
   * This method updates the global functions stored in the database.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @param {Boolean} reset Use true if 'WebApp Configuration' is completely new
   */
  async update(webAppConfiguration, reset) {
    if (this.appManager != null) {
      // Variable containing global functions to be stored in database
      let globalFunctions = null

      if (reset) {
        // 'WebApp Configuration' is completely new

        // Get all global functions stored in 'WebApp Configuration'
        if (webAppConfiguration.global != null) {
          if (webAppConfiguration.global.globalFunctions != null) {
            if (webAppConfiguration.global.globalFunctions.idsOfControlElements != null) {
              globalFunctions = {
                id: 1,
                idsOfControlElements: webAppConfiguration.global.globalFunctions.idsOfControlElements
              }
            }
          }
        }
      } else {
        // 'WebApp Configuration' is updated

        // Get all global functions stored in 'WebApp Configuration'
        // and log all changes made to global functions
        globalFunctions = await this.getUpdatedGlobalFunctions(webAppConfiguration)
      }

      // Delete all global functions stored in database
      await this.appManager.deleteGlobalFunctions()

      if (globalFunctions) {
        // Store new/changed global functions into database
        await this.appManager.storeGlobalFunctions(globalFunctions)
      }
    }
  }

  /**
   * This method updates the global functions in the database and also logs the changes.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @returns Array containing globals to be stored in database
   */
  async getUpdatedGlobalFunctions(webAppConfiguration) {
    // Variable containing 'Global Functions IDs' to be stored in database
    let globalFunctionsIds = []

    // Get 'Global Functions IDs' stored in database
    let storedGlobalFunctionsIds = []

    const storedGlobalFunctions = await this.appManager.getGlobalFunctions()

    if (storedGlobalFunctions != null) {
      if (storedGlobalFunctions.idsOfControlElements != null) {
        storedGlobalFunctionsIds = storedGlobalFunctions.idsOfControlElements
      }
    }

    // Get 'Global Functions IDs' stored in 'WebApp Configuration'
    let globalFunctionsIdsInWebAppConfiguration = []

    if (webAppConfiguration.global != null) {
      if (webAppConfiguration.global.globalFunctions != null) {
        if (webAppConfiguration.global.globalFunctions.idsOfControlElements != null) {
          globalFunctionsIdsInWebAppConfiguration = webAppConfiguration.global.globalFunctions.idsOfControlElements
        }
      }
    }

    storedGlobalFunctionsIds.forEach(storedGlobalFunctionsId => {
      // Check if 'Stored Global Functions ID' is also available in 'WebApp Configuration'
      const globalFunctionsIdInWebAppConfiguration = globalFunctionsIdsInWebAppConfiguration.find(
        element => element === storedGlobalFunctionsId
      )

      if (globalFunctionsIdInWebAppConfiguration) {
        // 'Stored Global Functions ID' is also available in 'WebApp Configuration'

        // Add 'Global Functions ID' as defined in 'WebApp Configuration' to
        // variable containing 'Global Functions IDs' to be stored into database
        globalFunctionsIds.push(storedGlobalFunctionsId)
      } else {
        // 'Stored Global Functions ID' is not available in 'WebApp Configuration'

        if (this.logChanges) {
          // Log changes made to global functions

          // Get ID of global function (short version)
          const shortGlobalFunctionsId = this.getShortId(storedGlobalFunctionsId)

          // Define message describing removing global function
          const message = `Global function removed (Global function ID: ${shortGlobalFunctionsId})`

          // Store log entry in database
          this.storeLogEntry(message)
        }
      }
    })

    globalFunctionsIdsInWebAppConfiguration.forEach(globalFunctionsIdInWebAppConfiguration => {
      // Check if 'Global Functions ID in WebApp Configuration' is also available
      // in 'Stored Global Functions IDs'
      const globalFunctionsIdInStoredGlobalRegualtionIds = storedGlobalFunctionsIds.find(
        element => element === globalFunctionsIdInWebAppConfiguration
      )

      if (!globalFunctionsIdInStoredGlobalRegualtionIds) {
        // 'Global Functions ID in WebApp Configuration' is not available in
        // 'Stored Global Functions IDs'

        // Add 'Global Functions ID' as defined in 'WebApp Configuration' to
        // variable containing global function IDs to be stored into database
        globalFunctionsIds.push(globalFunctionsIdInWebAppConfiguration)

        if (this.logChanges) {
          // Log changes made to global functions

          // Get ID of global function (short version)
          const shortGlobalFunctionsId = this.getShortId(globalFunctionsIdInWebAppConfiguration)

          // Define message describing adding global function ID
          const message = `Global function added (Global function ID: ${shortGlobalFunctionsId})`

          // Store log entry in database
          this.storeLogEntry(message)
        }
      }
    })

    const globalFunctions = {
      id: 1,
      idsOfControlElements: globalFunctionsIds
    }

    return globalFunctions
  }

  /**
   * This method returns the first seven characters of the given ID.
   *
   * @param {String} id ID as UUID
   * @returns ID containing first seven characters
   */
  getShortId(id) {
    let shortId = ''

    if (id) {
      shortId = id.substring(0, 7).toLowerCase()
    }

    return shortId
  }

  /**
   * This method creates a log entry containing the current time and the given
   * message. The log entry is stored into the database.
   *
   * @param {String} message Message used for log entry
   */
  storeLogEntry(message) {
    this.appManager.storeLogEntry({ timestamp: new Date(), message: message })
  }
}

export default GlobalFunctionsUpdater
