import ControlElementBase from '@/entities/controlelements/base/controlelementbase'

/**
 * The class ControlElementBlind stores the following information of a control element of type 'blind':
 *
 *  - ID of control element (e.g. '34428997-6762-44E5-B931-A7665EB85260')
 *  - Name of control element (e.g. 'Blind West')
 *  - Assignments of control element (an array containing objects of type 'Assignment')
 *  - Information if control element is enabled or not
 *  - Information if control element is favorite or not
 *  - Current action of blind control
 *  - Relative position of blind
 *  - Relative tilt of slats
 *  - Status of 'Lower Limit' (enabled or disabled)
 *  - Status of 'Cleaning Lock' (enabled or disabled)
 *  - Status of 'Weather Lock' (enabled or disabled)
 *  - Status of 'Lockout Protection' (enabled or disabled)
 *  - Status of 'Manually Overridden' (yes or no)
 *  - Status of 'Position Unreliable' (yes or no)
 *
 * The property rerender shall be incremented after a property (e.g. new blind position) has changed.
 */
class ControlElementBlind extends ControlElementBase {
  /**
   * The constructor of this class stores the given ID, name, assignments, isEnabled and isFavorite.
   * This constructor also also initializes the properties currentAction, relativePosition, relativeTilt,
   * lowerLimit, cleaningLockEnabled, weatherLockEnabled, lockoutProtectionEnabled, manuallyOverridden
   * and positionUnreliable.
   *
   * @param {string} id ID of control element
   * @param {string} name Name of control element
   * @param {Array<Assignment>} assignments Assignments of control element
   * @param {boolean} isEnabled Information if control element is enabled or not
   * @param {boolean} isFavorite Information if control element is favorite or not
   */
  constructor(id, name, assignments, isEnabled, isFavorite) {
    super(id, name, 'blind', assignments, isEnabled, isFavorite)

    this.currentAction = null
    this.relativePosition = 0
    this.relativeTilt = 0
    this.lowerLimitEnabled = false
    this.cleaningLockEnabled = false
    this.weatherLockEnabled = false
    this.lockoutProtectionEnabled = false
    this.manuallyOverriddenEnabled = false
    this.positionUnreliableEnabled = false
  }

  /**
   * This static method returns a clone of the given 'Control Element'. This method returns null if the given
   * 'Control Element' is not from type 'blind'.
   *
   * @param {ControlElementBlind} controlElement 'Control Element' to be cloned
   * @returns Cloned 'Control Element'
   */
  static clone(controlElement) {
    let clonedControlElement = null

    if (controlElement.type === 'blind') {
      clonedControlElement = new ControlElementBlind(
        controlElement.id,
        controlElement.name,
        controlElement.assignments,
        controlElement.isEnabled,
        controlElement.isFavorite
      )
    }

    return clonedControlElement
  }

  /**
   * This method copies the following properties of the given 'Control Element':
   *
   *  - Name
   *  - Assignments
   *
   * The property rerender is incremented (even there is no change). This method does not copy
   * when if the given 'Control Element' is not from type 'blind'.
   *
   * @param {ControlElementBlind} controlElement 'Control Element' to be copied
   */
  update(controlElement) {
    if (controlElement.type === 'blind') {
      this.name = controlElement.name
      this.assignments = controlElement.assignments

      this.rerender = this.rerender + 1
    }
  }

  /**
   * This method sets the given current action. This method increments the value of rerender if the
   * current action has changed.
   *
   * @param {string} currentAction New current action to be stored
   * @returns true if current action has changed otherwise false
   */
  setCurrentAction(currentAction) {
    let hasChanged = false

    if (this.currentAction !== currentAction) {
      hasChanged = true
      this.currentAction = currentAction
      this.rerender = this.rerender + 1
    }

    return hasChanged
  }

  /**
   * This method sets the given relative position. This method increments the value of rerender if the
   * relative position has changed.
   *
   * @param {Number} relativePosition New relative position to be stored
   * @returns true if relative position has changed otherwise false
   */
  setRelativePosition(relativePosition) {
    let hasChanged = false

    if (this.relativePosition !== relativePosition) {
      hasChanged = true
      this.relativePosition = relativePosition
      this.rerender = this.rerender + 1
    }

    return hasChanged
  }

  /**
   * This method sets the given relative tilt. This method increments the value of rerender if the
   * relative tilt has changed.
   *
   * @param {Number} relativeTilt New relative tilt to be stored
   * @returns true if relative tilt has changed otherwise false
   */
  setRelativeTilt(relativeTilt) {
    let hasChanged = false

    if (this.relativeTilt !== relativeTilt) {
      hasChanged = true
      this.relativeTilt = relativeTilt
      this.rerender = this.rerender + 1
    }

    return hasChanged
  }

  /**
   * This method sets the given lower limit status. This method increments the value of rerender
   * if the lower limit status has changed.
   *
   * @param {String} lowerLimitEnabled New lower limit status to be stored
   * @returns true if lower limit status has changed otherwise false
   */
  setLowerLimitStatus(lowerLimitEnabled) {
    let hasChanged = false

    const convertedLowerLimitEnabled = this.convert(lowerLimitEnabled)

    if (this.lowerLimitEnabled !== convertedLowerLimitEnabled) {
      hasChanged = true
      this.lowerLimitEnabled = convertedLowerLimitEnabled
      this.rerender = this.rerender + 1
    }

    return hasChanged
  }

  /**
   * This method sets the given cleaning lock status. This method increments the value of rerender
   * if the cleaning lock status has changed.
   *
   * @param {String} cleaningLockEnabled New cleaning lock status to be stored
   * @returns true if cleaning lock status has changed otherwise false
   */
  setCleaningLockStatus(cleaningLockEnabled) {
    let hasChanged = false

    const convertedCleaningLockEnabled = this.convert(cleaningLockEnabled)

    if (this.cleaningLockEnabled !== convertedCleaningLockEnabled) {
      hasChanged = true
      this.cleaningLockEnabled = convertedCleaningLockEnabled
      this.rerender = this.rerender + 1
    }

    return hasChanged
  }

  /**
   * This method sets the given weather lock status. This method increments the value of rerender
   * if the weather lock status has changed.
   *
   * @param {String} weatherLockEnabled New weather lock status to be stored
   * @returns true if weather lock status has changed otherwise false
   */
  setWeatherLockStatus(weatherLockEnabled) {
    let hasChanged = false

    const convertedWeatherLockEnabled = this.convert(weatherLockEnabled)

    if (this.weatherLockEnabled !== convertedWeatherLockEnabled) {
      hasChanged = true
      this.weatherLockEnabled = convertedWeatherLockEnabled
      this.rerender = this.rerender + 1
    }

    return hasChanged
  }

  /**
   * This method sets the given lockout protection status. This method increments the value of rerender
   * if the lockout protection status has changed.
   *
   * @param {String} lockoutProtectionEnabled New lockout protection status to be stored
   * @returns true if lockout protection status has changed otherwise false
   */
  setLockoutProtectionStatus(lockoutProtectionEnabled) {
    let hasChanged = false

    const convertedLockoutProtectionEnabled = this.convert(lockoutProtectionEnabled)

    if (this.lockoutProtectionEnabled !== convertedLockoutProtectionEnabled) {
      hasChanged = true
      this.lockoutProtectionEnabled = convertedLockoutProtectionEnabled
      this.rerender = this.rerender + 1
    }

    return hasChanged
  }

  /**
   * This method sets the given manually overridden status. This method increments the value of rerender
   * if the manually overridden status has changed.
   *
   * @param {String} manuallyOverriddenEnabled New manually overridden status to be stored
   * @returns true if manually overridden status has changed otherwise false
   */
  setManuallyOverriddenStatus(manuallyOverriddenEnabled) {
    let hasChanged = false

    const convertedManuallyOverriddenEnabled = this.convert(manuallyOverriddenEnabled)

    if (this.manuallyOverriddenEnabled !== convertedManuallyOverriddenEnabled) {
      hasChanged = true
      this.manuallyOverriddenEnabled = convertedManuallyOverriddenEnabled
      this.rerender = this.rerender + 1
    }

    return hasChanged
  }

  /**
   * This method sets the given position unreliable status. This method increments the value of rerender
   * if the position unreliable status has changed.
   *
   * @param {String} positionUnreliableEnabled New position unreliable status to be stored
   * @returns true if position unreliable status has changed otherwise false
   */
  setPositionUnreliableStatus(positionUnreliableEnabled) {
    let hasChanged = false

    const convertedPositionUnreliableEnabled = this.convert(positionUnreliableEnabled)

    if (this.positionUnreliableEnabled !== convertedPositionUnreliableEnabled) {
      hasChanged = true
      this.positionUnreliableEnabled = convertedPositionUnreliableEnabled
      this.rerender = this.rerender + 1
    }

    return hasChanged
  }

  /**
   * This method converts the given value as string to a value as boolean. The following
   * string values are returned as true:
   *
   *  - 'enabled'
   *  - 'yes'
   *
   * All other string values are returned as false.
   *
   * @param {String} valueAsString Value as string to be converted
   * @returns Converted value as string as a value as boolean
   */
  convert(valueAsString) {
    let valueAsBoolean = false

    switch (valueAsString) {
      case 'enabled':
      case 'yes':
        valueAsBoolean = true
        break
      default:
        valueAsBoolean = false
        break
    }

    return valueAsBoolean
  }
}

export default ControlElementBlind
