/**
 * The class ControlElementBase is the base class of all control elements. The base class stores the
 * following information of a control element:
 *
 *  - ID of control element (e.g. '2FFF0722-1E40-48F3-9850-1A12030CD8F4')
 *  - Name of control element (e.g. 'Reading Lamp')
 *  - Type of control element (e.g. 'switch', 'dimmer')
 *  - Assignments of control element (an array containing objects of type 'Assignment')
 *  - Information if control element is enabled or not
 *  - Information if control element is favorite or not
 *
 * The property rerender shall be incremented after a property has changed.
 */
class ControlElementBase {
  /**
   * The constructor of this class stores the given ID, name, type, assignments, isEnabled and
   * isFavorite. This constructor also initializes the property rerender with 0. The constructor
   * throws an error if the given id, name, type, isEnabled or isFavorite is not valid.
   *
   * @param {string} id ID of control element
   * @param {string} name Name of control element
   * @param {string} type Type of control element
   * @param {Array<Assignment>} assignments Assignments of control element
   * @param {boolean} isEnabled Information if control element is enabled or not
   * @param {boolean} isFavorite Information if control element is favorite or not
   */
  constructor(id, name, type, assignments, isEnabled, isFavorite) {
    // Check and store ID
    if (id) {
      if (typeof id === 'string') {
        this.id = id
      } else {
        throw new Error('id is not of type string')
      }
    } else {
      throw new Error('id is not valid')
    }

    // Check and store name
    if (name) {
      if (typeof name === 'string') {
        this.name = name
      } else {
        throw new Error('name is not of type string')
      }
    } else {
      this.name = ''
    }

    // Check and store type
    if (type) {
      if (typeof type === 'string') {
        this.type = type
      } else {
        throw new Error('type is not of type string')
      }
    } else {
      throw new Error('type is not valid')
    }

    // Store assignments without check
    this.assignments = assignments

    // Check if flag 'Reset State After Few Seconds' must be set
    this.resetStateAfterFewSeconds = false

    if (this.assignments) {
      let hasAtLeastOneDirectionRx = false
      let hasAtLeastOneDirection = false

      this.assignments.forEach(assignment => {
        if (assignment) {
          if (assignment.direction) {
            hasAtLeastOneDirection = true

            if (assignment.direction.includes('rx')) {
              hasAtLeastOneDirectionRx = true
            }
          }
        }
      })

      if (hasAtLeastOneDirection && !hasAtLeastOneDirectionRx) {
        this.resetStateAfterFewSeconds = true
      }
    }

    // Check and store isEnabled
    if (isEnabled != null) {
      if (typeof isEnabled === 'boolean') {
        this.isEnabled = isEnabled
      } else {
        throw new Error('isEnabled is not of type boolean')
      }
    } else {
      throw new Error('isEnabled is not valid')
    }

    // Check and store isFavorite
    if (isFavorite != null) {
      if (typeof isFavorite === 'boolean') {
        this.isFavorite = isFavorite
      } else {
        throw new Error('isFavorite is not of type boolean')
      }
    } else {
      throw new Error('isFavorite is not valid')
    }

    // Initialize rerender
    this.rerender = 0
  }
}

export default ControlElementBase
