import ControlElementBase from '@/entities/controlelements/base/controlelementbase'

/**
 * The class ControlElementSwitch stores the following information of a control element of type 'switch':
 *
 *  - ID of control element (e.g. '2FFF0722-1E40-48F3-9850-1A12030CD8F4')
 *  - Name of control element (e.g. 'Reading Lamp')
 *  - Assignments of control element (an array containing objects of type 'Assignment')
 *  - Confirmations of control element
 *  - Information if control element is enabled or not
 *  - Information if control element is favorite or not
 *  - Control element switch configuration for light mode
 *  - Control element switch configuration for dark mode
 *  - Current status of 'Switch' (true or false)
 *
 * The property rerender shall be incremented after a property (e.g. new value for status) has changed.
 */
class ControlElementSwitch extends ControlElementBase {
  /**
   * The constructor of this class stores the given ID, name, assignments, confirmations, isEnabled
   * and isFavorite. This constructor also initializes the properties configurationLightMode,
   * configurationDarkMode and status with null. The property rerender is initialized with 0.
   *
   * @param {string} id ID of control element
   * @param {string} name Name of control element
   * @param {Array<Assignment>} assignments Assignments of control element
   * @param {Array<Confirmation>} confirmations Confirmations of control element
   * @param {boolean} isEnabled Information if control element is enabled or not
   * @param {boolean} isFavorite Information if control element is favorite or not
   */
  constructor(id, name, assignments, confirmations, isEnabled, isFavorite) {
    super(id, name, 'switch', assignments, isEnabled, isFavorite)

    this.configurationLightMode = null
    this.configurationDarkMode = null
    this.confirmations = confirmations
    this.status = false
    this.rerender = 0
  }

  setConfigurationForLightMode(configurationLightMode) {
    this.configurationLightMode = configurationLightMode
  }

  setConfigurationForDarkMode(configurationDarkMode) {
    this.configurationDarkMode = configurationDarkMode
  }

  /**
   * This static method returns a clone of the given 'Control Element'. This method returns null if the given
   * 'Control Element' is not from type 'switch'.
   *
   * @param {ControlElementSwitch} controlElement 'Control Element' to be cloned
   * @returns Cloned 'Control Element'
   */
  static clone(controlElement) {
    let clonedControlElement = null

    if (controlElement.type === 'switch') {
      clonedControlElement = new ControlElementSwitch(
        controlElement.id,
        controlElement.name,
        controlElement.assignments,
        controlElement.confirmations,
        controlElement.isEnabled,
        controlElement.isFavorite
      )

      clonedControlElement.setConfigurationForLightMode(controlElement.configurationLightMode)
      clonedControlElement.setConfigurationForDarkMode(controlElement.configurationDarkMode)
    }

    return clonedControlElement
  }

  /**
   * This method copies the following properties of the given 'Control Element':
   *
   *  - Name
   *  - Assignments
   *  - Configuration for light mode
   *  - Configuration for dark mode
   *
   * The property rerender is incremented (even there is no change). This method does not copy
   * when if the given 'Control Element' is not from type 'switch'.
   *
   * @param {ControlElementSwitch} controlElement 'Control Element' to be copied
   */
  update(controlElement) {
    if (controlElement.type === 'switch') {
      this.name = controlElement.name
      this.assignments = controlElement.assignments
      this.configurationLightMode = controlElement.configurationLightMode
      this.configurationDarkMode = controlElement.configurationDarkMode

      this.rerender = this.rerender + 1
    }
  }

  /**
   * This method sets the given state. This method increments the value of rerender if the
   * state has changed.
   *
   * @param {boolean} state New state to be stored
   * @returns true if state has changed otherwise false
   */
  setState(state) {
    let hasChanged = false

    if (state !== this.state) {
      hasChanged = true
      this.state = state
      this.rerender = this.rerender + 1
    }

    return hasChanged
  }
}

export default ControlElementSwitch
