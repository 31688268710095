class TemperatureData {
  constructor() {
    this.temperatureAirValue = null
    this.temperatureAirUnit = null
    this.temperatureAirFeltValue = null
    this.temperatureAirFeltUnit = null
  }

  setTemperatureAir(value, unit) {
    this.temperatureAirValue = value
    this.temperatureAirUnit = unit
  }

  setTemperatureAirFelt(value, unit) {
    this.temperatureAirFeltValue = value
    this.temperatureAirFeltUnit = unit
  }
}

export default TemperatureData
