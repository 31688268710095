<template>
  <div
    id="sliding-menu"
    :rerender="rerender"
    class="sliding-menu-container sliding-menu-disable-scrollbar"
    :class="sliderIsDisabled ? 'sliding-menu-container-center' : 'sliding-menu-container-left'"
  >
    <div v-for="item in items" :key="item.id" class="sliding-menu-item" @click.stop.passive="select(item)">
      <div :id="item.id" :style="getStyleWidth(item.name)">
        <div v-if="selectedItem.id === item.id" class="sliding-menu-selected-item-text">
          {{ item.name }}
        </div>
        <div v-else class="sliding-menu-item-text">
          {{ item.name }}
        </div>
        <div v-if="selectedItem.id === item.id" class="sliding-menu-item-underline" />
        <div v-else class="sliding-menu-item-invisible-underline" />
      </div>
    </div>
  </div>
</template>

<script>
import { elementScrollIntoView } from 'seamless-scroll-polyfill'

export default {
  name: 'SlidingMenu',
  props: {
    items: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    },
    selectedItemId: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['itemChangedEvent', 'scrollbarEnabledEvent', 'scrollbarDisabledEvent', 'scrollbarPositionChangedEvent'],
  data() {
    return {
      elementSlidingMenu: null,
      selectedItem: { id: '', name: '' },
      sliderIsDisabled: false,
      centerSeletedItemNeeded: false,
      enableOrDisableSliderCheckNeeded: true,
      rerenderCounter: 0,
      resizeTimer: null
    }
  },
  updated() {
    if (this.enableOrDisableSliderCheckNeeded) {
      this.enableOrDisableSliderCheckNeeded = false
      this.enableOrDisableSlider()
    }

    if (this.centerSeletedItemNeeded) {
      this.centerSeletedItemNeeded = false
      this.centerSelectedItem()
    }
  },
  mounted() {
    this.elementSlidingMenu = document.getElementById('sliding-menu')

    window.addEventListener('resize', this.resizeHandler)

    if (this.elementSlidingMenu != null) {
      this.elementSlidingMenu.onscroll = () => {
        this.emitScrollbarPositionEvent()
      }
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  watch: {
    items: function () {
      this.initialize()
    },
    selectedItem: function () {
      this.centerSelectedItem()
    }
  },
  computed: {
    rerender() {
      return this.rerenderCounter
    }
  },
  methods: {
    resizeHandler() {
      this.clearResizeTimer()
      this.runResizeTimer()
    },
    runResizeTimer() {
      let count = 0

      this.resizeTimer = setInterval(() => {
        if (count <= 20) {
          this.enableOrDisableSlider()
          count += 1
        } else {
          this.clearResizeTimer()
        }
      }, 50)
    },
    clearResizeTimer() {
      if (this.resizeTimer != null) {
        clearInterval(this.resizeTimer)
        this.resizeTimer = null
      }
    },
    getStyleWidth(text) {
      let styleWidth = ''

      if (text) {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        context.font = '600 16px OpenSans'
        const textMetrics = context.measureText(text)
        let width = Math.ceil(textMetrics.width + 30)
        styleWidth = `width: ${width}px;`
      }

      return styleWidth
    },
    emitScrollbarPositionEvent() {
      if (this.elementSlidingMenu != null) {
        const scrollLeft = this.elementSlidingMenu.scrollLeft
        const invisiblePart = this.elementSlidingMenu.scrollWidth - this.elementSlidingMenu.clientWidth
        const scrollbarPosition = (scrollLeft / invisiblePart) * 100

        this.$emit('scrollbarPositionChangedEvent', scrollbarPosition)
      }
    },
    select(item) {
      if (this.selectedItem.id !== item.id) {
        this.selectedItem = item
        this.$emit('itemChangedEvent', item)
      }
    },
    enableOrDisableSlider() {
      if (this.elementSlidingMenu != null) {
        const scrollWidth = this.elementSlidingMenu.scrollWidth
        const clientWidth = this.elementSlidingMenu.clientWidth

        if (scrollWidth <= clientWidth) {
          this.sliderIsDisabled = true
          this.$emit('scrollbarDisabledEvent')
        } else {
          this.sliderIsDisabled = false
          this.$emit('scrollbarEnabledEvent')
          this.emitScrollbarPositionEvent()
        }

        this.centerSeletedItemNeeded = true
        this.rerenderCounter = this.rerenderCounter + 1
      }
    },
    initialize() {
      if (this.items !== null && this.items.length > 0) {
        let selectedItemSet = false

        if (this.selectedItemId != null) {
          const selectedItem = this.items.find(item => item.id === this.selectedItemId)

          if (selectedItem != null) {
            this.selectedItem = selectedItem
            selectedItemSet = true
          }
        }

        if (!selectedItemSet) {
          this.selectedItem = this.items[0]
        }
      } else {
        this.selectedItem = null
      }

      this.enableOrDisableSliderCheckNeeded = true
      this.$emit('itemChangedEvent', this.selectedItem)
    },
    centerSelectedItem() {
      if (this.selectedItem !== null) {
        let selectedItem = document.getElementById(this.selectedItem.id)
        if (selectedItem !== null) {
          elementScrollIntoView(selectedItem, { behavior: 'smooth', inline: 'center' })
        }
      }
    }
  }
}
</script>

<style scoped>
.sliding-menu-container {
  display: flex;
  overflow: auto;
  background-color: var(--background-color-primary);
}

.sliding-menu-container-left {
  width: auto;
  justify-content: flex-start;
}

.sliding-menu-container-center {
  width: 100%;
  justify-content: center;
}

.sliding-menu-disable-scrollbar {
  -ms-overflow-style: none; /* Hide scroolbar for Internet Explorer and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.sliding-menu-disable-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.sliding-menu-item {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  white-space: nowrap;
}

.sliding-menu-item-text {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: normal;
  margin: 0px 15px 11px 15px;
  color: var(--text-color-quaternary);
}

.sliding-menu-selected-item-text {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  margin: 0px 15px 11px 15px;
  color: var(--text-color-primary);
}

.sliding-menu-item-invisible-underline {
  height: 5px;
  margin: 0 15px;
}

.sliding-menu-item-underline {
  height: 5px;
  background-color: var(--innoxel-blue-color);
  margin: 0 15px;
}
</style>
