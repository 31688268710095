class GlobalScenesUpdater {
  constructor() {
    this.appManager = null
    this.logChanges = true
  }

  setAppManager(appManager) {
    this.appManager = appManager
  }

  /**
   * This method updates the global scenes stored in the database.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @param {Boolean} reset Use true if 'WebApp Configuration' is completely new
   */
  async update(webAppConfiguration, reset) {
    if (this.appManager != null) {
      // Variable containing global scenes to be stored in database
      let globalScenes = null

      if (reset) {
        // 'WebApp Configuration' is completely new

        // Get all global scenes stored in 'WebApp Configuration'
        if (webAppConfiguration.global != null) {
          if (webAppConfiguration.global.globalScenes != null) {
            if (webAppConfiguration.global.globalScenes.idsOfControlElements != null) {
              globalScenes = {
                id: 1,
                idsOfControlElements: webAppConfiguration.global.globalScenes.idsOfControlElements
              }
            }
          }
        }
      } else {
        // 'WebApp Configuration' is updated

        // Get all global scenes stored in 'WebApp Configuration'
        // and log all changes made to global scenes
        globalScenes = await this.getUpdatedGlobalScenes(webAppConfiguration)
      }

      // Delete all global scenes stored in database
      await this.appManager.deleteGlobalScenes()

      if (globalScenes) {
        // Store new/changed global scenes into database
        await this.appManager.storeGlobalScenes(globalScenes)
      }
    }
  }

  /**
   * This method updates the global scenes in the database and also logs the changes.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @returns Array containing globals to be stored in database
   */
  async getUpdatedGlobalScenes(webAppConfiguration) {
    // Variable containing 'Global Scenes IDs' to be stored in database
    let globalScenesIds = []

    // Get 'Global Scenes IDs' stored in database
    let storedGlobalScenesIds = []

    const storedGlobalScenes = await this.appManager.getGlobalScenes()

    if (storedGlobalScenes != null) {
      if (storedGlobalScenes.idsOfControlElements != null) {
        storedGlobalScenesIds = storedGlobalScenes.idsOfControlElements
      }
    }

    // Get 'Global Scenes IDs' stored in 'WebApp Configuration'
    let globalScenesIdsInWebAppConfiguration = []

    if (webAppConfiguration.global != null) {
      if (webAppConfiguration.global.globalScenes != null) {
        if (webAppConfiguration.global.globalScenes.idsOfControlElements != null) {
          globalScenesIdsInWebAppConfiguration = webAppConfiguration.global.globalScenes.idsOfControlElements
        }
      }
    }

    storedGlobalScenesIds.forEach(storedGlobalScenesId => {
      // Check if 'Stored Global Scenes ID' is also available in 'WebApp Configuration'
      const globalScenesIdInWebAppConfiguration = globalScenesIdsInWebAppConfiguration.find(
        element => element === storedGlobalScenesId
      )

      if (globalScenesIdInWebAppConfiguration) {
        // 'Stored Global Scenes ID' is also available in 'WebApp Configuration'

        // Add 'Global Scenes ID' as defined in 'WebApp Configuration' to
        // variable containing 'Global Scenes IDs' to be stored into database
        globalScenesIds.push(storedGlobalScenesId)
      } else {
        // 'Stored Global Scenes ID' is not available in 'WebApp Configuration'

        if (this.logChanges) {
          // Log changes made to global scenes

          // Get ID of global scene (short version)
          const shortGlobalScenesId = this.getShortId(storedGlobalScenesId)

          // Define message describing removing global scene
          const message = `Global scene removed (Global scene ID: ${shortGlobalScenesId})`

          // Store log entry in database
          this.storeLogEntry(message)
        }
      }
    })

    globalScenesIdsInWebAppConfiguration.forEach(globalScenesIdInWebAppConfiguration => {
      // Check if 'Global Scenes ID in WebApp Configuration' is also available
      // in 'Stored Global Scenes IDs'
      const globalScenesIdInStoredGlobalRegualtionIds = storedGlobalScenesIds.find(
        element => element === globalScenesIdInWebAppConfiguration
      )

      if (!globalScenesIdInStoredGlobalRegualtionIds) {
        // 'Global Scenes ID in WebApp Configuration' is not available in
        // 'Stored Global Scenes IDs'

        // Add 'Global Scenes ID' as defined in 'WebApp Configuration' to
        // variable containing global scene IDs to be stored into database
        globalScenesIds.push(globalScenesIdInWebAppConfiguration)

        if (this.logChanges) {
          // Log changes made to global scenes

          // Get ID of global scene (short version)
          const shortGlobalScenesId = this.getShortId(globalScenesIdInWebAppConfiguration)

          // Define message describing adding global scene ID
          const message = `Global scene added (Global scene ID: ${shortGlobalScenesId})`

          // Store log entry in database
          this.storeLogEntry(message)
        }
      }
    })

    const globalScenes = {
      id: 1,
      idsOfControlElements: globalScenesIds
    }

    return globalScenes
  }

  /**
   * This method returns the first seven characters of the given ID.
   *
   * @param {String} id ID as UUID
   * @returns ID containing first seven characters
   */
  getShortId(id) {
    let shortId = ''

    if (id) {
      shortId = id.substring(0, 7).toLowerCase()
    }

    return shortId
  }

  /**
   * This method creates a log entry containing the current time and the given
   * message. The log entry is stored into the database.
   *
   * @param {String} message Message used for log entry
   */
  storeLogEntry(message) {
    this.appManager.storeLogEntry({ timestamp: new Date(), message: message })
  }
}

export default GlobalScenesUpdater
