/**
 * The class ControlElementsAccessor is used for accessing the 'Control
 * Elements' stored in the appropriate object store (IndexedDB).
 */
class ControlElementsAccessor {
  /**
   * The constructor of this class stores the references to the database and to
   * the object store containing the 'Control Elements'.
   *
   * @param {any} database Reference to database.
   * @param {any} objectStore Reference to object store containing 'Control Elements'.
   */
  constructor(database, objectStore) {
    this.database = database
    this.objectStore = objectStore
  }

  /**
   * This method writes the given 'Control Elements' to the object store. 'Control
   * Elements' with the same ID are overwritten.
   *
   *  - Writing succeed: Returns empty Promise resolve.
   *  - Writing failed:  Returns Promise reject containing error message.
   *
   * @param {any} controlElements 'Control Elements' to be stored to object store.
   * @returns Promise containing result.
   */
  async write(controlElements) {
    return new Promise((resolve, reject) => {
      if (this.database != null && this.objectStore != null) {
        // Database and object store are valid

        // Create transaction for writing 'Control Elements'
        const transaction = this.database.transaction(this.objectStore, 'readwrite')

        // Get object store for writing 'Control Elements'
        const objectStore = transaction.objectStore(this.objectStore)

        // Put all 'Control Elements' to object store
        controlElements.forEach(controlElement => {
          objectStore.put(controlElement)
        })

        /**
         * This event handler is called when writing 'Control Elements' to the
         * object store succeed. It returns an empty Promise resolve.
         */
        transaction.oncomplete = () => {
          resolve()
        }

        /**
         * This event handler is called when writing 'Control Elements' to the
         * object store failed. This event handler returns a Promise reject
         * containing the error message.
         *
         * @param {Event} event Generated by objectStore.put()
         */
        transaction.onerror = event => {
          const errorMessage = `Error: Writing control elements failed! (${event.target.error})`
          reject(errorMessage)
        }
      } else {
        // Database and/or object store are invalid
        const errorMessage = 'Error: Writing control elements failed! Invalid database or object store.'
        reject(errorMessage)
      }
    })
  }

  /**
   * This method reads the 'Control Elements' from the object store.
   *
   *  - Reading succeed: Returns Promise resolve containing stored 'Control Elements'.
   *  - Reading failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async read() {
    return new Promise((resolve, reject) => {
      if (this.database != null && this.objectStore != null) {
        // Database and object store are valid

        // Create transaction for reading 'Control Elements'
        const transaction = this.database.transaction(this.objectStore, 'readonly')

        // Get object store for reading 'Control Elements'
        const objectStore = transaction.objectStore(this.objectStore)

        // Get 'Control Elements' from object store
        const request = objectStore.getAll()

        /**
         * This event handler is called when reading the 'Control Elements' from
         * the object store succeed. This event handler returns a Promise resolve
         * containing the 'Control Elements'.
         *
         * @param {Event} event Generated by objectStore.getAll()
         */
        request.onsuccess = event => {
          let controlElements = []

          // Get 'Control Elements' stored in event
          if (event.target.result.length > 0) {
            controlElements = event.target.result
          }

          resolve(controlElements)
        }

        /**
         * This event handler is called when reading the 'Control Elements' from
         * the object store failed. This event handler returns a Promise reject
         * containing the error message.
         *
         * @param {Event} event Generated by objectStore.getAll()
         */
        request.onerror = event => {
          const errorMessage = `Error: Reading control elements failed! (${event.target.error})`
          reject(errorMessage)
        }
      } else {
        // Database and/or object store are invalid
        const errorMessage = 'Error: Reading control elements failed! Invalid database or object store.'
        reject(errorMessage)
      }
    })
  }

  /**
   * This method deletes all 'Control Elements' from the object store.
   *
   *  - Deleting succeed: Returns empty Promise resolve.
   *  - Deleting failed:  Returns Promise reject containing error message.
   *
   * @returns Promise containing result.
   */
  async clear() {
    return new Promise((resolve, reject) => {
      if (this.database != null && this.objectStore != null) {
        // Database and object store are valid

        // Create transaction for deleting 'Control Elements'
        const transaction = this.database.transaction(this.objectStore, 'readwrite')

        // Get object store for deleting 'Control Elements'
        const objectStore = transaction.objectStore(this.objectStore)

        // Delete 'Control Elements' from object store
        const request = objectStore.clear()

        /**
         * This event handler is called when deleting 'Control Elements' from the object store
         * succeed. It returns an empty Promise resolve.
         */
        transaction.oncomplete = () => {
          resolve()
        }

        /**
         * This event handler is called when deleting 'Control Elements' from the object store
         * failed. This event handler returns a Promise reject containing the error message.
         *
         * @param {Event} event Generated by objectStore.clear()
         */
        request.onerror = event => {
          const errorMessage = `Error: Deleting control elements failed! (${event.target.error})`
          reject(errorMessage)
        }
      } else {
        // Database and/or object store are invalid
        const errorMessage = 'Error: Deleting control elements failed! Invalid database or object store.'
        reject(errorMessage)
      }
    })
  }

  /**
   * This method updates the given 'Control Element' in the object store.
   *
   *  - Writing succeed: Returns empty Promise resolve.
   *  - Writing failed:  Returns Promise reject containing error message.
   *
   * @param {any} controlElement 'Control Element' to be updated in object store.
   * @returns Promise containing result.
   */
  async update(controlElement) {
    return new Promise((resolve, reject) => {
      if (this.database != null && this.objectStore != null) {
        // Database and object store are valid

        // Create transaction for updating 'Control Element'
        const transaction = this.database.transaction(this.objectStore, 'readwrite')

        // Get object store for updating 'Control Element'
        const objectStore = transaction.objectStore(this.objectStore)

        // Put 'Control Elements' to object store
        objectStore.put(controlElement)

        /**
         * This event handler is called when updating 'Control Element' to the
         * object store succeed. It returns an empty Promise resolve.
         */
        transaction.oncomplete = () => {
          resolve()
        }

        /**
         * This event handler is called when updating 'Control Element' to the
         * object store failed. This event handler returns a Promise reject
         * containing the error message.
         *
         * @param {Event} event Generated by objectStore.put()
         */
        transaction.onerror = event => {
          const errorMessage = `Error: Updating control element failed! (${event.target.error})`
          reject(errorMessage)
        }
      } else {
        // Database and/or object store are invalid
        const errorMessage = 'Error: Updating control element failed! Invalid database or object store.'
        reject(errorMessage)
      }
    })
  }
}

export default ControlElementsAccessor
