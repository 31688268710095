/**
 * The class Room stores the following information about a room:
 *
 *  - ID of room (e.g. 'C7E4DFBD-CC20-44B2-B4B2-6FC93E9CC50D')
 *  - Name of room (e.g. 'Bathroom')
 *  - IDs of all control elements assigned to room (e.g. [ 'CDDDE652-2953-4C2B-911C-8A2E5C19DF33',
 *                                                         '41E1EEE1-D4AA-49E0-A7F2-87B43CD42FFB',
 *                                                         '5A013DF3-3DCE-41A1-ACA0-A55F752A4781' ])
 *
 */
class Room {
  /**
   * The constructor of this class stores the given ID, name and array containing the IDs of control
   * elements assigned to the room. The constructor throws an error if the given ID, name or array
   * containing the IDs of all control element is not valid.
   *
   * @param {string} id Unique ID of room
   * @param {string} name Name of room
   * @param {Array<string>} idsOfControlElements Array containing IDs of control elements assigned to room
   */
  constructor(id, name, idsOfControlElements) {
    this.idsOfControlElements = []

    // Check and store ID
    if (id) {
      if (typeof id === 'string') {
        this.id = id
      } else {
        throw new Error('id is not of type string')
      }
    } else {
      throw new Error('id is not valid')
    }

    // Check and store name
    if (name) {
      if (typeof name === 'string') {
        this.name = name
      } else {
        throw new Error('name is not of type string')
      }
    } else {
      throw new Error('name is not valid')
    }

    // Check and store array containing IDs of control elements
    if (idsOfControlElements instanceof Array) {
      if (idsOfControlElements.every(controlElementId => !!controlElementId)) {
        if (idsOfControlElements.every(controlElementId => typeof controlElementId === 'string')) {
          this.addIdsOfControlElements(idsOfControlElements)
        } else {
          throw new Error('At least one entry of idsOfControlElement is not of type string')
        }
      } else {
        throw new Error('At least one entry of idsOfControlElement is not valid')
      }
    } else {
      throw new Error('idsOfControlElement is not an instance of Array')
    }
  }

  /**
   * This method adds the given IDs of control elements to the array containing all
   * IDs of control elements. This method guarantees that there are no duplicated
   * IDs are added to the array containing all IDs of control elements.
   *
   * @param {Array<string>} idsOfControlElements Array containing IDs of control elements assigned to room
   */
  addIdsOfControlElements(idsOfControlElements) {
    idsOfControlElements.forEach(controlElementId => {
      const controlElementIdExists = this.idsOfControlElements.find(
        tmpControlElementId => tmpControlElementId === controlElementId
      )

      if (!controlElementIdExists) {
        this.idsOfControlElements.push(controlElementId)
      } else {
        console.log(controlElementId, ' already exists in rooms')
      }
    })
  }
}

export default Room
