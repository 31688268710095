class ControlElementsUpdater {
  constructor() {
    this.appManager = null
    this.logChanges = true
  }

  setAppManager(appManager) {
    this.appManager = appManager
  }

  /**
   * This method updates the control elements stored in the database.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @param {Boolean} reset Use true if 'WebApp Configuration' is completely new
   */
  async update(webAppConfiguration, reset) {
    if (this.appManager != null) {
      // Variable containing control elements to be stored in database
      let controlElements = []

      if (reset) {
        // 'WebApp Configuration' is completely new

        // Get all control elements stored in 'WebApp Configuration'
        controlElements = webAppConfiguration.controlElements
      } else {
        // 'WebApp Configuration' is updated

        // Get all control elements stored in 'WebApp Configuration'
        // and log all changes made to control elements
        controlElements = await this.getUpdatedControlElements(webAppConfiguration)
      }

      // Delete all control elements stored in database
      await this.appManager.deleteControlElements()

      // Store new/changed control elements into database
      await this.appManager.storeControlElements(controlElements)
    }
  }

  /**
   * This method updates the control elements in the database and also logs the changes.
   *
   * @param {Object} webAppConfiguration WebApp Configuration
   * @returns Array containing control elements to be stored in database
   */
  async getUpdatedControlElements(webAppConfiguration) {
    // Variable containing control elements to be stored in database
    let controlElements = []

    // Get control elements stored in database
    const storedControlElements = await this.appManager.getStoredControlElements()

    // Get control elements stored in 'WebApp Configuration'
    const controlElementsInWebAppConfiguration = webAppConfiguration.controlElements

    storedControlElements.forEach(storedControlElement => {
      // Check if 'Stored Control Element' is also available in 'WebApp Configuration'
      let controlElementInWebAppConfiguration = controlElementsInWebAppConfiguration.find(
        element => element.id === storedControlElement.id
      )

      if (controlElementInWebAppConfiguration) {
        // 'Stored Control Element' is also available in 'WebApp Configuration'

        // Take over values of 'isFavorite' and 'isEnabled'
        controlElementInWebAppConfiguration.isFavorite = storedControlElement.isFavorite
        controlElementInWebAppConfiguration.isEnabled = storedControlElement.isEnabled

        // Add 'Control Element' as defined in 'WebApp Configuration' to
        // variable containing control elements to be stored into database
        controlElements.push(controlElementInWebAppConfiguration)

        if (this.logChanges) {
          // Log changes made to control elements

          // Get name of 'Stored Control Element' and of control element stored in 'WebApp Configuration'
          const nameOfStoredControlElement = storedControlElement.name
          const nameOfControlElementInWebAppConfiguration = controlElementInWebAppConfiguration.name

          // Check if name of control element has changed
          const nameOfControlElementChanged = nameOfStoredControlElement !== nameOfControlElementInWebAppConfiguration

          // Also log the changes of the following properties (depending on type!!):
          //  - assignments
          //  - configurationDarkMode
          //  - configurationLightMode
          //  - ...

          if (nameOfControlElementChanged) {
            // There are changes made to control element

            // Define counter of changes
            let counter = 0

            // Get ID of control element (short version)
            const shortControlElementId = this.getShortId(storedControlElement.id)

            // Define message describing changes made to control element
            let message = `Control Element '${storedControlElement.name}' has been changed (Control Element ID: ${shortControlElementId}):\n`

            if (nameOfStoredControlElement !== nameOfControlElementInWebAppConfiguration) {
              // Name of control element has changed

              // Increment counter of changes
              counter += 1

              // Add text describing name change to message
              message += `\n${counter}) Name changed from '${nameOfStoredControlElement}' to '${nameOfControlElementInWebAppConfiguration}'`
            }

            // Store log entry in database
            this.storeLogEntry(message)
          }
        }
      } else {
        // 'Stored Control Element' is not available in 'WebApp Configuration'

        if (this.logChanges) {
          // Log changes made to control elements

          // Get ID of control element (short version)
          const shortControlElementId = this.getShortId(storedControlElement.id)

          // Define message describing removing control element
          const message = `Control Element '${storedControlElement.name}' removed (Control Element ID: ${shortControlElementId})`

          // Store log entry in database
          this.storeLogEntry(message)
        }
      }
    })

    controlElementsInWebAppConfiguration.forEach(controlElementInWebAppConfiguration => {
      // Check if 'Control Element in WebApp Configuration' is also available in 'Stored Control Elements'
      const controlElementInStoredControlElements = storedControlElements.find(
        element => element.id === controlElementInWebAppConfiguration.id
      )

      if (!controlElementInStoredControlElements) {
        // 'Control Element in WebApp Configuration' is not available in 'Stored Control Elements'

        // Add 'Control Element' as defined in 'WebApp Configuration' to
        // variable containing control element to be stored into database
        controlElements.push(controlElementInWebAppConfiguration)

        if (this.logChanges) {
          // Log changes made to control elements

          // Get ID of control elements (short version)
          const shortControlElementId = this.getShortId(controlElementInWebAppConfiguration.id)

          // Define message describing adding control element
          const message = `Control Element '${controlElementInWebAppConfiguration.name}' added (Control Element ID: ${shortControlElementId})`

          // Store log entry in database
          this.storeLogEntry(message)
        }
      }
    })

    return controlElements
  }

  /**
   * This method returns the first sevent characters of the given ID.
   *
   * @param {String} id ID as UUID
   * @returns ID containing first seven characters
   */
  getShortId(id) {
    let shortId = ''

    if (id) {
      shortId = id.substring(0, 7).toLowerCase()
    }

    return shortId
  }

  /**
   * This method creates a log entry containing the current time and the given
   * message. The log entry is stored into the database.
   *
   * @param {String} message Message used for log entry
   */
  storeLogEntry(message) {
    this.appManager.storeLogEntry({ timestamp: new Date(), message: message })
  }
}

export default ControlElementsUpdater
